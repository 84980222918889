<template>
  <v-card flat>
    <div
      v-if="orderProcessing.received_method === 1"
      class="delivery-section-wrapper"
    >
      <v-btn-toggle
        v-model="shippingInfo.self_shipping"
        color="primary"
        disabled
        group
      >
        <v-btn
          :disabled="
            !(orderProcessing.status === 1 || orderProcessing.status === 3)
          "
          :value="0"
          @click="getShippingPartners"
        >
          Đối tác vận chuyển
        </v-btn>

        <v-btn
          :disabled="
            !(orderProcessing.status === 1 || orderProcessing.status === 3)
          "
          :value="1"
        >
          Nhân viên giao
        </v-btn>
      </v-btn-toggle>

      <v-divider class="my-4"></v-divider>

      <div v-if="shippingInfo.self_shipping === 0" class="d-flex mt-6">
        <form-delivery style="width: 50%"></form-delivery>

        <table-delivery class="table-delivery-container"></table-delivery>
      </div>

      <div v-if="shippingInfo.self_shipping === 1" class="d-flex mt-6">
        <form-self-delivery style="width: 700px"></form-self-delivery>
      </div>
    </div>
  </v-card>
</template>

<script>
import FormDelivery from "./components/FormDelivery.vue";
import FormSelfDelivery from "./components/FormSelfDelivery.vue";
import TableDelivery from "./components/TableDelivery.vue";

export default {
  components: {
    FormDelivery,
    FormSelfDelivery,
    TableDelivery
  },

  computed: {
    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    shippingInfo() {
      return this.$store.getters["ORDER_PROCESSING/shippingInfo"];
    }
  },

  methods: {
    getShippingPartners() {
      this.$store.dispatch("ORDER_PROCESSING/getShippingPartners", {
        ...this.orderProcessing,
        ...this.shippingInfo
      });
    }
  }
};
</script>
