import { render, staticRenderFns } from "./TabParticipants.vue?vue&type=template&id=07ccc9dc&scoped=true&"
import script from "./TabParticipants.vue?vue&type=script&lang=js&"
export * from "./TabParticipants.vue?vue&type=script&lang=js&"
import style0 from "./TabParticipants.vue?vue&type=style&index=0&id=07ccc9dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ccc9dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VChip,VDataTable,VDialog,VIcon,VImg})
