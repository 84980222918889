<template>
  <tp-modal name="modal-form-address" width="70%" max-width="640px">
    <v-form ref="form" v-model="isValid" lazy-validation>
      <v-card flat>
        <!-- Start: Title and actions -->
        <v-card-title class="pb-4">
          <div class="font-weight-bold">Địa chỉ nhận hàng</div>
          <v-spacer></v-spacer>
          <v-btn
            class="cyan lighten-5 rounded-lg mr-2"
            color="primary"
            text
            @click="submitForm"
          >
            Xong
          </v-btn>
          <v-btn icon @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <!-- End: Title and actions -->
        <!-- Start: Main content -->
        <v-container class="grey lighten-3 pa-5" fluid>
          <v-card flat class="px-5 py-5 text-body-1">
            <div class="form-group">
              <div class="font-weight-bold mb-1">Họ và Tên</div>
              <tp-text-field
                v-model.trim="internalSelectedAddress.name"
                dense
                outlined
                placeholder="Nhập họ và tên người nhận"
                validate="required"
              ></tp-text-field>
            </div>

            <div class="form-group">
              <div class="font-weight-bold mb-1">Số điện thoại</div>
              <tp-text-field
                v-model.trim="internalSelectedAddress.phone"
                dense
                outlined
                placeholder="Nhập số điện thoại người nhận"
                validate="required|tel"
              ></tp-text-field>
            </div>

            <div class="form-group">
              <div class="font-weight-bold mb-1">Tỉnh/Thành phố</div>
              <tp-autocomplete
                v-model="internalSelectedAddress.tinh_thanhpho"
                class="text-body-1"
                clearable
                dense
                flat
                :items="cities"
                item-text="name"
                item-value="name"
                :menu-props="{ nudgeTop: '-40px' }"
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Tỉnh/Thành phố"
                solo
                validate="required"
              ></tp-autocomplete>
            </div>

            <div class="form-group">
              <div class="font-weight-bold mb-1">Quận Huyện</div>
              <tp-autocomplete
                v-model="internalSelectedAddress.quan_huyen"
                class="text-body-1"
                clearable
                dense
                flat
                :items="districts"
                item-text="name"
                item-value="name"
                :menu-props="{ nudgeTop: '-40px' }"
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Quận/Huyện"
                solo
                validate="required"
              ></tp-autocomplete>
            </div>

            <div class="form-group">
              <div class="font-weight-bold mb-1">Phường/Xã</div>
              <tp-autocomplete
                v-model="internalSelectedAddress.phuong_xa"
                class="text-body-1"
                clearable
                dense
                flat
                :items="wards"
                item-text="name"
                item-value="name"
                :menu-props="{ nudgeTop: '-40px' }"
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Phường/Xã"
                solo
                validate="required"
              ></tp-autocomplete>
            </div>

            <div class="form-group">
              <div class="font-weight-bold mb-1">Địa chỉ cụ thể</div>
              <tp-text-field
                outlined
                dense
                placeholder="Tòa nhà, tên đường,..."
                v-model="internalSelectedAddress.so_nha"
                validate="required"
              ></tp-text-field>
            </div>
          </v-card>
        </v-container>
        <!-- End: Main content -->
      </v-card>
    </v-form>
  </tp-modal>
</template>
<script>
export default {
  props: {
    employees: {
      type: Array
    },
    selectedAddress: null
  },

  data() {
    return {
      dialog: false,
      isValid: false,
      internalSelectedAddress: {
        name: null,
        phone: null,
        so_nha: null,
        phuong_xa: null,
        tinh_thanhpho: null,
        quan_huyen: null
      }
    };
  },

  async created() {
    await this.$store.dispatch("LOCAL_ADMINISTRATION/getCities");
    this.internalSelectedAddress = { ...this.selectedAddress };
  },

  computed: {
    cities() {
      return this.$store.getters["LOCAL_ADMINISTRATION/cities"];
    },

    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    },

    customerAddress() {
      return this.$store.getters["CUSTOMER/customerAddress"];
    },

    customerStatusRequest() {
      return this.$store.getters["CUSTOMER/statusRequest"];
    },

    districts() {
      return this.$store.getters["LOCAL_ADMINISTRATION/districts"];
    },

    wards() {
      return this.$store.getters["LOCAL_ADMINISTRATION/wards"];
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-form-address"
      });
    },

    async getDistrictsOfCity(city) {
      await this.$store.dispatch(
        "LOCAL_ADMINISTRATION/getDistrictsOfCity",
        city
      );
    },

    async getWardsOfDistrict(district) {
      await this.$store.dispatch(
        "LOCAL_ADMINISTRATION/getWardsOfDistrict",
        district
      );
    },

    async submitForm() {
      await this.$refs.form.validate();
      if (this.isValid) {
        this.$emit("addNewAddress", this.internalSelectedAddress);
        this.$modal.hide({ name: "modal-form-address" });
      }
    }
  },
  watch: {
    "internalSelectedAddress.tinh_thanhpho"(val) {
      if (val) {
        this.getDistrictsOfCity(val);
      }
    },
    "internalSelectedAddress.quan_huyen"(val) {
      if (val) {
        this.getWardsOfDistrict(val);
      }
    },
    selectedAddress(val) {
      this.internalSelectedAddress = { ...val };
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 500px;
}
.image {
  position: relative;
  a {
    padding-top: 70px;
    width: 70px;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &:hover .icon-remove-image {
    opacity: 1;
  }
  .icon-remove-image {
    opacity: 0;
    transition: all ease 0.2s;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    color: #868686;
    background: #ffffff4a;
    border: 1px solid #868686;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
</style>
