<template>
  <div class="form-delivery-wrapper rounded text-body-1">
    <div class="mb-4">
      <div class="mb-1 font-weight-medium">Nhân viên giao</div>

      <tp-autocomplete
        v-model="shippingInfo.shipping_by_id"
        :items="saleEmployee"
        hide-details="auto"
        item-text="name"
        item-value="id"
        placeholder="Chọn nhân viên giao hàng"
        style="width: 50%"
        validate="required"
      ></tp-autocomplete>
    </div>

    <div class="mb-4">
      <div class="mb-1 font-weight-medium">Quãng đường (km)</div>

      <tp-text-field
        v-model="shippingInfo.distance"
        hide-details="auto"
        style="width: 50%"
        type="number"
      ></tp-text-field>
    </div>

    <div class="mb-4">
      <div class="mb-1 font-weight-medium">Thời gian giao hàng dự kiến</div>

      <date-picker
        v-model="shippingInfo.shipping_est_date"
        class="custom-date-picker"
        format="DD/MM/YYYY HH:mm"
        style="width: 50%"
        type="datetime"
        value-type="YYYY-MM-DD HH:mm:ss"
      ></date-picker>
    </div>

    <div
      v-if="
        orderProcessing.status === 1 ||
          orderProcessing.status === 3 ||
          orderProcessing.status === 5
      "
    >
      <v-btn
        :loading="isLoading"
        color="success"
        depressed
        @click="handleUpdateShippingInfo"
        >Lưu
      </v-btn>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DatePicker from "vue2-datepicker";
import { useShippingInfoMutation } from "../composables";
import { createVuexHelpers } from "@/core/composables";

export default defineComponent({
  components: {
    DatePicker
  },

  setup() {
    const { useGetters } = createVuexHelpers();
    const { orderProcessing } = useGetters("ORDER", ["orderProcessing"]);

    const {
      shippingInfo,
      handleUpdateShippingInfo,
      isLoading
    } = useShippingInfoMutation();

    return {
      orderProcessing,
      shippingInfo,
      handleUpdateShippingInfo,
      isLoading
    };
  },

  data() {
    return {
      deliveryRequest: [
        { value: 0, text: "Cho xem hàng, không cho thử" },
        { value: 1, text: "Cho xem hàng, cho thử" },
        { value: 2, text: "Không cho xem hàng" }
      ]
    };
  },

  computed: {
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    saleEmployee() {
      return this.employees.filter(i => i.staff_roles.includes("SALES"));
    }
  }
});
</script>

<style lang="scss" scoped>
.custom-date-picker {
  width: 100%;

  ::v-deep .mx-input-wrapper {
    input {
      height: 40px;
      border: 1px solid #9e9e9e;
      background: inherit;
    }
  }
}

.gap-2 {
  gap: 8px;
}

.form-delivery-wrapper {
  background-color: #f8fafc;
  padding: 20px;
}
</style>
