<template>
  <v-main class="grey lighten-3">
    <v-overlay v-show="isShowOverlayLoading" opacity="0.5">
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <!-- Start: Header -->
    <sale-header></sale-header>
    <!-- End: Header -->
    <v-fade-transition>
      <component :is="activeView"></component>
    </v-fade-transition>

    <!-- ********** MODAL *********** -->
    <modal-order-processing></modal-order-processing>
    <modal-sale-payment></modal-sale-payment>
    <modal-sale-promotion></modal-sale-promotion>
    <modal-sale-receipt-returning></modal-sale-receipt-returning>
  </v-main>
</template>

<script>
import ModalOrderProcessing from "./components/modal/ModalOrderProcessing";
import ModalSalePayment from "./components/modal/ModalPayment/index";
import ModalSalePromotion from "./components/modal/ModalSalePromotion/index";
import ModalSaleReceiptReturning from "./components/modal/ModalSaleReceiptReturning";
import SaleHeader from "./components/SaleHeader/index";
import ViewCustomerReturn from "./pages/CustomerReturn";
import ViewOrder from "./pages/Order";
import ViewSaleReceipt from "./pages/SaleReceipt";

export default {
  components: {
    ModalOrderProcessing,
    ModalSalePayment,
    ModalSalePromotion,
    ModalSaleReceiptReturning,
    SaleHeader,
    ViewCustomerReturn,
    ViewOrder,
    ViewSaleReceipt
  },

  data() {
    return {
      views: ["view-sale-receipt", "view-order", "view-customer-return"]
    };
  },

  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    activeView() {
      if (!this.saleTabActive) return null;

      if (this.saleTabActive.order_type === "CUSTOMER_RETURN") {
        return "view-customer-return";
      }

      if (this.saleTabActive.order_type === "SALE_RECEIPT") {
        return "view-sale-receipt";
      }

      return "view-order";
    },

    customers() {
      return this.$store.getters["CUSTOMER/customers"];
    },
    isShowOverlayLoading() {
      return (
        (this.saleReceiptStatusRequest.value &&
          this.saleReceiptStatusRequest.value.includes("loading")) ||
        (this.orderStatusRequest.value &&
          this.orderStatusRequest.value.includes("loading"))
      );
    },
    localSaleTabActiveIndex() {
      return this.$store.getters["SALE/localSaleTabActiveIndex"];
    },
    order() {
      return this.$store.getters["ORDER/order"];
    },
    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },
    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },
    saleTabActiveIndex() {
      return this.$store.getters["SALE/saleTabActiveIndex"];
    }
  },

  watch: {
    saleTabActive(val) {
      if ((val && val.customer_id) || (val && val.khachhang_id)) {
        const customerId = val.customer_id || val.khachhang_id,
          code = customerId.toString().padStart(5, "0");

        const foundCustomer = this.customers.find(
          item => item.id === customerId
        );

        if (!foundCustomer) {
          this.$store.dispatch("CUSTOMER/getCustomers", {
            cur_page: 1,
            per_page: 100,
            search: `KH${code}`
          });
        }
      }
    },
    saleTabActiveIndex(val, oldVal) {
      if (val === oldVal) return;

      if (val === undefined) {
        this.$router.push({ name: "sale" });

        return;
      }

      console.log("val", val);
      console.log("oldVal", oldVal);

      if (this.saleTabs[val]) {
        console.log("this.saleTabs[val].id", this.saleTabs[val].id);
        if (this.saleTabs[val].id) {
          this.$router.push({
            name: "sale",
            query: {
              type: this.saleTabs[val].order_type,
              id: this.saleTabs[val].id
            }
          });
        } else {
          this.$router.push({ name: "sale" });
        }
      }
    }
  },

  async created() {
    const queryType = this.$route.query.type;
    const queryId = Number(this.$route.query.id);

    // set local saleTabs
    if (this.saleTabs && this.saleTabs.length === 0) {
      const localSaleTabs = JSON.parse(localStorage.getItem("TPSALE_TABS"));
      await this.$store.dispatch("SALE/setSaleTabs", localSaleTabs);
    }

    console.log("queryType", queryType);

    console.log("queryID", queryId);

    const getIndexSaleTab = () => {
      return this.saleTabs.findIndex(
        item => item.id === queryId && item.order_type === queryType
      );
    };

    if (queryType && queryId) {
      const foundLocalQueryTabIndex = getIndexSaleTab();

      /**
       * Check local storage if exist route query saleTab
       * YES - set sale tab active index is foundLocalQueryTabIndex
       * NO - get detail query sale tab (sale receipt/order) and set into local storage
       * */
      if (foundLocalQueryTabIndex !== -1) {
        console.log("foundLocalQueryTabIndex", foundLocalQueryTabIndex);
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          foundLocalQueryTabIndex
        );
      } else {
        console.log("not find");

        if (queryType === "SALE_RECEIPT") {
          await this.$store.dispatch(
            "SALE_RECEIPT/getSaleReceiptById",
            queryId
          );

          await this.$store.dispatch("SALE/addSaleTabItem", {
            ...this.saleReceipt,
            branch_id: this.saleReceipt.branch.id,
            khachhang_id: this.saleReceipt.khachhang.id
          });
        } else {
          await this.$store.dispatch("ORDER/getOrderById", queryId);

          console.log("this.order", this.order);

          if (this.order?.id) {
            await this.$store.dispatch("SALE/addSaleTabItem", {
              ...this.order
            });

            if (this.order.handles.length > 0) {
              await this.$store.dispatch(
                "ORDER/getOrderProcessingById",
                this.order.handles[0].id
              );

              await this.$store.dispatch(
                "SALE/setActiveOrderProcessingIndex",
                0
              );
            } else {
              await this.$store.dispatch("ORDER/setOrderProcessing", []);
            }
          }
        }

        // Set active tab
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          getIndexSaleTab()
        );
      }
    } else {
      if (!this.saleTabActiveIndex && this.saleTabActiveIndex !== 0) {
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          this.localSaleTabActiveIndex
        );
      }
    }

    // Get promotions
    await this.$store.dispatch("SALE/getPromotions");
  }
};
</script>

<style scoped></style>
