<template>
  <div class="white mr-1 pb-5">
    <v-data-table
      class="table-scroll tp-table__row-pointer datatable px-3 py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="options"
      item-key="id"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ indexOfOptionIds(item) }}
      </template>

      <template v-slot:[`item.product_name`]="{ item }">
        <div class="my-1">
          <div class="font-weight-medium">
            {{ `${item.product_name} - ${item.SKU}` }}
            <v-hover
              v-if="checkPromotionAvailable(item)"
              v-slot:default="{ hover }"
            >
              <v-icon
                class="red--text text--accent-1 ml-1"
                :class="{ 'red--text text--accent-2': hover }"
                size="18px"
                @click="openModalSalePromotion()"
              >
                mdi-wallet-giftcard
              </v-icon>
            </v-hover>
          </div>
          <div
            class="text-subtitle-2 font-weight-light grey--text text--darken-2"
          >
            <span class="font-weight-bold">{{ item.SKU }} - </span>
            <span class="item-subtitle mr-1" v-html="`${item.name}`"></span>
            <span v-if="item.given" style="color: red" class="font-weight-bold">
              <template v-if="item.tang_type === 1 || item.tang_type === 2">
                {{
                  item.tang_type === 1
                    ? "(quà tặng)"
                    : `(giảm giá ${item.tang_value}%)`
                }}
              </template>
              <template v-if="item.tang_type === 3">
                (giảm {{ item.tang_value | formatCurrency }})
              </template>
            </span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.serials`]="{ item }">
        <v-text-field
          v-if="
            flashSaleMode &&
              saleTabActive &&
              saleTabActive.branch_id === flashSaleBranchId
            // &&
            // item.serials.length > 0
          "
          class="text-body-1 my-2"
          dense
          hide-details
          outlined
          readonly
          :value="item.serials[0].serial_number"
        >
          <template #append>
            <v-icon class="tp-btn--text" @click="item.serials = []"
              >mdi-close</v-icon
            >
          </template>
        </v-text-field>

        <!-- :disabled="
            !enableSelectSerialForPromotion(item) || !!saleTabActive.id
          " -->

        <autocomplete-serials
          :product-option="item"
          :all-serials-of-sku="allSerialsOfSku"
          style="width: 230px"
          @change="updateSerialsChange(item, $event)"
          @focus="getStockSerialsByOptionId(item)"
        />
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.serials.length }}
      </template>

      <!-- :disabled="!!saleTabActive.id" -->

      <template v-slot:[`item.unit_price`]="{ item }">
        <tp-input-price
          v-model="item.unit_price"
          custom-class="text-body-1 mt-0 pt-0"
          dense
          flat
          hide-details
          :min-value="item.rebate_amount ? item.rebate_amount : 0"
          outlined
          solo
          placeholder="Giá trị"
        ></tp-input-price>
      </template>

      <template v-slot:[`item.rebate_amount`]="{ item }">
        <tp-input-price
          v-model="item.rebate_amount"
          custom-class="text-body-1 mt-0 pt-0"
          dense
          flat
          hide-details
          outlined
          solo
          placeholder="Giá trị"
        ></tp-input-price>
      </template>

      <template v-slot:[`item.sold_price`]="{ item }">
        {{ (item.unit_price - item.rebate_amount) | formatCurrency }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{
          ((item.unit_price - item.rebate_amount) * item.serials.length)
            | formatCurrency
        }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            small
            @click.stop="removeProductOption(item)"
          >
            <v-icon size="18px">mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-hover>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import { PRODUCT_PROMOTION_TYPE } from "@/modules/Sale/constants";
import AutocompleteSerials from "./AutocompleteSerials.vue";

export default {
  components: { AutocompleteSerials },

  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name"
        },
        {
          text: "Serials",
          align: "start",
          sortable: false,
          value: "serials"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Đơn giá",
          align: "start",
          sortable: false,
          value: "unit_price"
        },
        {
          text: "Chiết khấu",
          align: "start",
          sortable: false,
          value: "rebate_amount"
        },
        {
          text: "Giá sau giảm",
          align: "start",
          sortable: false,
          value: "sold_price"
        },
        {
          text: "Thành tiền",
          align: "start",
          sortable: false,
          value: "total"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        }
      ],

      allSerialsOfSku: []
    };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    options() {
      return this.saleTabActive.details.map(item => item.option);
    },
    flashSaleBranchId() {
      return this.$store.getters["SALE/flashSaleBranchId"];
    },
    flashSaleMode() {
      return this.$store.getters["SALE/flashSaleMode"];
    },
    optionIds() {
      return this.saleTabActive.details.map(item => item.option_id);
    },
    productOptionStockSerials() {
      return this.$store.getters["SERIAL/productOptionStockSerials"];
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  watch: {
    "saleTabActive.branch_id"(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.saleTabActive.details.forEach(detail => {
          detail.option.serials = [];
          detail.option.allSerials = [];
        });
      }
    }
  },

  methods: {
    checkPromotionAvailable(item) {
      if (item.promotions) {
        const { promotions } = item;

        return !!(
          promotions &&
          (promotions.promo_type_3 || promotions.promo_type_12)
        );
      } else {
        return false;
      }
    },

    checkPromotionCondition(promotion) {
      const { dk_limit_number_using, dk_time, dk_using_times } = promotion;

      return (
        (!dk_limit_number_using || dk_limit_number_using > dk_using_times) &&
        (!dk_time || moment(dk_time).valueOf() > moment().valueOf())
      );
    },

    enableSelectSerialForPromotion(item) {
      if (!item.parent) {
        return true;
      }
      let count = 0;
      const parentProduct = this.saleTabActive.options.find(
        option => option.id === item.parent
      );
      const { promotions } = parentProduct;
      if (promotions && promotions.promo_type_3) {
        const { promo_type_3 } = promotions;
        const { tk_option } = promo_type_3;
        tk_option.quantity = 5;
        const { quantity } = tk_option;
        if (!quantity) {
          return true;
        }
        const productsPromotion = this.saleTabActive.options.filter(
          option => option.parent === item.parent
        );
        productsPromotion.forEach(product => {
          count += product.serials.length;
        });

        return Number(quantity) - count > 0 || item.serials.length > 0;
      }
    },

    // async getStockSerialsByOptionId(option) {
    //   // if (option.allSerials.length !== 0) return;

    //   let res = [];

    //   await this.$store.dispatch("SERIAL/getStockSerialsByOptionId", option.id);

    //   this.branches.forEach(branch => {
    //     // Filter serial by branch id
    //     let arr = this.productOptionStockSerials.filter(
    //       item => item.branch_id === branch.id
    //     );

    //     arr = arr.map(value => {
    //       return {
    //         ...value,
    //         disabled:
    //           this.saleTabActive.branch_id &&
    //           this.saleTabActive.branch_id !== branch.id
    //       };
    //     });

    //     res = res.concat(arr);
    //   });

    //   const sortedDataByDisabled = res.sort((a, b) => {
    //     if (a.disabled === b.disabled) {
    //       return 0;
    //     }
    //     return a.disabled ? 1 : -1;
    //   });

    //   option.allSerials = this.groupByBranch(sortedDataByDisabled);
    // },

    async getStockSerialsByOptionId(option) {
      let res = [];

      res.push(...option.serials);

      await this.$store.dispatch("SERIAL/getStockSerialsByOptionId", option.id);

      this.branches.forEach(branch => {
        // Filter serial by branch id
        let arr = this.productOptionStockSerials.filter(
          item => item.branch_id === branch.id
        );

        arr = arr.map(value => {
          return {
            ...value,
            disabled:
              this.saleTabActive.branch_id &&
              this.saleTabActive.branch_id !== branch.id
          };
        });

        res = res.concat(arr);
      });

      const sortedDataByDisabled = res.sort((a, b) => {
        if (a.disabled === b.disabled) {
          return 0;
        }
        return a.disabled ? 1 : -1;
      });

      option.allSerials = this.groupByBranch(sortedDataByDisabled);
    },

    groupByBranch(data) {
      const groupedData = data.reduce((result, item) => {
        if (!result[item.branch_name]) {
          result[item.branch_name] = [];
        }
        result[item.branch_name].push(item);
        return result;
      }, {});

      const groupedArray = [];
      for (const key in groupedData) {
        groupedArray.push({ header: `${key} (${groupedData[key].length})` });
        groupedArray.push(...groupedData[key]);
      }
      return groupedArray;
    },

    indexOfOptionIds(value) {
      if (value.given) {
        return "";
      }
      return this.optionIds.indexOf(value.id) + 1;
    },

    openModalSalePromotion() {
      this.$modal.show({ name: "modal-sale-promotion" });
    },

    async removeProductOption(item) {
      this.saleTabActive.details = this.saleTabActive.details.filter(
        detail => detail.option.id !== item.id
      );

      // Update sale active tab
      await this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
    },

    updateSerialsChange(item) {
      const { promotions, parent, price } = item;
      const serialLength = item.serials.length;

      // trường hợp update serials cho sản phẩm được tặng
      if (parent) {
        item.totalPrice = price * serialLength;
        return this.$store.dispatch(
          "SALE/setSaleTabActive",
          this.saleTabActive
        );
      }

      if (promotions) {
        const { promo_type_3 } = promotions;

        if (promo_type_3 && this.checkPromotionCondition(promo_type_3)) {
          const { tk_option } = promo_type_3;

          if (tk_option) {
            const { option_list, tang_type, group, tang_value } = tk_option;
            const free = tang_type === PRODUCT_PROMOTION_TYPE.give_product;

            const checkAdded = this.saleTabActive.options.findIndex(
              option => option.parent === item.id
            );

            const rootIndex = this.saleTabActive.options.findIndex(
              option => option.id === item.id
            );

            if (serialLength === 0 && checkAdded !== -1) {
              this.saleTabActive.options = this.saleTabActive.options.filter(
                option => {
                  return option.parent !== item.id;
                }
              );
            } else {
              if (option_list && checkAdded === -1) {
                this.saleTabActive.options.splice(
                  1,
                  rootIndex,
                  ...option_list.map(value => {
                    return {
                      ...value,
                      serials: [],
                      allSerials: [],
                      given: true,
                      name: value.product_name,
                      free,
                      tang_type,
                      tang_value,
                      totalPrice: 0,
                      unit_price: value.price,
                      rebate_amount: free
                        ? value.price
                        : tang_type === 2
                        ? (value.price * tang_value) / 100
                        : tang_value,
                      sold_price: 0,
                      parent: item.id
                    };
                  })
                );
              }

              if (group && checkAdded === -1) {
                // cần call api lấy products theo group rồi add vào tương tự như bên trên
                let productsAllGroups = [];
                group.forEach(gr => {
                  productsAllGroups = productsAllGroups.concat(gr.options);
                });
                this.saleTabActive.options.splice(
                  1,
                  rootIndex,
                  ...productsAllGroups.map(value => {
                    return {
                      ...value,
                      serials: [],
                      allSerials: [],
                      given: true,
                      free,
                      tang_type,
                      tang_value,
                      unit_price: value.price,
                      rebate_amount: free
                        ? value.price
                        : tang_type === 2
                        ? (value.price * tang_value) / 100
                        : tang_value,
                      parent: item.id
                    };
                  })
                );
              }
            }
          }
        }

        // truowngf hop giam tien hoac giam %
        // if (promo_type_12 && this.checkPromotionCondition(promo_type_12)) {
        //   const { tk_money, tk_percent, tk_type } = promo_type_12;
        //   if (tk_type === 2) {
        //     item.totalPrice =
        //       serialLength * (Number(item.price) - Number(tk_money));
        //   } else if (tk_type === 1) {
        //     item.totalPrice =
        //       serialLength * ((Number(item.price) * (100 - tk_percent)) / 100);
        //   }
        //   this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
        //   return;
        // }
      }

      this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}
</style>
