var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header primary text-body-1 white--text d-flex justify-space-between px-6",attrs:{"app":"","height":"46px"}},[_c('div',{staticClass:"d-flex"},[_c('header-search',{staticClass:"header-search",attrs:{"disabled":_vm.saleTabActive && _vm.saleTabActive.status >= 4}}),_c('div',{staticClass:"header-tabs d-flex align-center"},[_c('v-tabs',{staticClass:"font-weight-medium",attrs:{"active-class":"white primary--text","background-color":"primary","dark":"","height":"46px"},model:{value:(_vm.saleTabActiveIndex),callback:function ($$v) {_vm.saleTabActiveIndex=$$v},expression:"saleTabActiveIndex"}},_vm._l((_vm.saleTabs),function(item,index){return _c('v-tab',{key:index,staticClass:"text-body-1 font-weight-bold text-none px-3"},[(item.order_type === 'SALE_AT_STORE')?_c('div',{staticClass:"mr-2",staticStyle:{"color":"#FDD835 !important"}},[_vm._v(" POS ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getCodeName(item.code))+" "),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'red accent-2' : null,"right":"","size":"20px"},on:{"click":function($event){$event.stopPropagation();return _vm.alertCloseTab(index, item)}}},[_vm._v(" mdi-close ")])]}}],null,true)})],1)}),1),_c('button-add-new-sale-tab')],1)],1),_c('div',{staticClass:"d-flex flex-row align-center header-right justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.orderStatusRequest.value === 'loading-getOrders',"dark":"","icon":"","small":""},on:{"click":function($event){return _vm.openModalOrderProcessing()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-recycle-variant")])],1)]}}])},[_c('span',{staticClass:"font-weight-bold text-body-2 pa-1"},[_vm._v("Xử lý đơn hàng")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"loading":_vm.orderStatusRequest.value === 'loading-getOrders',"dark":"","icon":"","small":""},on:{"click":function($event){return _vm.openModalSaleReceiptReturning()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-reply-all-outline")])],1)]}}])},[_c('span',{staticClass:"font-weight-bold text-body-2 pa-1"},[_vm._v("Trả hàng")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","depressed":"","icon":"","small":""},on:{"click":function($event){return _vm.goToDashboard()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home-import-outline")])],1)]}}])},[_c('span',{staticClass:"font-weight-bold text-body-2 pa-1"},[_vm._v("Quay về trang chủ")])]),_c('div',{staticClass:"header-right--user ml-4"},[_c('v-avatar',{staticClass:"primary",attrs:{"item":"","size":"24px"}},[_c('v-icon',{attrs:{"color":"white","size":"28px"}},[_vm._v("mdi-account-circle")])],1),_c('span',{staticClass:"font-weight-bold text-body-1 text-n ml-2"},[_vm._v(" "+_vm._s(_vm.getShortenName(_vm.user.name))+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }