<template>
  <v-menu offset-y>
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="mr-2" icon v-bind="attrs" v-on="on"
        ><v-icon color="primary">mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item-group color="primary">
        <template v-for="(item, i) in printActionItems">
          <v-list-item
            :disabled="saleTabActive.status < 4"
            :color="item.disabled ? 'grey lighten-2' : ''"
            :key="i + 10"
            target="_blank"
            :to="{
              name: item.routeName,
              params: {
                ...(item.routeName === 'print_order-invoice'
                  ? { orderId: saleTabActive.id }
                  : { handleId: saleTabActive.handles[0].id })
              }
            }"
          >
            <v-list-item-icon>
              <v-icon
                :color="saleTabActive.status < 4 ? 'grey lighten-2' : 'primary'"
                v-text="item.icon"
              ></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item
          v-for="(item, i) in actionItems"
          :key="i"
          :href="item.href"
          target="_blank"
        >
          <v-list-item-icon>
            <icon-zalo v-if="item.icon === 'zalo'"></icon-zalo>
            <v-icon v-else color="primary" v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import IconZalo from "@/core/assets/icons/IconZalo";

export default {
  components: { IconZalo },

  computed: {
    actionItems() {
      return [
        {
          text: "Trang order dành cho khách",
          icon: "mdi-file-document",
          href: this.saleTabActive.link_web
        }
        // {
        //   text: "Gửi email thông tin đơn hàng cho khách",
        //   icon: "mdi-email"
        // },
        // {
        //   text: "Gửi Zalo thông tin đơn hàng cho khách",
        //   icon: "zalo"
        // },
        // {
        //   text: "Gửi SMS thông tin đơn hàng cho khách",
        //   icon: "mdi-message-processing"
        // }
      ];
    },

    printActionItems() {
      return [
        {
          text: "In hóa đơn",
          icon: "mdi-printer",
          routeName: "print_order-invoice"
        },
        {
          text: "In phiếu xuất kho",
          icon: "mdi-printer",
          routeName: "print_delivery-bill"
        }
      ];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  }
};
</script>
