<template>
  <v-card class="text-body-1 py-4" flat>
    <div class="px-5">
      <div class="promotion-title font-weight-bold">{{ promotion.name }}</div>
      <div class="text-subtitle-2 font-weight-light grey--text text--darken-2">
        HSD:
        {{ promotion.dk_time ? promotion.dk_time : "Không giới hạn" }}
      </div>
      <div class="text-body-2 mt-1">
        Đã chọn
        <span class="font-weight-bold">{{
          selectedPromotionOptions.length
        }}</span>
        <span class="font-italic">
          (Được chọn tối đa
          <span class="font-weight-bold">
            {{ promotion.tk_option.quantity }}
          </span>
          sản phẩm bên dưới)
        </span>
      </div>
    </div>

    <v-divider class="mx-5 mt-2"></v-divider>

    <card-promotion-datatable
      :is-show-all="isShowAll"
      :promotion="promotion"
      :promotion-tk-options="promotionTkOptions"
    />

    <template v-if="promotionTkOptions.length > 3">
      <div
        v-if="!isShowAll"
        class="tp-btn--text primary--text font-weight-bold mx-4"
        @click="isShowAll = true"
      >
        <v-icon class="mr-1" color="primary">mdi-chevron-down</v-icon>
        <span>Xem thêm {{ promotionTkOptions.length - 3 }} sản phẩm</span>
      </div>
      <div
        v-else
        class="tp-btn--text primary--text font-weight-bold mx-4"
        @click="isShowAll = false"
      >
        <v-icon class="mr-1" color="primary">mdi-chevron-up</v-icon>
        Ẩn {{ promotionTkOptions.length - 3 }} sản phẩm
      </div>
    </template>
  </v-card>
</template>

<script>
import CardPromotionDatatable from "@/modules/Sale/components/modal/ModalSalePromotion/components/CardPromotionDatatable";
import { getTkOptions } from "@/core/composables/usePromotion";

export default {
  components: { CardPromotionDatatable },

  props: {
    promotion: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isShowAll: false
    };
  },

  computed: {
    promotionTkOptions() {
      return getTkOptions(this.promotion);
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    saleTabActiveProductOptionIndex() {
      return this.$store.getters["SALE/saleTabActiveProductOptionIndex"];
    },

    selectedPromotionOptions() {
      return this.saleTabActive.details[
        this.saleTabActiveProductOptionIndex
      ].gifts
        .filter(item => item.promotion?.id === this.promotion.id)
        .map(item => item.option_id);
    }
  }
};
</script>

<style scoped>
.promotion-title {
  font-size: 1.125rem;
}
</style>
