<template>
  <div class="shipping-handling-guide">
    <button class="title" @click="toggle">
      <span v-if="visible">Đóng hướng dẫn xử lý vận chuyển</span>
      <span v-else>Hướng dẫn xử lý vận chuyển</span>

      <v-icon>mdi-chevron-down</v-icon>
    </button>

    <div v-show="visible" class="mt-2">
      <p>
        Sau khi chọn đối tác, hoàn tất xử lý vận chuyển bằng một trong hai cách
        sau:
      </p>
      <div class="font-weight-bold">1. Tạo vận đơn tại CMS:</div>
      <p>
        Nhập đầy đủ thông tin bên phải và bấm tạo vận đơn. Hệ thống sẽ tự tạo
        vận đơn và phiếu thu COD tương ứng nếu có.
      </p>
      <div class="font-weight-bold">2. Nhân viên tự tạo vận đơn</div>
      <p>
        Truy cập trang quản lý của đối tác vận chuyển, tạo đơn vận chuyển và
        điền mã vận đơn vào CMS để hệ thống có thể theo dõi trạng thái đơn hàng.
      </p>
      <p>
        <i>
          *Hệ thống hiện tại chỉ hỗ trợ theo dõi trạng thái đơn hàng của GHN và
          VNPost. Ngay khi shipper đến lấy hàng, đơn xử lý sẽ được chuyển sang
          trạng thái Đang giao hàng.
        </i>
      </p>
      <p>
        <i>
          *Nếu muốn hủy vận đơn sau khi tạo, vui lòng truy cập trang quản trị
          của đối tác vận chuyển. Đơn vận chuyển sau khi hủy ở ĐTVC, bạn có thể
          nhập/tạo lại vận đơn tại đây.
        </i>
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useVisible } from "@thinkvn/composables/lib/useVisible";

export default defineComponent({
  setup() {
    return {
      ...useVisible()
    };
  }
});
</script>

<style lang="scss" scoped>
.shipping-handling-guide {
  border-radius: 4px;
  background: #f5f5f5;
  padding: 8px 16px;
}

.title {
  width: 100%;
  display: flex;
  justify-content: space-between;

  span {
    font-size: 14px;
  }
}
</style>
