var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sale-receipt__tab-participants text-body-1 px-4 pt-4 pb-5"},[_c('h6',{staticClass:"font-weight-bold text-h6"},[_vm._v(" Danh sách phục vụ ")]),_c('v-data-table',{staticClass:"rounded-0 pt-5",attrs:{"disable-pagination":"","disable-sort":"","headers":_vm.headers,"hide-default-footer":"","loading":_vm.invoiceParticipantStatusRequest.value ===
        'loading-getInvoiceParticipantsOfInvoice',"loading-text":"Đang tải dữ liệu","items":_vm.invoiceParticipants,"no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.name : "N/A")+" ")]}},{key:"item.role",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInVoiceRoleName(item.role))+" ")]}},{key:"item.docs",fn:function(ref){
        var item = ref.item;
return [(item.docs)?_c('div',{staticClass:"d-flex align-center"},[_vm._l((item.docs.slice(0, 2)),function(image){return _c('div',{key:image,staticClass:"img-wrapper mr-1"},[_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"grey darken-3 rounded",attrs:{"aspect-ratio":1,"contain":"","src":image}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"white pa-8",staticStyle:{"height":"70vh"}},[_c('v-img',{attrs:{"contain":"","src":image,"height":"100%","max-height":"70vh"}})],1)])],1)}),(item.docs.length > 2)?_c('div',{staticClass:"primary--text"},[_vm._v("+2")]):_vm._e()],2):_c('span',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.status === 1
            ? 'green'
            : item.status === 0
            ? 'orange'
            : 'red accent-2',"small":"","outlined":""}},[_vm._v(" "+_vm._s(item.status === 1 ? "Đã duyệt" : item.status === 0 ? "Chờ tài liệu" : "Bị từ chối")+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"fab":"","text":"","depressed":"","x-small":""},on:{"click":function($event){return _vm.openModalFormParticipant(item)}}},[_c('v-icon',{attrs:{"color":"primary","size":"24px"}},[_vm._v("mdi-square-edit-outline")])],1),(false)?_c('v-btn',{attrs:{"fab":"","text":"","depressed":"","x-small":""}},[_c('v-icon',{attrs:{"color":"red darken-3"}},[_vm._v("mdi-delete-outline")])],1):_vm._e()],1)]}}],null,true)}),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":_vm.openModalFormParticipant}},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" Thêm phục vụ ")],1),_c('modal-form-participant',{attrs:{"model-id":_vm.saleTabActive.id,"model-name":'App\\Models\\HoaDonBan'},on:{"onSuccessfullyCreate":_vm.getSaleReceiptParticipant,"onSuccessfullyUpdate":_vm.getSaleReceiptParticipant}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }