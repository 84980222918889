<template>
  <v-card flat>
    <v-card-text class="black--text" style="height: 380px">
      <div class="mb-6">
        <div class="text-h6 font-weight-bold mb-3">Chi nhánh thanh toán</div>

        <tp-select
          clearable
          dense
          hide-details="auto"
          :items="branches"
          item-text="name"
          item-value="id"
          outlined
          placeholder="Chọn chi nhánh"
          solo
          @change="onChangeBranch"
        ></tp-select>
      </div>

      <v-divider class="my-4"></v-divider>

      <div class="mb-5">
        <div class="text-h6 font-weight-bold mb-3">Phương thức thanh toán</div>

        <tp-select
          v-model="selectedMethod"
          dense
          hide-details="auto"
          :items="listPayment"
          item-text="text"
          item-value="kind"
          outlined
          placeholder="Chọn phương thức thanh toán"
          solo
        ></tp-select>
      </div>

      <v-form ref="formRef">
        <div v-if="selectedMethod">
          <div
            v-if="
              user.staff_roles.includes('CASH') &&
                selectedMethod === LIST_PAYMENT_KIND.TIEN_MAT.id
            "
          >
            <v-row v-for="(item, index) in payment.cash" :key="index + '_cash'">
              <v-col
                cols="3"
                class="d-flex flex-row justify-start align-center"
              >
                <v-icon color="primary">mdi-cash-100</v-icon>

                <span class="font-weight-medium text-body-1 ml-1 text-no-wrap"
                  >Tiền mặt</span
                >
              </v-col>

              <v-col cols="9">
                <tp-input-price
                  custom-class="text-body-1 mt-0 pt-0"
                  dense
                  flat
                  hide-details
                  outlined
                  solo
                  :value="item.amount"
                  @change="
                    $event => {
                      item.amount = $event;
                      onChangeData();
                    }
                  "
                ></tp-input-price>
              </v-col>

              <v-col cols="4"></v-col>
            </v-row>
          </div>

          <div v-if="selectedMethod === LIST_PAYMENT_KIND.CHUYEN_KHOAN.id">
            <v-row v-for="(item, index) in payment.bank" :key="index + '_bank'">
              <v-col
                cols="3"
                class="d-flex flex-row justify-start align-center"
              >
                <v-icon color="#0B9574">mdi-bank</v-icon>

                <span class="font-weight-medium text-body-1 ml-1 text-no-wrap"
                  >Chuyển khoản</span
                >
              </v-col>

              <v-col cols="3">
                <tp-input-price
                  custom-class="text-body-1 mt-0 pt-0"
                  dense
                  flat
                  hide-details
                  outlined
                  solo
                  :value="item.amount"
                  @change="
                    $event => {
                      item.amount = $event;
                      onChangeData();
                    }
                  "
                ></tp-input-price>
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="item.receiver_bank_id"
                  dense
                  flat
                  :items="companyBankAccounts"
                  item-value="id"
                  item-text="name"
                  hide-details="auto"
                  :menu-props="{ nudgeTop: '-40px' }"
                  outlined
                  solo
                  placeholder="Tài khoản nhận"
                  :rules="[v => !!v || 'Không được bỏ trống']"
                  @change="onChangeData()"
                >
                  <template #selection="data">
                    <div style="max-width: 100%">
                      <div class="text-truncate">
                        {{ data.item.number }} - {{ data.item.name }} ({{
                          data.item.bank_code
                        }})
                      </div>
                    </div>
                  </template>

                  <template #item="data">
                    <div class="py-1">
                      <div class="text-body-1 font-weight-bold">
                        {{ data.item.number }} - {{ data.item.name }}
                      </div>

                      <div class="text-body-2">{{ data.item.bank_code }}</div>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </div>

          <div v-if="selectedMethod === LIST_PAYMENT_KIND.QUET_THE.id">
            <v-row
              v-for="(item, index) in payment.credit"
              :key="index + '_credit'"
            >
              <v-col
                cols="3"
                class="d-flex flex-row justify-start align-center"
              >
                <v-icon color="#F36600">mdi-credit-card-outline</v-icon>

                <span class="font-weight-medium text-body-1 ml-1 text-no-wrap"
                  >Quẹt thẻ</span
                >
              </v-col>

              <v-col cols="4">
                <tp-input-price
                  custom-class="text-body-1 mt-0 pt-0"
                  dense
                  flat
                  hide-details
                  label="Số tiền chưa bao gồm phí"
                  outlined
                  :value="item.amount"
                  @change="
                    $event => {
                      item.amount = $event;
                      onChangeData();
                    }
                  "
                ></tp-input-price>

                <tp-input-price
                  custom-class="text-body-1 mt-2 pt-0"
                  flat
                  label="Phí thanh toán"
                  hide-details
                  :value="item.payment_fee"
                  @change="
                    $event => {
                      item.payment_fee = $event;
                      onChangeData();
                    }
                  "
                ></tp-input-price>
              </v-col>

              <v-col cols="2">
                <tp-select
                  v-model="item.receiverable_id"
                  :items="paymentPartner"
                  hide-details="auto"
                  item-text="name"
                  item-value="id"
                  placeholder="Đối tác"
                  solo
                  validate="required"
                  @change="onChangeData()"
                ></tp-select>
              </v-col>

              <v-col cols="3">
                <tp-select
                  v-model="item.pos_id"
                  :items="filteredPosDevices"
                  hide-details="auto"
                  item-text="name"
                  item-value="name"
                  no-data-text="Chọn chi nhánh trước"
                  placeholder="Chọn máy POS"
                  solo
                  validate="required"
                  @change="onChangeData()"
                ></tp-select>
              </v-col>
            </v-row>
          </div>

          <div v-if="selectedMethod === LIST_PAYMENT_KIND.TRA_GOP.id">
            <v-row
              v-for="(item, index) in payment.installment"
              :key="index + '_credit'"
            >
              <v-col
                cols="3"
                class="d-flex flex-row justify-start align-center"
              >
                <v-icon color="#7A4AEC">mdi-clock-outline</v-icon>
                <span class="font-weight-medium text-body-1 ml-1 text-no-wrap"
                  >Trả góp</span
                >
              </v-col>

              <v-col cols="5">
                <tp-input-price
                  custom-class="text-body-1 mt-0 pt-0"
                  dense
                  flat
                  hide-details
                  label="Số tiền chưa bao gồm phí"
                  outlined
                  :value="item.amount"
                  @change="
                    $event => {
                      item.amount = $event;
                      onChangeData();
                    }
                  "
                ></tp-input-price>

                <tp-input-price
                  custom-class="text-body-1 mt-2 pt-0"
                  dense
                  flat
                  hide-details
                  label="Phí thanh toán"
                  outlined
                  :value="item.payment_fee"
                  @change="
                    $event => {
                      item.payment_fee = $event;
                      onChangeData();
                    }
                  "
                ></tp-input-price>
              </v-col>

              <v-col cols="4">
                <tp-select
                  v-model="item.receiverable_id"
                  :items="installmentPartner"
                  hide-details="auto"
                  item-text="name"
                  item-value="id"
                  placeholder="Đối tác trả góp"
                  solo
                  validate="required"
                  @change="onChangeData()"
                ></tp-select>
              </v-col>
            </v-row>
          </div>

          <div v-if="selectedMethod === LIST_PAYMENT_KIND.COD.id">
            <v-row v-for="(item, index) in payment.cod" :key="index + '_cod'">
              <v-col
                cols="3"
                class="d-flex flex-row justify-start align-center"
              >
                <v-icon color="#F43E6A">mdi-truck-outline</v-icon>

                <span class="font-weight-medium text-body-1 ml-1 text-no-wrap">
                  COD
                </span>
              </v-col>

              <v-col cols="3">
                <tp-input-price
                  custom-class="text-body-1 mt-0 pt-0"
                  dense
                  flat
                  hide-details
                  outlined
                  solo
                  :value="item.amount"
                  @change="
                    $event => {
                      item.amount = $event;
                      onChangeData();
                    }
                  "
                ></tp-input-price>
              </v-col>

              <v-col cols="6">
                <tp-select
                  v-model="item.receiverable_id"
                  :items="shippingPartner"
                  hide-details="auto"
                  item-text="name"
                  item-value="id"
                  placeholder="Đối tác vận chuyển"
                  solo
                  validate="required"
                  @change="onChangeData()"
                ></tp-select>
              </v-col>
            </v-row>
          </div>
        </div>

        <div
          v-else
          class="pa-5 d-flex justify-center align-center text-body-1 font-italic grey--text"
        >
          Vui lòng chọn phương thức thanh toán
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { LIST_SUPPLIER_CATEGORIES } from "@/modules/PartnerAndMember/constant";
import { LIST_PAYMENT_KIND } from "@/core/constant";

export default {
  props: {
    hideCodMethod: {
      type: Boolean,
      default: false
    },
    hideInstallmentMethod: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "THANH TOAN"
    }
  },

  data() {
    return {
      installmentPartner: [],
      payment: {
        cash: [{ ...PAYMENT_DEFAULT_VALUE.CASH }],
        bank: [{ ...PAYMENT_DEFAULT_VALUE.BANK }],
        credit: [{ ...PAYMENT_DEFAULT_VALUE.CREDIT }],
        cod: [{ ...PAYMENT_DEFAULT_VALUE.COD }],
        installment: [{ ...PAYMENT_DEFAULT_VALUE.INSTALLMENT }]
      },
      paymentPartner: [],
      shippingPartner: [],
      branchId: null,
      selectedMethod: null,
      LIST_PAYMENT_KIND: LIST_PAYMENT_KIND
    };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    listPayment() {
      const arrayOfPayment = Object.values(this.payment).flat();

      const arrayOfPaymentWithoutCASH = arrayOfPayment.filter(
        payment => payment.kind !== this.LIST_PAYMENT_KIND.TIEN_MAT.id
      );

      if (this.user.staff_roles.includes("CASH")) {
        return arrayOfPayment;
      } else {
        return arrayOfPaymentWithoutCASH;
      }
    },

    companyBankAccounts() {
      const accounts = this.$store.getters["BANK_ACCOUNT/companyBankAccounts"];
      const filteredAccounts = accounts
        .filter(account => account.enable)
        .filter(account => account.functions.includes(this.type));

      if (!this.branchId) return filteredAccounts;

      return filteredAccounts.filter(account => {
        if (!account.branches) return false;

        return account.branches.includes(this.branchId);
      });
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },

    posDevices() {
      return this.$store.getters["SALE/posDevices"];
    },

    filteredPosDevices() {
      if (!this.branchId) return [];

      return this.posDevices.filter(
        device => device.branchId === this.branchId
      );
    }
  },

  async created() {
    this.paymentPartner = await this.$store.dispatch(
      "SUPPLIER/getAllSuppliers",
      `?sup_cate_ids=${LIST_SUPPLIER_CATEGORIES[3].id}`
    );

    const allShippingPartner = await this.$store.dispatch(
      "SUPPLIER/getAllSuppliers",
      `?sup_cate_ids=${LIST_SUPPLIER_CATEGORIES[2].id}&name='Ahamove'`
    );

    this.shippingPartner = allShippingPartner.filter(
      item => item.name === "Ahamove"
    );

    this.installmentPartner = await this.$store.dispatch(
      "SUPPLIER/getAllSuppliers",
      `?sup_cate_ids=${LIST_SUPPLIER_CATEGORIES[4].id}`
    );

    await this.$store.dispatch("BANK_ACCOUNT/getCompanyBankAccounts");
  },

  beforeDestroy() {
    this.onClearData();
  },

  methods: {
    onChangeBranch(value) {
      this.$emit("change:branch", value);
      this.branchId = value;
    },
    addPaymentItem(type) {
      if (type === "CASH") {
        this.payment.cash.push({ ...PAYMENT_DEFAULT_VALUE.CASH });
      }

      if (type === "BANK") {
        this.payment.bank.push({ ...PAYMENT_DEFAULT_VALUE.BANK });
      }

      if (type === "CREDIT") {
        this.payment.credit.push({ ...PAYMENT_DEFAULT_VALUE.CREDIT });
      }

      if (type === "INSTALLMENT") {
        this.payment.installment.push({ ...PAYMENT_DEFAULT_VALUE.INSTALLMENT });
      }
    },

    onChangeData() {
      this.$emit("change", this.payment);
    },

    onClearData() {
      this.payment = {
        cash: [{ ...PAYMENT_DEFAULT_VALUE.CASH }],
        bank: [{ ...PAYMENT_DEFAULT_VALUE.BANK }],
        credit: [{ ...PAYMENT_DEFAULT_VALUE.CREDIT }],
        cod: [{ ...PAYMENT_DEFAULT_VALUE.COD }],
        installment: [{ ...PAYMENT_DEFAULT_VALUE.INSTALLMENT }]
      };
    },

    removePaymentItem(type, index) {
      if (type === "CASH") {
        this.payment.cash.splice(index, 1);
      }

      if (type === "BANK") {
        this.payment.bank.splice(index, 1);
      }

      if (type === "CREDIT") {
        this.payment.credit.splice(index, 1);
      }

      if (type === "INSTALLMENT") {
        this.payment.installment.splice(index, 1);
      }
    }
  }
};

const PAYMENT_DEFAULT_VALUE = {
  CASH: {
    receiver_bank_id: null,
    receiverable_id: null,
    receiverable_type: "App\\Models\\Branch",
    kind: LIST_PAYMENT_KIND.TIEN_MAT.id,
    amount: 0,
    text: "Tiền mặt"
  },
  BANK: {
    receiver_bank_id: null,
    receiverable_id: -1,
    receiverable_type: null,
    kind: LIST_PAYMENT_KIND.CHUYEN_KHOAN.id,
    amount: 0,
    text: "Chuyển khoản"
  },
  CREDIT: {
    receiver_bank_id: null,
    receiverable_id: null,
    receiverable_type: "App\\Models\\Supplier",
    kind: LIST_PAYMENT_KIND.QUET_THE.id,
    amount: 0,
    payment_fee: 0,
    text: "Quẹt thẻ"
  },
  COD: {
    receiver_bank_id: null,
    receiverable_id: null,
    receiverable_type: "App\\Models\\Supplier",
    kind: LIST_PAYMENT_KIND.COD.id,
    amount: 0,
    text: "COD"
  },
  INSTALLMENT: {
    receiver_bank_id: null,
    receiverable_id: null,
    receiverable_type: "App\\Models\\Supplier",
    kind: LIST_PAYMENT_KIND.TRA_GOP.id,
    amount: 0,
    payment_fee: 0,
    text: "Trả góp"
  }
};
</script>
<style lang="scss">
.border-b {
  border-bottom: 1px solid #dadde0;
}
</style>
