<template>
  <v-menu offset-y>
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="mr-2" icon v-bind="attrs" v-on="on"
        ><v-icon color="primary">mdi-dots-horizontal</v-icon></v-btn
      >
    </template>

    <v-list>
      <v-list-item-group color="primary">
        <v-list-item
          :color="totalSerialsAvailableInHandle < 1 ? 'grey lighten-2' : ''"
          :disabled="totalSerialsAvailableInHandle < 1"
          target="_blank"
          :to="{
            name: 'print_order-invoice',
            params: { orderId: saleTabActive.id }
          }"
        >
          <v-list-item-icon>
            <v-icon
              :color="
                totalSerialsAvailableInHandle < 1 ? 'grey lighten-2' : 'primary'
              "
              >mdi-printer</v-icon
            >
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>In hoá đơn</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="(item, i) in actionItems"
          :key="i"
          :href="item.href"
          target="_blank"
        >
          <v-list-item-icon>
            <icon-zalo v-if="item.icon === 'zalo'"></icon-zalo>
            <v-icon v-else color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import IconZalo from "@/core/assets/icons/IconZalo";

export default {
  components: { IconZalo },

  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    actionItems() {
      return [
        {
          text: "Trang order dành cho khách",
          icon: "mdi-file-document",
          href: this.saleTabActive.link_web
        }
        // {
        //   text: "Gửi email thông tin đơn hàng cho khách",
        //   icon: "mdi-email"
        // },
        // { text: "Gửi Zalo thông tin đơn hàng cho khách", icon: "zalo" },
        // {
        //   text: "Gửi SMS thông tin đơn hàng cho khách",
        //   icon: "mdi-message-processing"
        // }
      ];
    },

    totalSerialsAvailableInHandle() {
      let totalSerialsCount = 0;

      this.saleTabActive.handles.forEach(handle => {
        if (handle.cancel_status === 0) {
          handle.details.forEach(detail => {
            totalSerialsCount += detail.serials.length;
          });
        }
      });

      return totalSerialsCount;
    }
  }
};
</script>
