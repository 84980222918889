import { render, staticRenderFns } from "./SaleAtStoreHeader.vue?vue&type=template&id=6f1df234&scoped=true&"
import script from "./SaleAtStoreHeader.vue?vue&type=script&lang=js&"
export * from "./SaleAtStoreHeader.vue?vue&type=script&lang=js&"
import style0 from "./SaleAtStoreHeader.vue?vue&type=style&index=0&id=6f1df234&lang=scss&scoped=true&"
import style1 from "./SaleAtStoreHeader.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f1df234",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VChip,VIcon,VTab,VTabs})
