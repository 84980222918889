var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-3"},[(_vm.saleTabActive.customer)?_c('div',{staticClass:"grey lighten-4 rounded px-3 py-2 mt-2"},[_c('div',[_vm._v(" Khách hàng: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.saleTabActive.customer.name))])]),_c('div',[_vm._v(" SĐT: "),_c('span',{staticClass:"font-weight-bold",class:{ 'red--text': !_vm.saleTabActive.customer.phone }},[_vm._v(_vm._s(_vm.saleTabActive.customer.phone || "Chưa có số điện thoại!"))]),(_vm.saleTabActive.customer.phone)?_c('v-btn',{attrs:{"color":"blue darken-4","fab":"","text":"","x-small":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.saleTabActive.customer.phone)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('div',[_vm._v(" Công nợ: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.saleTabActive.customer.cong_no)))])])]):_vm._e(),_c('v-radio-group',{staticClass:"font-weight-bold mb-3 mt-2",attrs:{"disabled":_vm.shippingInfo.tracking_code ||
        (_vm.orderProcessing.status >= 5 &&
          _vm.orderProcessing.received_method === 1) ||
        _vm.orderProcessing.cancel_status === 1,"dense":"","hide-details":"","row":""},model:{value:(_vm.orderProcessing.received_method),callback:function ($$v) {_vm.$set(_vm.orderProcessing, "received_method", $$v)},expression:"orderProcessing.received_method"}},[_c('v-radio',{attrs:{"value":1,"label":"Giao tận nơi"},on:{"click":function($event){return _vm.onSelectMethod(1)}}}),_c('v-radio',{attrs:{"value":2,"label":"Nhận tại cửa hàng"},on:{"click":function($event){return _vm.onSelectMethod(2)}}})],1),(_vm.orderProcessing.received_method === 1)?_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"border":"1px solid #aaaa","border-radius":"5px"},on:{"click":_vm.openModalFormAddress}},[(_vm.orderProcessing.received_address)?_c('div',{staticClass:"py-1 px-2 customer-address-container"},[_c('div',{staticStyle:{"max-width":"276px"}},[_c('div',{staticClass:"black--text d-inline-block"},[_c('span',[_vm._v(_vm._s(_vm.orderProcessing.received_address.name))]),_c('span',{staticClass:"px-1"},[_vm._v("-")]),_c('div',{staticClass:"copied-label"},[_vm._v(" "+_vm._s(_vm._f("VMask")(_vm.orderProcessing.received_address.phone,"### ### ####"))+" ")])]),_c('div',{staticClass:"text-body-2 grey--text text--darken-3"},[_c('div',{staticClass:"copied-label"},[_vm._v(" "+_vm._s(_vm.addressDetails)+" ")])])])]):_c('div',{staticClass:"py-2 px-2"},[_vm._v("Nhập địa chỉ")]),_c('div',{staticClass:"py-1 px-2"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.handleCopyAddress}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v("mdi-content-copy")])],1)]}}],null,false,2182826587)},[_c('span',[_vm._v("Sao chép địa chỉ")])]),(
          _vm.orderProcessing.received_address &&
            !_vm.shippingInfo.tracking_code &&
            _vm.orderProcessing.cancel_status !== 1
        )?_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.clearSelectedAddress}},[_c('v-icon',{attrs:{"size":"22px"}},[_vm._v("mdi-close")])],1)],1):_vm._e()],1)]):_vm._e(),(_vm.orderProcessing.received_method === 1)?_c('v-btn',{staticClass:"mt-1",attrs:{"disabled":_vm.shippingInfo.tracking_code ||
        (_vm.orderProcessing.status >= 5 &&
          _vm.orderProcessing.received_method === 1) ||
        _vm.orderProcessing.cancel_status === 1,"color":"primary","small":"","text":""},on:{"click":_vm.openModalSelectCustomerAddress}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-account-box-multiple-outline ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" CHỌN TỪ SỔ ĐỊA CHỈ KHÁCH HÀNG ")])],1):_vm._e(),(_vm.orderProcessing.received_method === 2)?_c('div',[_c('v-radio-group',{attrs:{"readonly":_vm.orderProcessing.status >= 6 || _vm.orderProcessing.cancel_status === 1,"dense":"","hide-details":""},model:{value:(_vm.orderProcessing.branch_nhan_id),callback:function ($$v) {_vm.$set(_vm.orderProcessing, "branch_nhan_id", $$v)},expression:"orderProcessing.branch_nhan_id"}},_vm._l((_vm.branches),function(item){return _c('div',{key:item.id,class:[
          'custom-radio-wrapper',
          { active: _vm.orderProcessing.branch_nhan_id === item.id }
        ]},[_c('v-radio',{attrs:{"label":((item.name) + " (" + (item.address) + ")"),"value":item.id}})],1)}),0)],1):_vm._e(),(_vm.orderProcessing.received_method === 1)?_c('ModalFormAddress',{attrs:{"selected-address":_vm.orderProcessing.received_address},on:{"addNewAddress":_vm.setSelectedAddress}}):_vm._e(),(_vm.orderProcessing.received_method === 1)?_c('ModalSelectCustomerAddress',{attrs:{"customer-addresses":_vm.customerAddresses,"selected":_vm.orderProcessing.received_address},on:{"select":_vm.setSelectedAddress}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }