<template>
  <div>
    <v-btn
      class="unset-case font-weight-medium text-body-1 pa-0"
      :disabled="isDisabled"
      small
      text
      @click="dialog = true"
    >
      {{ buttonName }}
      <v-icon right>mdi-chevron-right</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold text-h6">Chọn khách hàng</div>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            depressed
            :disabled="!selectedCustomer"
            color="primary"
            small
            @click="onSaved"
            >Chọn</v-btn
          >

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4 customer-wrapper">
          <panel-customer v-model="selectedCustomer"></panel-customer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PanelCustomer from "../PanelCustomer.vue";

export default {
  components: {
    PanelCustomer
  },

  props: {
    value: {
      type: Object,
      default: () => null
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    buttonName: {
      type: String,
      default: "Chọn khách hàng"
    }
  },

  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },
    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    }
  },

  data() {
    return {
      dialog: false,
      selectedCustomer: null
    };
  },

  methods: {
    async openModalCustomer(customerId) {
      await this.$store.dispatch("CUSTOMER/getCustomerById", customerId);

      this.$modal.show({
        name: "modal-customer",
        payload: {
          onSuccessfulCreate: customer => {
            console.log("onSuccessfulCreate", customer);
            this.saleTabActive.customer_id = customer.id;
            this.saleTabActive.customer = JSON.parse(JSON.stringify(customer));
          }
        }
      });
    },

    validateCustomerMessages() {
      let messages = [];

      if (this.customer.gender === null) {
        messages.push("giới tính");
      }

      if (!this.customer.addresses.length) {
        messages.push("địa chỉ");
      }

      if (!this.customer.dob && !this.customer.yob) {
        messages.push("ngày sinh hoặc năm sinh");
      }

      return messages.length
        ? `Vui lòng cập nhật ${messages.join(", ")} cho khách hàng!`
        : "";
    },

    showModalUpdateCustomer() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: "Cập nhật thông tin khách hàng",
          message: this.validateCustomerMessages(),
          confirmBtn: {
            text: "Cập nhật",
            color: "primary",
            onClickHandler: () => {
              this.openModalCustomer(this.selectedCustomer.id);
            }
          }
        }
      });
    },

    async onSaved() {
      await this.$store.dispatch(
        "CUSTOMER/getCustomerById",
        this.selectedCustomer.id
      );

      if (this.validateCustomerMessages().length) {
        this.showModalUpdateCustomer();
        return;
      }

      this.$emit("input", this.customer);

      this.saleTabActive.customer_id = this.selectedCustomer
        ? this.selectedCustomer.id
        : null;
      // Update sale active tab
      this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
      // Get customer addresses
      if (this.selectedCustomer) {
        this.$store.dispatch(
          "CUSTOMER/getCustomerAddresses",
          this.selectedCustomer.id
        );
      }
      this.dialog = false;
    }
  },

  watch: {
    dialog(value) {
      if (value) {
        this.selectedCustomer = this.value;
      }
    }
  }
};
</script>

<style scoped>
.customer-wrapper {
  height: 265px;
}
</style>
