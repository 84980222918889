<template>
  <div class="order-page">
    <!-- Start: Header -->
    <order-header @select-tab="onChangeActiveTab"></order-header>
    <!-- End: Header -->
    <component :is="currentPage"></component>
  </div>
</template>

<script>
import OrderHeader from "./components/OrderHeader.vue";
import TabDetailOrder from "./components/TabDetailOrder/index.vue";
import TabDeposit from "./components/TabTransaction/index.vue";
import TabDetailOrderProcessing from "./components/TabDetailOrderProcessing/index.vue";
import TabParticipant from "./components/TabParticipant";
import TabOrderHistory from "./components/TabOrderHistory/index.vue";

export default {
  name: "OrderPage",

  components: {
    OrderHeader,
    TabDetailOrder,
    TabDeposit,
    TabDetailOrderProcessing,
    TabOrderHistory,
    TabParticipant
  },

  data() {
    return {
      currentPage: "TabDetailOrder"
    };
  },

  // mounted() {
  //   this.$store.dispatch("ORDER/getOrderById", this.$route.query.id);
  // },

  methods: {
    onChangeActiveTab(tab) {
      switch (tab) {
        case "DETAIL_ORDER":
          this.currentPage = "TabDetailOrder";
          break;
        case "DEPOSIT":
          this.currentPage = "TabDeposit";
          break;
        case "PARTICIPANT":
          this.currentPage = "TabParticipant";
          break;
        case "HISTORY":
          this.currentPage = "TabOrderHistory";
          break;
        default:
          this.currentPage = "TabDetailOrderProcessing";
          break;
      }
    }
  }
};
</script>
