<template>
  <div class="px-4">
    <!-- Start: Branch and Time -->
    <div class="d-flex flex-row">
      <v-col cols="6" class="textfield__hide-outline pa-0">
        <v-autocomplete
          v-model="saleTabActive.branch_id"
          class="text-body-2 rounded mt-0"
          dense
          flat
          hide-details
          :items="branches"
          item-text="name"
          item-value="id"
          :menu-props="{ maxWidth: '332px' }"
          no-data-text="Không có dữ liệu"
          placeholder="Chọn chi nhánh"
        >
          <template slot="prepend">
            <v-icon style="margin-top: 2px" color="grey lighten-1" size="18px">
              mdi-map-marker
            </v-icon>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="textfield__hide-outline d-flex flex-row pa-0 justify-end">
        <div
          v-if="saleTabActive.id && saleTabActive.created_at"
          class="text-body-2"
        >
          {{ saleTabActive.created_at }}
        </div>
        <template v-else>
          <div>
            <tp-input-date
              v-model="saleTabActive.created_at"
              :allowed-dates="allowedCreatedDates"
              custom-class="text-body-2 mt-0"
              custom-style="width: 68px"
              dense
              hide-details
            ></tp-input-date>
          </div>
          <div>
            <tp-input-time
              v-model="saleTabActive.created_time"
              custom-class="text-center text-body-2 ml-3 mt-0"
              custom-style="width: 32px"
              dense
              hide-details
              width="100px"
            ></tp-input-time>
          </div>
        </template>
      </v-col>
    </div>
    <!-- End: Branch and Time -->
    <!-- Start: Employee -->
    <div class="d-flex flex-row mt-3">
      <v-col cols="6" class="pa-0">
        <div>
          <v-icon class="mr-3" color="grey lighten-1" size="18px">
            mdi-account
          </v-icon>
          <span>{{
            saleTabActive.id && saleTabActive.created_by_user
              ? saleTabActive.created_by_user.name
              : user.name
          }}</span>
        </div>
      </v-col>
    </div>
    <!-- End: Employee -->

    <v-divider class="my-4"></v-divider>

    <!-- Start: Customer -->
    <div>
      <!-- Start: Customer info -->
      <div
        v-if="saleTabActive.khachhang"
        class="grey lighten-4 rounded px-3 py-2 mt-2"
      >
        <div>
          Khách hàng:
          <span class="font-weight-bold">{{
            saleTabActive.khachhang_name
          }}</span>
        </div>
        <div>
          SĐT:
          <span class="font-weight-bold">{{
            saleTabActive.khachhang.phone
          }}</span>
          <v-btn
            fab
            x-small
            text
            color="blue darken-4"
            @click="copyToClipboard(saleTabActive.khachhang.phone)"
          >
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </div>
        <div>
          Công nợ:
          <span class="font-weight-bold">{{
            saleTabActive.khachhang_cong_no | formatCurrency
          }}</span>
        </div>
      </div>
      <!-- End: Customer info -->
    </div>
    <!-- End: Customer -->
  </div>
</template>

<script>
import DateTimeFunction from "@/core/utils/date-time";

export default {
  data() {
    return {
      allowedCreatedDates: val => {
        const now = new Date(),
          diffDays = DateTimeFunction.getDatesBetweenTwoDates(
            now,
            new Date(val)
          );

        return diffDays > -3 && diffDays < 1;
      },
      customerSearchKey: null
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    customers() {
      return this.$store.getters["CUSTOMER/customers"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    employeesSameBranchWithUser() {
      let employeesSameBranchWithUsers = [];
      if (
        this.saleTabActive &&
        this.saleTabActive.branch_id &&
        this.employees
      ) {
        this.employees.map(item => {
          if (item.branch_id === this.saleTabActive.branch_id)
            employeesSameBranchWithUsers.unshift(item);
        });
        this.employees.map(item => {
          if (item.branch_id !== this.saleTabActive.branch_id)
            employeesSameBranchWithUsers.push(item);
        });
        return employeesSameBranchWithUsers;
      } else return this.employees;
    }
  },
  watch: {
    customerSearchKey(val) {
      if (val !== null && val.length > 0) {
        this.$store.dispatch("CUSTOMER/getCustomers", {
          cur_page: 1,
          per_page: 100,
          search: val
        });
      }
    }
  },
  created() {
    this.$store.dispatch("BRANCH/getAllBranches");
  },
  methods: {
    changeRequireStaff(value) {
      if (value) {
        if (
          this.saleTabActive.participants &&
          this.saleTabActive.participants.length === 0
        ) {
          this.saleTabActive.participants = [];
          this.saleTabActive.participants.push({
            user_id: null,
            note: null
          });
        }
      }
    },
    async openModalCustomer(customerId) {
      if (customerId) {
        await this.$store.dispatch("CUSTOMER/getCustomerById", customerId);
      } else {
        await this.$store.dispatch("CUSTOMER/resetCustomer");
      }

      this.$modal.show({ name: "modal-customer" });
    },

    openModalSaleOrderPromotionVoucher() {
      this.$modal.show({ name: "modal-sale-order-promotion-voucher" });
    }
  }
};
</script>

<style lang="scss" scoped>
.textfield__hide-outline {
  ::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
    border: 0;
  }
}
</style>
