<template>
  <div>
    <v-menu bottom close-on-click offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="px-0 ml-2"
          color="white"
          icon
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card class="pa-2 d-flex flex-col">
        <v-btn
          class="mb-2"
          color="primary"
          outlined
          small
          @click="addTabItem()"
        >
          <v-icon left>mdi-package-variant-closed</v-icon>
          Đơn đặt hàng
        </v-btn>

        <v-btn color="primary" outlined small @click="addSaleReceiptItem()">
          <v-icon left>mdi-receipt-text-outline</v-icon>
          Hoá đơn bán
        </v-btn>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { INIT_SALE_RECEIPT_VALUE } from "@/modules/Transaction/constant";

export default {
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    saleEmployees() {
      return this.employees.filter(i => i.staff_roles.includes("SALES"));
    }
  },

  methods: {
    async addTabItem() {
      const saleTabsSaleReceipts = this.saleTabs.filter(item =>
        item.code.startsWith("Tạo mới")
      );

      console.log("saleTabsSaleReceipts", saleTabsSaleReceipts);

      const emptyOrder = {
        address_id: null,
        assigned_to_id: this.isSaleEmployee(this.user.id) ? this.user.id : null,
        branch_id: this.hasBranchId(this.user.branch_id)
          ? this.user.branch_id
          : null,
        branch_nhan_id: this.hasBranchId(this.user.branch_id)
          ? this.user.branch_id
          : null,
        branch_nhan: null,
        closing_user_id: this.isSaleEmployee(this.user.id)
          ? this.user.id
          : null,
        code: `Tạo mới ${saleTabsSaleReceipts.length + 1}`,
        count_cod_transaction_waiting: 0,
        count_paid: 0,
        count_transaction_waiting: 0,
        customer_id: null,
        customer: null,
        details: [],
        discount: null,
        handles: [],
        id: null,
        note: null,
        order_channel: 2,
        order_type: "ORDER",
        payment_fee: null,
        payment_info: null,
        pre_paid_amount: null,
        promos: [],
        promotion_details: null,
        promotion_id: null, // promotion for invoice
        received_address: null,
        received_method: 2,
        sale_at_store: false,
        shipping_est_date: null,
        shipping_fee: null,
        shipping_partner: null,
        status: 1,
        sum_cod_transaction_waiting: 0,
        sum_paid: 0,
        sum_transaction_waiting: 0,
        site: "THINKPRO",
        value: null,
        timeline: [
          {
            status: 1,
            is_complete: true,
            datetime: null,
            label: "Chờ xác nhận",
            description: ""
          },
          {
            status: 2,
            is_complete: false,
            datetime: null,
            label: "Đã xác nhận",
            description: ""
          },
          {
            status: 3,
            is_complete: false,
            datetime: null,
            label: "Đang xử lý",
            description: ""
          },
          {
            status: 4,
            is_complete: false,
            datetime: null,
            label: "Hoàn thành",
            description: ""
          }
        ]
      };

      await this.$store.dispatch("SALE/addSaleTabItem", emptyOrder);

      // Set active tab
      await this.$store.dispatch(
        "SALE/setSaleTabActiveIndex",
        this.saleTabs.length - 1
      );

      // Reset order and order processing
      await this.$store.dispatch("ORDER/setOrder", emptyOrder);
      await this.$store.dispatch("ORDER/resetOrderProcessing");
      this.$store.dispatch("ORDER/resetOrderPaymentTransactions");
      this.$store.dispatch("ORDER/resetOrderExportTransactions");

      await this.$router.push({ name: "sale" });
    },

    async addSaleReceiptItem() {
      const saleTabsSaleReceipts = this.saleTabs.filter(item =>
        item.code.startsWith("Hóa đơn")
      );

      await this.$store.dispatch("SALE/addSaleTabItem", {
        ...JSON.parse(JSON.stringify(INIT_SALE_RECEIPT_VALUE)),
        code: `Hóa đơn ${saleTabsSaleReceipts.length + 1}`,
        closing_user_id: this.user.role !== "admin" ? this.user.id : null,
        branch_id: this.user && this.user.branch_id ? this.user.branch_id : null
      });

      // Set active tab
      await this.$store.dispatch(
        "SALE/setSaleTabActiveIndex",
        this.saleTabs.length - 1
      );

      this.$store.dispatch("SALE_RECEIPT/resetSaleReceiptPaymentTransactions");

      // Push route
      await this.$router.push({ name: "sale" });
    },

    hasBranchId(branchId) {
      return this.branches.some(i => i.id === branchId);
    },

    isSaleEmployee(employeeId) {
      return this.saleEmployees.some(i => i.id === employeeId);
    }
  }
};
</script>

<style lang="scss" scoped></style>
