<template>
  <div class="mr-1">
    <!-- Start: Search Bar -->
    <search-bar></search-bar>
    <!-- End: Search Bar -->
    <!-- Start: Data Table -->
    <div class="white pb-5">
      <v-data-table
        class="table-scroll tp-table__row-pointer datatable px-3 py-2"
        calculate-widths
        disable-pagination
        no-data-text="Không có dữ liệu"
        no-results-text="Không tìm thấy kết quả phù hợp"
        :headers="headers"
        hide-default-footer
        :loading="false"
        loading-text="Đang tải dữ liệu"
        :items="saleTabActive.sale_receipt.options"
        item-key="id"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ indexOfOptionIds(item) }}
        </template>

        <template v-slot:[`item.product_name`]="{ item }">
          <div class="my-1">
            <div class="font-weight-bold">
              {{ item.product_name }}
              <v-hover
                v-if="checkPromotionAvailable(item)"
                v-slot:default="{ hover }"
              >
                <v-icon
                  class="red--text text--accent-1 ml-1"
                  :class="{ 'red--text text--accent-2': hover }"
                  size="18px"
                  @click="openModalSalePromotion()"
                >
                  mdi-wallet-giftcard
                </v-icon>
              </v-hover>
            </div>
            <div
              class="text-subtitle-2 font-weight-light grey--text text--darken-2"
            >
              <span class="font-weight-bold">{{ item.SKU }} - </span>
              <span class="item-subtitle mr-1" v-html="`${item.name}`"></span>
              <span
                v-if="item.given"
                style="color: red"
                class="font-weight-bold"
              >
                <template v-if="item.tang_type === 1 || item.tang_type === 2">
                  {{
                    item.tang_type === 1
                      ? "(quà tặng)"
                      : `(giảm giá ${item.tang_value}%)`
                  }}
                </template>
                <template v-if="item.tang_type === 3">
                  (giảm {{ item.tang_value | formatCurrency }})
                </template>
              </span>
            </div>
          </div>
        </template>

        <template v-slot:[`item.serials`]="{ item }">
          <v-autocomplete
            v-model="item.serials"
            class="tp-filter-autocomplete text-body-1 my-2"
            dense
            deletable-chips
            :items="item.allSerials"
            item-text="serial_number"
            item-value="id"
            hide-details
            hide-selected
            :disabled="!enableSelectSerialForPromotion(item)"
            multiple
            no-data-text="Không có dữ liệu"
            outlined
            placeholder="Chọn serials"
            single-line
            @change="updateSerialsChange(item)"
            small-chips
            return-object
            @focus="getStockSerialsByOptionId(item)"
          >
          </v-autocomplete>
        </template>

        <template v-slot:[`item.quantity`]="{ item }">
          {{ item.serials.length }}
        </template>

        <template v-slot:[`item.unit_sold_price`]="{ item }">
          <tp-input-price
            v-model="item.unit_sold_price"
            custom-class="text-body-1 mt-0 pt-0"
            dense
            flat
            hide-details
            :min-value="item.rebate_amount ? item.rebate_amount : 0"
            outlined
            solo
            placeholder="Giá trị"
          ></tp-input-price>
        </template>

        <template v-slot:[`item.rebate_amount`]="{ item }">
          <tp-input-price
            v-model="item.rebate_amount"
            custom-class="text-body-1 mt-0 pt-0"
            custom-style="width: 120px"
            dense
            flat
            hide-details
            outlined
            solo
            placeholder="Giá trị"
          ></tp-input-price>
        </template>

        <template v-slot:[`item.sold_price`]="{ item }">
          {{ (item.unit_sold_price - item.rebate_amount) | formatCurrency }}
        </template>

        <template v-slot:[`item.total`]="{ item }">
          {{
            ((item.unit_sold_price - item.rebate_amount) * item.serials.length)
              | formatCurrency
          }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-hover v-slot:default="{ hover }">
            <v-btn
              :color="hover ? 'grey darken-1' : 'grey lighten-1'"
              icon
              small
              @click.stop="removeProductOption(item)"
            >
              <v-icon size="18px">mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-hover>
        </template>
      </v-data-table>
    </div>
    <!-- End: Data Table -->
  </div>
</template>

<script>
import moment from "moment";
import { PRODUCT_PROMOTION_TYPE } from "../../../../constants";
import SearchBar from "./components/SearchBar";

export default {
  components: {
    SearchBar
  },
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name"
        },
        {
          text: "Serials",
          align: "start",
          sortable: false,
          value: "serials"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Đơn giá",
          align: "start",
          sortable: false,
          value: "unit_sold_price"
        },
        {
          text: "Chiết khấu",
          align: "start",
          sortable: false,
          value: "rebate_amount"
        },
        {
          text: "Giá sau giảm",
          align: "start",
          sortable: false,
          value: "sold_price"
        },
        {
          text: "Thành tiền",
          align: "start",
          sortable: false,
          value: "total"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    optionIds() {
      return this.saleTabActive.sale_receipt.options
        .filter(item => !item.parent)
        .map(item => item.id);
    },
    productOptionStockSerials() {
      return this.$store.getters["SERIAL/productOptionStockSerials"];
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  watch: {
    "saleTabActive.branch_id"(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.saleTabActive.sale_receipt.options.forEach(option => {
          option.serials = [];
          option.allSerials = [];
        });
      }
    }
  },
  methods: {
    checkPromotionAvailable(item) {
      const { promotions } = item;

      return !!(
        promotions &&
        (promotions.promo_type_3 || promotions.promo_type_12)
      );
    },

    checkPromotionCondition(promotion) {
      const { dk_limit_number_using, dk_time, dk_using_times } = promotion;

      return (
        (!dk_limit_number_using || dk_limit_number_using > dk_using_times) &&
        (!dk_time || moment(dk_time).valueOf() > moment().valueOf())
      );
    },

    enableSelectSerialForPromotion(item) {
      if (!item.parent) {
        return true;
      }
      let count = 0;
      const parentProduct = this.saleTabActive.sale_receipt.options.find(
        option => option.id === item.parent
      );
      const { promotions } = parentProduct;
      const { promo_type_3 } = promotions;
      const { tk_option } = promo_type_3;
      tk_option.quantity = 5;
      const { quantity } = tk_option;
      if (!quantity) {
        return true;
      }
      const productsPromotion = this.saleTabActive.sale_receipt.options.filter(
        option => option.parent === item.parent
      );
      productsPromotion.forEach(product => {
        count += product.serials.length;
      });

      return Number(quantity) - count > 0 || item.serials.length > 0;
    },

    async getStockSerialsByOptionId(option) {
      let res = [];

      await this.$store.dispatch("SERIAL/getStockSerialsByOptionId", option.id);

      this.branches.forEach(branch => {
        // Filter serial by branch id
        let arr = this.productOptionStockSerials.filter(
          item => item.branch_id === branch.id
        );

        arr = arr.map(value => {
          return {
            ...value,
            disabled:
              this.saleTabActive.branch_id &&
              this.saleTabActive.branch_id !== branch.id
          };
        });

        // Push into res array
        if (arr.length > 0) {
          res.push({ header: `${branch.name} (${arr.length})` });
          res = res.concat(arr);
          // Push devider
          // if (index < this.branches.length - 1) {
          //   res.push({ divider: true });
          // }
        }
      });

      option.allSerials = res;
    },

    indexOfOptionIds(value) {
      if (value.given) {
        return "";
      }
      return this.optionIds.indexOf(value.id) + 1;
    },

    openModalSalePromotion() {
      this.$modal.show({ name: "modal-sale-promotion" });
    },

    async removeProductOption(item) {
      this.saleTabActive.sale_receipt.options = this.saleTabActive.sale_receipt.options.filter(
        option => option.parent !== item.id && option.id !== item.id
      );
      // Update sale active tab
      await this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
    },

    updateSerialsChange(item) {
      const { promotions, parent, price } = item;
      const serialLength = item.serials.length;

      // trường hợp update serials cho sản phẩm được tặng
      if (parent) {
        item.totalPrice = price * serialLength;
        return this.$store.dispatch(
          "SALE/setSaleTabActive",
          this.saleTabActive
        );
      }

      if (promotions) {
        const { promo_type_3 } = promotions;

        if (promo_type_3 && this.checkPromotionCondition(promo_type_3)) {
          const { tk_option } = promo_type_3;

          if (tk_option) {
            const { option_list, tang_type, group, tang_value } = tk_option;
            const free = tang_type === PRODUCT_PROMOTION_TYPE.give_product;

            const checkAdded = this.saleTabActive.sale_receipt.options.findIndex(
              option => option.parent === item.id
            );

            const rootIndex = this.saleTabActive.sale_receipt.options.findIndex(
              option => option.id === item.id
            );

            if (serialLength === 0 && checkAdded !== -1) {
              this.saleTabActive.sale_receipt.options = this.saleTabActive.sale_receipt.options.filter(
                option => {
                  return option.parent !== item.id;
                }
              );
            } else {
              if (option_list && checkAdded === -1) {
                this.saleTabActive.sale_receipt.options.splice(
                  1,
                  rootIndex,
                  ...option_list.map(value => {
                    return {
                      ...value,
                      serials: [],
                      allSerials: [],
                      given: true,
                      name: value.product_name,
                      free,
                      tang_type,
                      tang_value,
                      totalPrice: 0,
                      unit_sold_price: value.price,
                      rebate_amount: free
                        ? value.price
                        : tang_type === 2
                        ? (value.price * tang_value) / 100
                        : tang_value,
                      sold_price: 0,
                      parent: item.id
                    };
                  })
                );
              }

              if (group && checkAdded === -1) {
                // cần call api lấy products theo group rồi add vào tương tự như bên trên
                let productsAllGroups = [];
                group.forEach(gr => {
                  productsAllGroups = productsAllGroups.concat(gr.options);
                });
                this.saleTabActive.sale_receipt.options.splice(
                  1,
                  rootIndex,
                  ...productsAllGroups.map(value => {
                    return {
                      ...value,
                      serials: [],
                      allSerials: [],
                      given: true,
                      free,
                      tang_type,
                      tang_value,
                      unit_sold_price: value.price,
                      rebate_amount: free
                        ? value.price
                        : tang_type === 2
                        ? (value.price * tang_value) / 100
                        : tang_value,
                      parent: item.id
                    };
                  })
                );
              }
            }
          }
        }

        // truowngf hop giam tien hoac giam %
        // if (promo_type_12 && this.checkPromotionCondition(promo_type_12)) {
        //   const { tk_money, tk_percent, tk_type } = promo_type_12;
        //   if (tk_type === 2) {
        //     item.totalPrice =
        //       serialLength * (Number(item.price) - Number(tk_money));
        //   } else if (tk_type === 1) {
        //     item.totalPrice =
        //       serialLength * ((Number(item.price) * (100 - tk_percent)) / 100);
        //   }
        //   this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
        //   return;
        // }
      }

      this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
    }
  }
};
</script>
