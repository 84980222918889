var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blue accent-2 px-4 py-2"},[_c('div',{staticClass:"search-box"},[(_vm.searchBy === 'OPTION')?_c('v-autocomplete',{staticClass:"text-body-1 mr-4",attrs:{"clearable":"","dense":"","flat":"","hide-details":"","items":_vm.filterSearchProductOptions,"item-text":"product_name","item-value":"id","loading":_vm.productOptionStatusRequest.value === 'loading-searchProductOptions',"menu-props":{ maxWidth: '344px' },"no-data-text":"Không có dữ liệu","no-filter":"","placeholder":"Tìm sản phẩm","prepend-inner-icon":"mdi-magnify","return-object":"","solo":"","search-input":_vm.productSearchKey},on:{"update:searchInput":function($event){_vm.productSearchKey=$event},"update:search-input":function($event){_vm.productSearchKey=$event},"change":function($event){return _vm.selectProductOption($event)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"title":data.item.name}},[_c('v-list-item-title',{staticClass:"font-weight-medium"},[_c('span',[_vm._v(_vm._s(data.item.product_name))]),(data.item.promotions && data.item.promotions.promo_type_3)?_c('v-icon',{staticClass:"red--text text--accent-1 ml-1",staticStyle:{"margin-bottom":"4px"},attrs:{"size":"18px"}},[_vm._v(" mdi-wallet-giftcard ")]):_vm._e()],1),_c('div',{staticClass:"grey--text text--darken-2 text-subtitle-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(data.item.SKU)+" - ")]),_c('span',{staticClass:"item-subtitle",domProps:{"innerHTML":_vm._s(data.item.name)}})]),_c('div',{staticClass:"d-flex justify-space-between text-subtitle-2 mt-1"},[_c('div',[(
                  _vm.calculateSalePrice(
                    data.item.price,
                    data.item.promotions
                  ) !== data.item.price
                )?[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.calculateSalePrice(data.item.price, data.item.promotions))))]),_c('span',{staticClass:"text-decoration-line-through font-weight-light ml-2"},[_vm._v(_vm._s(_vm._f("formatCurrency")(data.item.price)))])]:[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm._f("formatCurrency")(data.item.price)))])]],2),_c('div',[_vm._v("Sẵn bán: "+_vm._s(data.item.total_serials))])])],1)]}},{key:"append-outer",fn:function(){return [_c('v-icon',{staticClass:"tp-btn--text",attrs:{"color":"white"},on:{"click":function($event){_vm.searchBy = 'SERIAL'}}},[_vm._v("mdi-barcode-off")])]},proxy:true}],null,false,836765794)}):_vm._e(),(_vm.searchBy === 'SERIAL')?_c('v-text-field',{staticClass:"text-body-1",staticStyle:{"max-width":"344px"},attrs:{"clearable":"","prepend-inner-icon":"mdi-magnify","dense":"","flat":"","hide-details":"","placeholder":"Nhập serial và ấn enter","solo":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getSerialBySerialNumber.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-icon',{staticClass:"tp-btn--text",attrs:{"color":hover ? 'grey darken-1' : 'grey'},on:{"click":function($event){_vm.searchBy = 'OPTION'}}},[_vm._v("mdi-barcode")])]}}],null,false,346455258)})]},proxy:true}],null,false,642505060),model:{value:(_vm.serialNumber),callback:function ($$v) {_vm.serialNumber=$$v},expression:"serialNumber"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }