<template>
  <div>
    <!-- <v-overlay
      v-show="
        orderProcessing.hoa_don_ban_id &&
          saleReceiptStatusRequest.value !== 'success-getSaleReceiptById' &&
          saleReceiptStatusRequest.value !==
            'success-getSaleReceiptPaymentTransactions'
      "
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay> -->

    <v-overlay v-show="false" opacity="0.5">
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-card class="pa-4" flat>
      <div v-if="!orderProcessing.hoa_don_ban_code">
        <div class="text-h6 mb-5">Xuất hoá đơn</div>

        <invoice-form></invoice-form>
      </div>

      <div v-else>
        <sale-receipt-details></sale-receipt-details>
      </div>
    </v-card>
  </div>
</template>

<script>
import InvoiceForm from "./components/InvoiceForm.vue";
import SaleReceiptDetails from "../SaleReceiptDetails";

export default {
  components: {
    SaleReceiptDetails,
    InvoiceForm
  },

  computed: {
    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    }
  },

  created() {
    this.$store.dispatch("SALE/getPosDevices");
  }
};
</script>
