<template>
  <div class="text-body-1">
    <div style="width: 50%">
      <div class="mb-1">Chi nhánh thanh toán</div>

      <tp-select
        v-model="branchId"
        :items="branches"
        clearable
        dense
        hide-details="auto"
        item-text="name"
        item-value="id"
        outlined
        placeholder="Chọn chi nhánh"
        solo
      ></tp-select>

      <v-divider class="my-4"></v-divider>

      <div class="mb-5 d-flex justify-space-between">
        <div>Khách cần trả</div>

        <div class="font-weight-bold">
          {{ khachCanTra | formatCurrency }}
        </div>
      </div>

      <div class="mb-5 d-flex justify-space-between">
        <div>Cần thanh toán</div>

        <div>{{ canThanhToan | formatCurrency }}</div>
      </div>

      <v-divider class="my-4"></v-divider>

      <div class="mb-5 d-flex justify-space-between">
        <div>Khách thanh toán</div>

        <div class="font-weight-bold">
          {{ khachThanhToan | formatCurrency }}
        </div>
      </div>
    </div>

    <tp-form-transactions-payment
      ref="formRef"
      :branch-id="branchId"
      :model-id="orderProcessing.id"
      :model-type="LIST_MODEL_TYPES.HANDLE"
      :payment-cod="orderProcessing.status === 3"
      payment-tra-gop
      @change="onUpdateTransactions"
    ></tp-form-transactions-payment>

    <v-divider class="my-4"></v-divider>

    <div class="mb-5 ">
      <div class="amber lighten-5 px-3 py-2 rounded" style="font-size: 12px">
        <span>
          Đơn hàng đã được thanh toán
          <span class="font-weight-bold">
            {{ order.sum_paid | formatCurrency }} </span
          >, hệ thống tự động chuyển số tiền cọc tối đa có thể sử dụng vào hóa
          đơn bán dựa trên số tiền khách thanh toán. Nếu còn dư có thể chuyển
          cọc ở hóa đơn bán tiếp theo.
        </span>
      </div>

      <div
        class="mt-3 d-flex justify-space-between align-start"
        style="width: 50%"
      >
        <div>Chuyển cọc</div>

        <modal-transactions-history>
          <template #activator>
            <div class="font-weight-bold primary--text">
              {{ chuyenCoc | formatCurrency }}
            </div>
          </template>
        </modal-transactions-history>
      </div>
    </div>

    <v-divider class="my-4"></v-divider>

    <div class="d-flex align-center">
      <v-btn
        class="mr-4"
        color="green lighten-1"
        dark
        depressed
        @click="handleExportInvoice"
      >
        Xuất hoá đơn
      </v-btn>

      <div v-if="totalFee">
        <span
          ><span class="font-weight-bold">{{ totalFee | formatCurrency }}</span>
          phí thanh toán không tính vào giá trị trên đơn.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { sum } from "@vt7/utils";
import { LIST_PAYMENT_KIND, LIST_MODEL_TYPES } from "@/core/constant";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import ModalTransactionsHistory from "./ModalTransactionsHistory.vue";

export default {
  components: {
    ModalTransactionsHistory
  },

  data() {
    return {
      transactions: [],
      branchId: null,
      LIST_PAYMENT_KIND,
      LIST_MODEL_TYPES
    };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },
    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },
    order() {
      return this.$store.getters["ORDER/order"];
    },
    exportInvoiceStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },
    totalAmount() {
      return sum(this.transactions, item => item.amount || 0);
    },
    totalFee() {
      return sum(this.transactions, item => item.payment_fee || 0);
    },
    khachThanhToan() {
      return this.totalAmount + this.totalFee;
    },
    khachCanTra() {
      return this.orderProcessing.total_amount;
    },
    canThanhToan() {
      return this.khachCanTra - this.orderProcessing.deposit_amount;
    },
    chuyenCoc() {
      if (this.totalAmount < this.khachCanTra) {
        if (this.totalAmount > this.canThanhToan) {
          return this.khachCanTra - this.totalAmount;
        }

        return this.orderProcessing.deposit_amount;
      }

      return 0;
    }
  },

  methods: {
    validateExportInvoice() {
      const isValidForm = this.$refs.formRef.$refs.formRef.validate();

      if (!this.branchId) {
        showModalAlertError(this, {
          title: "Lỗi",
          message: "Vui lòng chọn chi nhánh thanh toán"
        });

        return false;
      }

      if (!isValidForm) return false;

      return true;
    },

    async handleExportInvoice() {
      if (!this.validateExportInvoice()) return;

      const formatedTransactions = this.transactions
        .filter(item => item.amount > 0)
        .map(item => {
          const receiverableType =
            item.kind === LIST_PAYMENT_KIND.TIEN_MAT.id
              ? "App\\Models\\Branch"
              : item.kind === LIST_PAYMENT_KIND.CHUYEN_KHOAN.id
              ? null
              : "App\\Models\\Supplier";

          let obj = {
            ...item,
            branch_id: this.branchId,
            transactionable_id: this.order.id,
            transactionable_code: this.order.code,
            transactionable_type: "App\\Models\\HoaDonBan",
            senderable_id: this.order.customer_id,
            senderable_type: "App\\Models\\User",
            sender_bank_id: null,
            receiverable_type: receiverableType
          };

          if (obj.receiverable_type === "App\\Models\\Branch") {
            obj.receiverable_id = this.branchId || this.order.branch_id;
          }

          return obj;
        });

      const payload = {
        handle_id: this.orderProcessing.id,
        transactions: formatedTransactions
      };

      const response = await this.$store.dispatch(
        "ORDER/exportInvoice",
        payload
      );

      if (this.exportInvoiceStatusRequest.value === "success-exportInvoice") {
        showToastActionAlert(this, {
          message: "Xuất hóa đơn bán thành công"
        });

        await this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
          response.id
        );

        await this.$store.dispatch(
          "ORDER/getOrderProcessingById",
          this.orderProcessing.id
        );

        this.$store.commit("SALE_RECEIPT/SET_SALE_RECEIPT", response);
      } else if (
        this.exportInvoiceStatusRequest.value === "error-exportInvoice"
      ) {
        showModalAlertError(this, {
          title: this.exportInvoiceStatusRequest.message.message
        });
      }
    },

    onUpdateTransactions(value) {
      this.transactions = value;
    }
  },

  mounted() {
    this.branchId = this.orderProcessing.branch_id;
  }
};
</script>

<style lang="scss" scoped></style>
