<template>
  <div class="pb-3">
    <!-- Start: Customer info -->
    <div
      v-if="saleTabActive.customer"
      class="grey lighten-4 rounded px-3 py-2 mt-2"
    >
      <div>
        Khách hàng:
        <span class="font-weight-bold">{{ saleTabActive.customer.name }}</span>
      </div>
      <div>
        SĐT:
        <span
          :class="{ 'red--text': !saleTabActive.customer.phone }"
          class="font-weight-bold"
          >{{ saleTabActive.customer.phone || "Chưa có số điện thoại!" }}</span
        >
        <v-btn
          v-if="saleTabActive.customer.phone"
          color="blue darken-4"
          fab
          text
          x-small
          @click="copyToClipboard(saleTabActive.customer.phone)"
        >
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </div>
      <div>
        Công nợ:
        <span class="font-weight-bold">{{
          saleTabActive.customer.cong_no | formatCurrency
        }}</span>
      </div>
    </div>
    <!-- End: Customer info -->

    <v-radio-group
      v-model="orderProcessing.received_method"
      :disabled="
        shippingInfo.tracking_code ||
          (orderProcessing.status >= 5 &&
            orderProcessing.received_method === 1) ||
          orderProcessing.cancel_status === 1
      "
      class="font-weight-bold mb-3 mt-2"
      dense
      hide-details
      row
    >
      <v-radio
        :value="1"
        label="Giao tận nơi"
        @click="onSelectMethod(1)"
      ></v-radio>
      <v-radio
        :value="2"
        label="Nhận tại cửa hàng"
        @click="onSelectMethod(2)"
      ></v-radio>
    </v-radio-group>

    <!-- Start: Customer Address -->
    <div
      v-if="orderProcessing.received_method === 1"
      class="d-flex justify-space-between
          align-center"
      style="
    border: 1px solid #aaaa;
    border-radius: 5px;"
      @click="openModalFormAddress"
    >
      <div
        v-if="orderProcessing.received_address"
        class="py-1 px-2 customer-address-container"
      >
        <div style="max-width: 276px">
          <div class="black--text d-inline-block">
            <span>{{ orderProcessing.received_address.name }}</span>
            <span class="px-1">-</span>

            <div class="copied-label">
              {{
                orderProcessing.received_address.phone | VMask("### ### ####")
              }}
            </div>
          </div>
          <div class="text-body-2 grey--text text--darken-3">
            <div class="copied-label">
              {{ addressDetails }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="py-2 px-2">Nhập địa chỉ</div>

      <!-- Start: Address action -->
      <div class="py-1 px-2">
        <!-- Start: Copy address -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" @click="handleCopyAddress" v-on="on">
              <v-icon size="20px">mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Sao chép địa chỉ</span>
        </v-tooltip>
        <!-- End: Copy address -->

        <!-- Start: Clear data -->
        <div
          v-if="
            orderProcessing.received_address &&
              !shippingInfo.tracking_code &&
              orderProcessing.cancel_status !== 1
          "
        >
          <v-btn icon @click="clearSelectedAddress">
            <v-icon size="22px">mdi-close</v-icon>
          </v-btn>
        </div>
        <!-- End: Clear data -->
      </div>
      <!-- End: Address action -->
    </div>
    <!-- End: Customer address -->

    <!-- Start: Choose Customer Address -->
    <v-btn
      v-if="orderProcessing.received_method === 1"
      :disabled="
        shippingInfo.tracking_code ||
          (orderProcessing.status >= 5 &&
            orderProcessing.received_method === 1) ||
          orderProcessing.cancel_status === 1
      "
      class="mt-1"
      color="primary"
      small
      text
      @click="openModalSelectCustomerAddress"
    >
      <v-icon size="18">
        mdi-account-box-multiple-outline
      </v-icon>
      <span class="ml-2">
        CHỌN TỪ SỔ ĐỊA CHỈ KHÁCH HÀNG
      </span>
    </v-btn>

    <div v-if="orderProcessing.received_method === 2">
      <v-radio-group
        v-model="orderProcessing.branch_nhan_id"
        :readonly="
          orderProcessing.status >= 6 || orderProcessing.cancel_status === 1
        "
        dense
        hide-details
      >
        <div
          v-for="item in branches"
          :key="item.id"
          :class="[
            'custom-radio-wrapper',
            { active: orderProcessing.branch_nhan_id === item.id }
          ]"
        >
          <v-radio
            :label="`${item.name} (${item.address})`"
            :value="item.id"
          ></v-radio>
        </div>
      </v-radio-group>
    </div>
    <!-- End: Received branch -->
    <ModalFormAddress
      v-if="orderProcessing.received_method === 1"
      :selected-address="orderProcessing.received_address"
      @addNewAddress="setSelectedAddress"
    />
    <ModalSelectCustomerAddress
      v-if="orderProcessing.received_method === 1"
      :customer-addresses="customerAddresses"
      :selected="orderProcessing.received_address"
      @select="setSelectedAddress"
    />
  </div>
</template>

<script>
import ModalFormAddress from "@/modules/Sale/pages/Order/pages/OrderPage/components/modals/ModalFormAddress.vue";
import ModalSelectCustomerAddress from "@/modules/Sale/pages/Order/pages/OrderPage/components/modals/ModalSelectCustomerAddress.vue";

export default {
  components: {
    ModalFormAddress,
    ModalSelectCustomerAddress
  },

  computed: {
    addressDetails() {
      return (
        this.orderProcessing.received_address.so_nha +
        ", " +
        this.orderProcessing.received_address.phuong_xa +
        ", " +
        this.orderProcessing.received_address.quan_huyen +
        ", " +
        this.orderProcessing.received_address.tinh_thanhpho
      );
    },

    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    customerAddresses() {
      return this.$store.getters["CUSTOMER/customerAddresses"];
    },

    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    shippingInfo() {
      return this.$store.getters["ORDER_PROCESSING/shippingInfo"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  methods: {
    clearSelectedAddress(event) {
      event.stopPropagation();
      this.$store.commit("ORDER/SET_ORDER_PROCESSING", {
        ...this.orderProcessing,
        received_address: null
      });
    },

    handleCopyAddress(event) {
      event.stopPropagation();
      this.copyToClipboard(
        `${this.orderProcessing.received_address.name}, ${this.orderProcessing.received_address.phone}, ${this.addressDetails}`
      );
    },

    onSelectMethod(method) {
      if (method === 1) {
        this.orderProcessing.status = 3;
        this.updateLabelStatus(5, "Đang giao hàng");
      } else {
        this.orderProcessing.status = 5;
        this.updateLabelStatus(5, "Chờ khách tới nhận");
      }
    },

    openModalSelectCustomerAddress() {
      this.$modal.show({ name: "modal-select-customer-address" });
    },

    openModalFormAddress() {
      !this.shippingInfo.tracking_code &&
      this.orderProcessing.cancel_status !== 1 &&
      !(
        this.orderProcessing.status >= 5 &&
        this.orderProcessing.received_method === 1
      )
        ? this.$modal.show({ name: "modal-form-address" })
        : null;
    },

    updateLabelStatus(statusId, content) {
      if (!this.orderProcessing.timeline) return;

      const { timeline } = this.orderProcessing;

      for (let i = 0; i < timeline.length; i++) {
        timeline[i].is_complete = false;

        if (timeline[i].status === statusId) {
          if (content) {
            timeline[i].label = content;
          }
        }
      }
    },

    setSelectedAddress(data) {
      this.$store.commit("ORDER/SET_ORDER_PROCESSING", {
        ...this.orderProcessing,
        received_address: data
      });
    }
  },

  watch: {
    "saleTabActive.received_method"(val) {
      if (val === 1) {
        this.saleTabActive.branch_nhan_id = null;
        this.saleTabActive.branch_nhan = null;
      }
      if (val === 2) {
        this.saleTabActive.shipping_partner = null;
      }
      // Update sale active tab
      this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
    }
  }
};
</script>

<style scoped>
.custom-radio-wrapper {
  border: 1px solid #f0f2fa;
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 8px;
}

.active {
  border: 1px solid #0065ee;
}
</style>
