var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table__row-pointer datatable px-3 pb-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có sản phẩm khuyến mại nào","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","hide-default-header":"","loading":false,"loading-text":"Đang tải dữ liệu","items":_vm.slidePromotionTkOptions,"item-key":"id"},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-bold grey--text text--darken-2"},[_c('span',[_vm._v(_vm._s(item.SKU))])])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"font-weight-bold red--text"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.calculateSalePrice(item.price)))+" ")]),_c('div',{staticClass:"text-body-2"},[_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.price)))])])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":!_vm.selectedPromotionOptions.includes(item.id) &&
          _vm.selectedPromotionOptions.length >=
            parseInt(_vm.promotion.tk_option.quantity),"input-value":_vm.selectedPromotionOptions,"value":item.id},on:{"click":function($event){return _vm.selectPromotionOption(item)}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }