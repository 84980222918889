<template>
  <div class="transaction-page px-5 pt-4 pb-6">
    <v-chip-group
      v-model="activeTab"
      active-class="blue darken-4 white--text font-weight-bold"
      mandatory
      outlined
      @change="handleSelectFilter"
    >
      <v-chip :outlined="activeTab !== 0">
        Tất cả ({{ orderPaymentTransactions.length }})
      </v-chip>

      <v-chip :outlined="activeTab !== 1">
        Thu ({{ paymentIncomeLength }})
      </v-chip>

      <v-chip :outlined="activeTab !== 2">
        Chi ({{ paymentSpendLength }})
      </v-chip>
    </v-chip-group>

    <div class="transaction-page__title mb-2">
      <div class="font-weight-bold text-h5">
        Lịch sử thanh toán
      </div>

      <div class="d-flex flex-row justify-end align-center mb-4">
        <span class="text-body-1 mr-2">Xem phiếu bị huỷ</span>
        <v-checkbox
          v-model="viewCancelStatus"
          class="mt-0"
          color="primary"
          dense
          hide-details
        ></v-checkbox>
      </div>
    </div>

    <div class="transaction-page__data mb-6">
      <list-payment-transaction
        :items="filteredOrderPaymentTransactions"
      ></list-payment-transaction>
    </div>

    <div class="transaction-page__actions">
      <v-btn
        v-if="
          order.status !== ORDER_STATUS.DA_HOAN_THANH ||
            hasAnyRole(['Admin', 'Nhóm quyền Kế toán tổng hợp'])
        "
        class="mr-2"
        color="primary"
        depressed
        @click="openModalFormOrderTransaction"
      >
        <v-icon class="mr-1" left small>mdi-plus</v-icon>
        Tạo phiếu thu
      </v-btn>

      <v-btn color="primary" outlined @click="openModalFormChiHoanCoc">
        <v-icon class="mr-1" left small>mdi-plus</v-icon>
        Tạo phiếu chi
      </v-btn>
    </div>

    <div class="transaction-page__modals">
      <modal-form-order-transaction :form-payment-type="'DAT COC'" />
      <modal-form-chi-hoan-coc
        :branch="order.branch"
        :customer="order.customer"
        :transactionable-code="order.code"
        :transactionable-id="order.id"
        transactionable-type="App\Models\Order"
        transactionable-type-name="đơn hàng"
      ></modal-form-chi-hoan-coc>
    </div>
  </div>
</template>
<script>
import ListPaymentTransaction from "./components/ListPaymentTransaction.vue";
import ModalFormChiHoanCoc from "@/modules/Sale/components/modal/ModalFormChiHoanCoc";
import ModalFormOrderTransaction from "./components/modal/ModalFormOrderTransaction.vue";
import { LIST_PAYMENT_STATUS, LIST_PAYMENT_TYPE } from "@/core/constant";
import { ORDER_STATUS } from "@/modules/Sale/constants";
import { usePermission } from "@/core/composables";

// import io from "socket.io-client";

export default {
  components: {
    ListPaymentTransaction,
    ModalFormChiHoanCoc,
    ModalFormOrderTransaction
  },

  data() {
    return {
      activeTab: 0,
      viewCancelStatus: false,
      selectedFilter: [0],
      LIST_PAYMENT_TYPE,
      isConnected: false,
      socketMessage: "",
      LIST_PAYMENT_STATUS,
      ORDER_STATUS
    };
  },

  setup() {
    const { hasAnyRole } = usePermission();

    return {
      hasAnyRole
    };
  },

  mounted() {
    this.$store.dispatch("SALE/getPosDevices");
  },

  computed: {
    filteredOrderPaymentTransactions() {
      const isStatusFilterApplied = this.selectedFilter.includes(0);

      return this.orderPaymentTransactions.filter(item => {
        const isCancel = item.status === LIST_PAYMENT_STATUS.CANCEL.id;
        const isSelectedType = this.selectedFilter.includes(item.type);

        if (isStatusFilterApplied && this.viewCancelStatus) {
          return true;
        }

        if (isStatusFilterApplied) {
          return !isCancel;
        }

        if (this.viewCancelStatus) {
          return isSelectedType;
        }

        return !isCancel && isSelectedType;
      });
    },

    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderPaymentTransactions() {
      return this.$store.getters["ORDER/orderPaymentTransactions"];
    },

    paymentIncomeLength() {
      const paymentIcome = this.orderPaymentTransactions.filter(
        item =>
          item.type === this.LIST_PAYMENT_TYPE.THU.id ||
          item.type === this.LIST_PAYMENT_TYPE.THU_HO.id
      );
      return paymentIcome.length;
    },

    paymentSpendLength() {
      const paymentSpend = this.orderPaymentTransactions.filter(
        item => item.type === this.LIST_PAYMENT_TYPE.CHI.id
      );
      return paymentSpend.length;
    }
  },

  methods: {
    openModalFormOrderTransaction() {
      this.$modal.show({ name: "modal-form-order-transaction" });
    },

    openModalFormChiHoanCoc() {
      this.$modal.show({ name: "modal-form-chi-hoan-coc" });
    },
    handleSelectFilter(value) {
      if (value === 0) this.selectedFilter = [0];
      if (value === 1)
        this.selectedFilter = [
          this.LIST_PAYMENT_TYPE.THU.id,
          this.LIST_PAYMENT_TYPE.THU_HO.id
        ];
      if (value === 2) this.selectedFilter = [this.LIST_PAYMENT_TYPE.CHI.id];
    }
  }
};
</script>
<style lang="scss">
.transaction-page {
  background-color: #fff;
  min-height: calc(100vh - 96px);
}

.transaction-page__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.transaction-page__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>
