<template>
  <div>
    <div class="amount-item pb-2">
      <div class="d-flex flex-col">
        <span class="flex-grow-1">Tổng tiền hàng</span>
        <span class="text-caption">{{ productQuantity }} sản phẩm</span>
      </div>
      <span>{{ goodsTotal | formatCurrency }}</span>
    </div>

    <!-- <div class="pb-2  amount-item shipping-wrapper">
      <span class="flex-grow-1">Phí giao hàng</span>

      <tp-input-price
        v-model="saleTabActive.shipping_fee"
        custom-class="text-body-1 mt-0 pt-0 w-113"
        dense
        hide-details
      ></tp-input-price>
    </div> -->

    <div
      v-if="saleTabActive.payment_fee"
      class="pb-2  amount-item shipping-wrapper"
    >
      <span class="flex-grow-1">Phí thanh toán</span>

      <span>{{ saleTabActive.payment_fee | formatCurrency }}</span>
    </div>

    <div
      v-if="saleTabActive.order_type === 'REPAIR'"
      class="pb-2  amount-item shipping-wrapper"
    >
      <span class="flex-grow-1">Phí sửa chữa</span>

      <tp-input-price
        v-model="saleTabActive.sc_fee"
        custom-class="text-body-1 mt-0 pt-0 w-113"
        dense
        hide-details
      ></tp-input-price>
    </div>

    <div v-if="saleTabActive.return_goods_value" class="amount-item mb-2">
      <span class="flex-grow-1">Giá trị hàng trả</span>
      <span>{{ saleTabActive.return_goods_value | formatCurrency }}</span>
    </div>

    <div v-if="saleTabActive.return_phitrahang" class="amount-item mb-2">
      <span class="flex-grow-1">Phí trả hàng</span>
      <span>{{ saleTabActive.return_phitrahang | formatCurrency }}</span>
    </div>

    <div v-if="saleTabActive.discount" class="amount-item mb-2">
      <span class="flex-grow-1">Giảm giá</span>
      <span>-{{ saleTabActive.discount | formatCurrency }}</span>
    </div>

    <div class="amount-item mb-2">
      <span class="flex-grow-1 font-weight-bold">Khách cần trả</span>
      <span>{{ saleTabActive.value | formatCurrency }}</span>
    </div>

    <div class="amount-item mb-2">
      <span class="flex-grow-1">Khách đã trả:</span>
      <span class="primary--text clickable-text" @click="goToTransactionsPage">
        {{ saleTabActive.sum_paid | formatCurrency }}
      </span>
    </div>

    <div class="amount-item mb-2">
      <span class="flex-grow-1">Còn nợ</span>
      <span class="font-weight-bold">{{
        saleTabActive.debt_customer || 0 | formatCurrency
      }}</span>
    </div>

    <!-- <div v-if="saleTabActive.id">
      <v-divider
        v-if="
          saleTabActive.count_transaction_waiting ||
            saleTabActive.count_cod_transaction_waiting
        "
        class="my-2"
      ></v-divider>

      <div
        v-if="saleTabActive.count_transaction_waiting"
        class="amount-item mb-2"
      >
        <span class="flex-grow-1"
          >TT chờ xử lý ({{
            saleTabActive.count_transaction_waiting
          }}
          phiếu)</span
        >
        <span>{{
          saleTabActive.sum_transaction_waiting | formatCurrency
        }}</span>
      </div>

      <div
        v-if="saleTabActive.count_cod_transaction_waiting"
        class="amount-item mb-2"
      >
        <span class="flex-grow-1"
          >COD chờ thu khách ({{
            saleTabActive.count_cod_transaction_waiting
          }}
          phiếu)</span
        >
        <span>{{
          saleTabActive.sum_cod_transaction_waiting | formatCurrency
        }}</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { PROMOTION_TK_TYPE } from "@/core/constant";
import { calculateOrderDiscountAmount } from "@/core/composables/usePromotion";

export default {
  computed: {
    customerNeedToPay() {
      let total = this.goodsTotal;

      if (this.saleTabActive.shipping_fee) {
        total += parseInt(this.saleTabActive.shipping_fee);
      }
      if (this.saleTabActive.payment_fee) {
        total += parseInt(this.saleTabActive.payment_fee);
      }
      if (this.saleTabActive.sc_fee) {
        total += parseInt(this.saleTabActive.sc_fee);
      }
      if (this.saleTabActive.return_goods_value) {
        total -= parseInt(this.saleTabActive.return_goods_value);
      }
      if (this.saleTabActive.return_phitrahang) {
        total += parseInt(this.saleTabActive.return_phitrahang);
      }
      if (this.saleTabActive.discount) {
        total -= parseInt(this.saleTabActive.discount);
      }

      return total;
    },

    goodsCount() {
      return (
        this.saleTabActive.details &&
        this.saleTabActive.details.reduce((counter, item) => {
          const giftCounter = item.gifts.reduce(
            (giftCounter, gift) => giftCounter + Number(gift.qty),
            0
          );

          return counter + Number(item.qty) + giftCounter;
        }, 0)
      );
    },

    goodsTotal() {
      return (
        this.saleTabActive.details &&
        this.saleTabActive.details.reduce((total, item) => {
          const giftTotal = item.gifts.reduce(
            (giftTotal, gift) =>
              giftTotal + gift.qty * (gift.unit_price - gift.rebate_amount),
            0
          );

          return (
            total +
            item.option.qty *
              (item.option.unit_price - item.option.rebate_amount) +
            giftTotal
          );
        }, 0)
      );
    },

    orderDiscount() {
      return this.saleTabActive.promos.reduce((total, item) => {
        if (
          (item.promo.tk_type === PROMOTION_TK_TYPE.PERCENT && item.selected) ||
          (item.promo.tk_type === PROMOTION_TK_TYPE.AMOUNT && item.selected)
        ) {
          total += calculateOrderDiscountAmount(this.goodsTotal, item.promo);
        }

        return total;
      }, 0);
    },

    orderHandlesRebateAmount() {
      return this.saleTabActive.handles.reduce((total, item) => {
        if (!item.cancel_status && item.discount) {
          return total + Number(item.discount);
        }

        return total;
      }, 0);
    },

    orderHandlesShippingAmount() {
      return this.saleTabActive.handles.reduce((total, item) => {
        if (!item.cancel_status && item.shipping_fee) {
          return total + Number(item.shipping_fee);
        }

        return total;
      }, 0);
    },

    productQuantity() {
      return this.saleTabActive.details.reduce((total, item) => {
        return total + Number(item.option.qty);
      }, 0);
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  methods: {
    goToTransactionsPage() {
      // 1 is index of transaction page
      this.$store.commit("ORDER/SET_ORDER_ACTIVE_SUB_TAB", 1);
    }
  },

  watch: {
    orderDiscount(val) {
      this.saleTabActive.discount = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.amount-item {
  align-items: flex-start;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
}

.w-113 {
  width: 113px;
}

.shipping-wrapper {
  display: flex;
  align-items: center;
}

.clickable-text {
  cursor: pointer;
}

.clickable-text:hover {
  text-decoration: underline;
}
</style>
