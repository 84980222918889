var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tp-modal',{attrs:{"name":"modal-order-processing","id":"modalSaleOrderProcessing","max-width":"1050px"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-4"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Xử lý đơn hàng")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeModal()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',{staticClass:"grey lighten-3 pa-5",attrs:{"fluid":""}},[_c('v-card',{staticClass:"card-content",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex justify-center mb-6 pt-6"},[_c('tp-search-option',{attrs:{"default-option":_vm.searchType,"outlined":"","options":_vm.searchOptions,"value":_vm.searchKey},on:{"search":function($event){return _vm.updateSearchKey($event)}}})],1),_c('v-data-table',{staticClass:"tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","dense":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.orderStatusRequest.value === 'loading-getOrders',"loading-text":"Đang tải dữ liệu","items":_vm.orders,"footer-props":_vm.dataTableFooterProps,"item-key":"id"},on:{"update:page":function($event){return _vm.updatePage($event)},"update:items-per-page":function($event){return _vm.updateItemPerPage($event)}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length > 0)?_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex"},[(item.order_type === 'SALE_AT_STORE')?_c('div',{staticClass:"mr-2 font-weight-bold",staticStyle:{"color":"#FDD835 !important"}},[_vm._v(" POS ")]):_vm._e(),_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(
                              item.code,
                              'modalSaleOrderProcessing'
                            )}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1),_c('td',[(item.details.length > 0)?_vm._l((item.details),function(option,index){return _c('ul',{key:index,staticClass:"my-2"},[_c('li',{},[_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(option.qty.toString().padStart(2, "0"))+" ")]),_c('span',[_vm._v(_vm._s(((option.option.product_name) + " - " + (option.option.SKU))))])])])])}):_c('div',[_vm._v("Không có sản phẩm nào.")])],2),_c('td',[(item.customer)?[_c('div',[_vm._v(_vm._s(item.customer.name))]),_c('div',{staticClass:"text-subtitle-2 font-weight-light"},[_vm._v(" "+_vm._s(item.customer.phone)+" ")])]:[_vm._v("N/A")]],2),_c('td',[_vm._v(_vm._s(_vm.orderChannelList[item.order_channel - 1].name))]),_c('td',[_vm._v(_vm._s(item.created_at))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.calculateOrderTotal(item.details)))+" ")]),_c('td',[_c('div',{staticStyle:{"min-width":"150px"}},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.getOrderStatus(item.status).color,"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.getOrderStatus(item.status).text)+" ")]),(item.cancel_status === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"red accent-2","icon":"","outlined":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red accent-2","size":"14px"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Đã hủy")])]):_vm._e()],1)]),_c('td',[_c('v-btn',{staticClass:"my-2",attrs:{"color":"primary","small":"","dark":"","depressed":""},on:{"click":function($event){return _vm.selectOrder(item)}}},[_vm._v(" Chọn ")])],1)])}),0):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":"8"}},[_vm._v("Không có dữ liệu")])])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }