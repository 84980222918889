<template>
  <div>
    <div @click="visible = true">
      <slot name="activator"></slot>
    </div>

    <v-dialog v-model="visible" max-width="1100">
      <v-card class="border-0" flat>
        <div class="card px-5 py-3 align-center d-flex" height="56x">
          <div class="font-weight-bold">Khách thanh toán</div>

          <v-spacer></v-spacer>

          <v-btn class="mr-2" color="primary" depressed @click="onSaved">
            Xong
          </v-btn>

          <v-btn icon @click="visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>

      <div
        class="v-window-item tab-item grey lighten-3 pa-5 v-window-item--active"
      >
        <v-card class="pa-5 text-body-1" flat>
          <div class="mb-5" style="width: 50%">
            <div class="d-flex justify-space-between">
              <div>Khách cần trả</div>
              <div class="font-weight-bold">
                {{ totalAmountToPay | formatCurrency }}
              </div>
            </div>

            <v-divider class="my-4"></v-divider>

            <div class="d-flex justify-space-between">
              <div>Khách thanh toán</div>
              <div class="font-weight-bold">
                {{ totalAmount | formatCurrency }}
              </div>
            </div>
          </div>

          <tp-form-transactions-payment
            ref="formRef"
            :branch-id="branchId"
            @change="onUpdateTransactions"
          ></tp-form-transactions-payment>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { sum } from "@vt7/utils";

export default {
  data() {
    return {
      visible: false,
      payments: []
    };
  },

  props: {
    branchId: {
      type: Number,
      require: true
    },
    totalAmountToPay: {
      type: Number,
      require: true,
      default: 0
    }
  },

  computed: {
    totalAmount() {
      return sum(this.payments, payment => payment.amount || 0);
    },
    totalFee() {
      return sum(this.payments, payment => payment.payment_fee || 0);
    }
  },

  methods: {
    onSaved() {
      const isValid = this.$refs.formRef.$refs.formRef.validate();

      if (!isValid) return;

      const payload = {
        payments: this.payments,
        totalAmount: this.totalAmount,
        totalFee: this.totalFee
      };

      this.$emit("onSaved", payload);
      this.visible = false;
    },

    onUpdateTransactions(value) {
      this.payments = value;
    }
  }
};
</script>
