var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-body-1 py-2"},[_c('sidebar-general'),_c('v-divider',{staticClass:"ma-4"}),_c('sidebar-customer'),_c('v-divider',{staticClass:"ma-4"}),_c('sidebar-main',{key:_vm.saleTabActive.code + _vm.saleTabActive.order_type,on:{"onChangePayment":function($event){_vm.payments = $event}}}),(!_vm.saleTabActive.status)?_c('div',{staticClass:"px-4 mt-5"},[_c('v-btn',{staticClass:"btn-submit",attrs:{"dark":_vm.saleReceiptStatusRequest.value !== 'loading-createSaleReceipt' ||
          _vm.saleReceiptStatusRequest.value === 'loading-getSaleReceiptById',"disabled":_vm.saleReceiptStatusRequest.value === 'loading-createSaleReceipt' ||
          _vm.saleReceiptStatusRequest.value === 'loading-getSaleReceiptById',"loading":_vm.saleReceiptStatusRequest.value === 'loading-createSaleReceipt' ||
          _vm.saleReceiptStatusRequest.value === 'loading-getSaleReceiptById',"block":"","color":"success","depressed":"","large":""},on:{"click":function($event){_vm.saleReceiptStatusRequest.value === 'loading-createSaleReceipt' ||
        _vm.saleReceiptStatusRequest.value === 'loading-getSaleReceiptById'
          ? null
          : _vm.createSaleReceipt()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Xuất hóa đơn ")],1),(!_vm.saleTabActive.id)?_c('v-btn',{staticClass:"mt-4",attrs:{"block":"","color":"primary","depressed":"","large":""},on:{"click":function($event){return _vm.createSaleReceipt(true)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Tạo hóa đơn nháp ")],1):_vm._e()],1):(_vm.saleTabActive.status !== _vm.SALE_RECEIPT_STATUS.CANCEL.value)?_c('div',{staticClass:"px-4 mt-5"},[_c('v-btn',{staticClass:"mr-2",attrs:{"to":{
        name: 'print_sale-receipt',
        params: {
          saleReceiptId: _vm.saleReceipt.id
        }
      },"block":"","color":"primary","depressed":"","large":"","target":"_blank"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-printer")]),_vm._v(" In phiếu ")],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }