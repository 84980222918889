<template>
  <tp-modal
    max-width="900px"
    name="modal-form-sale-receipt-transaction"
    width="90%"
    @close="closeModal()"
  >
    <v-card>
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Thông tin thanh toán hóa đơn {{ saleReceipt.code }}
        </div>

        <v-spacer></v-spacer>

        <v-btn
          :loading="
            paymentTransactionStatusRequest.value ===
              'loading-createPaymentTransaction'
          "
          class="ml-2"
          color="primary"
          dark
          depressed
          @click="onConfirm()"
        >
          Xác nhận
        </v-btn>

        <v-btn class="ml-2" icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <div
          class="v-window-item tab-item grey lighten-3 v-window-item--active"
        >
          <v-card class="pa-5 text-body-1" flat>
            <div class="mb-6" style="width: 50%">
              <div class="mb-3">
                Chi nhánh thanh toán
              </div>

              <tp-select
                v-model="paymentBranch"
                :items="branches"
                clearable
                dense
                hide-details="auto"
                item-text="name"
                item-value="id"
                outlined
                placeholder="Chọn chi nhánh"
                solo
              ></tp-select>
            </div>

            <div class="mb-5" style="width: 50%">
              <div class="d-flex justify-space-between">
                <div>Khách cần trả</div>
                <div class="font-weight-bold">
                  {{ saleReceipt.value | formatCurrency }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>Khách đã trả</div>
                <div class="font-weight-bold">
                  {{ saleReceipt.total_paid | formatCurrency }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>Cần thanh toán nốt</div>
                <div class="font-weight-bold">
                  {{ saleReceipt.need_to_pay | formatCurrency }}
                </div>
              </div>

              <v-divider class="my-4"></v-divider>

              <div class="d-flex justify-space-between">
                <div>Khách thanh toán</div>
                <div class="font-weight-bold">
                  {{ totalAmount | formatCurrency }}
                </div>
              </div>
            </div>

            <tp-form-transactions-payment
              :key="paymentKey"
              ref="formRef"
              :branch-id="paymentBranch"
              :model-id="saleReceipt.id"
              :model-type="LIST_MODEL_TYPES.HOA_DON_BAN"
              payment-cod
              payment-tra-gop
              @change="onUpdateTransactions"
            ></tp-form-transactions-payment>
          </v-card>
        </div>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import {
  getThanhToanNgayTotal,
  getThanhToanSauTotal,
  showModalAlertError,
  showToastActionAlert
} from "@/core/composables";
import { sum } from "@vt7/utils";
import { LIST_MODEL_TYPES } from "@/core/constant";

export default {
  props: {
    saleReceipt: {
      type: Object,
      required: true
    },
    saleReceiptTransactions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      paymentBranch: this.saleReceipt.branch_id,
      payments: [],
      LIST_MODEL_TYPES,
      paymentKey: 0
    };
  },

  async created() {
    await this.$store.dispatch("SALE/getPosDevices");
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    totalAmount() {
      return sum(this.payments, payment => payment.amount || 0);
    },

    debtAmount() {
      return (
        this.saleReceipt.value -
        this.thanhToanNgayTotal -
        this.thanhToanSauTotal
      );
    },

    paymentTransactionStatusRequest() {
      return this.$store.getters["PAYMENT_TRANSACTION/statusRequest"];
    },

    thanhToanNgayTotal() {
      return getThanhToanNgayTotal(this.saleReceiptTransactions);
    },

    thanhToanSauTotal() {
      return getThanhToanSauTotal(this.saleReceiptTransactions);
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },

  methods: {
    closeModal() {
      this.payments = [];
      this.paymentBranch = null;
      this.$modal.hide({ name: "modal-form-sale-receipt-transaction" });
    },

    async onConfirm() {
      const saleReceiptPayment = this.payments
        .filter(item => item.amount > 0)
        .map(item => {
          const obj = {
            branch_id: this.paymentBranch || this.saleReceipt.branch_id,
            transactionable_id: this.saleReceipt.id,
            transactionable_code: this.saleReceipt.code,
            transactionable_type: "App\\Models\\HoaDonBan",
            senderable_id: this.saleReceipt.khachhang_id,
            senderable_type: "App\\Models\\User",
            sender_bank_id: null,
            note: null,
            ...item
          };

          if (obj.receiverable_type === "App\\Models\\Branch") {
            obj.receiverable_id =
              this.paymentBranch || this.saleReceipt.branch_id;
          }

          return obj;
        });

      if (saleReceiptPayment.length === 0) {
        showModalAlertError(this, {
          title: "Vui lòng nhập dữ liệu ít nhất 1 phương thức thanh toán"
        });

        return;
      }

      if (!this.paymentBranch) {
        showModalAlertError(this, {
          title: "Vui lòng chọn chi nhánh thanh toán."
        });

        return;
      }

      await this.$store.dispatch(
        "PAYMENT_TRANSACTION/createPaymentTransaction",
        saleReceiptPayment
      );

      if (
        this.paymentTransactionStatusRequest.value ===
        "success-createPaymentTransaction"
      ) {
        showToastActionAlert(this, {
          message: "Tạo phiếu thu thành công"
        });

        await this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
          this.saleReceipt.id
        );
      } else if (
        this.paymentTransactionStatusRequest.value ===
        "error-createPaymentTransaction"
      ) {
        showModalAlertError(this, {
          title: this.paymentTransactionStatusRequest.message
        });
      }

      this.closeModal();
      this.paymentKey++;
    },

    onUpdateTransactions(value) {
      this.payments = value;
    }
  }
};
</script>
