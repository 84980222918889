<template>
  <div class="sale-receipt__tab-transactions text-body-1 px-4 pt-4 pb-5">
    <div class="transaction-page__title mb-2">
      <h6 class="font-weight-bold text-h6">
        Lịch sử thanh toán
      </h6>

      <div class="d-flex flex-row justify-end align-center mb-4">
        <span class="text-body-1 mr-2">Xem phiếu bị huỷ</span>
        <v-checkbox
          v-model="viewCancelStatus"
          class="mt-0"
          color="primary"
          dense
          hide-details
        ></v-checkbox>
      </div>
    </div>

    <v-data-table
      :headers="headers"
      :items="filteredSaleReceiptPaymentTransactions"
      :loading="
        saleReceiptStatusRequest.value ===
          'loading-getSaleReceiptPaymentTransactions'
      "
      class="elevation-0"
      disable-sort
      hide-default-footer
      no-data-text="Không có dữ liệu"
    >
      <template v-slot:header.amount="{ header }">
        <div class="w-max-content text-center">
          <div>{{ header.text }}</div>
          <div>(Không tính phí TT)</div>
        </div>
      </template>
      <!-- Start: Body -->
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0">
          <!-- Start: Items -->
          <tr
            v-for="(item, index) in items"
            :key="item.id"
            :class="{ 'grey lighten-5': index % 2 === 0 }"
          >
            <td>{{ item.created_at | formatTime }}</td>
            <td>
              <div class="primary--text tp-btn--text">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="copied-label"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="copyToClipboard(item.code)"
                    >
                      {{ item.code }}
                    </div>
                  </template>
                  <span>Sao chép</span>
                </v-tooltip>
              </div>
            </td>
            <td>
              {{ getPaymentTypeText(item.type) }}
            </td>
            <td>
              <span v-if="item.receiverable">{{ item.receiverable.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>{{ getPaymentKindText(item.kind) }}</td>
            <td>
              <span v-if="item.receiver_bank">
                {{ item.receiver_bank.bank_account_label }} -
                {{ item.receiver_bank.account_bank }}
              </span>
              <span v-else>N/A</span>
            </td>

            <td class="text-center">{{ item.amount | formatCurrency }}</td>
            <td class="text-center">{{ item.payment_fee | formatCurrency }}</td>

            <td class="text-center">
              <v-chip
                :color="getReceiptStatus(item.status).color"
                class="mr-2 font-weight-bold"
                outlined
                small
              >
                <v-progress-circular
                  v-if="getReceiptStatus(item.status).isLoading"
                  :color="getReceiptStatus(item.status).color"
                  :size="15"
                  :width="2"
                  class="mr-2"
                  indeterminate
                ></v-progress-circular>

                {{ item.status_label }}
              </v-chip>
            </td>

            <td>
              {{ item.explain }}
            </td>
            <!--<td>
            <v-btn
              class="px-4"
              color="red accent-2"
              :disabled="checkDisableCancelTransaction(item)"
              outlined
              small
              @click="cancelPaymentTransaction(item)"
              >Hủy</v-btn
            >
          </td>-->
          </tr>
          <!-- End: Items -->
        </tbody>

        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">Không có dữ liệu</td>
          </tr>
        </tbody>
      </template>
      <!-- End: Body -->
    </v-data-table>

    <div
      v-if="!saleTabActive.cancel_status && saleTabActive.id"
      class="d-flex align-center mt-4"
    >
      <v-btn
        v-if="
          hasAnyRole([
            'Admin',
            'Nhóm quyền Kế toán tổng hợp',
            'Nhóm quyền quản lý cửa hàng'
          ])
        "
        :disabled="!saleTabActive.id"
        class="mr-4"
        color="primary"
        dark
        depressed
        @click="openModalFormOrderTransaction"
      >
        <v-icon class="mr-1" left small>mdi-plus</v-icon>
        Tạo phiếu ghi thu
      </v-btn>

      <v-btn color="orange" dark depressed @click="openModalFormChiHoanTien">
        <v-icon class="mr-1" left small>mdi-arrow-left-top</v-icon>
        Chi hoàn tiền
      </v-btn>
    </div>

    <modal-form-sale-receipt-transaction
      :sale-receipt="saleTabActive"
      :sale-receipt-transactions="saleReceiptPaymentTransactions"
    />

    <modal-form-chi-hoan-coc
      :branch="saleTabActive.branch"
      :customer="saleTabActive.khachhang"
      :transactionable-code="saleTabActive.code"
      :transactionable-id="saleTabActive.id"
      transactionable-type="App\Models\HoaDonBan"
      transactionable-type-name="hóa đơn bán"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  getReceiptStatus,
  LIST_PAYMENT_KIND,
  LIST_PAYMENT_STATUS,
  LIST_PAYMENT_TYPE
} from "@/core/constant";
import {
  getPaymentKindText,
  getPaymentTypeText,
  showModalAlertError,
  showToastActionAlert,
  usePermission
} from "@/core/composables";

import ModalFormSaleReceiptTransaction from "./ModalFormSaleReceiptTransaction";
import ModalFormChiHoanCoc from "@/modules/Sale/components/modal/ModalFormChiHoanCoc";

export default defineComponent({
  components: {
    ModalFormChiHoanCoc,
    ModalFormSaleReceiptTransaction
  },

  setup() {
    const { hasAnyRole } = usePermission();

    return {
      hasAnyRole
    };
  },

  data() {
    return {
      headers: [
        {
          text: "Thời gian",
          align: "start",
          value: "created_at"
        },
        { text: "Phiếu ghi thu", value: "code" },
        { text: "Loại phiếu", value: "type" },
        { text: "Đối tượng nhận", value: "receiverable" },
        { text: "Phương thức", value: "kind" },
        { text: "Tài khoản", value: "receiver_bank" },
        { text: "Số tiền", value: "amount" },
        { text: "Phí thanh toán", value: "payment_fee" },
        { text: "Trạng thái", align: "center", value: "status" },
        { text: "Diễn giải", value: "explain" }
      ],
      viewCancelStatus: false,
      LIST_PAYMENT_STATUS,
      LIST_PAYMENT_TYPE
    };
  },

  filters: {
    formatTime(d) {
      const dateTime = new Date(d),
        hour = String(dateTime.getHours()).padStart(2, "0"),
        min = String(dateTime.getMinutes()).padStart(2, "0"),
        date = String(dateTime.getDate()).padStart(2, "0"),
        month = String(dateTime.getMonth() + 1).padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${hour}:${min} ${date}/${month}/${year}`;
    }
  },

  computed: {
    filteredSaleReceiptPaymentTransactions() {
      if (!this.viewCancelStatus) {
        return this.saleReceiptPaymentTransactions.filter(
          item => item.status !== LIST_PAYMENT_STATUS.CANCEL.id
        );
      }

      return this.saleReceiptPaymentTransactions;
    },

    paymentTransactionStatusRequest() {
      return this.$store.getters["PAYMENT_TRANSACTION/statusRequest"];
    },

    saleReceiptPaymentTransactions() {
      return this.$store.getters["SALE_RECEIPT/saleReceiptPaymentTransactions"];
    },

    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  methods: {
    // TODO: Will be removed
    cancelPaymentTransaction(transaction) {
      this.$modal.show({
        name: "modal-cancel",
        payload: {
          title: `<span>Hủy giao dịch <strong>${transaction.code}</strong></span>`,
          description:
            "Bạn chỉ có thể hủy các phiếu đang Chờ xác nhận. " +
            "Riêng dối với phiếu thu Tiền mặt, bạn có thể hủy phiếu đã Thành công trong ngày. " +
            "Hành động hủy sẽ không thể hoàn tác. Nhập lý do hủy vào ô bên dưới nếu bạn chắc chắn muốn tiếp tục:",
          onSubmitHandler: async cancelReason => {
            await this.$store.dispatch(
              "PAYMENT_TRANSACTION/cancelPaymentTransaction",
              {
                transactionId: transaction.id,
                note: cancelReason
              }
            );

            // Alert
            if (
              this.paymentTransactionStatusRequest.value ===
              "success-cancelPaymentTransaction"
            ) {
              // Alert
              showToastActionAlert(this, { message: "Đã hủy giao dịch" });

              // Get saleReceipt payments
              await this.$store.dispatch(
                "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
                this.saleTabActive.id
              );
            } else if (
              this.paymentTransactionStatusRequest.value ===
              "error-cancelPaymentTransaction"
            ) {
              // Alert error
              showModalAlertError(this, {
                title: "Không thể hủy giao dịch",
                message: this.paymentTransactionStatusRequest.message.message
              });
            }
          }
        }
      });
    },

    checkDisableCancelTransaction(transaction) {
      console.log(transaction);
      if (transaction.kind === LIST_PAYMENT_KIND.TIEN_MAT.id) {
        return transaction.status === LIST_PAYMENT_STATUS.CANCEL.id;
      }

      return transaction.status !== LIST_PAYMENT_STATUS.PENDING.id;
    },

    getReceiptStatus(status) {
      return getReceiptStatus(status);
    },

    getPaymentKindText(kind) {
      return getPaymentKindText(kind);
    },

    getPaymentTypeText(type) {
      return getPaymentTypeText(type);
    },

    openModalFormChiHoanTien() {
      this.$modal.show({ name: "modal-form-chi-hoan-coc" });
    },

    openModalFormOrderTransaction() {
      this.$modal.show({ name: "modal-form-sale-receipt-transaction" });
    }
  }
});
</script>

<style lang="scss" scoped>
.sale-receipt__tab-transactions {
  background-color: white;
  height: calc(100vh - 92px);
  overflow-y: auto;
}
</style>
