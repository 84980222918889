<template>
  <tp-modal name="modal-sale-promotion" max-width="768px">
    <v-card
      v-if="saleTabActiveProductOption && props.payload.promotions"
      flat
      slot-scope="props"
    >
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Khuyến mại của {{ saleTabActiveProductOption.option.product_name }}
        </div>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <card-promotion
          v-for="(item, index) in filterProductPromotion(
            props.payload.promotions
          )"
          :class="index === 0 ? null : 'mt-5'"
          :key="item.id"
          :promotion="item"
        />
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import CardPromotion from "@/modules/Sale/components/modal/ModalSalePromotion/components/CardPromotion";
import { PROMOTION_TK_TYPE } from "@/core/constant";

export default {
  components: { CardPromotion },

  data() {
    return {
      headers: [
        {
          text: "Sản phẩm khuyến mại",
          align: "start",
          sortable: false,
          value: "product"
        },
        {
          text: "Giá",
          align: "start",
          sortable: false,
          value: "price"
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "action"
        }
      ]
    };
  },

  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },
    saleTabActiveProductOption() {
      return this.$store.getters["SALE/saleTabActiveProductOption"];
    },
    saleTabActiveProductOptionIndex() {
      return this.$store.getters["SALE/saleTabActiveProductOptionIndex"];
    },
    selectedPromotionOptions() {
      return this.saleTabActive.details[
        this.saleTabActiveProductOptionIndex
      ].gifts.map(item => item.option_id);
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-sale-promotion" });
    },

    filterProductPromotion(promotions) {
      return promotions.filter(
        promotion => promotion.tk_type === PROMOTION_TK_TYPE.PRODUCT
      );
    },

    async selectPromotionOption(item, type, value) {
      // Check exist item in selectPromotionOption
      if (this.selectedPromotionOptions.includes(item.id)) {
        this.saleTabActive.details[
          this.saleTabActiveProductOptionIndex
        ].gifts = await this.saleTabActive.details[
          this.saleTabActiveProductOptionIndex
        ].gifts.filter(option => option.option_id !== item.id);
      } else {
        await this.saleTabActive.details[
          this.saleTabActiveProductOptionIndex
        ].gifts.unshift({
          option: {
            id: item.id,
            SKU: item.SKU,
            product_name: item.product_name,
            name: item.name
          },
          option_id: item.id,
          qty: 1,
          unit_price: item.price,
          rebate_amount:
            item.price - this.calculateSalePrice(item.price, type, value)
        });
      }

      // Update sale active tab
      await this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
    }
  }
};
</script>
