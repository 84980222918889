var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sale-receipt__tab-transactions text-body-1 px-4 pt-4 pb-5"},[_c('div',{staticClass:"transaction-page__title mb-2"},[_c('h6',{staticClass:"font-weight-bold text-h6"},[_vm._v(" Lịch sử thanh toán ")]),_c('div',{staticClass:"d-flex flex-row justify-end align-center mb-4"},[_c('span',{staticClass:"text-body-1 mr-2"},[_vm._v("Xem phiếu bị huỷ")]),_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"primary","dense":"","hide-details":""},model:{value:(_vm.viewCancelStatus),callback:function ($$v) {_vm.viewCancelStatus=$$v},expression:"viewCancelStatus"}})],1)]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredSaleReceiptPaymentTransactions,"loading":_vm.saleReceiptStatusRequest.value ===
        'loading-getSaleReceiptPaymentTransactions',"disable-sort":"","hide-default-footer":"","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"header.amount",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"w-max-content text-center"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('div',[_vm._v("(Không tính phí TT)")])])]}},{key:"body",fn:function(ref){
        var items = ref.items;
return [(items.length > 0)?_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:item.id,class:{ 'grey lighten-5': index % 2 === 0 }},[_c('td',[_vm._v(_vm._s(_vm._f("formatTime")(item.created_at)))]),_c('td',[_c('div',{staticClass:"primary--text tp-btn--text"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1)]),_c('td',[_vm._v(" "+_vm._s(_vm.getPaymentTypeText(item.type))+" ")]),_c('td',[(item.receiverable)?_c('span',[_vm._v(_vm._s(item.receiverable.name))]):_c('span',[_vm._v("N/A")])]),_c('td',[_vm._v(_vm._s(_vm.getPaymentKindText(item.kind)))]),_c('td',[(item.receiver_bank)?_c('span',[_vm._v(" "+_vm._s(item.receiver_bank.bank_account_label)+" - "+_vm._s(item.receiver_bank.account_bank)+" ")]):_c('span',[_vm._v("N/A")])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.amount)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.payment_fee)))]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticClass:"mr-2 font-weight-bold",attrs:{"color":_vm.getReceiptStatus(item.status).color,"outlined":"","small":""}},[(_vm.getReceiptStatus(item.status).isLoading)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"color":_vm.getReceiptStatus(item.status).color,"size":15,"width":2,"indeterminate":""}}):_vm._e(),_vm._v(" "+_vm._s(item.status_label)+" ")],1)],1),_c('td',[_vm._v(" "+_vm._s(item.explain)+" ")])])}),0):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":_vm.headers.length}},[_vm._v("Không có dữ liệu")])])])]}}])}),(!_vm.saleTabActive.cancel_status && _vm.saleTabActive.id)?_c('div',{staticClass:"d-flex align-center mt-4"},[(
        _vm.hasAnyRole([
          'Admin',
          'Nhóm quyền Kế toán tổng hợp',
          'Nhóm quyền quản lý cửa hàng'
        ])
      )?_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.saleTabActive.id,"color":"primary","dark":"","depressed":""},on:{"click":_vm.openModalFormOrderTransaction}},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" Tạo phiếu ghi thu ")],1):_vm._e(),_c('v-btn',{attrs:{"color":"orange","dark":"","depressed":""},on:{"click":_vm.openModalFormChiHoanTien}},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","small":""}},[_vm._v("mdi-arrow-left-top")]),_vm._v(" Chi hoàn tiền ")],1)],1):_vm._e(),_c('modal-form-sale-receipt-transaction',{attrs:{"sale-receipt":_vm.saleTabActive,"sale-receipt-transactions":_vm.saleReceiptPaymentTransactions}}),_c('modal-form-chi-hoan-coc',{attrs:{"branch":_vm.saleTabActive.branch,"customer":_vm.saleTabActive.khachhang,"transactionable-code":_vm.saleTabActive.code,"transactionable-id":_vm.saleTabActive.id,"transactionable-type":"App\\Models\\HoaDonBan","transactionable-type-name":"hóa đơn bán"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }