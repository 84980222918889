<template>
  <v-card flat>
    <v-card-title class="font-weight-bold text-h6 pb-4 px-5">
      Yêu cầu VAT
    </v-card-title>

    <v-row class="mx-5" no-gutters>
      <v-col cols="12" md="6" lg="4">
        <v-card class="grey lighten-5 px-5 pt-4 pb-5" flat>
          <v-form ref="form">
            <v-row class="no-gutters">
              <v-col cols="12">
                <div class="text-body-1 font-weight-bold mb-2">
                  Tên công ty
                </div>

                <tp-text-field
                  v-model="vatRequest.company_name"
                  placeholder="Nhập tên công ty"
                  solo
                  validate="required"
                ></tp-text-field>
              </v-col>

              <v-col cols="12">
                <div class="text-body-1 font-weight-bold mb-2">
                  Địa chỉ công ty
                </div>

                <tp-text-field
                  v-model="vatRequest.company_address"
                  placeholder="Nhập địa chỉ công ty"
                  solo
                  validate="required"
                ></tp-text-field>
              </v-col>

              <v-col cols="12">
                <div class="text-body-1 font-weight-bold mb-2">
                  Mã số thuế
                </div>

                <tp-text-field
                  v-model="vatRequest.tax_code"
                  placeholder="Nhập mã số thuế"
                  solo
                  validate="required"
                ></tp-text-field>
              </v-col>

              <v-col cols="12">
                <div class="text-body-1 font-weight-bold mb-2">
                  Email
                </div>

                <tp-text-field
                  v-model="vatRequest.email"
                  placeholder="Nhập email"
                  solo
                  validate="required|email"
                ></tp-text-field>
              </v-col>

              <v-col cols="12">
                <div class="text-body-1 font-weight-bold mb-2">
                  Tên người yêu cầu
                </div>

                <tp-text-field
                  v-model="vatRequest.requester_name"
                  placeholder="Nhập tên người yêu cầu"
                  solo
                  validate="required"
                ></tp-text-field>
              </v-col>

              <v-col cols="12">
                <div class="text-body-1 font-weight-bold mb-2">
                  Ghi chú (nếu có)
                </div>

                <tp-textarea
                  v-model="vatRequest.note"
                  placeholder="Nhập ghi chú"
                  rows="3"
                  solo
                ></tp-textarea>
              </v-col>
            </v-row>
          </v-form>

          <div class="">
            <v-btn
              v-if="!vatRequest.id"
              color="primary"
              dark
              depressed
              :loading="
                vatRequestStatusRequest.value === 'loading-createVatRequest'
              "
              @click="
                vatRequestStatusRequest.value === 'loading-createVatRequest'
                  ? null
                  : createVatRequest()
              "
            >
              Yêu cầu kế toán xuất VAT
            </v-btn>

            <v-btn
              v-else
              color="primary"
              dark
              depressed
              :loading="
                vatRequestStatusRequest.value === 'loading-updateVatRequest'
              "
              @click="updateVatRequest"
            >
              Lưu
            </v-btn>

            <div v-if="vatRequest.id" class="text-body-1 mt-3">
              Đơn xử lý đã được yêu cầu xuất VAT vào lúc
              <span class="font-weight-bold">{{ vatRequest.created_at }}</span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  computed: {
    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    vatRequest() {
      return this.$store.getters["VAT_REQUEST/vatRequest"];
    },

    vatRequestStatusRequest() {
      return this.$store.getters["VAT_REQUEST/statusRequest"];
    }
  },

  methods: {
    async createVatRequest() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      // set vat requestable
      this.vatRequest.vat_requestable_id = this.orderProcessing.id;
      this.vatRequest.vat_requestable_type = "App\\Models\\OrderHandle";

      await this.$store.dispatch(
        "VAT_REQUEST/createVatRequest",
        this.vatRequest
      );

      if (this.vatRequestStatusRequest.value === "success-createVatRequest") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Yêu cầu xuất VAT thành công"
          }
        });
      } else if (
        this.vatRequestStatusRequest.value === "error-createVatRequest"
      ) {
        // Alert error
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.vatRequestStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async updateVatRequest() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      await this.$store.dispatch(
        "VAT_REQUEST/updateVatRequest",
        this.vatRequest
      );

      if (this.vatRequestStatusRequest.value === "success-updateVatRequest") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      } else if (
        this.vatRequestStatusRequest.value === "error-updateVatRequest"
      ) {
        // Alert error
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.vatRequestStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>
