<template>
  <div class="px-4">
    <div class="font-weight-bold mb-2">Khách hàng</div>

    <div class="d_flex align-center px-0">
      <div class="d_flex align-center">
        <v-autocomplete
          ref="autocompleteCustomer"
          v-model="saleTabActive.khachhang"
          :class="
            customerStatusRequest.value === 'loading-getCustomers'
              ? 'autocomplete-customer--loading'
              : 'autocomplete-customer'
          "
          :disabled="!!saleTabActive.status"
          :items="customers"
          :menu-props="{ maxWidth: '280px' }"
          :readonly="!!saleTabActive.status"
          :search-input.sync="customerSearchKey"
          class="text-body-1"
          dense
          flat
          hide-details
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          no-filter
          outlined
          placeholder="Tìm khách hàng"
          prepend-inner-icon="mdi-magnify"
          return-object
          small-chips
          style="width: 280px;"
          @keypress.enter="searchCustomers"
        >
          <template v-slot:selection="{ item }">
            <v-hover v-slot:default="{ hover }">
              <div
                :class="hover ? 'cyan lighten-4' : 'cyan lighten-5'"
                class="text-truncate tp-btn--text rounded-xl px-3 py-1"
                @click="openModalCustomer(item.id)"
              >
                {{ item.name }}
              </div>
            </v-hover>
          </template>

          <template v-slot:item="data">
            <v-list-item-content :title="data.item.name">
              <v-list-item-title
                class="font-weight-medium"
                v-html="data.item.name"
              ></v-list-item-title>
              <div class="grey--text text--darken-2 text-subtitle-2">
                <span v-if="data.item.phone">{{
                  data.item.phone | VMask("### ### ####")
                }}</span>
              </div>
            </v-list-item-content>
          </template>

          <template #append>
            <div class="d-flex align-center">
              <div
                v-if="customerStatusRequest.value === 'loading-getCustomers'"
                class="d-flex align-center justify-center mr-2"
                style="width: 24px"
              >
                <v-progress-circular
                  color="grey lighten-2"
                  indeterminate
                ></v-progress-circular>
              </div>
              <v-icon
                v-else-if="saleTabActive.khachhang_id && !saleTabActive.id"
                class="tp-btn--text mr-1"
                @click="saleTabActive.khachhang_id = null"
                >mdi-close
              </v-icon>
              <v-btn
                v-if="!saleTabActive.id"
                :disabled="
                  !customerSearchKey ||
                    customerSearchKey.trim() === '' ||
                    customerStatusRequest.value === 'loading-getCustomers'
                "
                color="primary"
                depressed
                small
                @click="searchCustomers"
                >Tìm
              </v-btn>
            </div>
          </template>
        </v-autocomplete>

        <v-btn
          class="cyan lighten-5 btn-add px-0 ml-2"
          color="primary"
          depressed
          height="40px"
          icon
          width="40px"
          @click="openModalCustomer()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <!-- Start: Customer info -->

    <v-chip
      v-if="saleTabActive.khachhang"
      :class="
        saleTabActive.khachhang.cong_no < 0
          ? 'red lighten-5 red--text'
          : 'green lighten-5 green--text'
      "
      class="mt-2 font-weight-bold"
      label
      small
    >
      {{ saleTabActive.khachhang.phone }} - Nợ:
      {{ saleTabActive.khachhang.cong_no || 0 | formatCurrency }}
    </v-chip>

    <!-- End: Customer info -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      customerSearchKey: null
    };
  },

  computed: {
    customers() {
      return this.$store.getters["CUSTOMER/customers"];
    },

    customerStatusRequest() {
      return this.$store.getters["CUSTOMER/statusRequest"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  watch: {
    saleTabActive(val) {
      if (val && val.khachhang_id) {
        this.$store.dispatch("CUSTOMER/getCustomers", {
          cur_page: 1,
          per_page: 100,
          search: val.khachhang.id
        });
      }
    },
    "saleTabActive.khachhang"(val) {
      // Set customer id
      this.saleTabActive.khachhang_id = val ? val.id : null;
      // Update sale active tab
      this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
    }
  },

  async created() {
    if (
      this.saleTabActive &&
      this.saleTabActive.khachhang_id &&
      this.saleTabActive.khachhang
    ) {
      await this.$store.dispatch("CUSTOMER/getCustomers", {
        cur_page: 1,
        per_page: 100,
        search: this.saleTabActive.khachhang
          ? this.saleTabActive.khachhang.id
          : null
      });
    }
  },

  methods: {
    async openModalCustomer(customerId) {
      if (customerId) {
        await this.$store.dispatch("CUSTOMER/getCustomerById", customerId);
      } else {
        await this.$store.dispatch("CUSTOMER/resetCustomer");
      }

      this.$modal.show({ name: "modal-customer" });
    },

    searchCustomers() {
      if (
        !this.customerSearchKey ||
        this.customerSearchKey.trim() === "" ||
        this.customerStatusRequest.value === "loading-getCustomers"
      )
        return;

      this.$store.dispatch("CUSTOMER/getCustomers", {
        cur_page: 1,
        per_page: 100,
        search: this.customerSearchKey
      });

      this.$refs.autocompleteCustomer.isMenuActive = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.autocomplete-customer {
  &::v-deep .v-input__append-inner {
    margin-top: 6px !important;
  }
}

.autocomplete-customer--loading {
  &::v-deep .v-input__append-inner {
    margin-top: 4px !important;
  }
}
</style>
