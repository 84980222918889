var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"customer-info",class:[
        _vm.customerStatusRequest.value === 'loading-getCustomerById'
          ? 'cursor-wait'
          : 'cursor-pointer'
      ],on:{"click":function($event){return _vm.viewDetail(_vm.saleTabActive.customer.id)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.saleTabActive.customer.name)+" - "+_vm._s(_vm.saleTabActive.customer.phone)+" "),_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-information-variant-circle")])],1)]}}])},[_vm._v(" Khách còn nợ "+_vm._s(_vm._f("formatCurrency")(_vm.saleTabActive.customer.sum_debt))+" "),_c('br'),_vm._v(" Ở "+_vm._s(_vm.saleTabActive.customer.count_order_debt)+" đơn hàng ")])}
var staticRenderFns = []

export { render, staticRenderFns }