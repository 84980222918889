<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div
        class="customer-info"
        :class="[
          customerStatusRequest.value === 'loading-getCustomerById'
            ? 'cursor-wait'
            : 'cursor-pointer'
        ]"
        v-on="on"
        v-bind="attrs"
        @click="viewDetail(saleTabActive.customer.id)"
      >
        {{ saleTabActive.customer.name }} -
        {{ saleTabActive.customer.phone }}
        <v-icon size="15">mdi-information-variant-circle</v-icon>
      </div>
    </template>

    Khách còn nợ
    {{ saleTabActive.customer.sum_debt | formatCurrency }} <br />
    Ở {{ saleTabActive.customer.count_order_debt }} đơn hàng
  </v-tooltip>
</template>

<script>
import { BANK_ACCOUNT_OBJECT_TYPE } from "@/core/constant";

export default {
  data() {
    return {
      CMS_BASE_URL: process.env.VUE_APP_BASE_URL,
      visible: false
    };
  },

  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },
    customers() {
      return this.$store.getters["CUSTOMER/customers"];
    },
    customerStatusRequest() {
      return this.$store.getters["CUSTOMER/statusRequest"];
    }
  },

  methods: {
    getIndexOfCustomer(val) {
      const arr = this.customers.map(item => item.id);

      return arr.indexOf(val);
    },

    async viewDetail(id) {
      await this.$store.dispatch("CUSTOMER/getCustomerById", id);

      this.$modal.show({
        name: "modal-customer",
        payload: {
          index: this.getIndexOfCustomer(id)
        }
      });

      this.$store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
        id: id,
        type: BANK_ACCOUNT_OBJECT_TYPE.USER
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.customer-info {
  color: $color-primary;

  &:hover {
    text-decoration: underline;
  }
}

.cursor-wait {
  cursor: wait;
}
</style>
