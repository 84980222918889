<template>
  <div
    app
    class="header primary text-body-1 white--text d-flex justify-space-between px-6"
    height="46px"
  >
    <div class="d-flex">
      <header-search
        :disabled="saleTabActive && saleTabActive.status >= 4"
        class="header-search"
      ></header-search>

      <div class="header-tabs d-flex align-center">
        <v-tabs
          v-model="saleTabActiveIndex"
          active-class="white primary--text"
          background-color="primary"
          class="font-weight-medium"
          dark
          height="46px"
        >
          <v-tab
            v-for="(item, index) in saleTabs"
            :key="index"
            class="text-body-1 font-weight-bold text-none px-3"
          >
            <div
              v-if="item.order_type === 'SALE_AT_STORE'"
              class="mr-2"
              style="color:#FDD835 !important"
            >
              POS
            </div>

            {{ getCodeName(item.code) }}

            <v-hover v-slot:default="{ hover }">
              <v-icon
                :color="hover ? 'red accent-2' : null"
                right
                size="20px"
                @click.stop="alertCloseTab(index, item)"
              >
                mdi-close
              </v-icon>
            </v-hover>
          </v-tab>
        </v-tabs>

        <button-add-new-sale-tab></button-add-new-sale-tab>
      </div>
    </div>

    <div class="d-flex flex-row align-center header-right justify-end">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="orderStatusRequest.value === 'loading-getOrders'"
            dark
            icon
            small
            v-bind="attrs"
            @click="openModalOrderProcessing()"
            v-on="on"
          >
            <v-icon size="18px">mdi-recycle-variant</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold text-body-2 pa-1">Xử lý đơn hàng</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="orderStatusRequest.value === 'loading-getOrders'"
            class="ml-2"
            dark
            icon
            small
            v-bind="attrs"
            @click="openModalSaleReceiptReturning()"
            v-on="on"
          >
            <v-icon size="18px">mdi-reply-all-outline</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold text-body-2 pa-1">Trả hàng</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            dark
            depressed
            icon
            small
            v-bind="attrs"
            @click="goToDashboard()"
            v-on="on"
          >
            <v-icon>mdi-home-import-outline</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold text-body-2 pa-1">Quay về trang chủ</span>
      </v-tooltip>

      <div class="header-right--user ml-4">
        <v-avatar class="primary" item size="24px">
          <v-icon color="white" size="28px">mdi-account-circle</v-icon>
        </v-avatar>
        <span class="font-weight-bold text-body-1 text-n ml-2">
          {{ getShortenName(user.name) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { removeVietnameseTones } from "@vt7/utils";
import ButtonAddNewSaleTab from "./components/ButtonAddNewSaleTab.vue";
import HeaderSearch from "./components/HeaderSearch";
import { SALE_RECEIPT_STATUS } from "@/modules/Sale/pages/SaleReceipt/constants";
import SaleReceiptService from "@/modules/Transaction/service/sale-receipt.service";

export default {
  components: {
    ButtonAddNewSaleTab,
    HeaderSearch
  },
  data() {
    return {
      isExistBillOfSale: false
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    order() {
      return this.$store.getters["ORDER/order"];
    },
    saleTabActiveIndex: {
      get() {
        return parseInt(this.$store.getters["SALE/saleTabActiveIndex"]);
      },
      async set(val) {
        await this.$store.dispatch("SALE/setSaleTabActiveIndex", val);

        // Check saleTabActive type is order, get order detail and set data into saleTabActive state
        if (
          this.saleTabActive &&
          this.saleTabActive.order_type === "SALE_RECEIPT" &&
          this.saleTabActive.id
        ) {
          // SALE_RECEIPT
          await this.$store.dispatch(
            "SALE_RECEIPT/getSaleReceiptById",
            this.saleTabActive.id
          );

          await this.$store.dispatch("SALE/setSaleTabActive", {
            ...this.saleReceipt,
            branch_id: this.saleReceipt.branch.id,
            khachhang_id: this.saleReceipt.khachhang.id,
            type: 1
          });

          // Get sale receipt payments
          await this.$store.dispatch(
            "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
            this.saleReceipt.id
          );

          // Get sale receipt participant
          await this.$store.dispatch(
            "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
            {
              modelName: "App\\Models\\HoaDonBan",
              modelId: this.saleReceipt.id
            }
          );
        } else if (this.saleTabActive && this.saleTabActive.id) {
          // ORDER
          this.$store.commit("ORDER/SET_ORDER_ACTIVE_SUB_TAB", 0);

          await this.$store.dispatch(
            "ORDER/getOrderById",
            this.saleTabActive.id
          );
          // Update saleTabActive
          await this.$store.dispatch("SALE/setSaleTabActive", {
            ...this.saleTabActive
          });

          // Check order handles and set orderProcessing state
          // if (this.saleTabActive.handles.length > 0) {
          //   await this.$store.dispatch(
          //     "ORDER/getOrderProcessingById",
          //     this.saleTabActive.handles[0].id
          //   );
          //   await this.$store.dispatch("SALE/setActiveOrderProcessingIndex", 0);
          // } else {
          // await this.$store.dispatch("ORDER/setOrderProcessing", []);
          // }

          // Get order payments
          await this.$store.dispatch(
            "ORDER/getOrderPaymentTransactions",
            this.order.id
          );

          // Get order online payments
          await this.$store.dispatch(
            "ORDER/getOrderOnlinePayments",
            this.order.id
          );

          // Get order participant
          await this.$store.dispatch(
            "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
            {
              modelName: "App\\Models\\Order",
              modelId: this.order.id
            }
          );
        }
      }
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },
    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
    typeSaleTabs() {
      return this.$store.getters["SALE/typeSaleTabs"];
    },
    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },
    isChooseOrder() {
      const {
        details,
        customer,
        received_address,
        branch_nhan_id
      } = this.saleTabActive;

      return (
        details.length > 0 ||
        customer !== null ||
        received_address !== null ||
        branch_nhan_id !== null
      );
    }
  },

  methods: {
    getCodeName(code) {
      const codes = code.split("-");

      return codes.length > 0 ? codes[0] : code;
    },
    alertCloseTab(index, item) {
      if (item.type === 2) {
        if (!item.id && this.isChooseOrder) {
          this.$modal.show({
            name: "modal-alert",
            payload: {
              title: "Xác nhận",
              message: "Bạn có muốn đóng đơn đặt hàng này không?",
              confirmBtn: {
                text: "Đồng ý",
                onClickHandler: async () => {
                  await this.removeTabItem(index);
                }
              }
            }
          });
        } else {
          this.removeTabItem(index);
        }
      } else if (
        item.order_type === "SALE_RECEIPT" &&
        item.status === SALE_RECEIPT_STATUS.DRAFT.value
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title:
              "Hoá đơn chưa xuất, bạn có muốn đóng và xoá bản nháp này không",
            confirmBtn: {
              text: "Đóng và xoá",
              color: "red accent-2",
              onClickHandler: async () => {
                try {
                  await SaleReceiptService.deleteDraft({ id: item.id });
                  await this.removeTabItem(index);
                } catch (e) {
                  console.log("e", e);
                }
              }
            },
            cancelBtnText: "Chỉ đóng",
            onCancelHandler: () => {
              console.log("onCancelHandler");
              this.removeTabItem(index);
            }
          }
        });
      } else {
        this.removeTabItem(index);
      }
    },

    goToDashboard() {
      this.$router.push({ name: "dashboard" });
    },

    getShortenName(fullName) {
      const formattedFullname = removeVietnameseTones(fullName);
      const arr = formattedFullname.split(" ");
      const firstName = arr[arr.length - 1];

      const firstCharacterOfFirstName = arr
        .map((char, index) => {
          if (index < arr.length - 1) {
            return char.charAt(0);
          }
        })
        .filter(Boolean);

      return firstName + firstCharacterOfFirstName.join("");
    },

    onChangeSaleTab(index) {
      if (this.saleTabActiveIndex !== index) return;

      if (this.saleTabs[index].id) {
        this.$router.push({
          name: "sale",
          query: {
            type: this.saleTabs[index].type,
            id: this.saleTabs[index].id
          }
        });
      } else {
        this.$router.push({ name: "sale" });
      }
    },

    async openModalOrderProcessing() {
      await this.$store.dispatch("ORDER/getOrders", {
        search: null,
        search_key: null,
        per_page: 8,
        cur_page: 1
      });

      this.$modal.show({ name: "modal-order-processing" });
    },

    async openModalSaleReceiptReturning() {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceipts", {
        filter: {
          status: [1]
        },
        search: null,
        search_key: null,
        per_page: 8,
        cur_page: 1
      });

      this.$modal.show({ name: "modal-sale-receipt-returning" });
    },

    async removeTabItem(index) {
      await this.$store.dispatch("SALE/removeSaleTabItem", index);
      // Update active tab
      if (index === this.saleTabActiveIndex) {
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          this.saleTabActiveIndex === 0
            ? index
            : this.saleTabActiveIndex >= this.saleTabs.length - 1
            ? this.saleTabs.length - 1
            : index - 1
        );
      } else if (index < this.saleTabActiveIndex) {
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          this.saleTabActiveIndex > this.saleTabs.length - 1
            ? this.saleTabs.length - 1
            : this.saleTabActiveIndex - 1
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// .header {
//   box-shadow: 0 0 6px rgba(0, 0, 0, 0.1) !important;

//   &-tabs {
//     max-width: calc(100% - 360px - 360px - 32px - 32px);
//     ::v-deep {
//       .v-slide-group__next,
//       .v-slide-group__prev {
//         min-width: 30px;
//       }
//     }
//   }
//   &-right {
//     width: 360px;
//     &--user {
//       opacity: 0.87;
//     }
//   }
// }

.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}

.header-tabs {
  max-width: calc(100vw - 785px);
}

.header-search {
  width: max-content;
}
</style>
