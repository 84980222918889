<template>
  <div class="order-sidebar" flat>
    <div
      class="pa-4 d-flex flex-1 flex-col justify-start items-stretch text-body-1 overflow-y-auto"
    >
      <div class="text-body-2 font-weight-medium">
        <span v-if="orderProcessing.id && orderProcessing.created_at">
          {{ orderProcessing.created_at }}
        </span>
        <template v-else>
          {{ nowTime }}
        </template>
      </div>

      <!-- Start: Cancel reason -->
      <v-card v-if="orderProcessing.cancel_status === 1" class="mt-4" flat>
        <div class="red lighten-5 text-body-2 px-3 py-2">
          <span class="font-weight-medium">
            Đã bị hủy bởi
            <span class="font-weight-bold">
              {{
                orderProcessing.canceled_by_user
                  ? orderProcessing.canceled_by_user.name
                  : "N/A"
              }}
            </span>
          </span>
          <span v-if="orderProcessing.canceled_reasons">
            <span class="font-weight-medium">với lý do:</span>
            {{ orderProcessing.canceled_reasons }}
          </span>
        </div>
      </v-card>
      <!-- End: Cancel reason -->

      <div>
        <div class="mb-2 text-h6 font-weight-bold">Chi nhánh xuất</div>

        <tp-select
          v-model="orderProcessing.branch_id"
          :items="branches"
          :readonly="
            orderProcessing.status > 3 || orderProcessing.cancel_status === 1
          "
          class="mb-4"
          hide-details="auto"
          item-text="name"
          item-value="id"
          label="Chi nhánh phụ trách"
          placeholder="Chi nhánh phụ trách"
          validate="required"
        ></tp-select>

        <tp-autocomplete
          v-model="orderProcessing.assigned_to_id"
          :items="employees"
          :readonly="
            orderProcessing.status > 3 || orderProcessing.cancel_status === 1
          "
          class="mb-4"
          hide-details="auto"
          item-text="name"
          item-value="id"
          label="Nhân viên bán"
          placeholder="Nhân viên bán"
          validate="required"
        ></tp-autocomplete>
      </div>

      <div>
        <div class="text-h6 font-weight-bold">Thông tin nhận hàng</div>
        <panel-received-method></panel-received-method>
      </div>

      <div class="mt-2">
        <v-divider class="my-3"></v-divider>

        <summary-amount></summary-amount>
      </div>

      <v-divider class="my-3"></v-divider>

      <div>
        <div class="mb-2">
          <div class="font-weight-bold">Ghi chú</div>
        </div>

        <v-textarea
          v-model="orderProcessing.note"
          :readonly="orderProcessing.status === 6"
          class="text-body-1"
          outlined
          placeholder="Nhập ghi chú"
          rows="3"
        ></v-textarea>
      </div>
    </div>

    <v-card-actions
      v-if="orderProcessing.cancel_status !== 1"
      class="d-flex flex-row justify-space-between items-center px-4"
    >
      <sidebar-bottom class="sidebar-bottom"></sidebar-bottom>
    </v-card-actions>
  </div>
</template>

<script>
import PanelReceivedMethod from "./PanelReceivedMethod";
import SidebarBottom from "./SidebarBottom";
import SummaryAmount from "./SummaryAmount.vue";

import DateTimeFunction from "@/core/utils/date-time";

export default {
  components: {
    PanelReceivedMethod,
    SidebarBottom,
    SummaryAmount
  },

  data() {
    return {
      panel: [0, 1]
    };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    nowTime() {
      return `${DateTimeFunction.nowTime()} ${DateTimeFunction.today()}`;
    },

    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    }
  }
};
</script>

<style lang="scss" scoped>
.order-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-bottom {
  background-color: white;
  width: 360px;
  z-index: 1;
}
</style>
