<template>
  <v-autocomplete
    v-model="productOption.serials"
    class="tp-filter-autocomplete text-body-1 my-2"
    dense
    deletable-chips
    :items="
      productOption.allSerials.length
        ? productOption.allSerials
        : productOption.serials
    "
    item-text="serial_number"
    item-value="id"
    hide-details
    hide-selected
    :disabled="disabled"
    multiple
    no-data-text="Không có dữ liệu"
    outlined
    placeholder="Chọn serials"
    single-line
    small-chips
    return-object
    @change="$emit('change', $event)"
    @focus="$emit('focus')"
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    productOption: {
      type: Object,
      required: true
    }
  },

  computed: {
    productOptionSerialNumbers() {
      if (this.productOption.serials) {
        return this.productOption.serials.map(serial => serial.serial_number);
      }

      return [];
    },
    productOptionStockSerials() {
      return this.$store.getters["SERIAL/productOptionStockSerials"];
    }
  },

  watch: {
    "productOption.serials"(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        const serial = [...newVal].pop();

        if (
          this.checkExistedOldSerial(serial, this.productOptionStockSerials)
        ) {
          this.$modal.show({
            name: "modal-alert",
            payload: {
              title: `Bạn nên chọn serial tồn kho lâu hơn <span class="font-weight-bold red--text text--accent-2 mr-1">${serial.serial_number}</span> để xuất bán`,
              message:
                "Các serial được sắp xếp theo thứ tự nhập từ lâu đến mới nhất theo từng chi nhánh. " +
                "Xuất bán serial cũ hơn nhằm giảm số lượng tồn kho, ảnh hưởng trực tiếp đến KPI bán hàng của ngành hàng. " +
                "Bạn vẫn có thể chọn serial hiện tại nếu bấm tiếp tục. Hãy cân nhắc nhé!",
              confirmBtn: {
                color: "red accent-2",
                text: "Tiếp tục"
              },
              onCancelHandler: async () => {
                this.productOption.serials = [...oldVal];
                this.$emit("change", [...oldVal]);
              }
            }
          });
        }
      }
    }
  },

  methods: {
    checkExistedOldSerial(serial, allSerials) {
      const branchSerials = allSerials.filter(
        item =>
          item.branch_id === serial.branch_id &&
          !this.productOptionSerialNumbers.includes(item.serial_number)
      );

      return branchSerials.some(item => {
        return new Date(item.import_date) < new Date(serial.import_date);
      });
    }
  }
};
</script>
