var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white rounded"},[_c('v-stepper',{staticClass:"text-body-1 elevation-0",attrs:{"color":"green"}},[_c('v-stepper-header',{staticClass:"elevation-0"},[_vm._l((_vm.orderProcessing.timeline),function(step,index){return [_c('v-stepper-step',{key:("step-" + (step.status)),staticClass:"stepper-step_custom py-0",attrs:{"complete":_vm.orderProcessing.status >= step.status,"rules":[
            function () { return !(
                _vm.orderProcessing.cancel_status &&
                _vm.orderProcessing.status === step.status
              ); }
          ],"step":step.status,"color":"green lighten-1","error-icon":"mdi-close"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"step-wrapper"},'div',attrs,false),on),[_c('span',{class:{
                    'red--text':
                      _vm.orderProcessing.cancel_status &&
                      _vm.orderProcessing.status === step.status
                  },staticStyle:{"text-shadow":"none"}},[_vm._v(" "+_vm._s(step.label)+" ")]),(step.datetime)?_c('small',{staticClass:"mt-1"},[_vm._v(_vm._s(step.datetime))]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(_vm._s(step.description))])])],1),(index < _vm.orderProcessing.timeline.length - 1)?_c('v-divider',{key:("dv-" + (step.status)),staticClass:"mt-3"}):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }