<template>
  <tp-modal
    max-width="1100px"
    name="modal-form-order-transaction"
    width="90%"
    @close="closeModal()"
  >
    <v-card>
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Thông tin thanh toán đơn hàng {{ order.code }}
        </div>

        <v-spacer></v-spacer>

        <v-btn
          :loading="loading"
          class="ml-2"
          color="primary"
          dark
          depressed
          @click="onConfirm()"
        >
          Xác nhận
        </v-btn>

        <v-btn class="ml-2" icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <div
          class="v-window-item tab-item grey lighten-3 v-window-item--active"
        >
          <v-card class="pa-5 text-body-1" flat>
            <div class="mb-6" style="width: 50%">
              <div class="mb-3">
                Chi nhánh thanh toán
              </div>

              <tp-select
                v-model="paymentBranch"
                :items="branches"
                clearable
                dense
                hide-details="auto"
                item-text="name"
                item-value="id"
                outlined
                placeholder="Chọn chi nhánh"
                solo
              ></tp-select>
            </div>

            <div class="mb-5" style="width: 50%">
              <div class="d-flex justify-space-between">
                <div>Khách cần trả</div>
                <div class="font-weight-bold">
                  {{ order.value | formatCurrency }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>Khách đã trả</div>
                <div class="font-weight-bold">
                  {{ order.sum_paid | formatCurrency }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>Cần thanh toán nốt</div>
                <div class="font-weight-bold">
                  {{ order.debt_customer | formatCurrency }}
                </div>
              </div>

              <v-divider class="my-4"></v-divider>

              <div class="d-flex justify-space-between">
                <div>Khách thanh toán</div>
                <div class="font-weight-bold">
                  {{ totalAmount | formatCurrency }}
                </div>
              </div>
            </div>

            <tp-form-transactions-payment
              :key="paymentKey"
              ref="formRef"
              :branch-id="paymentBranch"
              :model-id="order.id"
              :model-type="LIST_MODEL_TYPES.ORDER"
              payment-tra-gop
              @change="onUpdateTransactions"
            ></tp-form-transactions-payment>
          </v-card>
        </div>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import { sum } from "@vt7/utils";
import { LIST_MODEL_TYPES } from "@/core/constant";

export default {
  name: "ModalNewDeposit",

  props: {
    formPaymentType: {
      type: String,
      default: "THANH TOAN"
    }
  },

  data() {
    return {
      paymentBranch: null,
      loading: false,
      payments: [],
      paymentKey: 0
    };
  },

  computed: {
    LIST_MODEL_TYPES() {
      return LIST_MODEL_TYPES;
    },
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    totalAmount() {
      return sum(this.payments, payment => payment.amount || 0);
    },

    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderAmount() {
      return this.order.value || 0;
    },

    orderDebt() {
      return (
        this.orderAmount -
        this.orderThanhToanNgayTotal -
        this.orderThanhToanSauTotal
      );
    },

    orderThanhToanNgayTotal() {
      return this.$store.getters["ORDER/orderThanhToanNgayTotal"];
    },

    orderThanhToanSauTotal() {
      return this.$store.getters["ORDER/orderThanhToanSauTotal"];
    },

    paymentTransactionStatusRequest() {
      return this.$store.getters["PAYMENT_TRANSACTION/statusRequest"];
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },

  methods: {
    closeModal() {
      this.payments = [];
      this.paymentBranch = null;
      this.$modal.hide({ name: "modal-form-order-transaction" });
    },

    async onConfirm() {
      const isValid = this.$refs.formRef.$refs.formRef.validate();

      if (!this.paymentBranch) {
        showModalAlertError(this, {
          title: "Không thể tạo phiếu cọc",
          message:
            "Có vẻ đơn hàng chưa có chi nhánh phụ trách. Vui lòng kiểm tra và thử lại."
        });

        return;
      }

      if (!isValid) return;

      this.loading = true;

      try {
        const orderPayment = this.payments
          .filter(item => item.amount > 0)
          .map(item => {
            const obj = {
              branch_id: this.paymentBranch || this.order.branch_id,
              transactionable_id: this.order.id,
              transactionable_code: this.order.code,
              transactionable_type: "App\\Models\\Order",
              senderable_id: this.order.customer_id,
              senderable_type: "App\\Models\\User",
              sender_bank_id: null,
              note: null,
              ...item
            };

            if (obj.receiverable_type === "App\\Models\\Branch") {
              obj.receiverable_id = this.paymentBranch || this.order.branch_id;
            }

            return obj;
          });

        if (orderPayment.length === 0) {
          showModalAlertError(this, {
            title: "Vui lòng nhập dữ liệu ít nhất 1 phương thức thanh toán"
          });

          return;
        }

        // eslint-disable-next-line no-unreachable
        await this.$store.dispatch(
          "PAYMENT_TRANSACTION/createPaymentTransaction",
          orderPayment
        );

        if (
          this.paymentTransactionStatusRequest.value ===
          "success-createPaymentTransaction"
        ) {
          showToastActionAlert(this, {
            message: "Thu cọc thành công"
          });

          await this.$store.dispatch(
            "ORDER/getOrderPaymentTransactions",
            this.order.id
          );
        } else if (
          this.paymentTransactionStatusRequest.value ===
          "error-createPaymentTransaction"
        ) {
          showModalAlertError(this, {
            title: this.paymentTransactionStatusRequest.message
          });
        }

        this.closeModal();

        this.paymentKey++;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    onUpdateTransactions(value) {
      this.payments = value;
    }
  }
};
</script>
