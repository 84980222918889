<template>
  <v-data-table
    class="tp-table__row-pointer datatable px-3 pb-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có sản phẩm khuyến mại nào"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    hide-default-header
    :loading="false"
    loading-text="Đang tải dữ liệu"
    :items="slidePromotionTkOptions"
    item-key="id"
  >
    <!-- Start: Product info -->
    <template v-slot:[`item.product`]="{ item }">
      <div class="my-2">
        <div class="font-weight-bold">
          {{ item.name }}
        </div>
        <div class="text-subtitle-2 font-weight-bold grey--text text--darken-2">
          <span>{{ item.SKU }}</span>
        </div>
      </div>
    </template>
    <!-- End: Product info -->
    <!-- Start: Price -->
    <template v-slot:[`item.price`]="{ item }">
      <div>
        <div class="font-weight-bold red--text">
          {{ calculateSalePrice(item.price) | formatCurrency }}
        </div>
        <div class="text-body-2">
          <span class="text-decoration-line-through">{{
            item.price | formatCurrency
          }}</span>
        </div>
      </div>
    </template>
    <!-- End: Price -->
    <!-- Start: Action -->
    <template v-slot:[`item.action`]="{ item }">
      <v-checkbox
        :disabled="
          !selectedPromotionOptions.includes(item.id) &&
            selectedPromotionOptions.length >=
              parseInt(promotion.tk_option.quantity)
        "
        :input-value="selectedPromotionOptions"
        :value="item.id"
        @click="selectPromotionOption(item)"
      ></v-checkbox>
    </template>
    <!-- End: Action -->
  </v-data-table>
</template>

<script>
import { calculateTkDiscountAmount } from "@/core/composables/usePromotion";

export default {
  props: {
    isShowAll: {
      type: Boolean,
      default: false
    },
    promotion: {
      type: Object,
      required: true
    },
    promotionTkOptions: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      headers: [
        {
          text: "Sản phẩm khuyến mại",
          align: "start",
          sortable: false,
          value: "product"
        },
        {
          text: "Giá",
          align: "center",
          sortable: false,
          value: "price"
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "action"
        }
      ]
    };
  },

  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    saleTabActiveProductOption() {
      return this.$store.getters["SALE/saleTabActiveProductOption"];
    },

    saleTabActiveProductOptionIndex() {
      return this.$store.getters["SALE/saleTabActiveProductOptionIndex"];
    },

    selectedPromotionOptions() {
      return this.saleTabActive.details[
        this.saleTabActiveProductOptionIndex
      ].gifts
        .filter(item => item.promotion?.id === this.promotion.id)
        .map(item => item.option_id);
    },

    slidePromotionTkOptions() {
      if (this.isShowAll) {
        return this.promotionTkOptions;
      }

      return this.promotionTkOptions.slice(0, 3);
    }
  },

  methods: {
    calculateSalePrice(price) {
      const discountAmount = calculateTkDiscountAmount(
        this.promotion.tk_option,
        price
      );

      return price - discountAmount;
    },

    async selectPromotionOption(item) {
      // Check exist item in selectPromotionOption
      if (this.selectedPromotionOptions.includes(item.id)) {
        this.saleTabActive.details[
          this.saleTabActiveProductOptionIndex
        ].gifts = await this.saleTabActive.details[
          this.saleTabActiveProductOptionIndex
        ].gifts.filter(option => option.option_id !== item.id);
      } else {
        await this.saleTabActive.details[
          this.saleTabActiveProductOptionIndex
        ].gifts.unshift({
          promotion: this.promotion,
          option: {
            id: item.id,
            SKU: item.SKU,
            product_name: item.product_name,
            name: item.name
          },
          option_id: item.id,
          qty: 1,
          unit_price: item.price,
          rebate_amount: item.price - this.calculateSalePrice(item.price)
        });
      }

      // Update sale active tab
      await this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
    }
  }
};
</script>
