<template>
  <div class="px-7">
    <div class="d-flex">
      <div class="w-1/2">
        <span class="font-weight-medium" style="font-size: 18px;">
          Chọn đối tác
        </span>
      </div>

      <div
        v-if="shippingInfo.created_by === LADING_TYPES.HE_THONG_TAO.value"
        class="w-1/2"
      >
        <span class="font-weight-medium" style="font-size: 18px;">
          Phí dự kiến
        </span>
      </div>
    </div>

    <v-radio-group v-model="shippingInfo.shipping_supplier_code">
      <div
        v-for="shippingSupplier in shippingSuppliers"
        :key="shippingSupplier.code"
        class="mt-2 d-flex"
      >
        <div class="w-1/2">
          <v-radio
            :disabled="
              !(orderProcessing.status === 1 || orderProcessing.status === 3)
            "
            :value="shippingSupplier.code"
          >
            <template #label>
              <div style="height: 50px;">
                <img
                  :alt="shippingSupplier.name"
                  :src="shippingSupplier.logo"
                  style="height: 100%; width: 100%"
                />
              </div>
            </template>
          </v-radio>
        </div>

        <div
          v-if="shippingInfo.created_by === LADING_TYPES.HE_THONG_TAO.value"
          class="w-1/2"
        >
          <span
            v-if="
              shippingFee && shippingFee[shippingInfo.shipping_supplier_code]
            "
          >
            {{
              shippingFee[shippingSupplier.code].est_shipping_fee
                | formatCurrency
            }}
          </span>
          <span v-else>-</span>
        </div>
      </div>
    </v-radio-group>

    <shipping-handling-guide class="mt-4"></shipping-handling-guide>
  </div>
</template>

<script>
import { computed, defineComponent, watch } from "vue";
import {
  LADING_TYPES,
  SHIPPING_SUPPLIERS
} from "@/modules/Sale/pages/Order/pages/OrderPage/constants";
import { createVuexHelpers } from "@/core/composables";
import ShippingHandlingGuide from "../components/shipping-handling-guide.vue";
import OrderService from "@/modules/Transaction/service/order.service";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import { debouncedWatch } from "@vueuse/core";

export default defineComponent({
  components: { ShippingHandlingGuide },

  setup() {
    const { useGetters } = createVuexHelpers();
    const { shippingInfo } = useGetters("ORDER_PROCESSING", ["shippingInfo"]);
    const { orderProcessing } = useGetters("ORDER", ["orderProcessing"]);

    const shippingSuppliers = computed(() => {
      const _shippingSuppliers = SHIPPING_SUPPLIERS.getCoreValues();

      return _shippingSuppliers.filter(shippingSupplier => {
        return shippingSupplier.lading_types.includes(
          shippingInfo.value.created_by
        );
      });
    });

    watch(
      () => shippingInfo.value.created_by,
      value => {
        if (
          value === "SYSTEM" &&
          shippingInfo.value.shipping_supplier_code &&
          SHIPPING_SUPPLIERS[
            shippingInfo.value.shipping_supplier_code
          ].lading_types.includes(LADING_TYPES.NHAN_VIEN_TAO.value)
        ) {
          console.log("null");
          shippingInfo.value.shipping_supplier_code = null;
        }
      }
    );

    const params = computed(() => {
      return {
        branch_id: orderProcessing.value.branch_id,
        serials: orderProcessing.value.serials,
        received_address: orderProcessing.value.received_address,
        ...shippingInfo.value
      };
    });
    const { fetch, data: shippingFee, isFetching } = useFetchData(
      () => OrderService.getShippingFee(params.value),
      {
        initialData: null,
        transform: response => {
          return response.data.reduce((acc, item) => {
            acc[item.code] = item;
            return acc;
          }, {});
        },
        reject(e) {
          console.log("e", { e });
        }
      }
    );

    debouncedWatch(
      () => params.value,
      value => {
        const { branch_id, received_address } = value;
        if (branch_id && received_address.so_nha) fetch();
      },
      { immediate: true, deep: true, debounce: 400 }
    );

    return {
      shippingInfo,
      shippingSuppliers,
      LADING_TYPES,
      shippingFee,
      isFetching,
      orderProcessing
    };
  }
});
</script>

<style lang="scss" scoped></style>
