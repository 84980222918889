<template>
  <div class="sale-at-store-sidebar text-body-1" flat>
    <div class="pa-4 sidebar-form-wrapper">
      <summary-amount class="mt-1"></summary-amount>

      <v-divider class="my-3"></v-divider>

      <div>
        <div class="d-flex align-center justify-space-between">
          <div class="font-weight-bold">Khách hàng</div>

          <button-choose-customer
            v-model="saleTabActive.customer"
            :button-name="
              saleTabActive.customer ? 'Thay đổi khách hàng' : 'Chọn khách hàng'
            "
            :is-disabled="saleTabActive.status > 1"
          ></button-choose-customer>
        </div>

        <button-view-customer-info
          v-if="saleTabActive.customer"
          class="mt-1"
        ></button-view-customer-info>
      </div>

      <v-divider class="my-3"></v-divider>

      <div class="mt-1">
        <panel-responsibility></panel-responsibility>
      </div>

      <v-divider class="my-3"></v-divider>

      <div>
        <div class="mb-2">
          <div class="font-weight-bold">Ghi chú</div>
        </div>

        <v-textarea
          v-model="saleTabActive.note"
          class="text-body-1"
          outlined
          :readonly="saleTabActive.cancel_status === 1"
          rows="3"
          placeholder="Nhập ghi chú"
        ></v-textarea>
      </div>
    </div>

    <div class="pa-4">
      <sidebar-bottom :order-form-ref="orderFormRef"></sidebar-bottom>
    </div>
  </div>
</template>

<script>
import ButtonChooseCustomer from "./components/ButtonChooseCustomer.vue";
import ButtonViewCustomerInfo from "./components/ButtonViewCustomerInfo.vue";
import PanelResponsibility from "./components/PanelResponsibility.vue";
import SidebarBottom from "./components/SidebarBottom.vue";
import SummaryAmount from "./components/SummaryAmount.vue";

export default {
  components: {
    ButtonChooseCustomer,
    ButtonViewCustomerInfo,
    PanelResponsibility,
    SidebarBottom,
    SummaryAmount
  },

  props: {
    orderFormRef: {
      type: Object,
      default: () => null
    }
  },

  computed: {
    addressDetails() {
      const {
        received_address,
        received_method,
        branch_nhan_id
      } = this.saleTabActive;

      if (!received_address) return this.getBranchName(branch_nhan_id);

      const { so_nha, phuong_xa, quan_huyen, tinh_thanhpho } = received_address;

      return received_method === 1
        ? `${so_nha}, ${phuong_xa}, ${quan_huyen}, ${tinh_thanhpho}`
        : `Cửa hàng: ${this.getBranchName(branch_nhan_id)}`;
    },

    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    saleTabActive: {
      get() {
        return this.$store.getters["SALE/saleTabActive"];
      },
      set(value) {
        this.$store.dispatch("SALE/setSaleTabActive", value);
      }
    }
  },

  methods: {
    getBranchName(branchId) {
      const branch = this.branches.find(branch => branch.id === branchId);
      return branch ? branch.name : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-form-wrapper {
  height: calc(100vh - 190px);
  overflow: auto;
  padding: 16px;
}

.sale-at-store-sidebar {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 122px);
  .sidebar-form-wrapper {
    overflow: auto;
  }
}
</style>
