<template>
  <div class="order-header" style="height: 46px">
    <div
      class="d-flex flex-row align-center justify-space-between order-header-sub"
    >
      <div class="d-flex align-center px-4">
        <div class="d-flex tab-wrapper">
          <v-tabs
            v-model="orderActiveSubTab"
            active-class="black--text font-weight-bold"
            background-color="transparent"
            class="font-weight-medium custom-order-tabs"
            color="primary"
            height="46px"
            show-arrows
          >
            <v-tab
              class="text-body-1 text-none px-3 black--text"
              @click="onHandlerClickTab('DETAIL_ORDER')"
            >
              <div class="d-flex align-baseline">
                Thông tin đơn hàng
                <v-chip class="ml-2" color="primary" x-small
                  >{{ totalSaleTabsProduct }}
                </v-chip>
              </div>
            </v-tab>

            <v-tab
              v-if="saleTabActive.id"
              class="text-body-1 text-none px-3 black--text"
              @click="onHandlerClickTab('DEPOSIT')"
            >
              Lịch sử thanh toán
            </v-tab>

            <v-tab
              v-if="saleTabActive.id"
              class="text-body-1 text-none px-3 black--text"
              @click="onHandlerClickTab('PARTICIPANT')"
            >
              <div class="d-flex align-baseline">
                Phục vụ
                <v-chip class="ml-2" color="primary" x-small
                  >{{ orderInvoiceParticipants.length }}
                </v-chip>
              </div>
            </v-tab>

            <!-- TODO Lịch sử đơn hàng -->
            <!-- <v-tab
              class="text-body-1 text-none px-3 black--text custom-divider"
              :disabled="!saleTabActive.id"
              @click="onHandlerClickTab('HISTORY')"
            >
              Lịch sử đơn hàng
            </v-tab> -->

            <v-tab
              v-for="(item, index) in saleTabActive.handles"
              :key="index + 19"
              class="text-body-1 text-none px-3 black--text"
              @click="viewOrderProcessing(item, index)"
            >
              {{ shortenCode(item.code) }}
              <v-chip
                v-if="item.id"
                :color="
                  item.cancel_status !== 1
                    ? findOrderProcessingStatus(item.status).color
                    : 'red'
                "
                class="ml-2 white--text font-weight-bold rounded-lg cursor-pointer"
                outlined
                small
              >
                {{
                  item.cancel_status !== 1
                    ? findOrderProcessingStatus(item.status).label
                    : "Đã hủy"
                }}
                <!-- {{ item.status }} -->
              </v-chip>
            </v-tab>
          </v-tabs>
        </div>

        <v-btn
          :disabled="
            !(
              saleTabActive.id &&
              totalSerialsAvailableInHandleDetails < productQuantity &&
              saleTabActive.status >= 2 &&
              saleTabActive.cancel_status !== 1
            )
          "
          class="ml-2 text-body-1 text-none font-weight-medium "
          color="primary"
          small
          text
          @click="addEmptyOrderProcessing()"
        >
          <v-icon>mdi-plus</v-icon>
          Thêm đơn xử lý
        </v-btn>
      </div>

      <div class="d-flex align-center">
        <v-btn
          :to="{ name: 'transaction_orders' }"
          class="text-body-1 text-none font-weight-medium mr-4"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-arrow-top-right</v-icon>
          Tất cả đơn đặt hàng
        </v-btn>

        <button-more-action v-if="false"></button-more-action>
      </div>
    </div>
  </div>
</template>

<script>
import {
  findOrderProcessingStatus,
  INIT_ORDER_PROCESSING_VALUE,
  INIT_SHIPPING_INFO_VALUE
} from "@/modules/Transaction/constant";
import { LIST_MODEL_TYPES } from "@/core/constant";
import ButtonMoreAction from "./ButtonMoreAction";

export default {
  components: { ButtonMoreAction },

  data() {
    return {
      activeOrderProcessing: null,
      LIST_MODEL_TYPES
    };
  },

  computed: {
    computedModelSiteConfigs: {
      get() {
        return this.saleModelSiteConfigs;
      },
      set(val) {
        this.$store.dispatch("SALE/setSaleModelSiteConfigs", val);
      }
    },

    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderActiveSubTab: {
      get() {
        return this.$store.getters["ORDER/orderActiveSubTab"];
      },
      set(value) {
        this.$store.commit("ORDER/SET_ORDER_ACTIVE_SUB_TAB", value);
      }
    },

    orderInvoiceParticipants() {
      return this.$store.getters["INVOICE_PARTICIPANT/invoiceParticipants"];
    },

    orderPaymentTransactionsLength() {
      return this.$store.getters["ORDER/orderPaymentTransactionsLength"];
    },

    productQuantity() {
      return this.order?.details.reduce((total, item) => {
        return total + Number(item.qty);
      }, 0);
    },

    saleModelSiteConfigs() {
      return this.$store.getters["SALE/saleModelSiteConfigs"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    totalSaleTabsProduct() {
      return this.saleTabActive?.details?.length;
    },
    totalSerialsAvailableInHandleDetails() {
      let totalSerialsCount = 0;

      this.saleTabActive.handles.forEach(handle => {
        if (handle.cancel_status === 0) {
          handle.details.forEach(detail => {
            totalSerialsCount += detail.serials.length;
          });
        }
      });

      return totalSerialsCount;
    }
  },

  watch: {
    "saleTabActive.id": {
      async handler(val) {
        if (val) {
          const response = await this.$store.dispatch(
            "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
            {
              modelId: val,
              modelType: LIST_MODEL_TYPES.ORDER
            }
          );
          // this.$emit("updateModelSiteConfigs", [...res]);
          await this.$store.dispatch("SALE/setSaleModelSiteConfigs", response);
        } else {
          await this.$store.dispatch("SALE/setSaleModelSiteConfigs", []);
        }
      },
      immediate: true
    },

    orderActiveSubTab(value) {
      if (value === 0) {
        this.onHandlerClickTab("DETAIL_ORDER");
      }
      if (value === 1) {
        this.onHandlerClickTab("DEPOSIT");
      }
    }
  },

  methods: {
    async addEmptyOrderProcessing() {
      const orderProcessing = JSON.parse(
        JSON.stringify(INIT_ORDER_PROCESSING_VALUE)
      );

      const shippingInfo = JSON.parse(JSON.stringify(INIT_SHIPPING_INFO_VALUE));

      // Set default value
      orderProcessing.address_id = this.saleTabActive.address_id;
      orderProcessing.branch_id = this.saleTabActive.branch_nhan_id
        ? this.saleTabActive.branch_nhan_id
        : this.saleTabActive.branch_id;
      orderProcessing.branch_nhan = this.saleTabActive.branch_nhan;
      orderProcessing.branch_nhan_id = this.saleTabActive.branch_nhan_id;
      orderProcessing.details = JSON.parse(
        JSON.stringify(this.saleTabActive.details)
      );
      orderProcessing.order_id = this.saleTabActive.id;
      orderProcessing.received_method = this.saleTabActive.received_method;
      orderProcessing.received_address = JSON.parse(
        JSON.stringify(this.saleTabActive.received_address)
      );
      orderProcessing.assigned_to_id = this.saleTabActive.assigned_to_id;

      // Set generated code
      orderProcessing.code = `${this.saleTabActive.code}-HDL${String(
        this.saleTabActive.handles.length + 1
      ).padStart(3, "0")}`;

      orderProcessing.payment_fee = null;

      orderProcessing.shipping_fee = null;

      orderProcessing.pay_amount = 0;

      orderProcessing.paid_amount = 0;

      // Add empty serials array into each option and gift
      orderProcessing.details.forEach(option => {
        option.serials = [];

        option.gifts.forEach(gift => {
          gift.serials = [];
        });
      });

      // Set order processing
      await this.$store.dispatch("ORDER/setOrderProcessing", orderProcessing);

      // Set empty shipping info
      this.$store.commit("ORDER_PROCESSING/SET_SHIPPING_INFO", shippingInfo);

      // Add new order processing into sale tab active handles
      this.saleTabActive.handles.push(orderProcessing);
      await this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);

      this.orderActiveSubTab = this.saleTabActive.handles.length + 2;

      // Set activeOrderProcessingIndex
      await this.$store.dispatch(
        "SALE/setActiveOrderProcessingIndex",
        this.saleTabActive.handles.length - 1
      );

      this.onHandlerClickTab("DETAIL_ORDER_PROCESSING");
    },

    findOrderProcessingStatus(status) {
      return findOrderProcessingStatus(status);
    },

    async onHandlerClickTab(payload) {
      this._validateTabAction(payload);

      const orderId = this.$route.query.id;

      if (payload === "DEPOSIT") {
        await this.$store.dispatch(
          "ORDER/getOrderPaymentTransactions",
          orderId
        );

        if (!this.order.id) {
          // Get order payments

          this.$modal.show({
            name: "modal-alert",
            payload: {
              title: "Chưa có thông tin đơn hàng",
              message: "Để tạo đặt cọc, bạn cần tạo đơn hàng trước"
            }
          });

          return;
        }
      }

      if (payload === "DETAIL_ORDER" && orderId) {
        console.log(
          "SALE/SET_SALE_TAB_ACTIVE -> OrderHeader",
          orderId,
          this.$route.query
        );

        await this.$store.dispatch("ORDER/getOrderById", orderId);

        this.$store.commit("SALE/SET_SALE_TAB_ACTIVE", this.order);
      }

      this.$emit("select-tab", payload);
    },

    _validateTabAction(payload) {
      if (
        ["DEPOSIT", "DETAIL_ORDER_PROCESSING"].includes(payload) &&
        this.totalSaleTabsProduct === 0
      ) {
        this.$modal.show({ name: "modal-empty-product" });
      }
    },

    shortenCode(code) {
      let arr = code.split("-");
      return arr[arr.length - 1];
    },

    async viewOrderProcessing(item, index) {
      const shippingInfo = JSON.parse(JSON.stringify(INIT_SHIPPING_INFO_VALUE));

      this.onHandlerClickTab("DETAIL_ORDER_PROCESSING");

      if (item.id) {
        await this.$store.dispatch("ORDER/getOrderById", this.saleTabActive.id);

        await this.$store.dispatch("SALE/setSaleTabActive", {
          ...this.order
        });

        await this.$store.dispatch("ORDER/getOrderProcessingById", item.id);
      } else {
        await this.$store.dispatch("ORDER/setOrderProcessing", item);
        await this.$store.commit(
          "ORDER_PROCESSING/SET_SHIPPING_INFO",
          shippingInfo
        );
      }

      await this.$store.dispatch("SALE/setActiveOrderProcessingIndex", index);
    }
  }
};
</script>

<style lang="scss" scoped>
.order-header {
  top: 46px !important;
}

.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}
</style>
<style lang="scss">
.order-header .v-toolbar__content {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
}

.order-header .order-header-main {
  padding-left: 16px;
  padding-right: 16px;
}

.order-header .order-header-sub {
  background: #edf5ff;
}

.border-0 {
  border: none !important;
}

.tab-wrapper {
  max-width: calc(100vw - 456px);
}

.custom-order-tabs {
  .v-tabs-slider-wrapper {
    height: 3px !important;
    display: flex;
    justify-content: center;

    .v-tabs-slider {
      width: 85%;
    }
  }
}

.custom-divider::after {
  content: "";
  background-color: white;
  height: 24px;
  width: 2px;
  position: absolute;
  right: 0;
}

.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
