<template>
  <div class="pb-3">
    <v-radio-group
      v-model="internalValue.received_method"
      class="font-weight-bold mb-3 mt-0"
      dense
      hide-details
      row
    >
      <v-radio label="Giao tận nơi" :value="1"></v-radio>
      <v-radio label="Nhận tại cửa hàng" :value="2"></v-radio>
    </v-radio-group>

    <!-- Start: Customer address -->
    <div
      v-if="internalValue.received_method === 1"
      class="d-flex justify-space-between
          align-center"
      style="
    border: 1px solid #aaaa;
    border-radius: 5px;"
      @click="openModalFormAddress"
    >
      <div
        v-if="internalValue.received_address"
        class="customer-address-container py-1 px-2"
      >
        <div style="max-width: 276px">
          <div class="black--text d-inline-block">
            <span>{{ internalValue.received_address.name }}</span>
            <span class="px-1">-</span>
          </div>
          <div>
            {{ internalValue.received_address.phone | VMask("### ### ####") }}
          </div>
          <div class="text-body-2 grey--text text--darken-3">
            <div class="copied-label">
              {{ addressDetails }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="py-2 px-2">Nhập địa chỉ</div>

      <!-- Start: Address action -->
      <div class="py-1 px-2">
        <!-- Start: Copy address -->
        <v-tooltip left v-if="internalValue.received_address">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="handleCopyAddress">
              <v-icon size="20px">mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Sao chép địa chỉ</span>
        </v-tooltip>
        <!-- End: Copy address -->
        <!-- Start: Clear data -->
        <div v-if="internalValue.received_address">
          <v-btn icon @click="clearSelectedAddress">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <!-- End: Clear data -->
      </div>
      <!-- End: Address action -->
    </div>
    <!-- End: Customer address -->

    <!-- Start: Choose Customer Address -->

    <v-btn
      v-if="internalValue.received_method === 1"
      class="mt-1"
      color="primary"
      small
      text
      @click="openModalSelectCustomerAddress"
    >
      <v-icon size="18">
        mdi-account-box-multiple-outline
      </v-icon>
      <span class="ml-2">
        CHỌN TỪ SỔ ĐỊA CHỈ KHÁCH HÀNG
      </span>
    </v-btn>

    <div v-if="internalValue.received_method === 2">
      <v-radio-group v-model="internalValue.branch_nhan_id" dense hide-details>
        <div
          v-for="item in branches"
          :class="[
            'custom-radio-wrapper',
            { active: internalValue.branch_nhan_id === item.id }
          ]"
          :key="item.id"
        >
          <v-radio
            :label="`${item.name} (${item.address})`"
            :value="item.id"
          ></v-radio>
        </div>
      </v-radio-group>
    </div>
    <!-- End: Received branch -->
    <ModalFormAddress
      v-if="internalValue.received_method === 1"
      :selected-address="internalValue.received_address"
      @addNewAddress="setSelectedAddress"
    />
    <ModalSelectCustomerAddress
      v-if="internalValue.received_method === 1"
      :customer-addresses="customerAddresses"
      :selected="internalValue.received_address"
      @select="setSelectedAddress"
    />
  </div>
</template>

<script>
import ModalFormAddress from "@/modules/Sale/pages/Order/pages/OrderPage/components/modals/ModalFormAddress.vue";
import ModalSelectCustomerAddress from "@/modules/Sale/pages/Order/pages/OrderPage/components/modals/ModalSelectCustomerAddress.vue";
export default {
  components: {
    ModalFormAddress,
    ModalSelectCustomerAddress
  },

  props: {
    value: {
      type: Object
    }
  },

  computed: {
    addressDetails() {
      return (
        this.internalValue.received_address.so_nha +
        ", " +
        this.internalValue.received_address.phuong_xa +
        ", " +
        this.internalValue.received_address.quan_huyen +
        ", " +
        this.internalValue.received_address.tinh_thanhpho
      );
    },

    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    customerAddresses() {
      return this.$store.getters["CUSTOMER/customerAddresses"];
    },

    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  methods: {
    clearSelectedAddress(event) {
      event.stopPropagation();
      this.internalValue.received_address = null;
    },

    handleCopyAddress(event) {
      event.stopPropagation();
      this.copyToClipboard(
        `${this.saleTabActive.received_address.name}, ${this.saleTabActive.received_address.phone}, ${this.addressDetails}`
      );
    },

    openModalSelectCustomerAddress() {
      this.$modal.show({ name: "modal-select-customer-address" });
    },

    openModalFormAddress() {
      this.saleTabActive.status > 1
        ? null
        : this.$modal.show({ name: "modal-form-address" });
    },

    setSelectedAddress(data) {
      this.$emit("input", { ...this.internalValue, received_address: data });
    }
  },

  watch: {
    "internalValue.received_method"(val) {
      if (val === 1) {
        this.internalValue.branch_nhan_id = null;
        this.internalValue.branch_nhan = null;
      }
      if (val === 2) {
        this.internalValue.shipping_partner = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-radio-wrapper {
  border: 1px solid #f0f2fa;
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 8px;
}
.active {
  border: 1px solid #0065ee;
}
</style>
