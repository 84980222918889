var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-col"},[(_vm.saleTabActive.id === undefined || _vm.saleTabActive.id === null)?_c('v-btn',{staticClass:"btn-submit",attrs:{"block":"","color":"primary","depressed":"","dark":"","loading":_vm.orderStatusRequest.value === 'loading-createOrder'},on:{"click":function($event){_vm.orderStatusRequest.value === 'loading-createOrder'
        ? null
        : _vm.createOrder()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Tạo đơn hàng ")],1):_vm._e(),(_vm.saleTabActive.id && _vm.saleTabActive.status < 4)?_c('div',{staticClass:"d-flex flex-col"},[_c('v-btn',{staticClass:"flex-grow-1",attrs:{"color":"primary","dark":"","depressed":"","loading":_vm.orderStatusRequest.value === 'loading-updateOrder',"outlined":""},on:{"click":function($event){_vm.orderStatusRequest.value === 'loading-updateOrder'
          ? null
          : _vm.updateOrder()}}},[_vm._v(" Lưu ")]),(_vm.saleTabActive.status < 2)?_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","dark":"","depressed":"","loading":_vm.orderStatusRequest.value === 'loading-updateOrder'},on:{"click":function($event){_vm.orderStatusRequest.value === 'loading-updateOrder'
          ? null
          : _vm.updateOrder(2)}}},[_vm._v(" Xác nhận ")]):_vm._e()],1):_vm._e(),(
      _vm.saleTabActive.id &&
        _vm.saleTabActive.status < 4 &&
        _vm.saleTabActive.cancel_status !== 1
    )?_c('div',[_c('v-divider',{staticClass:"my-3 dashed"}),_c('v-btn',{staticClass:"btn-submit",attrs:{"block":"","color":"red","depressed":"","outlined":""},on:{"click":_vm.cancelOrder}},[_vm._v(" Hủy đơn ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }