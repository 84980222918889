import { render, staticRenderFns } from "./InvoiceForm.vue?vue&type=template&id=232ab1ac&scoped=true&"
import script from "./InvoiceForm.vue?vue&type=script&lang=js&"
export * from "./InvoiceForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232ab1ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBtn,VDivider})
