var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-list-wrapper"},[_c('v-data-table',{staticClass:"table-scroll tp-table__row-pointer datatable px-3 py-2 rounded-0 h-full",attrs:{"headers":_vm.saleTabActive.status < 2 ||
      (_vm.saleTabActive.status < 4 && _vm.saleTabActiveNewProductOptions.length > 0)
        ? _vm.headers
        : _vm.headers.slice(0, 9),"height":_vm.order && _vm.order.id && !_vm.saleTabActive.sale_at_store
        ? 'calc(100vh - 212px)'
        : 'calc(100vh - 132px)',"items":_vm.saleTabActive.details,"loading":false,"calculate-widths":"","disable-pagination":"","fixed-header":"","hide-default-footer":"","item-key":"id","loading-text":"Đang tải dữ liệu","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
        var items = ref.items;
return [(items.length > 0)?_c('tbody',[_vm._l((items),function(item,index){return [_c('tr',{key:item.id,staticStyle:{"vertical-align":"top"}},[_c('td',[_c('div',{staticClass:"my-6"},[_vm._v(_vm._s(index + 1))])]),_c('td',[_c('div',{staticClass:"my-4"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.option.product_name)+" "),(
                      item.cur_promotions && item.cur_promotions.length > 0
                    )?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('v-icon',{staticClass:"red--text text--accent-1 ml-1",class:{ 'red--text text--accent-2': hover },attrs:{"size":"18px"},on:{"click":function($event){return _vm.openModalSaleOrderPromotion(
                          item,
                          index,
                          item.cur_promotions
                        )}}},[_vm._v(" mdi-wallet-giftcard ")])]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"text-subtitle-2 font-weight-bold grey--text text--darken-2"},[_c('span',[_vm._v(_vm._s(item.option.SKU))]),(item.option.name)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',{staticClass:"font-weight-light tag-p__mb-0",domProps:{"innerHTML":_vm._s(item.option.name)}})])])]),_c('td',{staticStyle:{"width":"170px"}},[_c('div',{staticClass:"my-4"},[(_vm.saleTabActive.status <= 3)?_c('div',[_c('v-text-field',{staticClass:"text-body-1 text-field__text-center custom-input-qty",attrs:{"rules":[_vm.quantityRules(item.option.qty, item.option_id)],"dense":"","flat":"","hide-details":"auto","outlined":"","required":"","single-line":"","type":"number"},on:{"input":function($event){return _vm.checkRulesQuantity(item.option.qty, item.option_id)}},model:{value:(item.option.qty),callback:function ($$v) {_vm.$set(item.option, "qty", $$v)},expression:"item.option.qty"}}),(!!_vm.saleTabActive.handles.length)?_c('div',{staticClass:"mt-1"},[_c('div',[_vm._v(" Trong đơn xử lý: "+_vm._s(_vm.totalSerialsAvailableInHandleDetails(item.option_id))+" ")]),_c('div',[_vm._v(" Còn chờ xử lý: "+_vm._s(item.qty > _vm.totalSerialsAvailableInHandleDetails(item.option_id) ? item.option.qty - _vm.totalSerialsAvailableInHandleDetails( item.option_id ) : 0)+" ")])]):_vm._e()],1):[_vm._v(_vm._s(item.option.qty))]],2)]),_c('td',[_c('div',{staticClass:"my-4"},[(_vm.saleTabActive.status <= 3)?_c('tp-input-price',{attrs:{"min-value":item.option.rebate_amount ? item.option.rebate_amount : 0,"value":item.option.unit_price,"custom-class":"text-body-1 mt-0 pt-0","custom-style":"width: 120px","dense":"","flat":"","hide-details":"","outlined":"","solo":""},on:{"change":function($event){(item.option.unit_price = $event) &&
                      (item.option.unit_sold_price = $event)}}}):[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.option.unit_price))+" ")]],2)]),_c('td',[_c('div',{staticClass:"my-4"},[(_vm.saleTabActive.status <= 3)?_c('tp-input-price',{attrs:{"value":item.option.rebate_amount,"custom-class":"text-body-1 mt-0 pt-0","custom-style":"width: 120px","dense":"","flat":"","hide-details":"","outlined":"","solo":""},on:{"change":function($event){item.option.rebate_amount = $event}}}):[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.rebate_amount))+" ")]],2)]),_c('td',[_c('div',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")((item.option.unit_price - item.option.rebate_amount)))+" ")])]),_c('td',[_c('div',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(((item.option.unit_price - item.option.rebate_amount) * item.option.qty)))+" ")])]),(!_vm.totalSerialsAvailableInHandleDetails(item.option_id))?_c('td',[_c('div',{staticClass:"my-5"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'grey darken-1' : 'grey lighten-1',"icon":"","small":""},on:{"click":function($event){return _vm.removeProductOption(index, item.option_id)}}},[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})],1)]):_vm._e()]),_vm._l((item.gifts),function(gift,indx){return _c('tr',{key:("gift-" + indx + "-" + (gift.option_id)),staticClass:"green lighten-4",staticStyle:{"vertical-align":"top"}},[_c('td'),_c('td',[_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(gift.option.name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-bold grey--text text--darken-2"},[_c('span',[_vm._v(_vm._s(gift.option.SKU))])])])]),_c('td',[_c('div',{staticStyle:{"width":"68px"}},[(_vm.saleTabActive.status < 4 && _vm.handleAllowEditValue)?_c('v-text-field',{staticClass:"text-body-1 text-field__text-center",attrs:{"dense":"","flat":"","hide-details":"","outlined":"","placeholder":"","single-line":"","solo":"","type":"number"},model:{value:(gift.qty),callback:function ($$v) {_vm.$set(gift, "qty", $$v)},expression:"gift.qty"}}):[_vm._v(_vm._s(gift.qty))]],2)]),_c('td',[(_vm.saleTabActive.status < 4 && _vm.handleAllowEditValue)?_c('tp-input-price',{attrs:{"value":gift.unit_price,"custom-class":"text-body-1 mt-0 pt-0","custom-style":"width: 120px","dense":"","flat":"","hide-details":"","outlined":"","solo":""},on:{"change":function($event){(gift.unit_price = $event) &&
                    (gift.unit_sold_price = $event)}}}):[_vm._v(_vm._s(_vm._f("formatCurrency")(gift.unit_price))+" ")]],2),_c('td',[(_vm.saleTabActive.status < 4 && _vm.handleAllowEditValue)?_c('tp-input-price',{attrs:{"value":gift.rebate_amount,"custom-class":"text-body-1 mt-0 pt-0","custom-style":"width: 120px","dense":"","flat":"","hide-details":"","outlined":"","solo":""},on:{"change":function($event){gift.rebate_amount = $event}}}):[_vm._v(_vm._s(_vm._f("formatCurrency")(gift.rebate_amount))+" ")]],2),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")((gift.unit_price - gift.rebate_amount)))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(((gift.unit_price - gift.rebate_amount) * gift.qty)))+" ")]),(_vm.saleTabActive.status < 2)?_c('td',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'grey darken-1' : 'grey lighten-1',"icon":"","small":""},on:{"click":function($event){return _vm.removeProductOptionGift(index, _vm.idx)}}},[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})],1):_vm._e()])})]}),_vm._l((_vm.saleTabActive.promos),function(promo){return [_vm._l((promo.promo_details),function(item){return _c('tr',{key:item.option.id,staticClass:"yellow lighten-5"},[_c('td',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"red accent-2","size":"20px"}},[_vm._v("mdi-ticket-percent-outline ")])],1),_c('td',[_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.option.name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-bold grey--text text--darken-2"},[_c('span',[_vm._v(_vm._s(item.option.SKU))])])])]),_c('td',{staticClass:"text-center"},[_c('div',{staticStyle:{"width":"68px"}},[_vm._v(" "+_vm._s(item.qty)+" ")])]),_c('td',{staticClass:"text-center"},[_vm._v("0")]),_c('td',{staticClass:"text-center"},[_vm._v("0")]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.unit_price)))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.calculatePromotionAmount( promo.promo.tk_option, item.unit_price )))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")((item.unit_price - _vm.calculatePromotionAmount( promo.promo.tk_option, item.unit_price ))))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")((item.unit_price - _vm.calculatePromotionAmount( promo.promo.tk_option, item.unit_price ))))+" ")]),_c('td')])})]})],2):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":"7"}},[_vm._v("Không có dữ liệu")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }