var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-dots-horizontal")])],1)]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[_vm._l((_vm.printActionItems),function(item,i){return [_c('v-list-item',{key:i + 10,attrs:{"disabled":_vm.saleTabActive.status < 4,"color":item.disabled ? 'grey lighten-2' : '',"target":"_blank","to":{
            name: item.routeName,
            params: Object.assign({}, (item.routeName === 'print_order-invoice'
                ? { orderId: _vm.saleTabActive.id }
                : { handleId: _vm.saleTabActive.handles[0].id }))
          }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.saleTabActive.status < 4 ? 'grey lighten-2' : 'primary'},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)],1)]}),_vm._l((_vm.actionItems),function(item,i){return _c('v-list-item',{key:i,attrs:{"href":item.href,"target":"_blank"}},[_c('v-list-item-icon',[(item.icon === 'zalo')?_c('icon-zalo'):_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }