<template>
  <div class="sidebar-return">
    <div class="blue lighten-5 font-weight-bold mt-5 px-4 py-2">
      Trả hàng
      <span
        class="primary--text cursor-pointer hover-underline"
        @click="openModalSaleReceipt(saleTabActive.hoadonban_id)"
      >
        {{ shortenSaleReceiptCode }}
      </span>
    </div>

    <!-- Start: Calculate -->
    <div class="px-4 mt-4">
      <div class="d-flex flex-row align-center justify-space-between">
        <div class="">
          Tổng tiền hàng trả
          <span class="font-weight-medium"
            >({{ returnGoodsAmountTotal.quantity }} sp)</span
          >
        </div>
        <div class="font-weight-medium">
          {{ returnGoodsAmountTotal.value | formatCurrency }}
        </div>
      </div>

      <div class="d-flex flex-row align-end justify-space-between mt-1">
        <div class="">Phí trả hàng</div>
        <div class="font-weight-medium">
          <tp-input-price
            v-model="saleTabActive.phitrahang"
            custom-class="text-body-1 font-weight-medium price--text-right mt-0 pt-0"
            custom-style="max-width: 120px"
            hide-details
            negative
          ></tp-input-price>
        </div>
      </div>

      <div class="d-flex flex-row align-center justify-space-between mt-4">
        <div class="">Tổng tiền trả</div>
        <div class="font-weight-medium">
          {{ returnAmountTotal | formatCurrency }}
        </div>
      </div>
    </div>
    <!-- End: Calculate -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    shortenSaleReceiptCode() {
      return this.saleTabActive.hoadonban_code.split("-")[0];
    },
    returnAmountTotal() {
      let total = this.returnGoodsAmountTotal.value;

      if (this.saleTabActive.phitrahang) {
        total -= this.saleTabActive.phitrahang;
      }

      return total;
    },
    returnGoodsAmountTotal() {
      let obj = {
        value: 0,
        quantity: 0
      };

      this.saleTabActive.options.forEach(item => {
        obj.quantity += item.serials.length;
        obj.value += item.serials.length * item.return_price;
      });

      return obj;
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },

  methods: {
    async openModalSaleReceipt(id) {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceiptById", id);

      this.$modal.show({
        name: "modal-sale-receipts"
      });

      this.$store.dispatch(
        "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
        id
      );

      this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\HoaDonBan",
          modelId: id
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.hover-underline:hover {
  text-decoration: underline;
}
</style>
