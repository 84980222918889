<template>
  <v-form ref="orderFormRef">
    <div class="detail-sale-at-store-page">
      <div class="sale-at-store-wrapper">
        <v-card class="flex-1 product-list-wrapper" flat>
          <product-list-with-serial></product-list-with-serial>
        </v-card>
      </div>

      <div class="sidebar-wrapper">
        <v-card flat>
          <right-sidebar :order-form-ref="orderFormRef"></right-sidebar>
        </v-card>
      </div>
    </div>
  </v-form>
</template>

<script>
import ProductListWithSerial from "./components/ProductListWithSerials";
import RightSidebar from "./components/RightSideBar";

export default {
  components: {
    ProductListWithSerial,
    RightSidebar
  },

  data() {
    return {
      orderFormRef: null
    };
  },

  computed: {
    order() {
      return this.$store.getters["ORDER/order"];
    }
  },

  mounted() {
    // Access the orderFormRef after the component has been mounted
    this.orderFormRef = this.$refs.orderFormRef;
  }
};
</script>

<style lang="scss" scoped>
.detail-sale-at-store-page {
  display: flex;
  height: calc(100vh - 46px - 46px - 8px);
  .sale-at-store-wrapper {
    padding: 16px 8px 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    .bottom-action > * {
      height: 335px;
      // border: 1px solid black;
    }

    .product-list-wrapper {
      height: calc(100vh - 120px);
      overflow: auto;
    }
  }

  .sidebar-wrapper {
    width: 344px;
    min-width: 360px;
    padding-top: 16px;
    padding-right: 8px;
    overflow: auto;
  }
}
.bottom-action-item {
  max-height: 266px;
  overflow: auto;
}
.gap-2 {
  gap: 8px;
}
.flex-1 {
  flex: 1;
}
</style>
