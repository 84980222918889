<template>
  <div class="text-body-1">
    <div class="mb-5 d-flex justify-space-between align-center">
      <div class="d-flex">
        <div class="font-weight-bold text-h6">
          Hoá đơn bán #{{ saleReceipt.code }}
        </div>

        <v-chip
          :color="
            saleReceipt.site === LIST_MODEL_SITES.GOODSPACE.id
              ? 'teal lighten-5 teal--text teal--lighten-1'
              : 'blue lighten-5 blue--text text--darken-4'
          "
          class="text-body-1 font-weight-bold px-2 py-2 ml-2"
          label
          small
        >
          {{
            saleReceipt.site
              ? LIST_MODEL_SITES[saleReceipt.site].text
              : LIST_MODEL_SITES.THINKPRO.text
          }}
        </v-chip>
      </div>

      <div>
        <v-btn
          v-if="
            isPermissionCancel &&
              (saleReceipt.status !== -1 || saleReceipt.status !== -1)
          "
          color="red lighten-5 red--text rounded-lg text--accent-2 mr-3"
          depressed
          @click="cancelSaleReceipt"
        >
          Hủy
        </v-btn>

        <v-btn
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          depressed
          text
          @click="goToSalePage"
        >
          Chỉnh sửa
        </v-btn>

        <v-btn
          :to="{
            name: 'print_sale-receipt',
            params: {
              saleReceiptId: saleReceipt.id
            }
          }"
          class="mr-2"
          color="primary"
          icon
          target="_blank"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </div>
    </div>

    <card-general></card-general>

    <card-customer class="mt-5"></card-customer>

    <card-products-detail class="mt-5"></card-products-detail>

    <v-card class="mt-5 grey lighten-5 px-3 py-2" flat>
      <tab-payment-transitions
        :sale-receipt-payment-transactions="availablePaymentTransactions"
      ></tab-payment-transitions>
    </v-card>

    <v-card
      v-if="canceledPaymentTransactions.length"
      class="mt-5 grey lighten-5 px-3 py-2"
      flat
    >
      <div class="mb-2 font-weight-medium">Xem phiếu bị huỷ</div>

      <tab-payment-transitions
        :sale-receipt-payment-transactions="canceledPaymentTransactions"
      ></tab-payment-transitions>
    </v-card>
  </div>
</template>

<script>
import CardCustomer from "./components/CardCustomer.vue";
import CardGeneral from "./components/CardGeneral.vue";
import CardProductsDetail from "./components/CardProductsDetail.vue";
import TabPaymentTransitions from "./components/TabPaymentTransitions.vue";
import { RECEIPT_STATUS, LIST_MODEL_SITES } from "@/core/constant";
import { compareTwoDates } from "@/core/utils";

export default {
  components: {
    CardCustomer,
    CardGeneral,
    CardProductsDetail,
    TabPaymentTransitions
  },

  data() {
    return {
      LIST_MODEL_SITES
    };
  },

  computed: {
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },

    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },

    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },

    saleReceiptPaymentTransactions() {
      return this.$store.getters["SALE_RECEIPT/saleReceiptPaymentTransactions"];
    },

    availablePaymentTransactions() {
      return this.saleReceiptPaymentTransactions.filter(
        item => item.status !== RECEIPT_STATUS.BI_HUY
      );
    },

    canceledPaymentTransactions() {
      return this.saleReceiptPaymentTransactions.filter(
        item =>
          item.status === RECEIPT_STATUS.BI_HUY ||
          item.status === RECEIPT_STATUS.DAO
      );
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },

    isPermissionCancel() {
      const { permissions, role } = this.user;

      if (role.toLowerCase() === "nhóm quyền quản lý cửa hàng") {
        return compareTwoDates(this.saleReceipt.created_at, new Date()) === 1;
      }

      return permissions.includes("sale_receipts.cancel");
    },

    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    }
  },

  methods: {
    cancelSaleReceipt() {
      this.$modal.show({
        name: "modal-cancel",
        payload: {
          title: `<span>Hủy hóa đơn <strong>${this.saleReceipt.code}</strong></span>`,
          onSubmitHandler: async cancelReason => {
            await this.$store.dispatch("SALE_RECEIPT/cancelSaleReceipt", {
              id: this.saleReceipt.id,
              cancelReason: cancelReason
            });

            await this.$store.dispatch(
              "SALE_RECEIPT/getSaleReceiptById",
              this.orderProcessing.hoa_don_ban_id
            );

            // Alert
            if (
              this.saleReceiptStatusRequest.value ===
              "success-cancelSaleReceipt"
            ) {
              // Alert
              this.$toast.show({
                name: "toast-action-alert",
                payload: {
                  message: "Đã hủy hóa đơn"
                }
              });
            } else if (
              this.saleReceiptStatusRequest.value === "error-cancelSaleReceipt"
            ) {
              // Alert error
              this.$modal.show({
                name: "modal-alert",
                payload: {
                  title: `<span class="red--text text--accent-2">${this.saleReceiptStatusRequest.message}</span>`,
                  cancelBtnText: "OK"
                }
              });
            }

            this.$modal.hide({ name: "modal-sale-receipts" });
          }
        }
      });
    },

    async goToSalePage() {
      const foundSaleTab = this.saleTabs.find(
        item => item.id === this.saleReceipt.id
      );

      if (!foundSaleTab) {
        let localSaleTabs = JSON.parse(localStorage.getItem("TPSALE_TABS"));

        await this.$store.dispatch("SALE/addSaleTabItem", {
          ...this.saleReceipt,
          branch_id: this.saleReceipt.branch.id,
          khachhang_id: this.saleReceipt.khachhang.id
        });

        // Set active tab
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          localSaleTabs.length
        );
      }

      // Push route
      await this.$router.push({
        name: "sale",
        query: {
          type: "SALE_RECEIPT",
          id: this.saleReceipt.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
