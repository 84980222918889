var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4"},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v("Khách hàng")]),_c('div',{staticClass:"d_flex align-center px-0"},[_c('div',{staticClass:"d_flex align-center"},[_c('v-autocomplete',{ref:"autocompleteCustomer",staticClass:"text-body-1",class:_vm.customerStatusRequest.value === 'loading-getCustomers'
            ? 'autocomplete-customer--loading'
            : 'autocomplete-customer',staticStyle:{"width":"280px"},attrs:{"disabled":!!_vm.saleTabActive.status,"items":_vm.customers,"menu-props":{ maxWidth: '280px' },"readonly":!!_vm.saleTabActive.status,"search-input":_vm.customerSearchKey,"dense":"","flat":"","hide-details":"","item-text":"name","item-value":"id","no-data-text":"Không có dữ liệu","no-filter":"","outlined":"","placeholder":"Tìm khách hàng","prepend-inner-icon":"mdi-magnify","return-object":"","small-chips":""},on:{"update:searchInput":function($event){_vm.customerSearchKey=$event},"update:search-input":function($event){_vm.customerSearchKey=$event},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchCustomers.apply(null, arguments)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('div',{staticClass:"text-truncate tp-btn--text rounded-xl px-3 py-1",class:hover ? 'cyan lighten-4' : 'cyan lighten-5',on:{"click":function($event){return _vm.openModalCustomer(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})]}},{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"title":data.item.name}},[_c('v-list-item-title',{staticClass:"font-weight-medium",domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('div',{staticClass:"grey--text text--darken-2 text-subtitle-2"},[(data.item.phone)?_c('span',[_vm._v(_vm._s(_vm._f("VMask")(data.item.phone,"### ### ####")))]):_vm._e()])],1)]}},{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[(_vm.customerStatusRequest.value === 'loading-getCustomers')?_c('div',{staticClass:"d-flex align-center justify-center mr-2",staticStyle:{"width":"24px"}},[_c('v-progress-circular',{attrs:{"color":"grey lighten-2","indeterminate":""}})],1):(_vm.saleTabActive.khachhang_id && !_vm.saleTabActive.id)?_c('v-icon',{staticClass:"tp-btn--text mr-1",on:{"click":function($event){_vm.saleTabActive.khachhang_id = null}}},[_vm._v("mdi-close ")]):_vm._e(),(!_vm.saleTabActive.id)?_c('v-btn',{attrs:{"disabled":!_vm.customerSearchKey ||
                  _vm.customerSearchKey.trim() === '' ||
                  _vm.customerStatusRequest.value === 'loading-getCustomers',"color":"primary","depressed":"","small":""},on:{"click":_vm.searchCustomers}},[_vm._v("Tìm ")]):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.saleTabActive.khachhang),callback:function ($$v) {_vm.$set(_vm.saleTabActive, "khachhang", $$v)},expression:"saleTabActive.khachhang"}}),_c('v-btn',{staticClass:"cyan lighten-5 btn-add px-0 ml-2",attrs:{"color":"primary","depressed":"","height":"40px","icon":"","width":"40px"},on:{"click":function($event){return _vm.openModalCustomer()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]),(_vm.saleTabActive.khachhang)?_c('v-chip',{staticClass:"mt-2 font-weight-bold",class:_vm.saleTabActive.khachhang.cong_no < 0
        ? 'red lighten-5 red--text'
        : 'green lighten-5 green--text',attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.saleTabActive.khachhang.phone)+" - Nợ: "+_vm._s(_vm._f("formatCurrency")(_vm.saleTabActive.khachhang.cong_no || 0))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }