<template>
  <tp-modal
    max-width="900px"
    name="modal-form-order-transaction"
    width="90%"
    @close="closeModal()"
  >
    <v-card>
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Thông tin thanh toán đơn hàng {{ order.code }}
        </div>

        <v-spacer></v-spacer>

        <v-btn
          :loading="loading"
          class="ml-2"
          color="primary"
          dark
          depressed
          @click="onConfirm()"
        >
          Xác nhận
        </v-btn>

        <v-btn class="ml-2" icon @click="closeModal()"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card flat>
          <v-card-text class="text-body-1">
            <v-row>
              <v-col
                class="d-flex flex-col justify-start items-start black--text"
              >
                <span class="text-no-wrap">Giá trị đơn hàng</span>
                <strong>{{ orderAmount | formatCurrency }}</strong>
              </v-col>
              <v-col
                class="d-flex flex-col justify-start items-start black--text"
              >
                <span class="text-no-wrap">Yêu cầu đặt cọc</span>
                <strong>{{ order.min_deposit_amount | formatCurrency }}</strong>
              </v-col>
              <v-col
                class="d-flex flex-col justify-start items-start black--text"
              >
                <span class="text-no-wrap">Thanh toán ngay</span>
                <strong>{{ orderThanhToanNgayTotal | formatCurrency }}</strong>
              </v-col>
              <v-col
                class="d-flex flex-col justify-start items-start black--text"
              >
                <span class="text-no-wrap">Thanh toán sau (COD)</span>
                <strong>{{ orderThanhToanSauTotal | formatCurrency }}</strong>
              </v-col>
              <v-col
                class="d-flex flex-col justify-start items-start black--text"
              >
                <span class="text-no-wrap">Nợ</span>
                <strong>{{ orderDebt | formatCurrency }}</strong>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <form-payment
          ref="payment"
          :type="formPaymentType"
          class="mt-4"
          hide-cod-method
          @change="payments = $event"
          @change:branch="paymentBranch = $event"
        ></form-payment>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import FormPayment from "@/modules/Sale/pages/Order/pages/OrderPage/components/FormPayment.vue";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";

export default {
  name: "ModalNewDeposit",

  components: {
    FormPayment
  },

  props: {
    formPaymentType: {
      type: String,
      default: "THANH TOAN"
    }
  },

  data() {
    return {
      paymentBranch: null,
      payments: {},
      loading: false
    };
  },

  computed: {
    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderAmount() {
      return this.order.value || 0;
    },

    orderDebt() {
      return (
        this.orderAmount -
        this.orderThanhToanNgayTotal -
        this.orderThanhToanSauTotal
      );
    },

    orderThanhToanNgayTotal() {
      return this.$store.getters["ORDER/orderThanhToanNgayTotal"];
    },

    orderThanhToanSauTotal() {
      return this.$store.getters["ORDER/orderThanhToanSauTotal"];
    },

    paymentTransactionStatusRequest() {
      return this.$store.getters["PAYMENT_TRANSACTION/statusRequest"];
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },

  methods: {
    closeModal() {
      this.payments = {};
      this.paymentBranch = null;
      this.$refs.payment.onClearData();
      this.$modal.hide({ name: "modal-form-order-transaction" });
    },

    async onConfirm() {
      if (!this.order.branch_id) {
        showModalAlertError(this, {
          title: "Không thể tạo phiếu cọc",
          message:
            "Có vẻ đơn hàng chưa có chi nhánh phụ trách. Vui lòng kiểm tra và thử lại."
        });

        return;
      }

      this.loading = true;

      try {
        const paymentArr = Object.values(this.payments).reduce(
          (lastVal, currentVal) => {
            lastVal = lastVal.concat(currentVal);
            return lastVal;
          },
          []
        );

        const orderPayment = paymentArr
          .filter(item => item.amount > 0)
          .map(item => {
            const obj = {
              branch_id: this.paymentBranch || this.order.branch_id,
              transactionable_id: this.order.id,
              transactionable_code: this.order.code,
              transactionable_type: "App\\Models\\Order",
              senderable_id: this.order.customer_id,
              senderable_type: "App\\Models\\User",
              sender_bank_id: null,
              note: null,
              ...item
            };

            if (obj.receiverable_type === "App\\Models\\Branch") {
              obj.receiverable_id = this.paymentBranch || this.order.branch_id;
            }

            return obj;
          });

        if (orderPayment.length === 0) {
          showModalAlertError(this, {
            title: "Vui lòng nhập dữ liệu ít nhất 1 phương thức thanh toán"
          });

          return;
        }

        await this.$store.dispatch(
          "PAYMENT_TRANSACTION/createPaymentTransaction",
          orderPayment
        );

        if (
          this.paymentTransactionStatusRequest.value ===
          "success-createPaymentTransaction"
        ) {
          showToastActionAlert(this, {
            message: "Thu cọc thành công"
          });

          await this.$store.dispatch(
            "ORDER/getOrderPaymentTransactions",
            this.order.id
          );
        } else if (
          this.paymentTransactionStatusRequest.value ===
          "error-createPaymentTransaction"
        ) {
          showModalAlertError(this, {
            title: this.paymentTransactionStatusRequest.message
          });
        }

        this.closeModal();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
