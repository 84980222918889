var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"black--text",staticStyle:{"height":"380px"}},[_c('div',{staticClass:"mb-6"},[_c('div',{staticClass:"text-h6 font-weight-bold mb-3"},[_vm._v("Chi nhánh thanh toán")]),_c('tp-select',{attrs:{"clearable":"","dense":"","hide-details":"auto","items":_vm.branches,"item-text":"name","item-value":"id","outlined":"","placeholder":"Chọn chi nhánh","solo":""},on:{"change":_vm.onChangeBranch}})],1),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"text-h6 font-weight-bold mb-3"},[_vm._v("Phương thức thanh toán")]),_c('tp-select',{attrs:{"dense":"","hide-details":"auto","items":_vm.listPayment,"item-text":"text","item-value":"kind","outlined":"","placeholder":"Chọn phương thức thanh toán","solo":""},model:{value:(_vm.selectedMethod),callback:function ($$v) {_vm.selectedMethod=$$v},expression:"selectedMethod"}})],1),_c('v-form',{ref:"formRef"},[(_vm.selectedMethod)?_c('div',[(
            _vm.user.staff_roles.includes('CASH') &&
              _vm.selectedMethod === _vm.LIST_PAYMENT_KIND.TIEN_MAT.id
          )?_c('div',_vm._l((_vm.payment.cash),function(item,index){return _c('v-row',{key:index + '_cash'},[_c('v-col',{staticClass:"d-flex flex-row justify-start align-center",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cash-100")]),_c('span',{staticClass:"font-weight-medium text-body-1 ml-1 text-no-wrap"},[_vm._v("Tiền mặt")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","dense":"","flat":"","hide-details":"","outlined":"","solo":"","value":item.amount},on:{"change":function ($event) {
                    item.amount = $event;
                    _vm.onChangeData();
                  }}})],1),_c('v-col',{attrs:{"cols":"4"}})],1)}),1):_vm._e(),(_vm.selectedMethod === _vm.LIST_PAYMENT_KIND.CHUYEN_KHOAN.id)?_c('div',_vm._l((_vm.payment.bank),function(item,index){return _c('v-row',{key:index + '_bank'},[_c('v-col',{staticClass:"d-flex flex-row justify-start align-center",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"#0B9574"}},[_vm._v("mdi-bank")]),_c('span',{staticClass:"font-weight-medium text-body-1 ml-1 text-no-wrap"},[_vm._v("Chuyển khoản")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","dense":"","flat":"","hide-details":"","outlined":"","solo":"","value":item.amount},on:{"change":function ($event) {
                    item.amount = $event;
                    _vm.onChangeData();
                  }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"dense":"","flat":"","items":_vm.companyBankAccounts,"item-value":"id","item-text":"name","hide-details":"auto","menu-props":{ nudgeTop: '-40px' },"outlined":"","solo":"","placeholder":"Tài khoản nhận","rules":[function (v) { return !!v || 'Không được bỏ trống'; }]},on:{"change":function($event){return _vm.onChangeData()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticStyle:{"max-width":"100%"}},[_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(data.item.number)+" - "+_vm._s(data.item.name)+" ("+_vm._s(data.item.bank_code)+") ")])])]}},{key:"item",fn:function(data){return [_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(data.item.number)+" - "+_vm._s(data.item.name)+" ")]),_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(data.item.bank_code))])])]}}],null,true),model:{value:(item.receiver_bank_id),callback:function ($$v) {_vm.$set(item, "receiver_bank_id", $$v)},expression:"item.receiver_bank_id"}})],1)],1)}),1):_vm._e(),(_vm.selectedMethod === _vm.LIST_PAYMENT_KIND.QUET_THE.id)?_c('div',_vm._l((_vm.payment.credit),function(item,index){return _c('v-row',{key:index + '_credit'},[_c('v-col',{staticClass:"d-flex flex-row justify-start align-center",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"#F36600"}},[_vm._v("mdi-credit-card-outline")]),_c('span',{staticClass:"font-weight-medium text-body-1 ml-1 text-no-wrap"},[_vm._v("Quẹt thẻ")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","dense":"","flat":"","hide-details":"","label":"Số tiền chưa bao gồm phí","outlined":"","value":item.amount},on:{"change":function ($event) {
                    item.amount = $event;
                    _vm.onChangeData();
                  }}}),_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-2 pt-0","flat":"","label":"Phí thanh toán","hide-details":"","value":item.payment_fee},on:{"change":function ($event) {
                    item.payment_fee = $event;
                    _vm.onChangeData();
                  }}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('tp-select',{attrs:{"items":_vm.paymentPartner,"hide-details":"auto","item-text":"name","item-value":"id","placeholder":"Đối tác","solo":"","validate":"required"},on:{"change":function($event){return _vm.onChangeData()}},model:{value:(item.receiverable_id),callback:function ($$v) {_vm.$set(item, "receiverable_id", $$v)},expression:"item.receiverable_id"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('tp-select',{attrs:{"items":_vm.filteredPosDevices,"hide-details":"auto","item-text":"name","item-value":"name","no-data-text":"Chọn chi nhánh trước","placeholder":"Chọn máy POS","solo":"","validate":"required"},on:{"change":function($event){return _vm.onChangeData()}},model:{value:(item.pos_id),callback:function ($$v) {_vm.$set(item, "pos_id", $$v)},expression:"item.pos_id"}})],1)],1)}),1):_vm._e(),(_vm.selectedMethod === _vm.LIST_PAYMENT_KIND.TRA_GOP.id)?_c('div',_vm._l((_vm.payment.installment),function(item,index){return _c('v-row',{key:index + '_credit'},[_c('v-col',{staticClass:"d-flex flex-row justify-start align-center",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"#7A4AEC"}},[_vm._v("mdi-clock-outline")]),_c('span',{staticClass:"font-weight-medium text-body-1 ml-1 text-no-wrap"},[_vm._v("Trả góp")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","dense":"","flat":"","hide-details":"","label":"Số tiền chưa bao gồm phí","outlined":"","value":item.amount},on:{"change":function ($event) {
                    item.amount = $event;
                    _vm.onChangeData();
                  }}}),_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-2 pt-0","dense":"","flat":"","hide-details":"","label":"Phí thanh toán","outlined":"","value":item.payment_fee},on:{"change":function ($event) {
                    item.payment_fee = $event;
                    _vm.onChangeData();
                  }}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('tp-select',{attrs:{"items":_vm.installmentPartner,"hide-details":"auto","item-text":"name","item-value":"id","placeholder":"Đối tác trả góp","solo":"","validate":"required"},on:{"change":function($event){return _vm.onChangeData()}},model:{value:(item.receiverable_id),callback:function ($$v) {_vm.$set(item, "receiverable_id", $$v)},expression:"item.receiverable_id"}})],1)],1)}),1):_vm._e(),(_vm.selectedMethod === _vm.LIST_PAYMENT_KIND.COD.id)?_c('div',_vm._l((_vm.payment.cod),function(item,index){return _c('v-row',{key:index + '_cod'},[_c('v-col',{staticClass:"d-flex flex-row justify-start align-center",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"#F43E6A"}},[_vm._v("mdi-truck-outline")]),_c('span',{staticClass:"font-weight-medium text-body-1 ml-1 text-no-wrap"},[_vm._v(" COD ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","dense":"","flat":"","hide-details":"","outlined":"","solo":"","value":item.amount},on:{"change":function ($event) {
                    item.amount = $event;
                    _vm.onChangeData();
                  }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('tp-select',{attrs:{"items":_vm.shippingPartner,"hide-details":"auto","item-text":"name","item-value":"id","placeholder":"Đối tác vận chuyển","solo":"","validate":"required"},on:{"change":function($event){return _vm.onChangeData()}},model:{value:(item.receiverable_id),callback:function ($$v) {_vm.$set(item, "receiverable_id", $$v)},expression:"item.receiverable_id"}})],1)],1)}),1):_vm._e()]):_c('div',{staticClass:"pa-5 d-flex justify-center align-center text-body-1 font-italic grey--text"},[_vm._v(" Vui lòng chọn phương thức thanh toán ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }