<template>
  <div class="detail-order-processing-page">
    <div class="order-processing-wrapper">
      <div class=" px-4 py-1">
        <v-chip-group
          v-model="activeTab"
          active-class="primary white--text font-weight-bold"
          mandatory
        >
          <v-chip class="white">
            Sản phẩm ({{ orderProcessing.serials.length }})
          </v-chip>

          <v-chip :disabled="!orderProcessing.id" class="white">
            Hoá đơn bán ({{
              shortenCode(orderProcessing.hoa_don_ban_code) || "Chưa xuất"
            }})
          </v-chip>

          <v-chip
            :disabled="
              orderProcessing.received_method === 2 ||
                !orderProcessing.hoa_don_ban_code
            "
            class="white"
          >
            Vận chuyển & Đóng gói
          </v-chip>
        </v-chip-group>
      </div>

      <order-processing-status
        v-if="orderProcessing.id && activeComponent !== 'TabInvoice'"
      ></order-processing-status>

      <div
        v-if="activeComponent !== 'TabInvoice'"
        class="order-processing-list-wrapper"
      >
        <component
          :is="activeComponent"
          :order-handle-participants="orderHandleParticipants"
          @getOrderHandleParticipant="getOrderHandleParticipant"
        ></component>
      </div>

      <div v-else>
        <tab-invoice></tab-invoice>
      </div>
    </div>

    <v-card class="sidebar-wrapper" flat>
      <order-processing-sidebar></order-processing-sidebar>
    </v-card>
  </div>
</template>

<script>
import TabVat from "./components/TabVat.vue";
import TabOrderProcessing from "./components/TabOrderProcessing/index.vue";
import TabParticipant from "./components/TabParticipant.vue";
import TabInvoice from "./components/TabInvoice/index.vue";
import TabDelivery from "./components/TabDelivery/index.vue";
import OrderProcessingSidebar from "./components/OrderProcessingSidebar/index.vue";
import OrderProcessingStatus from "./components/TabOrderProcessing/components/OrderProcessingStatus.vue";

export default {
  components: {
    TabVat,
    TabParticipant,
    TabOrderProcessing,
    TabInvoice,
    TabDelivery,
    OrderProcessingSidebar,
    OrderProcessingStatus
  },

  data() {
    return {
      activeTab: 0,
      orderHandleParticipants: [],
      shippingMethod: 0
    };
  },

  computed: {
    activeComponent() {
      let comp = "TabOrderProcessing";

      switch (this.activeTab) {
        case 0:
          comp = "TabOrderProcessing";
          break;

        case 1:
          comp = "TabInvoice";
          break;

        case 2:
          comp = "TabDelivery";
          break;

        default:
          break;
      }

      return comp;
    },

    heightStyle() {
      if (this.orderProcessing.id) {
        return { height: "calc(100vh - 268px)" };
      } else {
        return { height: "calc(100vh - 185px)" };
      }
    },

    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    orderProcessingVatRequest() {
      return this.$store.getters["VAT_REQUEST/vatRequest"];
    },

    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },

    sidebarWrapHeight() {
      if (this.orderProcessing.cancel_status === 1) {
        return null;
      }

      if (this.orderProcessing.status >= 3 && !this.orderProcessing.is_export) {
        return "height: calc(100vh - 250px)";
      }

      return "height: calc(100vh - 190px)";
    },

    shippingInfo() {
      return this.$store.getters["ORDER_PROCESSING/shippingInfo"];
    }
  },

  watch: {
    activeTab(val) {
      if (val === 1 && this.orderProcessing.hoa_don_ban_id) {
        this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptById",
          this.orderProcessing.hoa_don_ban_id
        );

        this.$store.dispatch(
          "SALE_RECEIPT/resetSaleReceiptPaymentTransactions"
        );

        this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
          this.orderProcessing.hoa_don_ban_id
        );
      }
    },

    "orderProcessing.branch_id"(newValue) {
      this.orderProcessing.branch_nhan_id = newValue;
    },

    // "orderProcessing.branch_nhan_id"(newValue) {
    //   this.orderProcessing.branch_id = newValue;
    // },

    async orderProcessing() {
      this.activeTab = 0;
      await this.$store.dispatch("ORDER_PROCESSING/getShippingPartners", {
        ...this.orderProcessing,
        ...this.shippingInfo
      });
    }
  },

  methods: {
    checkDisabledTab(tabIndex) {
      if (tabIndex === 0) return false;

      if (!this.orderProcessing.id) return true;

      return tabIndex !== 2 && this.orderProcessing.status < 3;
    },

    async getOrderHandleParticipant() {
      this.orderHandleParticipants = await this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\OrderHandle",
          modelId: this.orderProcessing.id,
          disabledMutationState: true
        }
      );
    },

    shortenCode(code) {
      if (!code) return "";
      return code.split("-")[0];
    }
  }
};
</script>
<style lang="scss">
.detail-order-processing-page {
  align-items: stretch;
  background-color: #eeeeee;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 46px - 46px);
  width: 100%;
  gap: 4px;
  overflow: hidden;

  .order-processing-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .order-processing-list-wrapper {
      margin-top: 4px;
      background-color: white;
      flex: 1 1 0%;

      .summary-amount {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 24px;
      }
    }

    .responsibility-wrapper {
      display: flex;
      gap: 16px;
      height: 46px;

      & > div {
        width: 390px;
      }
    }

    .delivery-section-wrapper {
      min-height: calc(100vh - 395px);
      margin-top: 8px;
      border-radius: 4px;
      background-color: white;
      padding: 16px;

      .form-delivery-container {
        width: 400px;
      }

      .table-delivery-container {
        flex: 1;
      }
    }
  }

  .sidebar-wrapper {
    max-width: 360px;
    border-radius: 0;
  }
}

.full-height {
  height: calc(100vh - 222px);
}
</style>
