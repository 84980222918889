<template>
  <div class="px-4">
    <sidebar-general-time />

    <!-- Start: Branch -->
    <div class="d-flex flex-row">
      <v-col class="textfield__hide-outline pa-0">
        <v-autocomplete
          v-model="saleTabActive.branch_id"
          :items="branches"
          :menu-props="{ maxWidth: '332px' }"
          :readonly="!!saleTabActive.status"
          class="text-body-2 rounded mt-0"
          dense
          flat
          hide-details
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          placeholder="Chọn chi nhánh"
        >
          <template slot="prepend">
            <v-icon color="grey lighten-1" size="18px" style="margin-top: 2px">
              mdi-map-marker
            </v-icon>
          </template>
        </v-autocomplete>
      </v-col>
    </div>
    <!-- End: Branch -->

    <!-- Start: Created user-->
    <div class="d-flex flex-row mt-1">
      <v-col class="pa-0" cols="12">
        <div>
          <v-icon
            color="grey lighten-1"
            size="18px"
            style="margin-right: 10px; margin-top: -3px"
          >
            mdi-account-plus
          </v-icon>
          <span v-if="!saleTabActive.id">Tạo bởi: {{ user.name }}</span>
          <span v-else>{{ saleTabActive.created_user.name }}</span>
        </div>
      </v-col>
    </div>
    <!-- End: Created user-->

    <!-- Start: Closing user-->
    <div class="textfield__hide-outline mt-1">
      <v-autocomplete
        v-model="saleTabActive.closing_user_id"
        :items="saleEmployees"
        :readonly="!!saleTabActive.status"
        class="text-body-1"
        dense
        flat
        hide-details
        item-text="name"
        item-value="id"
        no-data-text="Không có dữ liệu"
        placeholder="Chọn người chốt đơn"
        prefix="Chốt đơn:"
      >
        <template slot="prepend">
          <v-icon color="grey lighten-1" size="18px" style="margin-top: 0">
            mdi-account-star
          </v-icon>
        </template>
      </v-autocomplete>
    </div>
    <!-- End: Closing user-->
  </div>
</template>

<script>
import SidebarGeneralTime from "./SidebarGeneralTime";

export default {
  components: { SidebarGeneralTime },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    saleEmployees() {
      return this.employees.filter(i => i.staff_roles.includes("SALES"));
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },
    suppliersTransport() {
      return this.$store.getters["SUPPLIER/suppliers"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },

  async created() {
    await this.$store.dispatch("BRANCH/getAllBranches");
  }
};
</script>

<style lang="scss" scoped>
.textfield__hide-outline {
  ::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
    border: 0;
  }
}
</style>
