<template>
  <v-card class="grey lighten-5" flat>
    <v-data-table
      :headers="headers"
      :items="saleReceipt.options"
      :loading="false"
      calculate-widths
      class="datatable px-3 py-2 grey lighten-5"
      disable-pagination
      hide-default-footer
      item-key="id"
      loading-text="Đang tải dữ liệu"
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ indexOfOptionIds(item) }}
      </template>

      <template v-slot:[`item.product`]="{ item }">
        <div class="my-1">
          <div class="font-weight-medium">
            {{ item.product && item.product.name }}
          </div>

          <div
            class="text-subtitle-2 font-weight-bold grey--text text--darken-2"
          >
            <span>{{ item.SKU }}</span>
            <span v-if="item.name"> - </span>
            <span class="font-weight-light tag-p__mb-0">
              {{ item.name }}
            </span>
            <span class="font-weight-bold" style="color: red">{{
              item.parent
                ? item.free
                  ? " (quà tặng)"
                  : ` (giảm giá ${item.tang_value}%)`
                : ""
            }}</span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.serials`]="{ item }">
        <div class="mb-1">
          <v-chip
            v-for="(serial, index) in item.serials"
            :key="index"
            class="font-weight-medium cyan lighten-4 mr-1 mt-1 px-2"
            small
          >
            {{ serial.serial_number }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.serials.length }}
      </template>

      <template v-slot:[`item.unit_sold_price`]="{ item }">
        {{ item.unit_sold_price | formatCurrency }}
      </template>

      <template v-slot:[`item.rebate_amount`]="{ item }">
        {{ (item.unit_sold_price - item.sold_price) | formatCurrency }}
      </template>

      <template v-slot:[`item.sold_price`]="{ item }">
        {{ item.sold_price | formatCurrency }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ (item.sold_price * item.serials.length) | formatCurrency }}
      </template>
    </v-data-table>

    <v-divider class="ma-3"></v-divider>

    <div class="d-flex justify-end px-6 pb-5">
      <div class="group-total">
        <div class="d-flex justify-space-between font-weight-medium">
          <span class="mr-5">Tổng tiền hàng: </span>
          <span>{{ saleReceipt.goods_value | formatCurrency }}</span>
        </div>
        <div
          v-if="false"
          class="d-flex justify-space-between font-weight-medium mt-1"
        >
          <span class="mr-5">Phí vận chuyển: </span>
          <span>{{
            saleReceipt.phivanchuyen
              ? saleReceipt.phivanchuyen
              : 0 | formatCurrency
          }}</span>
        </div>

        <div
          v-if="false"
          class="d-flex justify-space-between font-weight-medium mt-1"
        >
          <span class="mr-5">Phí thanh toán: </span>
          <span>
            {{
              saleReceipt.handle_fee
                ? saleReceipt.handle_fee
                : 0 | formatCurrency
            }}
          </span>
        </div>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">Giảm giá hóa đơn: </span>
          <span
            >-{{
              saleReceipt.promo ? saleReceipt.promo.money : 0 | formatCurrency
            }}</span
          >
        </div>

        <v-divider class="my-2"></v-divider>

        <div class="d-flex justify-space-between font-weight-bold mt-1">
          <span class="mr-5">Khách cần trả: </span>
          <span>{{ saleReceipt.need_to_pay | formatCurrency }}</span>
        </div>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5"
            >Đã trả <span class="text-body-2">(Thành công)</span>
          </span>
          <span>{{ Number(saleReceipt.total_paid) | formatCurrency }}</span>
        </div>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">Còn nợ: </span>
          <span>{{
            Number(saleReceipt.khachhang_con_no) | formatCurrency
          }}</span>
        </div>

        <v-divider class="my-2"></v-divider>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">
            Đã trả <span class="text-body-2">(Đang xử lý)</span>
          </span>

          <span>{{
            Number(saleReceipt.total_transaction_waiting) | formatCurrency
          }}</span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      productSelected: {
        promos: [],
        product: {
          name: ""
        }
      },
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product"
        },
        {
          text: "Serials",
          align: "start",
          sortable: false,
          value: "serials"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Đơn giá",
          align: "start",
          sortable: false,
          value: "unit_sold_price"
        },
        {
          text: "Chiết khấu",
          align: "start",
          sortable: false,
          value: "rebate_amount"
        },
        {
          text: "Giá sau giảm",
          align: "start",
          sortable: false,
          value: "sold_price"
        },
        {
          text: "Thành tiền",
          align: "start",
          sortable: false,
          value: "total"
        }
      ]
    };
  },

  computed: {
    optionIds() {
      return this.saleReceipt.options
        .filter(item => !item.parent)
        .map(item => item.id);
    },

    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },

    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },

    saleReceiptSuccessfullThuTotal() {
      return this.$store.getters["SALE_RECEIPT/saleReceiptSuccessfullThuTotal"];
    },

    saleReceiptThuTotal() {
      return this.$store.getters["SALE_RECEIPT/saleReceiptThuTotal"];
    }
  },

  methods: {
    indexOfOptionIds(value) {
      if (value.parent) {
        return "";
      }
      return this.optionIds.indexOf(value.id) + 1;
    },

    async openModalOrder(id) {
      await this.$store.dispatch("ORDER/getOrderById", id);

      if (this.orderStatusRequest.value === "success-getOrderById") {
        this.$modal.show({
          name: "modal-order"
        });
      }

      // Get order payments
      await this.$store.dispatch("ORDER/getOrderPaymentTransactions", id);

      // Get order participant
      await this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\Order",
          modelId: id
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.group-total {
  max-width: 100%;
  width: 310px;

  .text-total {
    font-size: 1.125rem;
  }
}
</style>
