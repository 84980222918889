<template>
  <div class="pa-4">
    <div class="filter-wrapper d-flex">
      <div class="flex-1 mr-4">
        <div class="font-weight-bold text-body-1">Kênh bán</div>

        <tp-select
          v-model="selectedOrderChannel"
          class="mb-4 white"
          hide-details="auto"
          :items="modifiedListOrderChannels"
          item-text="name"
          item-value="name"
          placeholder="Kênh đặt hàng"
        ></tp-select>
      </div>

      <div class="flex-1">
        <div class="font-weight-bold text-body-1">Chi nhánh</div>

        <tp-select
          v-model="selectedBranches"
          class="mb-4 white"
          hide-details="auto"
          :items="modifiedBranches"
          item-text="name"
          item-value="name"
          placeholder="Chi nhánh phụ trách"
        ></tp-select>
      </div>
    </div>

    <v-card class="table-wrapper" flat>
      <data-table-items
        :selected-branches="selectedBranches"
        :selected-order-channel="selectedOrderChannel"
      ></data-table-items
    ></v-card>
  </div>
</template>

<script>
import { LIST_ORDER_CHANNELS } from "@/modules/Sale/constants";
import DataTableItems from "./components/DataTableItems.vue";

export default {
  name: "TabOrderHistory",

  components: { DataTableItems },

  data() {
    return {
      LIST_ORDER_CHANNELS: LIST_ORDER_CHANNELS,
      selectedOrderChannel: "Tất cả",
      selectedBranches: "Tất cả"
    };
  },

  computed: {
    modifiedListOrderChannels() {
      let newList = [...this.LIST_ORDER_CHANNELS];
      newList.unshift({ id: 0, name: "Tất cả" });

      return newList.map(item => ({ id: item.id, name: item.name }));
    },

    modifiedBranches() {
      let newList = [...this.branches];
      newList.unshift({ id: 0, name: "Tất cả" });

      return newList.map(item => ({ id: item.id, name: item.name }));
    },

    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  }
};
</script>

<style scoped>
.filter-wrapper {
  width: 550px;
}

.table-wrapper {
  height: calc(100vh - 205px);
}
</style>
