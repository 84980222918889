<template>
  <div>
    <div v-if="saleTabActive.order_type === 'SALE_AT_STORE'">
      <sale-at-store-page></sale-at-store-page>
    </div>

    <div v-else>
      <order-page></order-page>
    </div>
  </div>
</template>

<script>
import OrderPage from "./pages/OrderPage";
import SaleAtStorePage from "./pages/SaleAtStorePage";

export default {
  components: {
    OrderPage,
    SaleAtStorePage
  },

  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  }
};
</script>

<style lang="scss" scoped></style>
