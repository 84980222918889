<template>
  <div class="product-list-wrapper">
    <v-data-table
      :headers="
        saleTabActive.status < 2 ||
        (saleTabActive.status < 4 && saleTabActiveNewProductOptions.length > 0)
          ? headers
          : headers.slice(0, 9)
      "
      :height="
        order && order.id && !saleTabActive.sale_at_store
          ? 'calc(100vh - 212px)'
          : 'calc(100vh - 132px)'
      "
      :items="saleTabActive.details"
      :loading="false"
      calculate-widths
      class="table-scroll tp-table__row-pointer datatable px-3 py-2 rounded-0 h-full"
      disable-pagination
      fixed-header
      hide-default-footer
      item-key="id"
      loading-text="Đang tải dữ liệu"
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
    >
      <!-- Start: Body -->
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0">
          <template v-for="(item, index) in items">
            <!-- Start: Ordered product -->
            <tr :key="item.id" style="vertical-align: top">
              <td>
                <div class="my-6">{{ index + 1 }}</div>
              </td>
              <!-- Start: Product info -->
              <td>
                <div class="my-4">
                  <div class="font-weight-bold">
                    {{ item.option.product_name }}
                    <v-hover
                      v-if="
                        item.cur_promotions && item.cur_promotions.length > 0
                      "
                      v-slot:default="{ hover }"
                    >
                      <v-icon
                        :class="{ 'red--text text--accent-2': hover }"
                        class="red--text text--accent-1 ml-1"
                        size="18px"
                        @click="
                          openModalSaleOrderPromotion(
                            item,
                            index,
                            item.cur_promotions
                          )
                        "
                      >
                        mdi-wallet-giftcard
                      </v-icon>
                    </v-hover>
                  </div>
                  <div
                    class="
                            text-subtitle-2
                            font-weight-bold
                            grey--text
                            text--darken-2
                          "
                  >
                    <span>{{ item.option.SKU }}</span>
                    <span v-if="item.option.name"> - </span>
                    <span
                      class="font-weight-light tag-p__mb-0"
                      v-html="item.option.name"
                    >
                    </span>
                  </div>
                </div>
              </td>
              <!-- End: Product info -->
              <!-- Start: Quantity -->
              <td style="width: 170px">
                <div class="my-4">
                  <div v-if="saleTabActive.status <= 3">
                    <v-text-field
                      v-model="item.option.qty"
                      :rules="[quantityRules(item.option.qty, item.option_id)]"
                      class="text-body-1 text-field__text-center custom-input-qty"
                      dense
                      flat
                      hide-details="auto"
                      outlined
                      required
                      single-line
                      type="number"
                      @input="
                        checkRulesQuantity(item.option.qty, item.option_id)
                      "
                    ></v-text-field>

                    <div v-if="!!saleTabActive.handles.length" class="mt-1">
                      <div>
                        Trong đơn xử lý:
                        {{
                          totalSerialsAvailableInHandleDetails(item.option_id)
                        }}
                      </div>
                      <div>
                        Còn chờ xử lý:
                        {{
                          item.qty >
                          totalSerialsAvailableInHandleDetails(item.option_id)
                            ? item.option.qty -
                              totalSerialsAvailableInHandleDetails(
                                item.option_id
                              )
                            : 0
                        }}
                      </div>
                    </div>
                  </div>
                  <template v-else>{{ item.option.qty }}</template>
                </div>
              </td>
              <!-- End: Quantity -->
              <!-- Start: Price -->
              <td>
                <div class="my-4">
                  <tp-input-price
                    v-if="saleTabActive.status <= 3"
                    :min-value="
                      item.option.rebate_amount ? item.option.rebate_amount : 0
                    "
                    :value="item.option.unit_price"
                    custom-class="text-body-1 mt-0 pt-0"
                    custom-style="width: 120px"
                    dense
                    flat
                    hide-details
                    outlined
                    solo
                    @change="
                      (item.option.unit_price = $event) &&
                        (item.option.unit_sold_price = $event)
                    "
                  ></tp-input-price>
                  <template v-else>
                    {{ item.option.unit_price | formatCurrency }}
                  </template>
                </div>
              </td>
              <!-- End: Price -->
              <!-- Start: Rebate -->
              <td>
                <div class="my-4">
                  <tp-input-price
                    v-if="saleTabActive.status <= 3"
                    :value="item.option.rebate_amount"
                    custom-class="text-body-1 mt-0 pt-0"
                    custom-style="width: 120px"
                    dense
                    flat
                    hide-details
                    outlined
                    solo
                    @change="item.option.rebate_amount = $event"
                  ></tp-input-price>
                  <template v-else>
                    {{ item.rebate_amount | formatCurrency }}
                  </template>
                </div>
              </td>
              <!-- End: Rebate -->
              <!-- Start: Sale price -->
              <td>
                <div class="my-4">
                  {{
                    (item.option.unit_price - item.option.rebate_amount)
                      | formatCurrency
                  }}
                </div>
              </td>
              <!-- End: Sale price -->
              <!-- Start: Total -->
              <td>
                <div class="my-4">
                  {{
                    ((item.option.unit_price - item.option.rebate_amount) *
                      item.option.qty)
                      | formatCurrency
                  }}
                </div>
              </td>
              <!-- End: Total -->
              <!-- Start: Action -->
              <td v-if="!totalSerialsAvailableInHandleDetails(item.option_id)">
                <div class="my-5">
                  <v-hover v-slot:default="{ hover }">
                    <v-btn
                      :color="hover ? 'grey darken-1' : 'grey lighten-1'"
                      icon
                      small
                      @click="removeProductOption(index, item.option_id)"
                    >
                      <v-icon size="18px">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-hover>
                </div>
              </td>
              <!-- End: Action -->
            </tr>
            <!-- End: Ordered product -->
            <!-- Start: Promotion products -->
            <tr
              v-for="(gift, indx) in item.gifts"
              :key="`gift-${indx}-${gift.option_id}`"
              class="green lighten-4"
              style="vertical-align: top "
            >
              <td></td>
              <!-- Start: Product info -->
              <td>
                <div class="my-2">
                  <div class="font-weight-bold">
                    {{ gift.option.name }}
                  </div>
                  <div
                    class="
                            text-subtitle-2
                            font-weight-bold
                            grey--text
                            text--darken-2
                          "
                  >
                    <span>{{ gift.option.SKU }}</span>
                  </div>
                </div>
              </td>
              <!-- End: Product info -->
              <!-- Start: Quantity -->
              <td>
                <div style="width: 68px">
                  <v-text-field
                    v-if="saleTabActive.status < 4 && handleAllowEditValue"
                    v-model="gift.qty"
                    class="text-body-1 text-field__text-center"
                    dense
                    flat
                    hide-details
                    outlined
                    placeholder=""
                    single-line
                    solo
                    type="number"
                  ></v-text-field>
                  <template v-else>{{ gift.qty }}</template>
                </div>
              </td>
              <!-- End: Quantity -->
              <!-- <td class="text-center">N/A</td> -->
              <!-- Start: Returned quantity -->
              <!-- <td class="text-center">
        {{ countReturnedQuantityOfGift(item.option_id, gift.option_id) }}
      </td> -->
              <!-- End: Returned quantity -->
              <!-- Start: Price -->
              <td>
                <tp-input-price
                  v-if="saleTabActive.status < 4 && handleAllowEditValue"
                  :value="gift.unit_price"
                  custom-class="text-body-1 mt-0 pt-0"
                  custom-style="width: 120px"
                  dense
                  flat
                  hide-details
                  outlined
                  solo
                  @change="
                    (gift.unit_price = $event) &&
                      (gift.unit_sold_price = $event)
                  "
                ></tp-input-price>
                <template v-else
                  >{{ gift.unit_price | formatCurrency }}
                </template>
                <!--              {{ gift.unit_price | formatCurrency }}-->
              </td>
              <!-- End: Price -->
              <!-- Start: Rebate -->
              <td>
                <tp-input-price
                  v-if="saleTabActive.status < 4 && handleAllowEditValue"
                  :value="gift.rebate_amount"
                  custom-class="text-body-1 mt-0 pt-0"
                  custom-style="width: 120px"
                  dense
                  flat
                  hide-details
                  outlined
                  solo
                  @change="gift.rebate_amount = $event"
                ></tp-input-price>
                <template v-else
                  >{{ gift.rebate_amount | formatCurrency }}
                </template>
              </td>
              <!-- End: Rebate -->
              <!-- Start: Sale price -->
              <td>
                {{ (gift.unit_price - gift.rebate_amount) | formatCurrency }}
              </td>
              <!-- End: Sale price -->
              <!-- Start: Total -->
              <td>
                {{
                  ((gift.unit_price - gift.rebate_amount) * gift.qty)
                    | formatCurrency
                }}
              </td>
              <!-- End: Total -->
              <!-- Start: Action -->
              <td v-if="saleTabActive.status < 2">
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    :color="hover ? 'grey darken-1' : 'grey lighten-1'"
                    icon
                    small
                    @click="removeProductOptionGift(index, idx)"
                  >
                    <v-icon size="18px">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-hover>
              </td>
              <!-- End: Action -->
            </tr>
            <!-- End: Promotion products -->
          </template>

          <template v-for="promo in saleTabActive.promos">
            <template>
              <tr
                v-for="item in promo.promo_details"
                :key="item.option.id"
                class="yellow lighten-5"
              >
                <td>
                  <v-icon class="mr-1" color="red accent-2" size="20px"
                    >mdi-ticket-percent-outline
                  </v-icon>
                </td>

                <td>
                  <div class="my-2">
                    <div class="font-weight-bold">
                      {{ item.option.name }}
                    </div>
                    <div
                      class="
                            text-subtitle-2
                            font-weight-bold
                            grey--text
                            text--darken-2
                          "
                    >
                      <span>{{ item.option.SKU }}</span>
                    </div>
                  </div>
                </td>

                <td class="text-center">
                  <div style="width: 68px">
                    {{ item.qty }}
                  </div>
                </td>

                <td class="text-center">0</td>

                <td class="text-center">0</td>

                <td>{{ item.unit_price | formatCurrency }}</td>

                <td>
                  {{
                    calculatePromotionAmount(
                      promo.promo.tk_option,
                      item.unit_price
                    ) | formatCurrency
                  }}
                </td>

                <td>
                  {{
                    (item.unit_price -
                      calculatePromotionAmount(
                        promo.promo.tk_option,
                        item.unit_price
                      ))
                      | formatCurrency
                  }}
                </td>

                <td>
                  {{
                    (item.unit_price -
                      calculatePromotionAmount(
                        promo.promo.tk_option,
                        item.unit_price
                      ))
                      | formatCurrency
                  }}
                </td>

                <td></td>
              </tr>
            </template>
          </template>
        </tbody>

        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td colspan="7">Không có dữ liệu</td>
          </tr>
        </tbody>
      </template>
      <!-- End: Body -->
    </v-data-table>
  </div>
</template>

<script>
import { HEADERS_SALE_ORDER_TABLE } from "@/modules/Sale/constants"; // import { PROMOTION_TK_TYPE } from "@/core/constant";
import { calculateTkDiscountAmount } from "@/core/composables/usePromotion";
import {
  countReturnedQuantityOfGift,
  countReturnedQuantityOfOption
} from "@/core/composables";

export default {
  data() {
    return {
      headers: HEADERS_SALE_ORDER_TABLE,
      quantityRules: (value, option_id) => {
        return (
          value >= this.totalSerialsAvailableInHandleDetails(option_id) ||
          "Không hợp lệ"
        );
      }
    };
  },

  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    saleTabActiveNewProductOptions() {
      return this.$store.getters["SALE/saleTabActiveNewProductOptions"];
    },

    handleAllowEditValue() {
      let result = false;
      if (
        this.saleTabActive &&
        this.saleTabActive.handles &&
        this.saleTabActive.handles.length > 0
      ) {
        if (this.saleTabActive.handles.length === 1) {
          // check only one handles and handles is cancel then can edit value
          this.saleTabActive.handles.map(handle => {
            if (handle.cancel_status && handle.cancel_status === 1)
              result = true;
          });
        } else {
          this.saleTabActive.handles.map(handle => {
            result = handle.cancel_status && handle.cancel_status === 0;
          });
        }
      }
      return result;
    },

    order() {
      return this.$store.getters["ORDER/order"];
    },

    totalSerialsAvailableInHandle() {
      let totalSerialsCount = 0;

      this.saleTabActive.handles.forEach(handle => {
        if (handle.cancel_status === 0) {
          handle.details.forEach(detail => {
            totalSerialsCount += detail.serials.length;
          });
        }
      });

      return totalSerialsCount;
    }
  },

  methods: {
    calculatePromotionAmount(promotionTkOption, price) {
      return calculateTkDiscountAmount(promotionTkOption, price);
    },

    totalSerialsAvailableInHandleDetails(id) {
      let count = 0;
      this.saleTabActive.handles.forEach(handle => {
        if (handle.cancel_status === 0) {
          handle.details.forEach(detail => {
            if (detail.option_id === id && detail.serials) {
              count += detail.serials.length;
            }
          });
        }
      });
      return count;
    },

    countReturnedQuantityOfGift(optionId, giftId) {
      return countReturnedQuantityOfGift(this.saleTabActive, optionId, giftId);
    },

    countReturnedQuantityOfOption(optionId) {
      return countReturnedQuantityOfOption(this.saleTabActive, optionId);
    },

    isAllowEditValue(optionId) {
      let result = true,
        isCheckExistOrderCancel = false,
        count;
      this.saleTabActive.handles.map(orderProcessing => {
        count = 0;
        if (orderProcessing.cancel_status === 1) isCheckExistOrderCancel = true;
        if (orderProcessing.cancel_status === 0)
          isCheckExistOrderCancel = false;
        // Find option
        const option = orderProcessing.details.find(
          option => option.option_id === optionId
        );

        if (option && option.serials) count += option.serials.length;
        if (
          !isCheckExistOrderCancel &&
          count < 1 &&
          orderProcessing.status < 5
        ) {
          result = true;
        } else if (
          isCheckExistOrderCancel &&
          count > 1 &&
          orderProcessing.status < 5
        ) {
          result = false;
        } else
          result =
            (isCheckExistOrderCancel && count === 0) ||
            (isCheckExistOrderCancel && count === 1);
      });
      return result;
    },

    checkRulesQuantity(quantity, optionId) {
      if (quantity < this.totalSerialsAvailableInHandleDetails(optionId)) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Số lượng không được nhỏ hơn số lượng đã trả khách"
          }
        });
      }
    },

    async openModalSaleOrderPromotion(item, index, promotions) {
      // Set option and option index into state
      await this.$store.dispatch("SALE/setSaleTabActiveProductOption", item);
      await this.$store.dispatch(
        "SALE/setSaleTabActiveProductOptionIndex",
        index
      );

      this.$modal.show({
        name: "modal-sale-promotion",
        payload: {
          promotions: promotions
        }
      });
    },

    async removeProductOption(index, optionId) {
      this.saleTabActive.details.splice(index, 1);

      // Update sale active tab & sale tab active new option ids
      await this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
      await this.$store.dispatch(
        "SALE/setSaleTabActiveNewProductOption",
        this.saleTabActiveNewProductOptions.filter(item => item !== optionId)
      );
    },

    async removeProductOptionGift(optionIndex, giftIndex) {
      this.saleTabActive.details[optionIndex].gifts.splice(giftIndex, 1);

      // Update sale active tab
      await this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
    }
  }
};
</script>

<style lang="scss" scoped>
.product-table {
  height: calc(100% - 93px - 8px);
}

.product-list-wrapper {
  position: relative;

  .button-print {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 99;
  }
}

.custom-input-qty {
  ::v-deep .v-input__control {
    .v-input__slot {
      width: 65px;
    }

    .v-text-field__details {
      padding: 0;
    }
  }
}
</style>
