import Axios from "axios";
import { getCurrentInstance } from "vue";
import { useMutation } from "@thinkvn/composables/lib/useMutation";
import {
  createVuexHelpers,
  showModalAlertError,
  showToastActionAlert
} from "@/core/composables";
import OrderService from "@/modules/Transaction/service/order.service";

export const useShippingInfoMutation = () => {
  const { useGetters, useActions } = createVuexHelpers();
  const { shippingInfo } = useGetters("ORDER_PROCESSING", ["shippingInfo"]);
  const { setOrderProcessing } = useActions("ORDER", ["setOrderProcessing"]);

  const context = getCurrentInstance()?.proxy;
  const { mutate: handleUpdateShippingInfo, isLoading } = useMutation(
    () => OrderService.updateShippingInfo(shippingInfo.value),
    {
      onError(e) {
        if (Axios.isAxiosError(e)) {
          showModalAlertError(context, { title: e.response?.data.message });
        }
      },
      onSuccess(response) {
        showToastActionAlert(context, { message: "Tạo vận đơn thành công" });
        setOrderProcessing(response.data.order_handle);
      }
    }
  );

  return {
    shippingInfo,
    handleUpdateShippingInfo,
    isLoading
  };
};
