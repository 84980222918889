<template>
  <tp-modal max-width="1050px" name="modal-sale-receipt-returning">
    <v-card>
      <v-card-title class="pb-4">
        <div class="font-weight-bold">Chọn hoá đơn trả hàng</div>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="card-content" flat>
          <!-- Start: Search box -->
          <div class="d-flex justify-center mb-6 pt-6">
            <div class="d-flex flex-col align-center">
              <tp-search-option
                v-model="searchKey"
                :default-option="searchType"
                :options="searchOptions"
                outlined
                @search="updateSearchKey($event)"
              ></tp-search-option>
            </div>
          </div>

          <!-- End: Search box -->
          <!-- Start: Datatable -->
          <v-data-table
            :footer-props="dataTableFooterProps"
            :headers="headers"
            :items="saleReceipts"
            :loading="
              saleReceiptStatusRequest.value === 'loading-getSaleReceipts'
            "
            calculate-widths
            class="tp-table__row-pointer datatable px-3 py-2"
            dense
            disable-pagination
            hide-default-footer
            item-key="id"
            loading-text="Đang tải dữ liệu"
            no-data-text="Không có dữ liệu"
            no-results-text="Không tìm thấy kết quả phù hợp"
            @update:page="updatePage($event)"
            @update:items-per-page="updateItemPerPage($event)"
          >
            <template v-slot:[`item.code`]="{ item }">
              {{ shortenSaleReceiptCode(item.code) }}
            </template>

            <template v-slot:[`item.value`]="{ item }">
              {{ item.value | formatCurrency }}
            </template>

            <template v-slot:[`item.khachhang_con_no`]="{ item }">
              {{ item.khachhang_con_no | formatCurrency }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                class="my-2"
                color="primary"
                dark
                depressed
                small
                @click="selectSaleReceipt(item)"
              >
                Chọn
              </v-btn>
            </template>
          </v-data-table>
          <!-- End: Datatable -->
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import DateTimeFunction from "@/core/utils/date-time";

// Group Array of Objects by Keys or Property Values
const groupBy = keys => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map(key => obj[key]).join("-");
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, []);

export default {
  data() {
    return {
      searchType: 0,
      dataTablePagination: {
        page: 1,
        itemsPerPage: 8,
        pageStart: 1,
        pageStop: 1,
        pageCount: 0,
        itemsLength: 0
      },
      headers: [
        {
          text: "Mã hóa đơn",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Thời gian tạo",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Khách hàng",
          align: "start",
          sortable: false,
          value: "khachhang_name"
        },
        {
          text: "SĐT",
          align: "start",
          sortable: false,
          value: "khachhang_phone"
        },
        {
          text: "Người bán",
          align: "start",
          sortable: false,
          value: "created_user_name"
        },
        {
          text: "Chi nhánh",
          align: "start",
          sortable: false,
          value: "branch_name"
        },
        {
          text: "Giá trị hóa đơn",
          align: "start",
          sortable: false,
          value: "value"
        },
        {
          text: "Khách còn nợ",
          align: "start",
          sortable: false,
          value: "khachhang_con_no"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        }
      ],
      searchOptions: [
        { type: "customer_name", label: "Theo tên khách hàng" },
        { type: "customer_phone", label: "Theo SĐT khách hàng" },
        { type: "serial_number", label: "Theo Serials" },
        { type: "MPN", label: "Theo MPN" },
        { type: "SKU", label: "Theo SKU" },
        { type: "code", label: "Theo mã hoá đơn" },
        { type: "order_code", label: "Theo mã đơn hàng" }
      ],
      searchKey: null
    };
  },
  computed: {
    dataTableFooterProps() {
      return {
        pagination: this.dataTablePagination,
        itemsPerPageAllText: "Tất cả",
        itemsPerPageOptions: [8, 16, 32],
        itemsPerPageText: "Hiển thị"
      };
    },
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
    saleReceipts() {
      return this.$store.getters["SALE_RECEIPT/saleReceipts"];
    },
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },
    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },
    paginationInfo() {
      return this.$store.getters["SALE_RECEIPT/paginationInfo"];
    },
    typeSaleTabs() {
      return this.$store.getters["SALE/typeSaleTabs"];
    }
  },
  async created() {
    await this.getSaleReceipts();
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-sale-receipt-returning" });
    },

    shortenSaleReceiptCode(code) {
      if (!code) return "";
      return code.split("-")[0];
    },

    async getSaleReceipts() {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceipts", {
        filter: {
          status: [1]
        },
        search: this.searchKey,
        search_key: this.searchOptions[this.searchType].type,
        per_page: this.dataTablePagination.itemsPerPage,
        cur_page: this.dataTablePagination.page
      });

      this.dataTablePagination.pageStart =
        (this.dataTablePagination.page - 1) *
        this.dataTablePagination.itemsPerPage;
      this.dataTablePagination.pageStop =
        this.dataTablePagination.pageStart +
          this.dataTablePagination.itemsPerPage <
        this.paginationInfo.itemTotal
          ? this.dataTablePagination.pageStart +
            this.dataTablePagination.itemsPerPage
          : this.paginationInfo.itemTotal;
      this.dataTablePagination.pageCount = this.paginationInfo.pageTotal;
      this.dataTablePagination.itemsLength = this.paginationInfo.itemTotal;
    },

    async selectSaleReceipt(item) {
      // Get sale receipt info
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceiptById", item.id);

      const discountRatio =
          this.saleReceipt.promotionReduce / this.saleReceipt.goods_value,
        serialsArr = this.saleReceipt.serials.filter(
          serial => serial.hoadonban_id === this.saleReceipt.id
        );

      // Group by option_id value
      let options = groupBy(["option_id"])(serialsArr).filter(
        item => item !== null
      );

      // Map results
      options = options.map(option => {
        return {
          id: option[0].option_id,
          SKU: option[0].option_sku,
          name: option[0].option_name,
          product: {
            name: option[0].product_name
          },
          return_price: option[0].sold_price * (1 - discountRatio),
          sold_price: option[0].sold_price,
          serials: [],
          allSerials: option.map(item => {
            return {
              id: item.id,
              serial_number: item.serial_number,
              sold_price: item.sold_price
            };
          })
        };
      });

      // Check option length
      if (options.length === 0) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span>Hóa đơn này đã trả hết sản phẩm</span>`,
            cancelBtnText: "OK"
          }
        });

        return false;
      }
      if (this.typeSaleTabs.includes(3)) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span>Đã tồn tại một đơn trả</span>`,
            message:
              "Bạn chắc chắn muốn mở đơn này. Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
            confirmBtn: {
              color: "red accent-2",
              text: "Xác nhận",
              onClickHandler: async () => {
                await this.$store.dispatch("SALE/changeSaleTabItem", {
                  index: this.typeSaleTabs.indexOf(3),
                  data: {
                    order_type: "CUSTOMER_RETURN",
                    hoadonban_code: this.saleReceipt.code,
                    hoadonban_id: this.saleReceipt.id,
                    code: `Trả hàng`,
                    created_at: DateTimeFunction.today(),
                    created_time: DateTimeFunction.nowTime(),
                    khachhang: this.saleReceipt.khachhang,
                    khachhang_id: this.saleReceipt.khachhang.id,
                    khachhang_name: this.saleReceipt.khachhang.name,
                    branch_id: this.saleReceipt.branch.id,
                    value: null,
                    phitrahang: 0,
                    note: null,
                    options: options,
                    serials: [],
                    sale_receipt: {
                      created_at: null,
                      khachhang_id: null,
                      branch_id: null,
                      value: null,
                      phivanchuyen: 0,
                      note: null,
                      promotion: null,
                      promo: null,
                      options: [],
                      serials: []
                    },
                    // Type: 1 - Sale receipt, 2 - Order , 3 - Customer return
                    type: 3
                  }
                });
                // Set active tab
                await this.$store.dispatch(
                  "SALE/setSaleTabActiveIndex",
                  this.typeSaleTabs.indexOf(3)
                );
                await this.$store.dispatch("CUSTOMER/getCustomers", {
                  cur_page: 1,
                  per_page: 50,
                  search: this.saleReceipt.khachhang.id
                    ? this.saleReceipt.khachhang.id
                    : ""
                });
                // Close modal
                this.closeModal();
              }
            }
          }
        });
      } else {
        const customerReturn = {
          order_type: "CUSTOMER_RETURN",
          hoadonban_code: this.saleReceipt.code,
          hoadonban_id: this.saleReceipt.id,
          code: `Trả hàng`,
          created_at: DateTimeFunction.today(),
          created_time: DateTimeFunction.nowTime(),
          khachhang: this.saleReceipt.khachhang,
          khachhang_id: this.saleReceipt.khachhang.id,
          khachhang_name: this.saleReceipt.khachhang.name,
          branch_id: this.saleReceipt.branch.id,
          value: null,
          phitrahang: 0,
          note: null,
          options: options,
          serials: [],
          sale_receipt: {
            created_at: null,
            khachhang_id: null,
            branch_id: null,
            value: null,
            phivanchuyen: 0,
            note: null,
            promotion: null,
            promo: null,
            options: [],
            serials: []
          },
          // Type: 1 - Sale receipt, 2 - Order , 3 - Customer return
          type: 3,
          participants: [
            {
              user_id: null,
              note: null
            }
          ]
        };
        await this.$store.dispatch("CUSTOMER/getCustomers", {
          cur_page: 1,
          per_page: 50,
          search: this.saleReceipt.khachhang.id
            ? this.saleReceipt.khachhang.id
            : ""
        });

        await this.$store.dispatch("SALE/addSaleTabItem", customerReturn);

        // Set active tab
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          this.saleTabs.length - 1
        );

        // Close modal
        this.closeModal();
      }
    },

    async updateItemPerPage(val) {
      this.dataTablePagination.itemsPerPage = val;
      await this.getSaleReceipts();
    },

    async updatePage(val) {
      this.dataTablePagination.page = val;
      await this.getSaleReceipts();
    },

    getIndexOfSearchOption(option) {
      const types = this.searchOptions.map(item => item.type);

      return types.indexOf(option);
    },

    async updateSearchKey(val) {
      this.searchKey = val.searchKey;

      if (val.option) {
        this.searchType = this.getIndexOfSearchOption(val.option);
      }
      await this.getSaleReceipts();
    }
  }
};
</script>

<style lang="scss">
.card-content {
  min-height: 400px;
}
</style>
