<template>
  <div class="sidebar-return">
    <div class="blue lighten-5 font-weight-bold mt-5 px-4 py-2">
      Mua hàng
    </div>
    <!-- Start: Calculate -->
    <div class="px-4 mt-4">
      <div class="d-flex flex-row align-center justify-space-between">
        <div class="">Tổng tiền hàng</div>
        <div class="font-weight-medium">
          {{ saleGoodsAmountTotal.value | formatCurrency }}
        </div>
      </div>

      <div class="d-flex flex-row align-center justify-space-between mt-3">
        <div class="">Giảm giá hóa đơn</div>
        <div class="font-weight-medium">
          -{{
            saleTabActive.sale_receipt.promo
              ? saleTabActive.sale_receipt.promo.money
              : 0 | formatCurrency
          }}
        </div>
      </div>

      <div class="d-flex flex-row align-center justify-space-between mt-4">
        <div class="">Tổng tiền mua</div>
        <div class="font-weight-medium">
          {{ saleAmountTotal | formatCurrency }}
        </div>
      </div>
    </div>
    <!-- End: Calculate -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    paymentTotal() {
      return this.$store.getters["SALE/paymentTotal"];
    },
    promotions() {
      return this.$store.getters["SALE/promotions"];
    },
    saleAmountTotal() {
      let total = this.saleGoodsAmountTotal.value;

      if (this.saleTabActive.sale_receipt.phivanchuyen) {
        total += this.saleTabActive.sale_receipt.phivanchuyen;
      }
      if (this.saleTabActive.sale_receipt.promo) {
        total -= this.saleTabActive.sale_receipt.promo.money;
      }

      if (this.saleTabActive.sale_receipt.payment_fee) {
        total -= this.saleTabActive.sale_receipt.payment_fee;
      }

      return total;
    },
    saleGoodsAmountTotal() {
      let obj = {
        value: 0,
        quantity: 0
      };

      this.saleTabActive.sale_receipt.options.forEach(item => {
        obj.quantity += item.serials.length;
        obj.value +=
          (item.unit_sold_price - item.rebate_amount) * item.serials.length;
      });

      return obj;
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },
  methods: {
    changePromotion(promotion) {
      if (promotion) {
        this.saleTabActive.sale_receipt.promo = {
          id: promotion.id,
          money:
            promotion.tk_type === 1
              ? (this.saleGoodsAmountTotal.value *
                  Number(promotion.tk_percent)) /
                100
              : Number(promotion.tk_money)
        };
      } else {
        this.saleTabActive.sale_receipt.promo = null;
      }

      // Update sale tab active
      this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
    },

    openModalSalePayment() {
      this.$modal.show({ name: "modal-sale-payment" });
    }
  }
};
</script>

<style lang="scss" scoped></style>
