import { render, staticRenderFns } from "./SidebarGeneral.vue?vue&type=template&id=687b5538&scoped=true&"
import script from "./SidebarGeneral.vue?vue&type=script&lang=js&"
export * from "./SidebarGeneral.vue?vue&type=script&lang=js&"
import style0 from "./SidebarGeneral.vue?vue&type=style&index=0&id=687b5538&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "687b5538",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAutocomplete,VBtn,VCol,VDivider,VIcon})
