<template>
  <tp-modal
    name="modal-order-processing"
    id="modalSaleOrderProcessing"
    max-width="1050px"
  >
    <v-card>
      <v-card-title class="pb-4">
        <div class="font-weight-bold">Xử lý đơn hàng</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="card-content" flat>
          <!-- Start: Search box -->
          <div class="d-flex justify-center mb-6 pt-6">
            <tp-search-option
              :default-option="searchType"
              outlined
              :options="searchOptions"
              :value="searchKey"
              @search="updateSearchKey($event)"
            ></tp-search-option>
          </div>
          <!-- End: Search box -->
          <!-- Start: Datatable -->
          <v-data-table
            class="tp-table__row-pointer datatable px-3 py-2"
            calculate-widths
            dense
            disable-pagination
            no-data-text="Không có dữ liệu"
            no-results-text="Không tìm thấy kết quả phù hợp"
            :headers="headers"
            hide-default-footer
            :loading="orderStatusRequest.value === 'loading-getOrders'"
            loading-text="Đang tải dữ liệu"
            :items="orders"
            :footer-props="dataTableFooterProps"
            item-key="id"
            @update:page="updatePage($event)"
            @update:items-per-page="updateItemPerPage($event)"
          >
            <!-- Start: Body -->
            <template v-slot:body="{ items }">
              <tbody v-if="items.length > 0">
                <!-- Start: Items -->
                <tr v-for="item in items" :key="item.id">
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex">
                          <div
                            v-if="item.order_type === 'SALE_AT_STORE'"
                            class="mr-2 font-weight-bold"
                            style="color:#FDD835 !important"
                          >
                            POS
                          </div>

                          <div
                            class="copied-label"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="
                              copyToClipboard(
                                item.code,
                                'modalSaleOrderProcessing'
                              )
                            "
                          >
                            {{ item.code }}
                          </div>
                        </div>
                      </template>

                      <span>Sao chép</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <template v-if="item.details.length > 0">
                      <ul
                        class="my-2"
                        v-for="(option, index) in item.details"
                        :key="index"
                      >
                        <li class="">
                          <span>
                            <span class="font-weight-medium"
                              >{{ option.qty.toString().padStart(2, "0") }}
                            </span>
                            <span>{{
                              `${option.option.product_name} - ${option.option.SKU}`
                            }}</span>
                          </span>
                        </li>
                      </ul>
                    </template>
                    <div v-else>Không có sản phẩm nào.</div>
                  </td>
                  <td>
                    <template v-if="item.customer">
                      <div>{{ item.customer.name }}</div>
                      <div class="text-subtitle-2 font-weight-light">
                        {{ item.customer.phone }}
                      </div>
                    </template>
                    <template v-else>N/A</template>
                  </td>
                  <td>{{ orderChannelList[item.order_channel - 1].name }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    {{ calculateOrderTotal(item.details) | formatCurrency }}
                  </td>
                  <td>
                    <div style="min-width: 150px">
                      <v-chip
                        class="font-weight-bold"
                        :color="getOrderStatus(item.status).color"
                        small
                        outlined
                      >
                        {{ getOrderStatus(item.status).text }}
                      </v-chip>
                      <v-tooltip v-if="item.cancel_status === 1" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2"
                            color="red accent-2"
                            icon
                            outlined
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="red accent-2" size="14px"
                              >mdi-close</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Đã hủy</span>
                      </v-tooltip>
                    </div>
                  </td>
                  <td>
                    <v-btn
                      class="my-2"
                      color="primary"
                      small
                      dark
                      depressed
                      @click="selectOrder(item)"
                    >
                      Chọn
                    </v-btn>
                  </td>
                </tr>
                <!-- End: Items -->
              </tbody>

              <tbody v-else>
                <tr class="v-data-table__empty-wrapper">
                  <td colspan="8">Không có dữ liệu</td>
                </tr>
              </tbody>
            </template>
            <!-- End: Body -->
          </v-data-table>
          <!-- End: Datatable -->
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import {
  LIST_ORDER_CHANNELS,
  LIST_STATUS_ORDER
} from "../../../Transaction/constant";
import { getOrderStatusByValue } from "../../../Transaction/utils/common";

export default {
  data() {
    return {
      dataTablePagination: {
        page: 1,
        itemsPerPage: 8,
        pageStart: 1,
        pageStop: 1,
        pageCount: 0,
        itemsLength: 0
      },
      headers: [
        {
          text: "Mã ĐH",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Sản phẩm",
          align: "start",
          sortable: false,
          value: "options"
        },
        {
          text: "Khách hàng",
          align: "start",
          sortable: false,
          value: "customer"
        },
        {
          text: "Kênh đặt hàng",
          align: "start",
          sortable: false,
          value: "order_channel"
        },
        {
          text: "Thời gian",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Giá trị đơn",
          align: "start",
          sortable: false,
          value: "total"
        },
        {
          text: "Trạng thái",
          align: "start",
          sortable: false,
          value: "status"
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "action"
        }
      ],
      orderChannelList: LIST_ORDER_CHANNELS,
      orderStatusList: LIST_STATUS_ORDER,
      searchType: 0,
      searchOptions: [
        { type: "customer_name", label: "Theo tên khách hàng" },
        { type: "customer_phone", label: "Theo SĐT khách hàng" },
        { type: "customer_email", label: "Theo email khách hàng" },
        { type: "code", label: "Theo mã order" },
        { type: "product_name", label: "Theo tên sản phẩm" },
        { type: "SKU", label: "Theo MPN" },
        { type: "MPN", label: "Theo SKU" }
      ],
      searchKey: null,
      optionSearchKey: null
    };
  },
  computed: {
    dataTableFooterProps() {
      return {
        pagination: this.dataTablePagination,
        itemsPerPageAllText: "Tất cả",
        itemsPerPageOptions: [8, 16, 32],
        itemsPerPageText: "Hiển thị"
      };
    },
    order() {
      return this.$store.getters["ORDER/order"];
    },
    orders() {
      return this.$store.getters["ORDER/orders"];
    },
    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },
    paginationInfo() {
      return this.$store.getters["ORDER/paginationInfo"];
    },
    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },
    typeSaleTabs() {
      return this.$store.getters["SALE/typeSaleTabs"];
    }
  },
  async created() {
    await this.getOrders();
  },
  methods: {
    calculateOrderTotal(options) {
      return options.reduce((total, item) => {
        const giftTotal = item.gifts.reduce(
          (giftTotal, gift) =>
            giftTotal + gift.qty * (gift.unit_price - gift.rebate_amount),
          0
        );

        return (
          total + item.qty * (item.unit_price - item.rebate_amount) + giftTotal
        );
      }, 0);
    },

    closeModal() {
      this.$modal.hide({ name: "modal-order-processing" });
    },

    async getOrders() {
      await this.$store.dispatch("ORDER/getOrders", {
        search: this.searchKey,
        search_key: this.optionSearchKey,
        per_page: this.dataTablePagination.itemsPerPage,
        cur_page: this.dataTablePagination.page
      });

      this.dataTablePagination.pageStart =
        (this.dataTablePagination.page - 1) *
        this.dataTablePagination.itemsPerPage;
      this.dataTablePagination.pageStop =
        this.dataTablePagination.pageStart +
          this.dataTablePagination.itemsPerPage <
        this.paginationInfo.itemTotal
          ? this.dataTablePagination.pageStart +
            this.dataTablePagination.itemsPerPage
          : this.paginationInfo.itemTotal;
      this.dataTablePagination.pageCount = this.paginationInfo.pageTotal;
      this.dataTablePagination.itemsLength = this.paginationInfo.itemTotal;
    },

    getOrderStatus(value) {
      return getOrderStatusByValue(value);
    },

    async handleGetHandles(item) {
      console.log("hhi", item);
      // Check order handles and set orderProcessing state
      if (item.handles.length > 0) {
        await this.$store.dispatch(
          "ORDER/getOrderProcessingById",
          item.handles[0].id
        );
        await this.$store.dispatch("SALE/setActiveOrderProcessingIndex", 0);
      } else {
        await this.$store.dispatch("ORDER/setOrderProcessing", []);
      }
    },

    async selectOrder(order) {
      const foundSaleTabIndex = this.saleTabs.findIndex(
        saleTab => saleTab.id === order.id
      );

      if (foundSaleTabIndex === -1) {
        // Set or get data from local storage
        let localSaleTabs = JSON.parse(localStorage.getItem("TPSALE_TABS"));

        await localStorage.setItem(
          "TPSALE_TAB_ACTIVE_INDEX",
          JSON.stringify(localSaleTabs.length)
        );

        await this.$store.dispatch("ORDER/getOrderById", order.id);

        await this.$store.dispatch("SALE/addSaleTabItem", {
          ...this.order,
          // Type: 1 - Sale receipt, 2 - Order , 3 - Customer return
          type: 2
        });

        // Check order handles and set orderProcessing state
        await this.handleGetHandles(this.order);

        // Set active tab
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          localSaleTabs.length
        );
      } else {
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          foundSaleTabIndex
        );
      }

      this.closeModal();
    },

    async updateItemPerPage(val) {
      this.dataTablePagination.itemsPerPage = val;
      await this.getOrders();
    },

    async updatePage(val) {
      this.dataTablePagination.page = val;
      await this.getOrders();
    },

    async updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.optionSearchKey = val.option;
      await this.getOrders();
    }
  }
};
</script>

<style lang="scss">
.card-content {
  min-height: 400px;
}
</style>
