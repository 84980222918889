<template>
  <div class="text-body-1 py-2">
    <div class="details-wrapper">
      <sidebar-general></sidebar-general>

      <sidebar-return></sidebar-return>

      <sidebar-sale
        v-if="!!saleTabActive.sale_receipt.options.length"
      ></sidebar-sale>

      <v-divider class="my-4"></v-divider>

      <sidebar-total ref="sidebarTotalRef"></sidebar-total>
    </div>

    <div class="px-4 pt-5">
      <v-btn
        class="btn-submit"
        block
        color="primary"
        depressed
        dark
        large
        :loading="
          customerReturnStatusRequest.value === 'loading-createCustomerReturn'
        "
        @click="
          customerReturnStatusRequest.value === 'loading-createCustomerReturn'
            ? null
            : createCustomerReturn()
        "
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        {{ saleGoodsAmountTotal.quantity > 0 ? "Đổi hàng" : "Trả hàng" }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import SidebarGeneral from "./components/SidebarGeneral";
import SidebarReturn from "./components/SidebarReturn";
import SidebarSale from "./components/SidebarSale";
import SidebarTotal from "./components/SidebarTotal";

export default {
  components: {
    SidebarGeneral,
    SidebarReturn,
    SidebarSale,
    SidebarTotal
  },

  computed: {
    customerReturnStatusRequest() {
      return this.$store.getters["CUSTOMER_RETURN/statusRequest"];
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },
    saleGoodsAmountTotal() {
      let obj = {
        value: 0,
        quantity: 0
      };

      this.saleTabActive.sale_receipt.options.forEach(item => {
        obj.quantity += item.serials.length;
        obj.value +=
          (item.unit_sold_price - item.rebate_amount) * item.serials.length;
      });

      return obj;
    }
  },

  methods: {
    createCustomerReturn() {
      this.$refs.sidebarTotalRef.createCustomerReturn();
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-submit {
  font-size: 1.0125rem;
}

.details-wrapper {
  height: calc(100vh - 145px);
  overflow: auto;
}
</style>
