<template>
  <div class="detail-order-page">
    <div class="order-wrapper h-full">
      <v-form ref="orderFormRef" class="h-full flex flex-col">
        <order-status></order-status>

        <div class="flex-1 mt-1">
          <product-list class="h-full table-product-list"></product-list>
        </div>
      </v-form>
    </div>
    <div class="sidebar-wrapper">
      <order-sidebar :order-form-ref="orderFormRef"></order-sidebar>
    </div>
  </div>
</template>

<script>
import ProductList from "./components/ProductList.vue";
import OrderStatus from "./components/OrderStatus.vue";
import OrderSidebar from "./components/OrderSidebar";

export default {
  components: {
    ProductList,
    OrderStatus,
    OrderSidebar
  },

  data() {
    return {
      orderFormRef: null
    };
  },

  computed: {
    order() {
      return this.$store.getters["ORDER/order"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  created() {
    this.$store.dispatch("BRANCH/getAllBranches");
  },

  mounted() {
    // Access the orderFormRef after the component has been mounted
    this.orderFormRef = this.$refs.orderFormRef;
  }
};
</script>

<style lang="scss" scoped>
.detail-order-page {
  display: flex;
  height: calc(100vh - 46px - 46px);

  .order-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    .bottom-action > * {
      height: 335px;
      // border: 1px solid black;
    }
  }

  .sidebar-wrapper {
    width: 344px;
    min-width: 360px;
    padding-left: 4px;
    overflow: auto;
  }
}

.bottom-action-item {
  max-height: 266px;
  overflow: auto;
}

.gap-2 {
  gap: 8px;
}

.flex-1 {
  flex: 1;
}
</style>
