<template>
  <div class="text-body-1 py-2">
    <sidebar-general />

    <v-divider class="ma-4"></v-divider>

    <sidebar-customer />

    <v-divider class="ma-4"></v-divider>

    <sidebar-main
      :key="saleTabActive.code + saleTabActive.order_type"
      @onChangePayment="payments = $event"
    />

    <div v-if="!saleTabActive.status" class="px-4 mt-5">
      <v-btn
        :dark="
          saleReceiptStatusRequest.value !== 'loading-createSaleReceipt' ||
            saleReceiptStatusRequest.value === 'loading-getSaleReceiptById'
        "
        :disabled="
          saleReceiptStatusRequest.value === 'loading-createSaleReceipt' ||
            saleReceiptStatusRequest.value === 'loading-getSaleReceiptById'
        "
        :loading="
          saleReceiptStatusRequest.value === 'loading-createSaleReceipt' ||
            saleReceiptStatusRequest.value === 'loading-getSaleReceiptById'
        "
        block
        class="btn-submit"
        color="success"
        depressed
        large
        @click="
          saleReceiptStatusRequest.value === 'loading-createSaleReceipt' ||
          saleReceiptStatusRequest.value === 'loading-getSaleReceiptById'
            ? null
            : createSaleReceipt()
        "
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        Xuất hóa đơn
      </v-btn>

      <v-btn
        v-if="!saleTabActive.id"
        block
        class="mt-4"
        color="primary"
        depressed
        large
        @click="createSaleReceipt(true)"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>

        Tạo hóa đơn nháp
      </v-btn>
    </div>

    <div
      v-else-if="saleTabActive.status !== SALE_RECEIPT_STATUS.CANCEL.value"
      class="px-4 mt-5"
    >
      <v-btn
        :to="{
          name: 'print_sale-receipt',
          params: {
            saleReceiptId: saleReceipt.id
          }
        }"
        block
        class="mr-2"
        color="primary"
        depressed
        large
        target="_blank"
      >
        <v-icon class="mr-1">mdi-printer</v-icon>

        In phiếu
      </v-btn>
    </div>
  </div>
</template>

<script>
import SidebarMain from "./components/SidebarMain";
import SidebarGeneral from "./components/SidebarGeneral";
import SidebarCustomer from "./components/SidebarCustomer";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import { ARRAY_LIST_MODEL_SITES, LIST_PAYMENT_KIND } from "@/core/constant";
import { SALE_RECEIPT_STATUS } from "@/modules/Sale/pages/SaleReceipt/constants";

export default {
  props: {
    modelSiteConfigs: {
      type: Array,
      default: () => []
    }
  },

  components: {
    SidebarCustomer,
    SidebarMain,
    SidebarGeneral
  },

  data() {
    return {
      paymentBranch: null,
      payments: [],
      receiptValidate: true,
      LIST_PAYMENT_KIND,
      SALE_RECEIPT_STATUS
    };
  },

  computed: {
    goodsAmountTotal() {
      let total = 0;

      this.saleTabActive.options.forEach(item => {
        total += item.serials.length * item.price;
      });

      return total;
    },
    payAmount() {
      let totalPrice = this.goodsAmountTotal.value;
      let reduceTotal = this.saleTabActive.promo
        ? this.saleTabActive.promo.money
        : 0;

      // Calculate payment fee
      totalPrice += this.paymentFee;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.saleTabActive.totalPrice = totalPrice;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.saleTabActive.reduceTotal = reduceTotal;

      return totalPrice;
    },
    paymentFee() {
      return this.$store.getters["SALE/paymentFee"];
    },

    saleModelSiteConfigs() {
      return this.$store.getters["SALE/saleModelSiteConfigs"];
    },

    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },
    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  methods: {
    async createSaleReceipt(draft = false) {
      //Validate Receipt
      await this.validateReceipt();
      if (!this.receiptValidate) return;

      const saleReceiptData = await this.handleFormatSaleReceiptData();

      saleReceiptData.transactions = this.formatTransactions(this.payments);

      console.log(saleReceiptData.transactions);

      await this.$store.dispatch("SALE_RECEIPT/createSaleReceipt", {
        ...saleReceiptData,
        status: draft
          ? SALE_RECEIPT_STATUS.DRAFT.value
          : SALE_RECEIPT_STATUS.SUCCESS.value
      });

      if (this.saleReceiptStatusRequest.value === "success-createSaleReceipt") {
        showToastActionAlert(this, { message: "Tạo hóa đơn thành công" });

        await this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptById",
          this.saleReceipt.id
        );

        await this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
          this.saleReceipt.id
        );

        await this.$store.dispatch("SALE/setSaleTabActive", {
          ...this.saleReceipt,
          created_at: this.saleReceipt.created_at
            ? this.saleReceipt.created_at.slice(0, 10)
            : "00:00",
          created_time: this.saleReceipt.created_at
            ? this.saleReceipt.created_at.slice(
                11,
                this.saleReceipt.created_at.length
              )
            : "00:00",
          khachhang_id: this.saleReceipt.khachhang.id
        });

        // Update sale tabs
        await this.$store.dispatch(
          "SALE/setSaleTabs",
          JSON.parse(JSON.stringify(this.saleTabs))
        );

        this.$router.push({
          name: "sale",
          query: {
            type: this.saleTabActive.type,
            id: this.saleTabActive.id
          }
        });
      } else if (
        this.saleReceiptStatusRequest.value === "error-createSaleReceipt"
      ) {
        showModalAlertError(this, {
          title: "Lỗi tạo mới hóa đơn",
          message: this.saleReceiptStatusRequest.message
        });
      }
    },

    formatTransactions(value) {
      if (!this.payments.length) return [];

      const formattedTransactions = value
        .filter(item => item.amount > 0)
        .map(item => {
          const receiverableType =
            item.kind === LIST_PAYMENT_KIND.TIEN_MAT.id
              ? "App\\Models\\Branch"
              : item.kind === LIST_PAYMENT_KIND.CHUYEN_KHOAN.id
              ? null
              : "App\\Models\\Supplier";

          const obj = {
            ...item,
            branch_id: this.saleTabActive.branch_id,
            transactionable_type: "App\\Models\\HoaDonBan",
            senderable_type: "App\\Models\\User",
            senderable_id: this.saleTabActive.khachhang_id,
            sender_bank_id: null,
            receiverable_type: receiverableType
          };

          if (receiverableType === "App\\Models\\Branch") {
            obj.receiverable_id = this.saleTabActive.branch_id;
          }

          return obj;
        });

      return formattedTransactions;
    },

    handleFormatSaleReceiptData() {
      return new Promise(resolve => {
        const obj = { ...this.saleTabActive };
        let serials = [];

        obj.options.forEach(option => {
          const childProducts = obj.options.filter(
            opt => opt.parent === option.id
          );
          const childSerials = [];

          childProducts.forEach(product => {
            childSerials.push(...product.serials.map(value => value.id));
          });

          const arr = option.serials.map(item => {
            return {
              id: item.id,
              unit_sold_price: option.unit_sold_price,
              sold_price: option.unit_sold_price - option.rebate_amount,
              option_id: option.id,
              promos: []
            };
          });

          serials = serials.concat(arr);
        });

        obj.serials = serials;
        obj.value = obj.totalPrice || 0;

        console.log("obj", obj);

        resolve(obj);
      });
    },

    validateReceipt() {
      //Model site config
      if (!this.saleTabActive.site) {
        showModalAlertError(
          this,
          {
            title: "Lỗi tạo mới hóa đơn",
            message: `<div class="body-1">Vui lòng chọn một trong số các thương hiệu sau:
             ${ARRAY_LIST_MODEL_SITES.map(i => " " + i.text)}
              </div>`
          },
          () => {
            this.receiptValidate = true;
          }
        );
        this.receiptValidate = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-submit {
  font-size: 1.0125rem;
}

.icon-card {
  cursor: pointer;
}
</style>
