<template>
  <v-data-table
    :headers="headers"
    :items="saleReceiptPaymentTransactions"
    :loading="
      saleReceiptStatusRequest.value === 'getSaleReceiptPaymentTransactions'
    "
    calculate-widths
    class="datatable text-body-1 grey lighten-5"
    disable-pagination
    hide-default-footer
    item-key="id"
    loading-text="Đang tải dữ liệu"
    no-data-text="Không có phiếu thu nào"
    no-results-text="Không tìm thấy kết quả phù hợp"
  >
    <!-- Start: Body -->
    <template v-slot:body="{ items }">
      <tbody v-if="items.length > 0">
        <!-- Start: Items -->
        <tr
          v-for="(item, index) in items"
          :key="item.id"
          :class="{ 'grey lighten-5': index % 2 === 0 }"
        >
          <td>{{ item.created_at }}</td>
          <td>
            <div class="primary--text tp-btn--text">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="copied-label"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="copyToClipboard(item.code, 'modal-order')"
                  >
                    {{ item.code }}
                  </div>
                </template>
                <span>Sao chép</span>
              </v-tooltip>
            </div>
          </td>
          <td>
            {{ getPaymentTypeText(item.type) }}
          </td>
          <td>
            <span v-if="item.receiverable">{{ item.receiverable.name }}</span>
            <span v-else>N/A</span>
          </td>
          <td>{{ getPaymentKindText(item.kind) }}</td>
          <td>
            <span v-if="item.receiver_bank">
              {{ item.receiver_bank.bank_account_label }} -
              {{ item.receiver_bank.account_bank }}
            </span>
            <span v-else>N/A</span>
          </td>
          <td>
            {{ item.amount | formatCurrency }}
          </td>

          <td>{{ item.payment_fee | formatCurrency }}</td>

          <td>
            <div class="d-flex">
              <v-chip
                :color="getReceiptStatus(item.status).color"
                class="mr-2 font-weight-bold"
                outlined
                small
              >
                <v-progress-circular
                  v-if="getReceiptStatus(item.status).isLoading"
                  :color="getReceiptStatus(item.status).color"
                  :size="15"
                  :width="2"
                  class="mr-2"
                  indeterminate
                ></v-progress-circular>

                {{ item.status_label }}
              </v-chip>

              <v-tooltip bottom content-class="font-weight-medium">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" size="18" v-bind="attrs" v-on="on"
                    >mdi-information-variant-circle
                  </v-icon>
                </template>

                <span>{{ getReceiptStatus(item.status).tooltip }}</span>
              </v-tooltip>
            </div>
          </td>
        </tr>
        <!-- End: Items -->
      </tbody>

      <tbody v-else>
        <tr class="v-data-table__empty-wrapper">
          <td :colspan="headers.length">Không có dữ liệu</td>
        </tr>
      </tbody>
    </template>
    <!-- End: Body -->
  </v-data-table>
</template>

<script>
import { getReceiptStatus, LIST_PAYMENT_STATUS } from "@/core/constant";
import { getPaymentKindText, getPaymentTypeText } from "@/core/composables";

export default {
  props: {
    saleReceiptPaymentTransactions: {
      type: Array,
      require: true
    }
  },

  data() {
    return {
      headers: [
        {
          text: "Thời gian",
          align: "start",
          value: "created_at",
          sortable: false
        },
        { text: "Phiếu ghi thu", value: "code", sortable: false },
        { text: "Loại phiếu", value: "type", sortable: false },
        { text: "Đối tượng nhận", value: "receiverable", sortable: false },
        { text: "Phương thức", value: "kind", sortable: false },
        { text: "Tài khoản", value: "receiver_bank", sortable: false },
        {
          text: "Số tiền (không tính phí TT)",
          value: "amount",
          sortable: false
        },
        { text: "Phí thanh toán", value: "payment_fee", sortable: false },
        {
          text: "Trạng thái",
          align: "center",
          value: "status",
          sortable: false
        }
      ],
      LIST_PAYMENT_STATUS
    };
  },

  computed: {
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    }
  },

  filters: {
    formatTime(d) {
      const dateTime = new Date(d),
        hour = String(dateTime.getHours()).padStart(2, "0"),
        min = String(dateTime.getMinutes()).padStart(2, "0"),
        date = String(dateTime.getDate()).padStart(2, "0"),
        month = String(dateTime.getMonth() + 1).padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${hour}:${min} ${date}/${month}/${year}`;
    }
  },

  methods: {
    getPaymentKindText(kind) {
      return getPaymentKindText(kind);
    },

    getPaymentTypeText(type) {
      return getPaymentTypeText(type);
    },

    getReceiptStatus(status) {
      return getReceiptStatus(status);
    }
  }
};
</script>

<style lang="scss" scoped>
.group-total {
  max-width: 100%;
  width: 310px;

  .text-total {
    font-size: 1.125rem;
  }
}
</style>
