<template>
  <div class="white mr-1 pb-5">
    <v-data-table
      class="table-scroll tp-table__row-pointer datatable px-3 py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="saleTabActive.options"
      item-key="id"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ indexOfOptionIds(item.id) + 1 }}
      </template>

      <template v-slot:[`item.product`]="{ item }">
        <div class="my-1">
          <div class="font-weight-bold">
            {{ item.product.name }}
          </div>
          <div
            class="text-subtitle-2 font-weight-light grey--text text--darken-2"
          >
            <span class="font-weight-bold">{{ item.SKU }} - </span>
            <span class="item-subtitle mr-1" v-html="item.name"></span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.serials`]="{ item }">
        <v-autocomplete
          v-model="item.serials"
          class="tp-filter-autocomplete text-body-1 my-2"
          dense
          deletable-chips
          :items="item.allSerials"
          item-text="serial_number"
          item-value="id"
          hide-details
          hide-selected
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn serials"
          single-line
          small-chips
          return-object
        >
        </v-autocomplete>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.serials.length }}
      </template>

      <template v-slot:[`item.return_price`]="{ item }">
        <tp-input-price
          v-model="item.return_price"
          custom-class="text-body-1 mt-0 pt-0"
          custom-style="width: 120px"
          dense
          flat
          hide-details
          outlined
          solo
        ></tp-input-price>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ (item.return_price * item.serials.length) | formatCurrency }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product"
        },
        {
          text: "Serials",
          align: "start",
          sortable: false,
          value: "serials"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Giá bán (Trừ CK)",
          align: "start",
          sortable: false,
          value: "return_price"
        },
        {
          text: "Giá trị trả",
          align: "start",
          sortable: false,
          value: "total"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    optionIds() {
      return this.saleTabActive.options.map(item => item.id);
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    indexOfOptionIds(value) {
      return this.optionIds.indexOf(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}
</style>
