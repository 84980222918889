<template>
  <div class="white rounded">
    <v-stepper class="text-body-1 elevation-0" color="green">
      <v-stepper-header class="elevation-0">
        <template v-for="(step, index) in orderProcessing.timeline">
          <v-stepper-step
            :key="`step-${step.status}`"
            :complete="orderProcessing.status >= step.status"
            :rules="[
              () =>
                !(
                  orderProcessing.cancel_status &&
                  orderProcessing.status === step.status
                )
            ]"
            :step="step.status"
            class="stepper-step_custom py-0"
            color="green lighten-1"
            error-icon="mdi-close"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="step-wrapper" v-bind="attrs" v-on="on">
                  <span
                    :class="{
                      'red--text':
                        orderProcessing.cancel_status &&
                        orderProcessing.status === step.status
                    }"
                    style="text-shadow:none"
                  >
                    <!-- <template
                      v-if="
                        orderProcessing.received_method === 2 &&
                          step.status === 5
                      "
                    >
                      Đang giữ hàng
                    </template> -->

                    {{ step.label }}
                  </span>

                  <small v-if="step.datetime" class="mt-1">{{
                    step.datetime
                  }}</small>
                </div>
              </template>

              <span>{{ step.description }}</span>
            </v-tooltip>
          </v-stepper-step>

          <v-divider
            v-if="index < orderProcessing.timeline.length - 1"
            :key="`dv-${step.status}`"
            class="mt-3"
          ></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
export default {
  computed: {
    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    }
  }
};
</script>

<style lang="scss" scoped>
.stepper-step_custom {
  ::v-deep &.v-stepper__step--error {
    .v-stepper__step__step {
      background-color: #ff5252;

      .v-icon {
        color: white;
        font-size: 18px;
      }
    }
  }
}

.step-wrapper {
  cursor: help;
}
</style>
