<template>
  <div class="order-status">
    <v-stepper
      :key="saleTabActive.id"
      :elevation="0"
      class="text-body-1 rounded-0"
    >
      <v-stepper-header>
        <template v-for="(step, index) in saleTabActive.timeline">
          <v-stepper-step
            :key="step.status"
            :complete="step.is_complete"
            :step="step.status"
            color="green lighten-1"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="step-wrapper" v-bind="attrs" v-on="on">
                  {{ step.label }}

                  <small class="mt-2">{{ step.datetime }}</small>
                </div>
              </template>

              <span>{{ step.description }}</span>
            </v-tooltip>
          </v-stepper-step>

          <v-divider
            v-if="index < saleTabActive.timeline.length - 1"
            :key="`t-${step.status}`"
          ></v-divider>
        </template>

        <template v-if="saleTabActive.cancel_status">
          <v-divider></v-divider>

          <v-stepper-step :rules="[() => false]" step="6">
            Bị hủy

            <small class="mt-2">14-04-2023 15:18</small>
          </v-stepper-step>
        </template>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
export default {
  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  }
};
</script>

<style scoped>
.order-status {
}

.step-wrapper {
  cursor: help;
}
</style>
