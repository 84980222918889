<template>
  <div class="d-flex flex-row">
    <!-- Start: Main content -->
    <div class="main-wrapper">
      <main-content class="main-content"></main-content>
    </div>
    <!-- End: Main content -->
    <!-- Start: Right sidebar -->
    <div class="white sidebar-wrapper">
      <right-sidebar></right-sidebar>
    </div>
    <!-- End: Right sidebar -->
    <modal-create-service-area />
  </div>
</template>

<script>
import RightSidebar from "./components/RightSidebar";
import MainContent from "./components/MainContent";
import ModalCreateServiceArea from "@/modules/Sale/components/modal/ModalCreateServiceArea";

export default {
  components: {
    RightSidebar,
    MainContent,
    ModalCreateServiceArea
  }
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  flex: 1;
  height: calc(100vh - 92px);
  max-height: calc(100vh - 92px);
  overflow: hidden;
  .main-content {
    height: 100%;
    overflow-y: scroll;
  }
}
.sidebar-wrapper {
  height: calc(100vh - 92px);
  max-height: calc(100vh - 92px);
  overflow-y: auto;
  width: 360px;
}
</style>
