var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-body-1"},[_c('div',{staticClass:"mb-5 d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"font-weight-bold text-h6"},[_vm._v(" Hoá đơn bán #"+_vm._s(_vm.saleReceipt.code)+" ")]),_c('v-chip',{staticClass:"text-body-1 font-weight-bold px-2 py-2 ml-2",attrs:{"color":_vm.saleReceipt.site === _vm.LIST_MODEL_SITES.GOODSPACE.id
            ? 'teal lighten-5 teal--text teal--lighten-1'
            : 'blue lighten-5 blue--text text--darken-4',"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.saleReceipt.site ? _vm.LIST_MODEL_SITES[_vm.saleReceipt.site].text : _vm.LIST_MODEL_SITES.THINKPRO.text)+" ")])],1),_c('div',[(
          _vm.isPermissionCancel &&
            (_vm.saleReceipt.status !== -1 || _vm.saleReceipt.status !== -1)
        )?_c('v-btn',{attrs:{"color":"red lighten-5 red--text rounded-lg text--accent-2 mr-3","depressed":""},on:{"click":_vm.cancelSaleReceipt}},[_vm._v(" Hủy ")]):_vm._e(),_c('v-btn',{staticClass:"cyan lighten-5 rounded-lg mr-2",attrs:{"color":"primary","depressed":"","text":""},on:{"click":_vm.goToSalePage}},[_vm._v(" Chỉnh sửa ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"to":{
          name: 'print_sale-receipt',
          params: {
            saleReceiptId: _vm.saleReceipt.id
          }
        },"color":"primary","icon":"","target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1)]),_c('card-general'),_c('card-customer',{staticClass:"mt-5"}),_c('card-products-detail',{staticClass:"mt-5"}),_c('v-card',{staticClass:"mt-5 grey lighten-5 px-3 py-2",attrs:{"flat":""}},[_c('tab-payment-transitions',{attrs:{"sale-receipt-payment-transactions":_vm.availablePaymentTransactions}})],1),(_vm.canceledPaymentTransactions.length)?_c('v-card',{staticClass:"mt-5 grey lighten-5 px-3 py-2",attrs:{"flat":""}},[_c('div',{staticClass:"mb-2 font-weight-medium"},[_vm._v("Xem phiếu bị huỷ")]),_c('tab-payment-transitions',{attrs:{"sale-receipt-payment-transactions":_vm.canceledPaymentTransactions}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }