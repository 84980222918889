<template>
  <v-card class="pa-6 service-data" elevation="0">
    <div class="service-data__header font-weight-black text-h5">
      Dịch vụ đi kèm
    </div>

    <v-data-table
      :headers="headers"
      :items="invoiceParticipants"
      :loading="
        invoiceParticipantStatusRequest.value ===
          'loading-getInvoiceParticipantsOfInvoice'
      "
      class="service-data__table rounded-0 pt-5"
      disable-pagination
      disable-sort
      hide-default-footer
      no-data-text="Không có dữ liệu"
    >
      <template #[`item.user`]="{ item }">
        {{ item.user ? item.user.name : "N/A" }}
      </template>

      <template #[`item.role`]="{ item }">
        {{ getInVoiceRoleName(item.role) }}
      </template>

      <template #[`item.docs`]="{ item }">
        <div v-if="item.docs" class="d-flex align-center">
          <div
            v-for="image in item.docs.slice(0, 2)"
            :key="image"
            class="img-wrapper mr-1"
          >
            <v-dialog max-width="80%">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  :aspect-ratio="1"
                  :src="image"
                  class="grey darken-3 rounded"
                  contain
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <div class="white pa-8" style="height: 70vh">
                <v-img
                  :src="image"
                  contain
                  height="100%"
                  max-height="70vh"
                ></v-img>
              </div>
            </v-dialog>
          </div>
          <div v-if="item.docs.length > 2" class="primary--text">+2</div>
        </div>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.status`]="{ item }" class="text-center">
        <v-chip
          :color="
            item.status === 1
              ? 'green'
              : item.status === 0
              ? 'orange'
              : 'red accent-2'
          "
          class="font-weight-bold"
          outlined
          small
        >
          {{
            item.status === 1
              ? "Đã duyệt"
              : item.status === 0
              ? "Chờ tài liệu"
              : "Bị từ chối"
          }}
        </v-chip>
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-btn
            class=""
            depressed
            fab
            text
            x-small
            @click="openModalFormParticipant(item)"
          >
            <v-icon color="primary" size="24px">mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn v-if="false" depressed fab text x-small>
            <v-icon color="red darken-3">mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-btn
      class="mt-6"
      color="primary"
      dark
      depressed
      @click="openModalFormParticipant"
    >
      Thêm ghi nhận phục vụ
    </v-btn>

    <modal-form-participant
      :model-id="saleTabActive.id"
      :model-name="'App\\Models\\Order'"
      @onSuccessfullyCreate="getOrderParticipant"
      @onSuccessfullyUpdate="getOrderParticipant"
    ></modal-form-participant>
  </v-card>
</template>

<script>
import ModalFormParticipant from "@/core/components/modals/ModalFormParticipant/index";

export default {
  components: {
    ModalFormParticipant
  },

  data() {
    return {
      headers: [
        {
          text: "Thời gian",
          align: "start",
          value: "created_at"
        },
        {
          text: "Người thực hiện",
          value: "user"
        },
        {
          text: "Nghiệp vụ",
          value: "role"
        },
        {
          text: "Giá trị",
          value: "value"
        },
        {
          text: "Ghi chú",
          value: "note"
        },
        {
          text: "Tài liệu",
          value: "docs"
        },
        {
          text: "Trạng thái",
          value: "status"
        },
        {
          text: "",
          align: "right",
          value: "actions"
        }
      ]
    };
  },

  computed: {
    invoiceParticipants() {
      return this.$store.getters["INVOICE_PARTICIPANT/invoiceParticipants"];
    },

    invoiceParticipantStatusRequest() {
      return this.$store.getters["INVOICE_PARTICIPANT/statusRequest"];
    },

    invoiceRoles() {
      return this.$store.getters["ROLE/rolesName"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  methods: {
    getInVoiceRoleName(code) {
      const res = this.invoiceRoles.find(item => item.value === code);

      return res.text || "N/A";
    },

    async getOrderParticipant() {
      await this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\Order",
          modelId: this.saleTabActive.id
        }
      );
    },

    openModalFormParticipant(item) {
      if (item.id) {
        this.$store.dispatch("INVOICE_PARTICIPANT/setInvoiceParticipant", {
          ...item
        });
      } else {
        this.$store.dispatch("INVOICE_PARTICIPANT/resetInvoiceParticipant");
      }

      this.$modal.show({ name: "modal-form-participant" });
    }
  }
};
</script>

<style lang="scss">
.service-data__table {
  tbody tr:nth-child(odd) {
    background: #fafafa;
  }
}

.img-wrapper {
  cursor: pointer;
  height: 32px;
  width: 32px;
}
</style>
