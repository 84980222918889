<template>
  <tp-modal name="modal-sale-payment" max-width="768px">
    <v-card slot-scope="props">
      <v-card-title class="pb-4">
        <div class="font-weight-bold">Khách thanh toán</div>
        <v-spacer></v-spacer>
        <v-btn
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="onCompleteHandle(props.payload && props.payload.onComplete)"
        >
          Xong
        </v-btn>
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="px-5" flat>
          <!-- Start: Payment methods -->
          <div class="py-5">
            <div class="font-weight-bold mb-3">Phương thức</div>
            <v-row>
              <!-- Start: Payment method item -->
              <v-col
                class="py-0"
                v-for="item in paymentMethodList"
                :key="item.id"
              >
                <v-chip
                  class="payment-methods--item font-weight-medium justify-center rounded-lg"
                  color="primary"
                  :dark="paymentListTypes.includes(item.id)"
                  :outlined="!paymentListTypes.includes(item.id)"
                  @click="
                    selectPaymentMethod(
                      item,
                      props.payload && props.payload.single
                        ? props.payload.single
                        : undefined
                    )
                  "
                >
                  {{ item.name }}
                </v-chip>
              </v-col>
              <!-- End: Payment methods item -->
            </v-row>
          </div>
          <!-- End: Payment methods -->
          <!-- Start: Payment amount -->
          <div class="">
            <div
              class="d-flex flex-row align-center justify-space-between font-weight-bold"
            >
              <div>Tổng cộng</div>
              <div class="text-h6">
                {{ paymentTotal | formatCurrency }}
              </div>
            </div>

            <v-divider class="mt-2"></v-divider>

            <div class="pb-7">
              <form-payment-amount
                v-for="(item, index) in paymentList"
                :disabled-payment-fee="
                  props.payload && props.payload.disabledPaymentFee
                    ? props.payload.disabledPaymentFee
                    : false
                "
                :key="index"
                :payment-method="item"
                :payment-method-index="index"
                :payment-method-list="paymentMethodList"
              ></form-payment-amount>
            </div>
          </div>
          <!-- End: Payment amount -->
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import FormPaymentAmount from "./components/FormPaymentAmount";

export default {
  components: {
    FormPaymentAmount
  },
  data() {
    return {
      paymentMethodList: [
        { id: 1, name: "Tiền mặt" },
        { id: 2, name: "Quẹt thẻ" },
        { id: 3, name: "Chuyển khoản" },
        { id: 4, name: "COD" },
        { id: 5, name: "Trả góp" }
      ]
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    paymentList() {
      return this.$store.getters["SALE/paymentList"];
    },
    paymentListTypes() {
      return this.$store.getters["SALE/paymentListTypes"];
    },
    paymentTotal() {
      return this.$store.getters["SALE/paymentTotal"];
    }
  },
  async created() {
    await this.$store.dispatch("PAYMENT_ACCOUNT/getPaymentAccounts");
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-sale-payment" });
      // Reset payment list in state
      this.$store.dispatch("SALE/setPaymentList", []);
    },

    async onCompleteHandle(onComplete) {
      // if (onComplete && typeof onComplete === "function") {
      //   onComplete(this.paymentTotal);
      // }
      // // Close modal
      // this.$modal.hide({ name: "modal-sale-payment" });

      let isNotExistPaymentAmount = false;
      this.paymentList.map(item => {
        if (item.payment_amount === 0) isNotExistPaymentAmount = true;
      });
      if (!isNotExistPaymentAmount) {
        await this.$store.dispatch(
          "SALE/setPaymentList",
          this.paymentList.filter(item => item.payment_amount > 0)
        );
        if (onComplete && typeof onComplete === "function") {
          onComplete(this.paymentTotal);
        }
        // Close modal
        this.$modal.hide({ name: "modal-sale-payment" });
      } else {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Vui lòng nhập đủ số tiền trong các phương thức thanh toán"
          }
        });
      }
    },

    selectPaymentMethod(item, single) {
      if (
        this.paymentListTypes.includes(item.id) ||
        (single && this.paymentList.length === 1)
      )
        return false;

      this.paymentList.push({
        payment_type: item.id,
        payment_amount: 0,
        payment_fee: 0,
        payment_account_id: null,
        shipping_partner: null,
        installment_method: null
      });

      // Update payment list in state
      this.$store.dispatch("SALE/setPaymentList", this.paymentList);
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-methods {
  &--item {
    cursor: pointer;
    width: 100%;
    transition: all 0.4s ease;
    &:hover {
      background-color: #f7f7f7;
    }
  }
}
.icon-remove {
  cursor: pointer;
  transition: all 0.25s ease;
}
</style>
