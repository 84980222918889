<template>
  <div>
    <tp-autocomplete
      v-model="saleTabActive.assigned_to_id"
      class="mb-4"
      hide-details="auto"
      :items="saleEmployees"
      item-text="name"
      item-value="id"
      label="Nhân viên bán"
      placeholder="Chọn nhân viên bán"
      :readonly="
        saleTabActive.status === 4 || saleTabActive.cancel_status === 1
      "
      validate="required"
    ></tp-autocomplete>

    <tp-autocomplete
      v-model="saleTabActive.closing_user_id"
      class="mb-4"
      hide-details="auto"
      :items="saleEmployees"
      item-text="name"
      item-value="id"
      label="Nhân viên chốt đơn"
      placeholder="Chọn nhân viên chốt đơn"
      :readonly="
        saleTabActive.status === 4 || saleTabActive.cancel_status === 1
      "
      validate="required"
    ></tp-autocomplete>

    <tp-select
      v-model="saleTabActive.branch_id"
      class="mb-4"
      hide-details="auto"
      :items="branches"
      item-text="name"
      item-value="id"
      label="Chi nhánh phụ trách"
      placeholder="Chi nhánh phụ trách"
      :readonly="
        saleTabActive.status === 4 || saleTabActive.cancel_status === 1
      "
      validate="required"
    ></tp-select>

    <tp-select
      v-model="saleTabActive.site"
      class="mb-4"
      hide-details="auto"
      item-text="text"
      item-value="id"
      :items="ARRAY_LIST_MODEL_SITES"
      label="Thương hiệu bán"
      placeholder="Thương hiệu bán"
      :readonly="
        saleTabActive.status > 1 ||
          saleTabActive.cancel_status === 1 ||
          saleTabActive.order_channel === 1
      "
    ></tp-select>

    <tp-select
      v-model="saleTabActive.order_channel"
      class="mb-4"
      hide-details="auto"
      :items="orderChannelList"
      item-text="name"
      item-value="id"
      label="Kênh đặt hàng"
      placeholder="Kênh đặt hàng"
      :readonly="
        saleTabActive.status > 1 ||
          saleTabActive.cancel_status === 1 ||
          saleTabActive.order_channel === 1
      "
      validate="required"
    ></tp-select>

    <tp-select
      v-model="saleTabActive.source"
      class="mb-4"
      hide-details="auto"
      :items="LIST_ORDER_SOURCES"
      item-text="label"
      item-value="value"
      label="Nguồn đặt hàng"
      placeholder="Chọn nguồn đặt hàng"
      :readonly="saleTabActive.status > 1 || saleTabActive.cancel_status === 1"
      validate="required"
    ></tp-select>
  </div>
</template>

<script>
import { LIST_ORDER_CHANNELS } from "@/modules/Sale/constants";
import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";
import { LIST_ORDER_SOURCES } from "@/modules/Transaction/constant";

export default {
  data() {
    return {
      orderChannelList: LIST_ORDER_CHANNELS,
      LIST_ORDER_SOURCES: LIST_ORDER_SOURCES,
      ARRAY_LIST_MODEL_SITES: ARRAY_LIST_MODEL_SITES
    };
  },

  computed: {
    computedModelSiteConfigs: {
      get() {
        return this.saleModelSiteConfigs;
      },
      set(val) {
        this.$store.dispatch("SALE/setSaleModelSiteConfigs", val);
      }
    },

    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    saleEmployees() {
      return this.employees.filter(i => i.staff_roles.includes("SALES"));
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    saleModelSiteConfigs() {
      return this.$store.getters["SALE/saleModelSiteConfigs"];
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },

  async created() {
    await this.$store.dispatch("BRANCH/getAllBranches");
    await this.$store.dispatch("EMPLOYEE/getAllEmployees");
  },

  methods: {
    isSaleEmployee(employeeId) {
      return this.saleEmployees.some(i => i.id === employeeId);
    }
  }
};
</script>
