<template>
  <div class="order-processing--wrapper mr-1">
    <!-- Start: Main content -->
    <div class="scrollable">
      <div
        v-if="orderStatusRequest.value === 'loading-getOrderProcessingById'"
        class="text-center mt-8"
      >
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <div class="grey--text text--darken-2 mt-4">Đang tải dữ liệu</div>
      </div>

      <template v-else>
        <template v-if="orderProcessing && orderProcessing.code">
          <product-list></product-list>
        </template>

        <template v-else>
          <v-card flat>
            <v-card-title
              class="flex-column justify-center font-weight-light grey--text"
            >
              <div>Chưa có đơn xử lý nào.</div>
              <div>(Chú ý: Đơn hàng cần được xác nhận trước khi xử lý)</div>
            </v-card-title>
          </v-card>
        </template>
      </template>
    </div>
    <!-- End: Main content -->
  </div>
</template>

<script>
import ProductList from "./components/ProductList";

export default {
  components: {
    ProductList
  },

  computed: {
    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    }
  },

  methods: {
    updateDetailOrderHeight(val) {
      this.$emit("updateDetailOrderHeight", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.order-processing--wrapper {
  flex: 1;
  overflow-y: hidden;
  padding: 12px;
  .scrollable {
    height: calc(100% - 40px);
    overflow-y: auto;
  }
}
</style>
