<template>
  <div class="page-sale-receipt">
    <div class="order-header" style="height: 46px">
      <div
        class="d-flex flex-row align-center justify-space-between order-header-sub"
      >
        <div class="d-flex align-center px-4">
          <v-tabs
            v-model="activeTab"
            class="font-weight-medium"
            active-class="black--text font-weight-bold"
            background-color="transparent"
            color="black"
            height="46px"
            @change="onChangeTab"
          >
            <v-tab class="text-body-1 text-none px-3 black--text">
              Thông tin
            </v-tab>

            <v-tab
              class="text-body-1 text-none px-3 black--text"
              :disabled="!saleTabActive.id"
            >
              Lịch sử thanh toán {{ saleReceiptPaymentTransactionsLength }}
            </v-tab>

            <v-tab
              class="text-body-1 text-none px-3 black--text"
              :disabled="!saleTabActive.id"
            >
              Phục vụ {{ saleReceiptInvoiceParticipants.length }}
            </v-tab>
          </v-tabs>
        </div>

        <div class="d-flex align-center">
          <v-btn
            class="text-body-1 text-none font-weight-medium mr-4"
            color="blue darken-4"
            small
            text
            :to="{ name: 'transaction_sale-receipts' }"
          >
            <v-icon left>mdi-arrow-top-right</v-icon>
            Tất cả hoá đơn bán
          </v-btn>
        </div>
      </div>
    </div>

    <component
      :is="activeComponent"
      :model-site-configs="computedModelSiteConfigs"
    ></component>
  </div>
</template>

<script>
import TabDetail from "./components/TabDetail";
import TabParticipants from "./components/TabParticipants";
import TabTransactions from "./components/TabTransaction";
import FormRadioSiteModelAvailable from "@/core/components/form/FormRadioSiteModelAvailable";
import { LIST_MODEL_TYPES } from "@/core/constant";

export default {
  components: {
    FormRadioSiteModelAvailable,
    TabDetail,
    TabParticipants,
    TabTransactions
  },

  data() {
    return {
      activeTab: 0,
      LIST_MODEL_TYPES
    };
  },

  computed: {
    activeComponent() {
      if (this.activeTab === 1) {
        return "TabTransactions";
      }

      if (this.activeTab === 2) {
        return "TabParticipants";
      }

      return "TabDetail";
    },

    computedModelSiteConfigs: {
      get() {
        return this.saleModelSiteConfigs;
      },
      set(val) {
        this.$store.dispatch("SALE/setSaleModelSiteConfigs", val);
      }
    },

    saleModelSiteConfigs() {
      return this.$store.getters["SALE/saleModelSiteConfigs"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    saleReceiptInvoiceParticipants() {
      return this.$store.getters["INVOICE_PARTICIPANT/invoiceParticipants"];
    },

    saleReceiptPaymentTransactionsLength() {
      return this.$store.getters[
        "SALE_RECEIPT/saleReceiptPaymentTransactionsLength"
      ];
    }
  },

  watch: {
    "saleTabActive.id": {
      async handler(val) {
        if (val) {
          const response = await this.$store.dispatch(
            "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
            {
              modelId: val,
              modelType: LIST_MODEL_TYPES.HOA_DON_BAN
            }
          );
          await this.$store.dispatch("SALE/setSaleModelSiteConfigs", response);
        } else {
          await this.$store.dispatch("SALE/setSaleModelSiteConfigs", []);
        }
      },
      immediate: true
    }
  },

  methods: {
    onChangeTab() {
      if (this.saleTabActive.id && this.activeTab === 1) {
        this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
          this.saleTabActive.id
        );
      }
    }
  }
};
</script>
