<template>
  <div>
    <v-btn
      class="pa-0 unset-case font-weight-medium text-body-1"
      :disabled="isDisabled"
      small
      text
      @click="visible = true"
      >{{ buttonName }}
      <v-icon right>mdi-chevron-right</v-icon>
    </v-btn>

    <v-dialog v-model="visible" max-width="500">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold text-h6">
            Chọn phương thức giao hàng
          </div>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            depressed
            :disabled="isValid"
            color="primary"
            small
            @click="onSaved"
            >Chọn</v-btn
          >

          <v-btn icon @click="visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4 received-method-wrapper">
          <panel-received-method
            v-model="internalValue"
          ></panel-received-method>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PanelReceivedMethod from "../PanelReceivedMethod.vue";
export default {
  components: { PanelReceivedMethod },

  props: {
    buttonName: {
      type: String,
      default: "Phương thức giao hàng"
    },
    value: {
      type: Object,
      default: () => null
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      visible: false,
      selectedDelivery: null,
      internalValue: {
        received_method: 1,
        branch_nhan_id: null,
        received_address: null
      }
    };
  },

  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    isValid() {
      return this.internalValue.received_method === 1
        ? !this.internalValue.received_address
        : !this.internalValue.branch_nhan_id;
    }
  },

  methods: {
    onSaved() {
      this.$emit("input", {
        ...this.saleTabActive,
        received_method: this.internalValue.received_method,
        branch_nhan_id: this.internalValue.branch_nhan_id,
        received_address: this.internalValue.received_address
      });

      this.visible = false;
    }
  },

  watch: {
    visible: {
      handler: function() {
        this.internalValue = {
          received_method: this.value.received_method,
          branch_nhan_id: this.value.branch_nhan_id,
          received_address: this.value.received_address
        };
      }
    }
  }
};
</script>

<style scoped>
.received-method-wrapper {
  height: 245px;
}
</style>
