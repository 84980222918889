var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-6 service-data",attrs:{"elevation":"0"}},[_c('div',{staticClass:"service-data__header font-weight-black text-h5"},[_vm._v(" Dịch vụ đi kèm ")]),_c('v-data-table',{staticClass:"service-data__table rounded-0 pt-5",attrs:{"headers":_vm.headers,"items":_vm.orderHandleParticipants,"loading":_vm.invoiceParticipantStatusRequest.value ===
        'loading-getInvoiceParticipantsOfInvoice',"disable-pagination":"","disable-sort":"","hide-default-footer":"","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.name : "N/A")+" ")]}},{key:"item.role",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInVoiceRoleName(item.role))+" ")]}},{key:"item.docs",fn:function(ref){
        var item = ref.item;
return [(item.docs)?_c('div',{staticClass:"d-flex align-center"},[_vm._l((item.docs.slice(0, 2)),function(image){return _c('div',{key:image,staticClass:"img-wrapper mr-1"},[_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"grey darken-3 rounded",attrs:{"aspect-ratio":1,"src":image,"contain":""}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"white pa-8",staticStyle:{"height":"70vh"}},[_c('v-img',{attrs:{"src":image,"contain":"","height":"100%","max-height":"70vh"}})],1)])],1)}),(item.docs.length > 2)?_c('div',{staticClass:"primary--text"},[_vm._v("+2")]):_vm._e()],2):_c('span',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.status === 1
            ? 'green'
            : item.status === 0
            ? 'orange'
            : 'red accent-2',"outlined":"","small":""}},[_vm._v(" "+_vm._s(item.status === 1 ? "Đã duyệt" : item.status === 0 ? "Chờ tài liệu" : "Bị từ chối")+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"depressed":"","fab":"","text":"","x-small":""},on:{"click":function($event){return _vm.openModalFormParticipant(item)}}},[_c('v-icon',{attrs:{"color":"primary","size":"24px"}},[_vm._v("mdi-square-edit-outline")])],1),(false)?_c('v-btn',{attrs:{"depressed":"","fab":"","text":"","x-small":""}},[_c('v-icon',{attrs:{"color":"red darken-3"}},[_vm._v("mdi-delete-outline")])],1):_vm._e()],1)]}}],null,true)}),_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":_vm.openModalFormParticipant}},[_vm._v("Thêm ghi nhận phục vụ ")]),_c('modal-form-participant',{attrs:{"model-id":_vm.orderProcessing.id,"model-name":'App\\Models\\OrderHandle'},on:{"onSuccessfullyCreate":function($event){return _vm.$emit('getOrderHandleParticipant')},"onSuccessfullyUpdate":function($event){return _vm.$emit('getOrderHandleParticipant')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }