<template>
  <div>
    <sale-at-store-header
      @select-tab="onChangeActiveTab"
    ></sale-at-store-header>

    <component :is="selectedTab"></component>
  </div>
</template>

<script>
import SaleAtStoreHeader from "./components/SaleAtStoreHeader.vue";
import TabDetail from "./TabDetail";
import TabParticipant from "./TabParticipant";
import TabTransaction from "./TabTransaction";

export default {
  components: {
    SaleAtStoreHeader,
    TabDetail,
    TabParticipant,
    TabTransaction
  },

  data() {
    return {
      selectedTab: "TabDetail"
    };
  },

  created() {
    this.$store.dispatch("BRANCH/getAllBranches");
  },

  methods: {
    onChangeActiveTab(tab) {
      this.selectedTab = tab;
    }
  }
};
</script>

<style lang="scss" scoped></style>
