<template>
  <div class="d-flex flex-col">
    <template>
      <v-btn
        v-if="orderProcessing.id === undefined || orderProcessing.id === null"
        :loading="orderProcessing.value === 'loading-createOrderProcessing'"
        block
        class="btn-submit"
        color="primary"
        dark
        depressed
        large
        @click="createOrderProcessing"
      >
        <v-icon left>mdi-plus</v-icon>

        Tạo đơn xử lý
      </v-btn>

      <v-btn
        v-if="orderProcessing.id && orderProcessing.status !== 6"
        :loading="orderStatusRequest.value === 'loading-updateOrderProcessing'"
        block
        class="btn-submit"
        color="primary"
        dark
        depressed
        large
        outlined
        @click="updateOrderProcessing(orderProcessing.status)"
      >
        Lưu
      </v-btn>

      <v-btn
        v-if="
          orderProcessing.status === 5 && orderProcessing.received_method === 1
        "
        :loading="orderStatusRequest.value === 'loading-updateOrderProcessing'"
        block
        class="btn-submit mt-3"
        color="green lighten-1"
        dark
        depressed
        large
        @click="confirmUpdateOrder"
      >
        Khách đã nhận hàng
      </v-btn>
    </template>

    <!-- Start: Cancel -->
    <v-btn
      v-if="orderProcessing.id"
      block
      class="mt-3"
      color="red"
      depressed
      large
      outlined
      @click="cancelOrderProcessing"
    >
      Huỷ
    </v-btn>
  </div>
</template>

<script>
import { showModalAlertError } from "@/core/composables";

export default {
  computed: {
    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    orderProcessingTotal() {
      let total = this.goodsTotal;

      if (this.orderProcessing.assigned_shipping_amount) {
        total += parseInt(this.orderProcessing.assigned_shipping_amount);
      }
      if (this.orderProcessing.handle_fee) {
        total += parseInt(this.orderProcessing.handle_fee);
      }
      if (this.orderProcessing.assigned_promotion_amount) {
        total -= parseInt(this.orderProcessing.assigned_promotion_amount);
      }
      if (this.orderProcessing.assigned_preorder_amount) {
        total -= parseInt(this.orderProcessing.assigned_preorder_amount);
      }

      return total;
    },

    orderProcessingStatusRequest() {
      return this.$store.getters["ORDER_PROCESSING/statusRequest"];
    },

    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    shippingInfo() {
      return this.$store.getters["ORDER_PROCESSING/shippingInfo"];
    }
  },

  methods: {
    cancelOrderProcessing() {
      this.$modal.show({
        name: "modal-cancel",
        payload: {
          title: `<span>Hủy đơn xử lý <strong>${this.orderProcessing.code}</strong></span>`,
          onSubmitHandler: async cancelReason => {
            await this.$store.dispatch("ORDER/cancelOrderProcessing", {
              id: this.orderProcessing.id,
              cancelReason: cancelReason
            });

            // Alert
            if (
              this.orderStatusRequest.value === "success-cancelOrderProcessing"
            ) {
              // Alert
              this.$toast.show({
                name: "toast-action-alert",
                payload: {
                  message: "Đã hủy đơn xử lý"
                }
              });
              await this.$store.dispatch(
                "ORDER/getOrderProcessingById",
                this.orderProcessing.id
              );
              // Get order in order to update latest handles array
              const response = await this.$store.dispatch(
                "ORDER/getOrderById",
                this.saleTabActive.id
              );

              await this.$store.dispatch("SALE/setSaleTabActive", response);
            } else if (
              this.orderStatusRequest.value === "error-cancelOrderProcessing"
            ) {
              // Alert error
              this.$modal.show({
                name: "modal-alert",
                payload: {
                  title: `<span class="red--text text--accent-2">${this.orderStatusRequest.message}</span>`,
                  cancelBtnText: "OK"
                }
              });
            }
          }
        }
      });
    },

    async createOrderProcessing() {
      this.orderProcessing.value = this.orderProcessingTotal;
      // Set serials
      const serialArr = this.orderProcessing.details.reduce((serials, item) => {
        serials = serials.concat(item.serials);

        // Get gift serials
        const giftSerialArr = item.gifts.reduce(
          (giftSerials, gift) => giftSerials.concat(gift.serials),
          []
        );
        serials = serials.concat(giftSerialArr);

        return serials;
      }, []);

      this.orderProcessing.serials = serialArr.map(item => item.id);

      if (!this.orderProcessing.serials.length) {
        showModalAlertError(this, {
          title: "Chưa chọn serial",
          message:
            "Có vẻ bạn chưa chọn serial. Vui lòng kiểm tra và chọn ít nhất một serial."
        });

        return;
      }

      // Request
      await this.$store.dispatch(
        "ORDER/createOrderProcessing",
        this.orderProcessing
      );

      if (this.orderProcessing.received_method === 1) {
        this.shippingInfo.handle_id = this.orderProcessing.id;

        await this.updateShippingInfo();
      }

      // Alert
      if (this.orderStatusRequest.value === "success-createOrderProcessing") {
        await this.$store.dispatch(
          "ORDER/getOrderProcessingById",
          this.orderProcessing.id
        );
        this.saleTabActive.status = 3;
        // Update saleTabActive
        await this.$store.dispatch("SALE/setSaleTabActive", this.order);
        await this.$store.dispatch("ORDER/updateOrder", this.saleTabActive);
        // Toast alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã tạo đơn xử lý"
          }
        });
      } else if (
        this.orderStatusRequest.value === "error-createOrderProcessing"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.orderStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
      // }
    },

    confirmUpdateOrder() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `Bạn chắc chắn Khách đã nhận hàng?`,
          message:
            "Đơn xử lý chuyển sang trạng thái Đã giao quá 24h không cho phép hủy. Hành động này sẽ không thể hoàn tác, bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            text: "Xác nhận",
            color: "primary lighten-2",
            onClickHandler: () => this.updateOrderProcessing(6)
          }
        }
      });
    },

    async updateOrderProcessing(status) {
      this.orderProcessing.value = this.orderProcessingTotal;

      // Set serials
      const serialArr = this.orderProcessing.details.reduce((serials, item) => {
        serials = serials.concat(item.serials);

        // Get gift serials
        const giftSerialArr = item.gifts.reduce(
          (giftSerials, gift) => giftSerials.concat(gift.serials),
          []
        );
        serials = serials.concat(giftSerialArr);

        return serials;
      }, []);

      this.orderProcessing.serials = serialArr.map(item => item.id);

      const objectSender = { ...this.orderProcessing };

      if (status === "export") {
        objectSender.is_export = true;
        if (objectSender.received_method === 2) {
          objectSender.status = 6;
        }
      } else {
        objectSender.is_export = false;
        objectSender.status = status;
      }

      await this.$store.dispatch("ORDER/updateOrderProcessing", objectSender);

      if (this.orderProcessing.received_method === 1) {
        this.updateShippingInfo();
      }

      // Alert
      if (this.orderStatusRequest.value === "success-updateOrderProcessing") {
        await this.$store.dispatch(
          "ORDER/getOrderProcessingById",
          this.orderProcessing.id
        );
        // Get order in order to update latest handles array
        await this.$store.dispatch("ORDER/getOrderById", this.saleTabActive.id);
        // Update saleTabActive by new order data
        await this.$store.dispatch("SALE/setSaleTabActive", this.order);
        // Toast alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã cập nhật đơn xử lý"
          }
        });
      } else if (
        this.orderStatusRequest.value === "error-updateOrderProcessing"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.orderStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async updateShippingInfo() {
      await this.$store.dispatch("ORDER_PROCESSING/updateShippingInfo", {
        ...this.shippingInfo,
        order_handle_id: this.orderProcessing.id
      });

      if (
        this.orderProcessingStatusRequest.value === "success-updateShippingInfo"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật vận chuyển thành công"
          }
        });
      } else if (
        this.orderProcessingStatusRequest.value === "error-updateShippingInfo"
      ) {
        // Alert error
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.orderProcessingStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>
