<template>
  <div>
    <div class="amount-item amount-item--bg">
      <div class="flex-grow-1">Tổng tiền hàng</div>
      <div>{{ orderProcessingTotal | formatCurrency }}</div>
    </div>

    <div class="amount-item align-baseline">
      <div class="flex-grow-1">Giảm giá</div>
      <div>
        <tp-input-price
          v-model="orderProcessing.assigned_promotion_amount"
          :empty-value="null"
          :hint="
            `Tối đa có thể dùng ${formatCurrency(
              orderProcessing.id
                ? orderProcessing.max_assigned_promotion_amount
                : order.max_assigned_promotion_amount
            )}`
          "
          :validate="`maxVal:${order.max_assigned_promotion_amount}`"
          persistent-hint
        ></tp-input-price>
      </div>
    </div>

    <v-divider class="my-4"></v-divider>

    <div class="amount-item amount-item--bg">
      <div class="flex-grow-1">Khách cần trả</div>
      <div class="font-weight-black">
        {{ khachCanTra | formatCurrency }}
      </div>
    </div>

    <div v-if="orderProcessing.hoa_don_ban_id">
      <div class="amount-item amount-item--bg">
        <div class="flex-grow-1">Khách đã trả</div>
        <div>{{ orderProcessing.paid_amount | formatCurrency }}</div>
      </div>

      <div class="amount-item amount-item--bg">
        <div class="flex-grow-1">Khách còn nợ</div>
        <div>
          {{ khachConNo | formatCurrency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "@vt7/utils";

export default {
  methods: { formatCurrency },

  computed: {
    goodsTotal() {
      return (
        this.orderProcessing.details &&
        this.orderProcessing.details.reduce((total, item) => {
          const giftTotal = item.gifts.reduce(
            (giftTotal, gift) =>
              giftTotal +
              gift.serials.length * (gift.unit_price - gift.rebate_amount),
            0
          );

          return (
            total +
            item.serials.length * (item.unit_price - item.rebate_amount) +
            giftTotal
          );
        }, 0)
      );
    },

    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    orderProcessingTotal() {
      let total = this.goodsTotal;

      if (this.orderProcessing.assigned_shipping_amount) {
        total += parseInt(this.orderProcessing.assigned_shipping_amount);
      }
      if (this.orderProcessing.handle_fee) {
        total += parseInt(this.orderProcessing.handle_fee);
      }

      if (
        this.orderProcessing.id &&
        this.orderProcessing.hoa_don_ban_id &&
        this.orderProcessing.payment_fee
      ) {
        total += this.orderProcessing.payment_fee;
      }

      return total;
    },

    khachCanTra() {
      return (
        this.orderProcessing.value -
        (this.orderProcessing.assigned_promotion_amount || 0)
      );
    },

    khachConNo() {
      return this.khachCanTra - this.orderProcessing.paid_amount;
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  }
};
</script>

<style lang="scss" scoped>
.amount-item {
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  width: 300px;
  margin-bottom: 8px;
}
</style>
