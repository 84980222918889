<template>
  <div>
    <v-autocomplete
      ref="autocompleteCustomer"
      v-model="internalSelectedValue"
      class="text-body-1 mb-2"
      dense
      flat
      hide-details
      :items="customers"
      item-text="name"
      item-value="id"
      loader-height="3px"
      :menu-props="{ maxWidth: '454px', nudgeTop: '-2px', closeOnClick: false }"
      no-data-text="Không có dữ liệu"
      no-filter
      placeholder="Tìm kiếm bằng Email hoặc SĐT"
      prepend-inner-icon="mdi-magnify"
      return-object
      :search-input.sync="customerSearchKey"
      small-chips
      solo
      outlined
      @keypress.enter="searchCustomers"
    >
      <template v-slot:selection="{ item }">
        <v-hover v-slot:default="{ hover }">
          <div
            class="text-truncate tp-btn--text rounded-xl px-3 py-1"
            :class="hover ? 'cyan lighten-4' : 'cyan lighten-5'"
            @click="openModalCustomer(item.id)"
          >
            {{ item.name }}
          </div>
        </v-hover>
      </template>

      <template v-slot:item="data">
        <v-list-item-content :title="data.item.name">
          <v-list-item-title
            class="font-weight-medium"
            v-html="data.item.name"
          ></v-list-item-title>

          <div class="grey--text text--darken-2 text-subtitle-2">
            <span v-if="data.item.phone">{{
              data.item.phone | VMask("### ### ####")
            }}</span>
          </div>

          <div class="grey--text text--darken-2 text-subtitle-2">
            <span>{{ data.item.email || "N/A" }}</span>
          </div>
        </v-list-item-content>
      </template>

      <template #append>
        <div class="d-flex align-center">
          <div
            v-if="customerStatusRequest.value === 'loading-getCustomers'"
            class="d-flex align-center justify-center mr-2"
            style="width: 24px"
          >
            <v-progress-circular
              color="grey lighten-2"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-icon
            class="tp-btn--text mr-1"
            @click="internalSelectedValue = null"
            >mdi-close</v-icon
          >
          <v-btn
            color="primary"
            depressed
            :disabled="
              !customerSearchKey ||
                customerSearchKey.trim() === '' ||
                customerStatusRequest.value === 'loading-getCustomers'
            "
            small
            @click="searchCustomers"
            >Tìm</v-btn
          >
        </div>
      </template>
    </v-autocomplete>
    <!-- Start: Customer info -->
    <div
      v-if="internalSelectedValue"
      class="customer-infor-wrapper blue lighten-5 rounded px-3 py-2"
    >
      <div v-if="internalSelectedValue && internalSelectedValue.guest">
        Khách hàng:
        <span class="font-weight-bold">{{ internalSelectedValue.name }}</span>
      </div>

      <div class="d-flex">
        <v-icon class="mr-2">mdi-phone-outline</v-icon>
        <span
          class="font-weight-bold"
          :class="{ 'red--text': !internalSelectedValue.phone }"
          >{{ internalSelectedValue.phone || "Chưa có số điện thoại!" }}</span
        >
        <v-btn
          fab
          x-small
          text
          color="blue darken-4"
          @click="copyToClipboard(internalSelectedValue.phone)"
        >
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </div>

      <div class="mb-2 d-flex">
        <v-icon class="mr-2">mdi-email-outline</v-icon>
        <span class="font-weight-bold">{{
          internalSelectedValue.email || "N/A"
        }}</span>
      </div>

      <div class="mb-2">
        <div>
          <a
            class="text-decoration-underline cursor-pointer"
            :href="
              `${CMS_BASE_URL}/#/transaction/orders?tabs=result&itemPerPage=50&currentPage=1&searchKey=${internalSelectedValue.phone}&searchType=1`
            "
            target="_blank"
            >Số đơn đã mua: {{ internalSelectedValue.total_ordered }}</a
          >
          <v-icon color="primary" right>mdi-chevron-right</v-icon>
        </div>
      </div>

      <div>
        Công nợ:
        <span
          v-if="internalSelectedValue.cong_no"
          class="red--text font-weight-bold"
          >{{ internalSelectedValue.cong_no | formatCurrency }}</span
        >

        <span v-else class="font-weight-bold">0</span>
      </div>
    </div>
    <!-- End: Customer info -->

    <v-btn
      v-if="saleTabActive.status < 2"
      class="px-1 mt-2"
      color="primary"
      text
      @click="openModalCustomer"
    >
      <v-icon class="mr-1">mdi-plus</v-icon>
      Tạo khách hàng mới
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      CMS_BASE_URL: process.env.VUE_APP_BASE_URL,
      customerSearchKey: null,
      isEditCustomerInfor: false
    };
  },

  computed: {
    customers() {
      return this.$store.getters["CUSTOMER/customers"];
    },

    internalSelectedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },

    customerStatusRequest() {
      return this.$store.getters["CUSTOMER/statusRequest"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  watch: {
    saleTabActive(val) {
      if (val && val.customer_id) {
        this.$store.dispatch("CUSTOMER/getCustomerAddresses", val.customer_id);
        this.$store.dispatch("CUSTOMER/getCustomers", {
          cur_page: 1,
          per_page: 100,
          search: val.customer ? val.customer.id : null
        });
      }
    }
  },

  async created() {
    if (
      this.saleTabActive &&
      this.saleTabActive.customer_id &&
      this.saleTabActive.customer
    ) {
      await this.$store.dispatch(
        "CUSTOMER/getCustomerAddresses",
        this.saleTabActive.customer_id
      );

      await this.$store.dispatch("CUSTOMER/getCustomers", {
        cur_page: 1,
        per_page: 100,
        search: !this.saleTabActive.customer
          ? null
          : this.saleTabActive.customer.id
          ? this.saleTabActive.customer.id
          : this.saleTabActive.customer.phone
      });
    }
  },

  methods: {
    async copyToClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã sao chép vào bộ nhớ tạm"
          }
        });
      } catch ($e) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Không thể sao chép vào bộ nhớ tạm"
          }
        });
      }
    },

    async openModalCustomer(customerId) {
      if (customerId && typeof customerId === "number") {
        await this.$store.dispatch("CUSTOMER/getCustomerById", customerId);
      } else {
        await this.$store.dispatch("CUSTOMER/resetCustomer");
      }

      this.$modal.show({
        name: "modal-customer",
        payload: {
          onSuccessfulCreate: customer => {
            this.saleTabActive.customer_id = customer.id;
            this.saleTabActive.customer = JSON.parse(JSON.stringify(customer));
          }
        }
      });
    },

    searchCustomers() {
      if (
        !this.customerSearchKey ||
        this.customerSearchKey.trim() === "" ||
        this.customerStatusRequest.value === "loading-getCustomers"
      )
        return;

      this.$store.dispatch("CUSTOMER/getCustomers", {
        cur_page: 1,
        per_page: 100,
        search: this.customerSearchKey
      });

      this.$refs.autocompleteCustomer.isMenuActive = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.customer-infor-wrapper {
  position: relative;

  .button-edit {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
