<template>
  <div class="px-4">
    <div class="d-flex flex-row align-center justify-space-between">
      <div class="font-weight-bold">
        {{ returnText }}
      </div>
      <div class="font-weight-bold red--text text-h6">
        {{ Math.abs(returnAmountTotal - saleAmountTotal) | formatCurrency }}
      </div>
    </div>

    <div v-if="saleAmountTotal > returnAmountTotal">
      <div class="mt-1 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <div>Khách thanh toán</div>

          <modal-payment-transactions
            :branch-id="saleTabActive.branch_id"
            :total-amount-to-pay="Math.abs(returnAmountTotal - saleAmountTotal)"
            @onSaved="handleSavedTransaction"
          >
            <template #activator>
              <v-btn icon>
                <v-icon color="primary">mdi-credit-card-outline</v-icon>
              </v-btn>
            </template>
          </modal-payment-transactions>
        </div>

        <div>{{ totalAmountTransaction | formatCurrency }}</div>
      </div>

      <div
        v-if="totalFeeTransaction"
        class="mt-1 d-flex align-center justify-space-between"
      >
        <div>Phí thanh toán</div>

        <div>{{ totalFeeTransaction | formatCurrency }}</div>
      </div>

      <div class="mt-1 d-flex align-center justify-space-between">
        <div>Khách còn nợ</div>

        <div>
          {{
            (Math.abs(returnAmountTotal - saleAmountTotal) -
              totalAmountTransaction)
              | formatCurrency
          }}
        </div>
      </div>
    </div>

    <div class="mt-3">
      <div class="mb-3 font-weight-bold">Ghi chú</div>

      <tp-textarea
        v-model="saleTabActive.reason_refund"
        placeholder="Ghi chú thông tin sản phẩm lỗi, lý do đổi trả"
      ></tp-textarea>
    </div>
  </div>
</template>

<script>
import ModalPaymentTransactions from "./ModalPaymentTransactions.vue";

export default {
  components: {
    ModalPaymentTransactions
  },

  data() {
    return {
      CMS_BASE_URL: process.env.VUE_APP_BASE_URL,
      totalAmountTransaction: 0,
      totalFeeTransaction: 0
    };
  },
  computed: {
    customerReturn() {
      return this.$store.getters["CUSTOMER_RETURN/customerReturn"];
    },
    customerReturnStatusRequest() {
      return this.$store.getters["CUSTOMER_RETURN/statusRequest"];
    },
    order() {
      return this.$store.getters["ORDER/order"];
    },
    paymentText() {
      return this.saleAmountTotal <= this.returnAmountTotal
        ? "Tiền trả khách"
        : "Khách thanh toán";
    },
    paymentList() {
      return this.$store.getters["SALE/paymentList"];
    },
    paymentTotal() {
      return this.$store.getters["SALE/paymentTotal"];
    },
    returnAmountTotal() {
      let total = this.returnGoodsAmountTotal.value;

      if (this.saleTabActive.phitrahang) {
        total -= this.saleTabActive.phitrahang;
      }
      if (this.saleTabActive.sale_receipt.payment_fee) {
        total -= this.saleTabActive.sale_receipt.payment_fee;
      }

      return total;
    },
    returnGoodsAmountTotal() {
      let obj = {
        value: 0,
        quantity: 0
      };

      this.saleTabActive.options.forEach(item => {
        obj.quantity += item.serials.length;
        obj.value += item.serials.length * item.return_price;
      });

      return obj;
    },
    returnText() {
      return this.saleAmountTotal <= this.returnAmountTotal
        ? "Cần trả khách"
        : "Khách cần trả";
    },
    saleAmountTotal() {
      let total = this.saleGoodsAmountTotal.value;

      if (this.saleTabActive.sale_receipt.phivanchuyen) {
        total += this.saleTabActive.sale_receipt.phivanchuyen;
      }
      if (this.saleTabActive.sale_receipt.promo) {
        total -= this.saleTabActive.sale_receipt.promo.money;
      }

      return total;
    },
    saleGoodsAmountTotal() {
      let obj = {
        value: 0,
        quantity: 0
      };

      this.saleTabActive.sale_receipt.options.forEach(item => {
        obj.quantity += item.serials.length;
        obj.value +=
          (item.unit_sold_price - item.rebate_amount) * item.serials.length;
      });

      return obj;
    },
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },
    saleTabActiveIndex() {
      return this.$store.getters["SALE/saleTabActiveIndex"];
    },
    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    }
  },
  methods: {
    async createCustomerReturn() {
      const obj = { ...this.saleTabActive };
      let returnSerials = [];

      obj.options.forEach(option => {
        const arr = option.serials.map(item => {
          return {
            ...item,
            return_price: option.return_price
          };
        });

        returnSerials = returnSerials.concat(arr);
      });

      obj.serials = returnSerials;
      // Calculate customer return value
      obj.value =
        this.returnAmountTotal >= this.saleAmountTotal
          ? this.returnAmountTotal - this.saleAmountTotal
          : 0;
      // Set created_at
      obj.created_at =
        obj.created_at && obj.created_time
          ? `${obj.created_at} ${obj.created_time}`
          : undefined;

      // Request
      // eslint-disable-next-line no-unreachable
      const response = await this.$store.dispatch(
        "CUSTOMER_RETURN/createCustomerReturn",
        obj
      );

      // Alert
      if (
        this.customerReturnStatusRequest.value ===
        "success-createCustomerReturn"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Trả hàng thành công"
          }
        });

        if (response.type === "EXPORT_GOODS") {
          this.openNewSaleReceipt(response.id);
        } else {
          this.closeCurrentTab();
        }

        window.open(
          `/#/transaction/customer-return?search=${response.code}&search_key=5&limit=50&page=1`,
          "_blank"
        );
      } else if (
        this.customerReturnStatusRequest.value === "error-createCustomerReturn"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: "<span class='red--text'>Thất bại</span>",
            message:
              this.customerReturnStatusRequest &&
              this.customerReturnStatusRequest.message
                ? this.customerReturnStatusRequest.message
                : "Trả hàng thất bại"
          }
        });
      }
    },

    handleSavedTransaction(value) {
      const formattedTransactions = value.payments
        .filter(item => item.amount > 0)
        .map(item => {
          let obj = {
            branch_id: this.saleTabActive.branch_id,
            transactionable_type: "App\\Models\\HoaDonBan",
            senderable_type: "App\\Models\\User",
            senderable_id: this.saleTabActive.khachhang_id,
            sender_bank_id: null,
            ...item
          };

          if (obj.receiverable_type === "App\\Models\\Branch") {
            obj.receiverable_id = this.saleTabActive.branch_id;
          }

          return obj;
        });

      this.saleTabActive.transactions = formattedTransactions;
      this.totalAmountTransaction = value.totalAmount;
      this.totalFeeTransaction = value.totalFee;
    },

    async openNewSaleReceipt(saleReceiptId) {
      const foundSaleTabIndex = this.saleTabs.findIndex(
        saleTab => saleTab.id === saleReceiptId
      );

      if (foundSaleTabIndex === -1) {
        // Set or get data from local storage
        let localSaleTabs = JSON.parse(localStorage.getItem("TPSALE_TABS"));

        await localStorage.setItem(
          "TPSALE_TAB_ACTIVE_INDEX",
          JSON.stringify(localSaleTabs.length)
        );

        await this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptById",
          saleReceiptId
        );

        await this.$store.dispatch("SALE/addSaleTabItem", {
          ...this.saleReceipt,
          branch_id: this.saleReceipt.branch.id,
          khachhang_id: this.saleReceipt.khachhang.id
        });

        // Check order handles and set orderProcessing state
        this.closeCurrentTab();
        // Set active tab
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          localSaleTabs.length
        );
      } else {
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          foundSaleTabIndex
        );
      }
    },

    closeCurrentTab() {
      // Close current saleTabActive
      this.saleTabs.splice(this.saleTabActiveIndex, 1);
      // Update saleTabs
      this.$store.dispatch("SALE/setSaleTabs", this.saleTabs);
    },

    async createReceiptVoucher(paymentInfo) {
      const receiptVoucher = {
        created_at:
          this.saleTabActive.created_at && this.saleTabActive.created_time
            ? `${this.saleTabActive.created_at} ${this.saleTabActive.created_time}`
            : undefined,
        branch_id: this.saleTabActive.branch_id,
        sender: {
          model_name: "App\\Models\\User",
          model_id: this.saleTabActive.khachhang_id
        },
        receiver: {
          model_name: "App\\Models\\User",
          model_id: this.saleReceipt.created_user.id
        },
        type: 1,
        apply_type: 2,
        voucher_links: [
          {
            model_name: "App\\Models\\HoaDonBan",
            model_id: this.saleReceipt.id,
            code: this.saleReceipt.code,
            amount: paymentInfo.payment_amount + paymentInfo.payment_fee
          }
        ],
        ...paymentInfo
      };

      await this.$store.dispatch("RECEIPT_VOUCHER/addCashFlow", receiptVoucher);
    },

    async createSaleReceipt() {
      const obj = { ...this.saleTabActive.sale_receipt };
      let serials = [];

      await obj.options.forEach(option => {
        const childProducts = obj.options.filter(
          opt => opt.parent === option.id
        );
        const childSerials = [];
        childProducts.forEach(product => {
          childSerials.push(...product.serials.map(value => value.id));
        });
        const { promotions } = option;
        const countProduct = option.serials.length;

        const arr = option.serials.map((item, index) => {
          let promos = [];
          if (promotions) {
            if (promotions.promo_type_3) {
              promos.push({
                id: promotions.promo_type_3.id,
                money: null,
                gifts: childSerials.filter((v, idx) =>
                  this.splitChildProducts(
                    childSerials.length,
                    countProduct,
                    index,
                    idx
                  )
                )
              });
            }
            if (promotions.promo_type_12) {
              promos.push({
                id: promotions.promo_type_12.id,
                money: promotions.promo_type_12.tk_money,
                gifts: []
              });
            }
          }
          return {
            id: item.id,
            unit_sold_price: option.unit_sold_price,
            sold_price: option.unit_sold_price - option.rebate_amount,
            promos
          };
        });

        serials = serials.concat(arr);
      });

      obj.serials = serials;

      // Set branch id, customer id and customer return id
      obj.branch_id = this.saleTabActive.branch_id;
      obj.khachhang_id = this.saleTabActive.khachhang_id;
      obj.return_good_id = this.customerReturn.id;
      obj.created_at =
        obj.created_at && obj.created_time
          ? `${obj.created_at} ${obj.created_time}`
          : undefined;

      // Calculate sale receipt value
      obj.value =
        this.saleAmountTotal >= this.returnAmountTotal
          ? this.saleAmountTotal - this.returnAmountTotal
          : 0;

      // Request
      await this.$store.dispatch("SALE_RECEIPT/createSaleReceipt", obj);
      // Alert
      if (this.saleReceiptStatusRequest.value === "success-createSaleReceipt") {
        // Get sale receipt
        await this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptById",
          this.saleReceipt.id
        );

        // Create receipt voucher
        if (obj.value > 0) {
          if (this.paymentList.length > 0) {
            await this.paymentList.forEach(item => {
              this.createReceiptVoucher(item);
            });
          }

          // Reset payment list
          await this.$store.dispatch("SALE/setPaymentList", []);
        }
      }
    },

    splitChildProducts(total, count, index, idxCondition) {
      const splitCountFloor = Math.floor(total / count);
      if (index < count - 1) {
        return (
          idxCondition >= splitCountFloor * index &&
          idxCondition <= splitCountFloor * (index + 1) - 1
        );
      }
      return (
        idxCondition >= splitCountFloor * index && idxCondition <= total - 1
      );
    },

    async handleAddInvoiceParticipant(
      model_id,
      value,
      require_tech_staff_hardware
    ) {
      const dataSender = {
        model_name: "App\\Models\\KhachTraHang",
        model_id: model_id,
        user_id: value.user_id,
        note: value.note,
        role: require_tech_staff_hardware ? "TECHNIQUE_HARDWARE" : "TECHNIQUE"
      };
      await this.$store.dispatch("ORDER/addInvoiceParticipant", dataSender);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-card {
  cursor: pointer;
}
</style>
