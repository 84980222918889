<template>
  <div class="d-flex flex-row">
    <!-- Start: Main content -->
    <div class="main-wrapper">
      <main-content class="main-content"></main-content>
      <sale-content class="sale-content white"></sale-content>
    </div>
    <!-- End: Main content -->
    <!-- Start: Right sidebar -->
    <div class="white sidebar-wrapper">
      <right-sidebar></right-sidebar>
    </div>
    <!-- End: Right sidebar -->
  </div>
</template>

<script>
import RightSidebar from "./components/RightSidebar/index";
import MainContent from "./components/MainContent/index";
import SaleContent from "./components/SaleContent/index";

export default {
  components: {
    RightSidebar,
    MainContent,
    SaleContent
  }
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  flex: 1;
  height: calc(100vh - 46px);
  max-height: calc(100vh - 46px);
  overflow: hidden;
  .main-content {
    height: calc((100vh - 46px) / 2);
    overflow-y: scroll;
  }
  .sale-content {
    height: calc((100vh - 46px) / 2);
    overflow-y: scroll;
  }
}
.sidebar-wrapper {
  height: calc(100vh - 46px);
  max-height: calc(100vh - 46px);
  width: 360px;
}
</style>
