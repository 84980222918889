import { buildConstants } from "@vt7/utils";

export const LADING_TYPES = {
  NHAN_VIEN_TAO: {
    value: "STAFF",
    label: "Nhân viên tạo"
  },
  HE_THONG_TAO: {
    value: "SYSTEM",
    label: "Hệ thống tạo"
  }
};

export const SHIPPING_SUPPLIERS = buildConstants(
  {
    VNPOST: {
      name: "VNPOST",
      id: 2004,
      code: "NCC2004",
      logo: "https://my.vnpost.vn/img/auth/logo.png",
      lading_types: [
        LADING_TYPES.NHAN_VIEN_TAO.value,
        LADING_TYPES.HE_THONG_TAO.value
      ]
    },
    GIAO_HANG_NHANH: {
      name: "Giao hàng nhanh",
      id: 2000,
      code: "NCC2000",
      logo:
        "https://theme.hstatic.net/200000472237/1001083717/14/logo-footer_medium.png?v=570",
      lading_types: [
        LADING_TYPES.NHAN_VIEN_TAO.value,
        LADING_TYPES.HE_THONG_TAO.value
      ]
    },
    AHAMOVE: {
      name: "ahamove",
      id: 2055,
      code: "NCC2055",
      logo:
        "https://home.ahamove.com/wp-content/uploads/2022/02/logo-moi-2022-02-e1644389695993-300x112.png",
      lading_types: [LADING_TYPES.NHAN_VIEN_TAO.value]
    },
    XANH_SM: {
      name: "Xanh SM",
      id: 2219,
      code: "NCC2219",
      logo:
        "https://upload.wikimedia.org/wikipedia/vi/b/b1/Logo_GSM_xanh_SM.png",
      lading_types: [LADING_TYPES.NHAN_VIEN_TAO.value]
    },
    LALAMOVE: {
      name: "lalamove",
      id: 2218,
      code: "NCC2218",
      logo: "https://www.lalamove.com/hubfs/Lalamove%20Website%202020/logo.svg",
      lading_types: [LADING_TYPES.NHAN_VIEN_TAO.value]
    },
    GRAB: {
      name: "Grab",
      id: 2009,
      code: "NCC2009",
      logo:
        "https://inkythuatso.com/uploads/thumbnails/800/2021/11/logo-grab-inkythuatso-2-01-24-10-33-22.jpg",
      lading_types: [LADING_TYPES.NHAN_VIEN_TAO.value]
    }
  },
  "code"
);
