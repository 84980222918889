var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tp-modal',{attrs:{"max-width":"1050px","name":"modal-sale-receipt-returning"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-4"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Chọn hoá đơn trả hàng")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeModal()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',{staticClass:"grey lighten-3 pa-5",attrs:{"fluid":""}},[_c('v-card',{staticClass:"card-content",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex justify-center mb-6 pt-6"},[_c('div',{staticClass:"d-flex flex-col align-center"},[_c('tp-search-option',{attrs:{"default-option":_vm.searchType,"options":_vm.searchOptions,"outlined":""},on:{"search":function($event){return _vm.updateSearchKey($event)}},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}})],1)]),_c('v-data-table',{staticClass:"tp-table__row-pointer datatable px-3 py-2",attrs:{"footer-props":_vm.dataTableFooterProps,"headers":_vm.headers,"items":_vm.saleReceipts,"loading":_vm.saleReceiptStatusRequest.value === 'loading-getSaleReceipts',"calculate-widths":"","dense":"","disable-pagination":"","hide-default-footer":"","item-key":"id","loading-text":"Đang tải dữ liệu","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp"},on:{"update:page":function($event){return _vm.updatePage($event)},"update:items-per-page":function($event){return _vm.updateItemPerPage($event)}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.shortenSaleReceiptCode(item.code))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.value))+" ")]}},{key:"item.khachhang_con_no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.khachhang_con_no))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"my-2",attrs:{"color":"primary","dark":"","depressed":"","small":""},on:{"click":function($event){return _vm.selectSaleReceipt(item)}}},[_vm._v(" Chọn ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }