<template>
  <div class="form-delivery-wrapper rounded text-body-1">
    <span style="font-size: 18px; font-weight: 600">Tạo vận đơn</span>

    <div class="mt-4">
      <v-radio-group
        v-model="shippingInfo.created_by"
        :disabled="
          !(orderProcessing.status === 1 || orderProcessing.status === 3)
        "
        row
      >
        <v-radio
          v-for="type in ladingTypes"
          :key="type.value"
          v-bind="type"
        ></v-radio>
      </v-radio-group>
    </div>

    <template
      v-if="shippingInfo.created_by === LADING_TYPES.NHAN_VIEN_TAO.value"
    >
      <div>
        <div>
          Truy cập trang quản lý của ĐVVC, tạo đơn và điền thông tin vận đơn vào
          bên dưới.
        </div>
        <a
          v-if="
            shippingInfo.shipping_supplier_code ===
              SHIPPING_SUPPLIERS.GIAO_HANG_NHANH.code
          "
          href="https://khachhang.ghn.vn/"
          style="font-size: 14px; color: #246FFF"
          target="_blank"
        >
          <span>Tới trang quản lý của GHN</span>

          <v-icon style="font-size: 16px; color: #246FFF"
            >mdi-arrow-top-right
          </v-icon>
        </a>
        <a
          v-else-if="
            shippingInfo.shipping_supplier_code ===
              SHIPPING_SUPPLIERS.VNPOST.code
          "
          href="https://my.vnpost.vn/"
          style="font-size: 14px; color: #246FFF"
          target="_blank"
        >
          <span>Tới trang quản lý của VNPOST</span>

          <v-icon style="font-size: 16px; color: #246FFF"
            >mdi-arrow-top-right
          </v-icon>
        </a>
      </div>

      <div style="width: 50%">
        <div class="mt-3">
          <div style="font-weight: 500">Mã vận đơn</div>

          <tp-text-field
            v-model="shippingInfo.tracking_code"
            hide-details="auto"
            placeholder="Nhập mã vận đơn"
          ></tp-text-field>
        </div>

        <div class="mt-3">
          <div style="font-weight: 500">
            Tiền thu hộ (Chưa bao gồm phí ship)
          </div>

          <tp-input-price
            v-model="shippingInfo.cod_amount"
            hide-details="auto"
          ></tp-input-price>
        </div>
      </div>

      <div
        v-if="
          orderProcessing.status === 1 ||
            orderProcessing.status === 3 ||
            orderProcessing.status === 5
        "
        class="mt-3"
      >
        <v-btn
          :loading="isLoading"
          color="success"
          depressed
          @click="handleUpdateShippingInfo"
          >Lưu
        </v-btn>
      </div>
    </template>

    <template v-else>
      <v-row>
        <v-col cols="6">
          <div class="font-weight-medium">Chọn ca lấy hàng</div>

          <tp-select
            v-model="shippingInfo.pick_shift"
            :items="shippingDeliveryShifts"
            hide-details="auto"
            item-text="label"
            item-value="value"
            placeholder="Chọn ca lấy hàng"
          ></tp-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="font-weight-medium">
            Tiền thu hộ (Chưa bao gồm phí ship)
          </div>

          <tp-input-price
            v-model="shippingInfo.cod_amount"
            hide-details="auto"
          ></tp-input-price>
        </v-col>

        <v-col cols="6">
          <div class="font-weight-medium">Người trả phí</div>

          <v-radio-group
            v-model="shippingInfo.payer"
            class="mt-0"
            hide-details="auto"
            row
          >
            <v-radio label="Shop trả" value="SHOP"></v-radio>
            <v-radio label="Khách trả" value="CUSTOMER"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="d-flex justify-space-between">
            <div class="font-weight-medium">Khối lượng (g)</div>

            <div v-if="shippingInfo.packaging_info.weight">
              {{ convertGramToKilogram(shippingInfo.packaging_info.weight) }} kg
            </div>
          </div>

          <tp-text-field
            v-model="shippingInfo.packaging_info.weight"
            hide-details="auto"
            placeholder="Nhập khối lượng"
            step="any"
            type="number"
          ></tp-text-field>
        </v-col>

        <v-col cols="6">
          <div class="d-flex gap-2">
            <div>
              <div class="font-weight-medium">Dài (cm)</div>

              <tp-text-field
                v-model="shippingInfo.packaging_info.length"
                hide-details="auto"
                step="any"
                type="number"
              ></tp-text-field>
            </div>

            <div>
              <div class="font-weight-medium">Rộng (cm)</div>

              <tp-text-field
                v-model="shippingInfo.packaging_info.width"
                hide-details="auto"
                step="any"
                type="number"
              ></tp-text-field>
            </div>

            <div>
              <div class="font-weight-medium">Cao (cm)</div>

              <tp-text-field
                v-model="shippingInfo.packaging_info.height"
                hide-details="auto"
                step="any"
                type="number"
              ></tp-text-field>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="font-weight-medium">Ghi chú giao hàng</div>

          <v-textarea
            v-model="shippingInfo.packaging_info.request_shipping"
            hide-details="auto"
            no-resize
            outlined
            placeholder="Nhập ghi chú"
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row v-if="shippingInfo.tracking_code">
        <v-col>
          <div class="font-weight-medium">Mã vận đơn</div>

          <tp-text-field
            v-model="shippingInfo.tracking_code"
            hide-details="auto"
            placeholder="Nhập mã vận đơn"
          ></tp-text-field>
        </v-col>
      </v-row>

      <div
        v-if="orderProcessing.status === 1 || orderProcessing.status === 3"
        class="mt-3"
      >
        <v-btn
          :loading="isLoading"
          color="success"
          depressed
          @click="handleUpdateShippingInfo"
          >Tạo vận đơn
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, defineComponent, computed, watch } from "vue";
import { createVuexHelpers } from "@/core/composables";
import {
  LADING_TYPES,
  SHIPPING_SUPPLIERS
} from "@/modules/Sale/pages/Order/pages/OrderPage/constants";
import OrderService from "@/modules/Transaction/service/order.service";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import { useShippingInfoMutation } from "../composables";

export default defineComponent({
  setup() {
    const { useGetters } = createVuexHelpers();
    const { shippingInfo } = useGetters("ORDER_PROCESSING", ["shippingInfo"]);
    const { orderProcessing } = useGetters("ORDER", ["orderProcessing"]);

    const { handleUpdateShippingInfo, isLoading } = useShippingInfoMutation();

    const params = computed(() => ({
      shipping_supplier_code: shippingInfo.value.shipping_supplier_code,
      branch_id: orderProcessing.value.branch_id
    }));

    const { fetch, data: shippingDeliveryShifts } = useFetchData(
      () => OrderService.getDeliveryTime(params.value),
      {
        initialData: [],
        enabled: () =>
          shippingInfo.value.shipping_supplier_code &&
          orderProcessing.value.branch_id,
        transform: data => {
          return Object.entries(data).map(([key, text]) => {
            return { label: text, value: key };
          });
        }
      }
    );

    watch(
      () => params.value,
      () => {
        fetch();
      },
      { immediate: true, deep: true }
    );

    const deliveryRequest = ref([
      { value: 0, text: "Cho xem hàng, không cho thử" },
      { value: 1, text: "Cho xem hàng, cho thử" },
      { value: 2, text: "Không cho xem hàng" }
    ]);

    const convertGramToKilogram = gram => gram / 1000;

    const isSentToDelivery = computed(() => {
      return !!shippingInfo.value.tracking_code && !!orderProcessing.value.id;
    });

    return {
      LADING_TYPES,
      ladingTypes: Object.values(LADING_TYPES),
      params,
      shippingDeliveryShifts,
      deliveryRequest,
      shippingInfo,
      orderProcessing,
      isSentToDelivery,
      convertGramToKilogram,
      SHIPPING_SUPPLIERS,
      handleUpdateShippingInfo,
      isLoading
    };
  }
});
</script>

<style lang="scss" scoped>
.gap-2 {
  gap: 8px;
}

.form-delivery-wrapper {
  background-color: #f8fafc;
  padding: 20px;
}
</style>
