<template>
  <tp-modal name="modal-select-customer-address" width="70%" max-width="640px">
    <v-card v-if="customerAddresses.length" flat>
      <!-- Start: Title and actions -->
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Địa chỉ nhận hàng của {{ customerAddresses[0].name }}
        </div>

        <v-spacer></v-spacer>
        <v-btn
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="handleClickDone()"
        >
          Xong
        </v-btn>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- End: Title and actions -->
      <!-- Start: Main content -->
      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="px-5 py-5 text-body-1" flat>
          <v-radio-group v-model="radios" class="py-0" style="margin:0">
            <v-radio
              v-for="customerAddress in customerAddresses"
              class="customer-address-container pb-4 pt-2"
              :key="customerAddress.id"
              :value="customerAddress"
            >
              <template v-slot:label>
                <div>
                  <div>
                    <span class="font-weight-bold">{{
                      customerAddress.name
                    }}</span>
                    |
                    <span>{{
                      customerAddress.phone | VMask("### ### ####")
                    }}</span>
                  </div>
                  <span>{{
                    customerAddress.so_nha +
                      ", " +
                      customerAddress.phuong_xa +
                      ", " +
                      customerAddress.quan_huyen +
                      ", " +
                      customerAddress.tinh_thanhpho
                  }}</span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card>
      </v-container>
      <!-- End: Main content -->
    </v-card>
    <v-card v-else>
      <!-- Start: Title and actions -->
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Địa chỉ nhận hàng
        </div>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- End: Title and actions -->

      <!-- Start: Main content -->
      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card flat class="px-5 py-5">
          <p>Khách hàng này chưa có địa chỉ</p>
        </v-card>
      </v-container>
      <!-- End: Main content -->
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  props: {
    employees: {
      type: Array
    },
    data: Array,
    selected: null,
    customerAddresses: Array
  },

  data() {
    return {
      selectedCity: null,
      selectedDistrict: null,
      selectedWards: null,
      dialog: false,
      radios: this.selected
    };
  },

  async created() {
    await this.$store.dispatch("LOCAL_ADMINISTRATION/getCities");
  },

  computed: {
    cities() {
      return this.$store.getters["LOCAL_ADMINISTRATION/cities"];
    },

    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    },

    customerStatusRequest() {
      return this.$store.getters["CUSTOMER/statusRequest"];
    },

    districts() {
      return this.$store.getters["LOCAL_ADMINISTRATION/districts"];
    },

    wards() {
      return this.$store.getters["LOCAL_ADMINISTRATION/wards"];
    }
  },

  methods: {
    async getDistrictsOfCity(city) {
      await this.$store.dispatch(
        "LOCAL_ADMINISTRATION/getDistrictsOfCity",
        city
      );
    },

    async getWardsOfDistrict(district) {
      await this.$store.dispatch(
        "LOCAL_ADMINISTRATION/getWardsOfDistrict",
        district
      );
    },

    closeModal() {
      this.$modal.hide({
        name: "modal-select-customer-address"
      });
    },

    handleClickDone() {
      this.$modal.hide({
        name: "modal-select-customer-address"
      });
      this.$emit("select", this.radios);
    }
  },

  watch: {
    selectedCity(val) {
      if (val) {
        this.getDistrictsOfCity(val);
      }
    },
    selectedDistrict(val) {
      if (val) {
        this.getWardsOfDistrict(val);
      }
    },
    selected(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.radios = newVal;
      }
    },
    deep: true
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 500px;
}
.image {
  position: relative;
  a {
    padding-top: 70px;
    width: 70px;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &:hover .icon-remove-image {
    opacity: 1;
  }
  .icon-remove-image {
    opacity: 0;
    transition: all ease 0.2s;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    color: #868686;
    background: #ffffff4a;
    border: 1px solid #868686;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.customer-address-container {
  border-bottom: 1px solid #e9e9e9;
}

.customer-address-container:last-child {
  border: none;
}
</style>
