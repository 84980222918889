var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white mr-1 pb-5"},[_c('v-data-table',{staticClass:"table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":false,"loading-text":"Đang tải dữ liệu","items":_vm.saleTabActive.options,"item-key":"id"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.indexOfOptionIds(item.id) + 1)+" ")]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.product.name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-light grey--text text--darken-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.SKU)+" - ")]),_c('span',{staticClass:"item-subtitle mr-1",domProps:{"innerHTML":_vm._s(item.name)}})])])]}},{key:"item.serials",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{staticClass:"tp-filter-autocomplete text-body-1 my-2",attrs:{"dense":"","deletable-chips":"","items":item.allSerials,"item-text":"serial_number","item-value":"id","hide-details":"","hide-selected":"","multiple":"","no-data-text":"Không có dữ liệu","outlined":"","placeholder":"Chọn serials","single-line":"","small-chips":"","return-object":""},model:{value:(item.serials),callback:function ($$v) {_vm.$set(item, "serials", $$v)},expression:"item.serials"}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serials.length)+" ")]}},{key:"item.return_price",fn:function(ref){
var item = ref.item;
return [_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","custom-style":"width: 120px","dense":"","flat":"","hide-details":"","outlined":"","solo":""},model:{value:(item.return_price),callback:function ($$v) {_vm.$set(item, "return_price", $$v)},expression:"item.return_price"}})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")((item.return_price * item.serials.length)))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }