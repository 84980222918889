<template>
  <div>
    <div class="cursor-pointer hover-underline" @click="visible = true">
      <slot name="activator"></slot>
    </div>

    <v-dialog v-model="visible" max-width="1300" width="80%">
      <v-card class="border-0" flat>
        <div class="card px-5 py-3 align-center d-flex" height="56x">
          <div class="font-weight-bold">Lịch sử thanh toán của đơn hàng</div>

          <v-spacer></v-spacer>

          <div v-if="metaData" class="d-flex">
            <div>
              Khách đã trả:
              <span class="font-weight-bold">{{
                metaData.total_amount | formatCurrency
              }}</span>
            </div>
            <v-divider class="mx-4" vertical></v-divider>

            <div>
              Đã chuyển cọc:
              <span class="font-weight-bold">{{
                metaData.total_deposit || 0 | formatCurrency
              }}</span>
            </div>

            <v-divider class="mx-4" vertical></v-divider>

            <div>
              Khách còn dư:
              <span class="font-weight-bold">{{
                metaData.total_remain | formatCurrency
              }}</span>
            </div>
          </div>

          <v-btn icon @click="visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>

      <div
        class="v-window-item tab-item grey lighten-3 pa-5 v-window-item--active"
      >
        <v-card class="pa-5" flat>
          <list-payment-transaction
            :isShowTotal="false"
            :items="availablePaymentTransactions"
            :loading="isFetching"
          ></list-payment-transaction>
        </v-card>

        <v-card
          v-if="canceledPaymentTransactions.length"
          class="mt-5 pa-5"
          flat
        >
          <div class="mb-2 font-weight-medium">Xem phiếu bị huỷ</div>

          <list-payment-transaction
            :isShowTotal="false"
            :items="canceledPaymentTransactions"
          ></list-payment-transaction>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { computed } from "vue";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";

import { createVuexHelpers } from "@/core/composables";
import { RECEIPT_STATUS } from "@/core/constant";

import ListPaymentTransaction from "@/modules/Sale/pages/Order/pages/OrderPage/components/TabTransaction/components/ListPaymentTransaction.vue";
import OrderService from "@/modules/Transaction/service/order.service";

export default {
  components: {
    ListPaymentTransaction
  },

  setup() {
    const { useGetters } = createVuexHelpers();
    const { order } = useGetters("ORDER", ["order"]);

    const { data, fetch, isFetching } = useFetchData(
      () => OrderService.getDepositTransactions(order.value.id),
      {
        transform: response => response.data.data,
        initialData: {
          meta: null,
          transactions: []
        }
      }
    );

    fetch();

    return {
      data,
      fetch,
      isFetching,
      order,
      metaData: computed(() => data.value.meta),
      availablePaymentTransactions: computed(() =>
        data.value.transactions.filter(
          item => item.status !== RECEIPT_STATUS.BI_HUY
        )
      ),
      canceledPaymentTransactions: computed(() =>
        data.value.transactions.filter(
          item => item.status === RECEIPT_STATUS.BI_HUY
        )
      )
    };
  },

  data() {
    return {
      visible: false
    };
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
