<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="HEADERS_SALE_ORDER_HISTORY"
    hide-default-footer
    :loading="false"
    loading-text="Đang tải dữ liệu"
    :items="
      filterOrdersByBranchesAndStatus(
        fakeData,
        selectedBranches,
        selectedOrderChannel
      )
    "
    item-key="id"
  >
    <template v-slot:[`item.status`]="{ item }">
      <v-chip outlined small>
        {{ item.status }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import { HEADERS_SALE_ORDER_HISTORY } from "@/modules/Sale/constants";

export default {
  props: {
    selectedBranches: {
      type: String,
      default: "Tất cả"
    },
    selectedOrderChannel: {
      type: String,
      default: "Tất cả"
    }
  },

  data() {
    return {
      HEADERS_SALE_ORDER_HISTORY: HEADERS_SALE_ORDER_HISTORY,
      fakeData: [
        {
          code: "SO1001",
          time: "2023-04-24 10:30:00",
          customer: "John Doe",
          employee: "Jane Smith",
          branches: "53 Thái Hà",
          status: "Delivered",
          cancel_reason: "",
          orderChannel: "Tất cả"
        },
        {
          code: "SO1002",
          time: "2023-04-23 14:45:00",
          customer: "Mary Johnson",
          employee: "Bob Davis",
          branches: "Branch B",
          status: "Cancelled",
          cancel_reason: "Out of stock",
          orderChannel: "Website"
        },
        {
          code: "SO1003",
          time: "2023-04-22 08:00:00",
          customer: "Tom Wilson",
          employee: "Alice Lee",
          branches: "Branch C",
          status: "Processing",
          cancel_reason: "",
          orderChannel: "Chi nhánh"
        },
        {
          code: "SO1004",
          time: "2023-04-21 13:15:00",
          customer: "Sarah Brown",
          employee: "David Kim",
          branches: "Branch A",
          status: "Delivered",
          cancel_reason: "",
          orderChannel: "Tổng đài"
        },
        {
          code: "SO1005",
          time: "2023-04-20 17:30:00",
          customer: "Peter Davis",
          employee: "Karen Smith",
          branches: "Branch B",
          status: "Cancelled",
          cancel_reason: "Wrong item shipped",
          orderChannel: "Facebook"
        }
      ]
    };
  },

  methods: {
    filterOrdersByBranchesAndStatus(orders, branches, orderChannel) {
      // If both branches and orderChannel are set to "all", return the original orders array
      if (branches === "Tất cả" && orderChannel === "Tất cả") {
        return orders;
      }

      // Filter orders based on branches and orderChannel
      const filteredOrders = orders.filter(order => {
        // Check if branches filter is not set to "Tất cả" and order's branches do not match the selected branches
        if (branches !== "Tất cả" && order.branches !== branches) {
          return false;
        }

        // Check if orderChannel filter is not set to "Tất cả" and order's orderChannel does not match the selected orderChannel
        if (orderChannel !== "Tất cả" && order.orderChannel !== orderChannel) {
          return false;
        }

        // If order passes both filters, return true to include it in the filteredOrders array
        return true;
      });

      return filteredOrders;
    }
  }
};
</script>
