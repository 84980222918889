<template>
  <div class="d-flex flex-col">
    <v-btn
      v-if="saleTabActive.id === undefined || saleTabActive.id === null"
      class="btn-submit"
      block
      color="primary"
      depressed
      dark
      :loading="orderStatusRequest.value === 'loading-createOrder'"
      @click="
        orderStatusRequest.value === 'loading-createOrder'
          ? null
          : createOrder()
      "
    >
      <v-icon class="mr-2">mdi-plus</v-icon>
      Tạo đơn hàng
    </v-btn>

    <div
      v-if="saleTabActive.id && saleTabActive.status < 4"
      class="d-flex flex-col"
    >
      <v-btn
        color="primary"
        class="flex-grow-1"
        dark
        depressed
        :loading="orderStatusRequest.value === 'loading-updateOrder'"
        outlined
        @click="
          orderStatusRequest.value === 'loading-updateOrder'
            ? null
            : updateOrder()
        "
      >
        Lưu
      </v-btn>

      <v-btn
        v-if="saleTabActive.status < 2"
        class="mt-2"
        color="primary"
        dark
        depressed
        :loading="orderStatusRequest.value === 'loading-updateOrder'"
        @click="
          orderStatusRequest.value === 'loading-updateOrder'
            ? null
            : updateOrder(2)
        "
      >
        Xác nhận
      </v-btn>
    </div>

    <div
      v-if="
        saleTabActive.id &&
          saleTabActive.status < 4 &&
          saleTabActive.cancel_status !== 1
      "
    >
      <v-divider class="my-3 dashed"></v-divider>

      <v-btn
        block
        class="btn-submit"
        color="red"
        depressed
        outlined
        @click="cancelOrder"
      >
        Hủy đơn
      </v-btn>
    </div>
  </div>
</template>

<script>
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import { LIST_MODEL_TYPES, ARRAY_LIST_MODEL_SITES } from "@/core/constant";

export default {
  props: {
    orderFormRef: {
      type: Object,
      default: () => null
    }
  },

  data() {
    return {
      orderValidate: true
    };
  },

  computed: {
    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },

    disableCompleteOrder() {
      let disabled = false;

      if (this.saleTabActive.handles.length === 0) return true;

      return disabled;
    },

    hasProductWithEmptySerials() {
      return this.saleTabActive.details.some(
        item => item.option.serials.length === 0
      );
    },

    goodsTotal() {
      return this.saleTabActive.details.reduce((total, item) => {
        const giftTotal = item.gifts.reduce(
          (giftTotal, gift) =>
            giftTotal + gift.qty * (gift.unit_price - gift.rebate_amount),
          0
        );

        return (
          total + item.qty * (item.unit_price - item.rebate_amount) + giftTotal
        );
      }, 0);
    },

    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },

    saleModelSiteConfigs() {
      return this.$store.getters["SALE/saleModelSiteConfigs"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    }
  },

  methods: {
    cancelOrder() {
      this.$modal.show({
        name: "modal-cancel",
        payload: {
          title: `<span>Hủy đơn đặt hàng <strong>${this.saleTabActive.code}</strong></span>`,
          description: `Hành động này đồng thời sẽ hủy tất cả đơn xử lý của đơn hàng và không thể hoàn tác. Nhập lý do hủy vào ô bên dưới nếu bạn chắc chắn muốn tiếp tục:`,
          onSubmitHandler: async cancelReason => {
            await this.$store.dispatch("ORDER/cancelOrder", {
              id: this.saleTabActive.id,
              cancelReason: cancelReason
            });
            // Update SaleTabActive
            await this.$store.dispatch("SALE/setSaleTabActive", this.order);

            // Alert
            if (this.orderStatusRequest.value === "success-cancelOrder") {
              showToastActionAlert(this, { message: "Đã hủy đơn hàng" });
            } else if (this.orderStatusRequest.value === "error-cancelOrder") {
              showModalAlertError(this, {
                title: this.orderStatusRequest.message
              });
            }
          }
        }
      });
    },

    alertCompleteOrderPayload(status) {
      if (status === 4) {
        return {
          title: `<span>Hoàn thành ngay đơn đặt hàng <strong>${this.saleTabActive.code}</strong></span>`,
          message:
            "Trạng thái đơn hàng ngay lập tức sẽ chuyển sang hoàn thành. " +
            "Đồng thời, hệ thống sẽ hủy toàn bộ đơn xử lý chưa được xuất hóa đơn và hoàn thành tất cả đơn xử lý đã được xuất hóa đơn. " +
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?"
        };
      }
      if (status === 6) {
        return {
          title: `<span>Hoàn thành ngay đơn trả hàng <strong>${this.saleTabActive.code}</strong></span>`,
          message:
            "Trạng thái đơn hàng ngay lập tức sẽ chuyển sang đã trả hàng. "
        };
      }

      return "";
    },

    completeOrder(status) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          ...this.alertCompleteOrderPayload(status),
          confirmBtn: {
            color: "red accent-2",
            text: "Xác nhận",
            onClickHandler: async () => {
              const payload = {
                id: this.saleTabActive.id,
                status: status
              };

              await this.$store.dispatch("ORDER/completeOrder", payload);
              if (this.orderStatusRequest.value === "success-completeOrder") {
                // Update saleTabActive
                await this.$store.dispatch("SALE/setSaleTabActive", {
                  ...this.order,
                  type: 2
                });

                showToastActionAlert(this, {
                  message: "Đã hoàn thành đơn hàng"
                });
              } else if (
                this.orderStatusRequest.value === "error-completeOrder"
              ) {
                showModalAlertError(this, {
                  title: this.orderStatusRequest.message
                });
              }
            }
          }
        }
      });
    },

    changeStatusToProcessing() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Chuyển trạng thái về đang xử lý <strong>${this.saleTabActive.code}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xác nhận",
            onClickHandler: async () => {
              this.saleTabActive.status = 3;
              await this.updateOrder();
            }
          }
        }
      });
    },

    async createOrder() {
      // Validate order
      await this.validateOrder();
      if (!this.orderValidate) return;

      // Set value
      this.saleTabActive.value = this.goodsTotal;

      this.saleTabActive.details.forEach(detail => {
        detail.option.allSerials = [];
      });

      // TODO: Set promotions for details option
      // Request creating

      // console.log("this.saleTabActive", this.saleTabActive);
      await this.$store.dispatch("ORDER/createOrder", this.saleTabActive);

      // Alert
      if (this.orderStatusRequest.value === "success-createOrder") {
        //Update saleTabActive
        await this.$store.dispatch("SALE/setSaleTabActive", {
          ...this.order,
          type: 2
        });

        const response = await this.$store.dispatch(
          "MODEL_SITE_CONFIG/updateModelSiteConfigs",
          {
            data: this.saleModelSiteConfigs.map(item => {
              item.model_id = this.order.id;

              return item;
            }),
            modelId: this.order.id,
            modelType: LIST_MODEL_TYPES.ORDER
          }
        );

        await this.$store.dispatch("SALE/setSaleModelSiteConfigs", response);

        // Update sale tabs
        await this.$store.dispatch(
          "SALE/setSaleTabs",
          JSON.parse(JSON.stringify(this.saleTabs))
        );

        this.$router.push({
          name: "sale",
          query: {
            type: this.saleTabActive.order_type,
            id: this.saleTabActive.id
          }
        });

        showToastActionAlert(this, { message: "Thêm đơn hàng thành công" });
      } else if (this.orderStatusRequest.value === "error-createOrder") {
        showModalAlertError(this, { title: this.orderStatusRequest.message });
      }
    },

    async openModalCustomer(customerId) {
      await this.$store.dispatch("CUSTOMER/getCustomerById", customerId);

      this.$modal.show({
        name: "modal-customer"
      });
    },

    validateCustomerMessages() {
      let messages = [];

      if (this.customer.gender === null) {
        messages.push("giới tính");
      }

      if (!this.customer.addresses.length) {
        messages.push("địa chỉ");
      }

      if (!this.customer.dob && !this.customer.yob) {
        messages.push("ngày sinh hoặc năm sinh");
      }

      return messages.length
        ? `Vui lòng cập nhật ${messages.join(", ")} cho khách hàng!`
        : "";
    },

    showModalUpdateCustomer() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: "Cập nhật thông tin khách hàng",
          message: this.validateCustomerMessages(),
          confirmBtn: {
            text: "Cập nhật",
            color: "primary",
            onClickHandler: () => {
              this.openModalCustomer(this.saleTabActive.customer.id);
            }
          }
        }
      });
    },

    validateOrder(status) {
      //Form
      const isFormValid = this.orderFormRef.validate();

      if (isFormValid) {
        this.orderValidate = true;
      } else {
        this.orderValidate = false;
      }

      // Model site config
      if (!this.saleTabActive.site) {
        showModalAlertError(
          this,
          {
            title: "Lỗi tạo mới đơn đặt hàng",
            message: `<div class='body-1'>Vui lòng chọn một trong số các thương hiệu sau: 
             ${ARRAY_LIST_MODEL_SITES.map(i => " " + i.text)}
              </div>`
          },
          () => {
            this.orderValidate = true;
          }
        );
        this.orderValidate = false;
      }

      // Branch
      if (!this.saleTabActive.branch_id) {
        showModalAlertError(this, { title: "Bạn chưa chọn chi nhánh" }, () => {
          this.orderValidate = true;
        });

        this.orderValidate = false;
      }

      // Goods count
      if (
        this.hasProductWithEmptySerials ||
        !this.saleTabActive.details.length
      ) {
        showModalAlertError(
          this,
          {
            title: "Đơn hàng chưa có sản phẩm nào",
            message:
              "Có vẻ bạn chưa thêm serial vào đơn hàng. Vui lòng kiểm tra và thử lại."
          },
          () => {
            this.orderValidate = true;
          }
        );

        this.orderValidate = false;
      }
      // Confirm order
      if (status === 2) {
        // Assign to id
        if (!this.saleTabActive.assigned_to_id) {
          showModalAlertError(
            this,
            { title: "Bạn chưa chọn người phụ trách" },
            () => {
              this.orderValidate = true;
            }
          );

          this.orderValidate = false;
        }

        // Received method
        if (
          this.saleTabActive.received_method === 1 &&
          !this.saleTabActive.received_address
        ) {
          showModalAlertError(
            this,
            {
              title: "Chưa chọn địa chỉ nhận hàng",
              message:
                "Bạn không thể xác nhận đơn khi địa chỉ nhận hàng bị bỏ trống. Vui lòng nhập địa chỉ và thử lại."
            },
            () => {
              this.orderValidate = true;
            }
          );

          this.orderValidate = false;
        }
        if (
          this.saleTabActive.received_method === 2 &&
          !this.saleTabActive.branch_nhan_id
        ) {
          showModalAlertError(
            this,
            {
              title: "Chưa chọn chi nhánh nhận hàng",
              message:
                "Bạn không thể xác nhận đơn khi chi nhánh nhận hàng bị bỏ trống. Vui lòng nhập chi nhánh và thử lại."
            },
            () => {
              this.orderValidate = true;
            }
          );

          this.orderValidate = false;
        }
      }
    },

    async updateOrder(status) {
      await this.$store.dispatch(
        "CUSTOMER/getCustomerById",
        this.order.customer.id
      );

      // Validate order
      await this.validateOrder(status);
      if (!this.orderValidate) return;

      // Customer
      if (this.validateCustomerMessages().length) {
        this.showModalUpdateCustomer();
        return;
      }

      // Set value
      this.saleTabActive.value = this.goodsTotal;

      // Set promotions for details option
      this.saleTabActive.details.forEach(option => {
        // TODO: Handle set promotion for option
        if (option.unit_price) option.unit_sold_price = option.unit_price;
      });

      const objectSender = { ...this.saleTabActive };
      if (status) {
        objectSender.status = status;
      }

      //Update qty
      objectSender.details.forEach(item => {
        item.option.qty = item.option.serials.length;
      });

      // Request updating
      await this.$store.dispatch("ORDER/updateOrder", objectSender);

      // Alert
      if (this.orderStatusRequest.value === "success-updateOrder") {
        if (status) {
          this.saleTabActive.status = status;
        }

        await this.$store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
          data: this.saleModelSiteConfigs,
          modelId: this.order.id,
          modelType: LIST_MODEL_TYPES.ORDER
        });

        // Update saleTabActive
        await this.$store.dispatch("SALE/setSaleTabActive", { ...this.order });

        showToastActionAlert(this, {
          message:
            status && status === 2
              ? "Đã xác nhận đơn hàng"
              : status && status === 4
              ? "Đã hoàn thành đơn hàng"
              : "Cập nhật đơn hàng thành công"
        });

        // Reset sale tab active new product option ids
        await this.$store.dispatch("SALE/setSaleTabActiveNewProductOption", []);
      } else if (this.orderStatusRequest.value === "error-updateOrder") {
        showModalAlertError(this, { title: this.orderStatusRequest.message });
      }
    }
  }
};
</script>

<style scoped>
.dashed {
  border-top: 1px dashed #ebeaed;
}
</style>
