<template>
  <div class="d-flex align-center">
    <div
      class="textfield__hide-outline hoa-don-ban d-flex align-center pa-0 justify-end"
      :class="{ 'py-1': saleTabActive.id }"
    >
      <v-icon class="mr-2" color="grey lighten-1" size="17px">
        mdi-clock-time-four
      </v-icon>

      <span
        v-if="saleTabActive.id"
        class="text-body-2 mt-0 created_at_active"
        >{{
          formatDateTime(saleTabActive.created_at, "DD/MM/YYYY - hh:mm")
        }}</span
      >

      <template v-else>
        <tp-input-date
          v-model="saleTabActive.created_at"
          :allowed-dates="allowedCreatedDates"
          :custom-class="
            saleTabActive.xuatBu
              ? 'text-body-2 mt-0'
              : 'text-body-2 mt-0 created_at_active'
          "
          custom-style="width: 68px"
          :disabled="!saleTabActive.xuatBu || !!saleTabActive.id"
          dense
          hide-details
        ></tp-input-date>

        <span class="mr-2 ml-1">-</span>

        <tp-input-time
          v-model="saleTabActiveCreatedTime"
          :custom-class="
            saleTabActive.xuatBu
              ? 'text-body-2 mt-0'
              : 'text-body-2 mt-0 created_time_active'
          "
          custom-style="width: 32px"
          dense
          hide-details
          width="100px"
          :disabled="!!saleTabActive.id"
        ></tp-input-time>
      </template>
    </div>
  </div>
</template>

<script>
import {
  formatDateTime,
  getDatesBetweenTwoDates,
  getTime,
  setTime
} from "@/core/utils";

export default {
  data() {
    return {
      allowedCreatedDates: val => {
        const now = new Date(),
          diffDays = getDatesBetweenTwoDates(now, new Date(val));

        return diffDays > -3 && diffDays < 1;
      }
    };
  },

  computed: {
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    saleTabActiveCreatedTime: {
      get() {
        return getTime(this.saleTabActive.created_at);
      },
      set(val) {
        this.saleTabActive.created_at = formatDateTime(
          setTime(this.saleTabActive.created_at, val),
          "YYYY-MM-DD hh:mm"
        );
      }
    }
  },

  methods: {
    formatDateTime
  }
};
</script>

<style lang="scss" scoped>
.textfield__hide-outline {
  ::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
    border: 0;
  }
}
</style>
