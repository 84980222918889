var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-1"},[_c('search-bar'),_c('div',{staticClass:"white pb-5"},[_c('v-data-table',{staticClass:"table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":false,"loading-text":"Đang tải dữ liệu","items":_vm.saleTabActive.sale_receipt.options,"item-key":"id"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.indexOfOptionIds(item))+" ")]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.product_name)+" "),(_vm.checkPromotionAvailable(item))?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{staticClass:"red--text text--accent-1 ml-1",class:{ 'red--text text--accent-2': hover },attrs:{"size":"18px"},on:{"click":function($event){return _vm.openModalSalePromotion()}}},[_vm._v(" mdi-wallet-giftcard ")])]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"text-subtitle-2 font-weight-light grey--text text--darken-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.SKU)+" - ")]),_c('span',{staticClass:"item-subtitle mr-1",domProps:{"innerHTML":_vm._s(("" + (item.name)))}}),(item.given)?_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"red"}},[(item.tang_type === 1 || item.tang_type === 2)?[_vm._v(" "+_vm._s(item.tang_type === 1 ? "(quà tặng)" : ("(giảm giá " + (item.tang_value) + "%)"))+" ")]:_vm._e(),(item.tang_type === 3)?[_vm._v(" (giảm "+_vm._s(_vm._f("formatCurrency")(item.tang_value))+") ")]:_vm._e()],2):_vm._e()])])]}},{key:"item.serials",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{staticClass:"tp-filter-autocomplete text-body-1 my-2",attrs:{"dense":"","deletable-chips":"","items":item.allSerials,"item-text":"serial_number","item-value":"id","hide-details":"","hide-selected":"","disabled":!_vm.enableSelectSerialForPromotion(item),"multiple":"","no-data-text":"Không có dữ liệu","outlined":"","placeholder":"Chọn serials","single-line":"","small-chips":"","return-object":""},on:{"change":function($event){return _vm.updateSerialsChange(item)},"focus":function($event){return _vm.getStockSerialsByOptionId(item)}},model:{value:(item.serials),callback:function ($$v) {_vm.$set(item, "serials", $$v)},expression:"item.serials"}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serials.length)+" ")]}},{key:"item.unit_sold_price",fn:function(ref){
var item = ref.item;
return [_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","dense":"","flat":"","hide-details":"","min-value":item.rebate_amount ? item.rebate_amount : 0,"outlined":"","solo":"","placeholder":"Giá trị"},model:{value:(item.unit_sold_price),callback:function ($$v) {_vm.$set(item, "unit_sold_price", $$v)},expression:"item.unit_sold_price"}})]}},{key:"item.rebate_amount",fn:function(ref){
var item = ref.item;
return [_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","custom-style":"width: 120px","dense":"","flat":"","hide-details":"","outlined":"","solo":"","placeholder":"Giá trị"},model:{value:(item.rebate_amount),callback:function ($$v) {_vm.$set(item, "rebate_amount", $$v)},expression:"item.rebate_amount"}})]}},{key:"item.sold_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")((item.unit_sold_price - item.rebate_amount)))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(((item.unit_sold_price - item.rebate_amount) * item.serials.length)))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'grey darken-1' : 'grey lighten-1',"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeProductOption(item)}}},[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }