<template>
  <div class="blue accent-2 px-4 py-2">
    <div class="search-box">
      <!-- Start: Search by option -->
      <v-autocomplete
        v-if="searchBy === 'OPTION'"
        class="text-body-1 mr-4"
        clearable
        dense
        flat
        hide-details
        :items="filterSearchProductOptions"
        item-text="product_name"
        item-value="id"
        :loading="
          productOptionStatusRequest.value === 'loading-searchProductOptions'
        "
        :menu-props="{ maxWidth: '344px' }"
        no-data-text="Không có dữ liệu"
        no-filter
        placeholder="Tìm sản phẩm"
        prepend-inner-icon="mdi-magnify"
        return-object
        solo
        :search-input.sync="productSearchKey"
        @change="selectProductOption($event)"
      >
        <template v-slot:item="data">
          <v-list-item-content :title="data.item.name">
            <v-list-item-title class="font-weight-medium">
              <span>{{ data.item.product_name }}</span>
              <v-icon
                v-if="data.item.promotions && data.item.promotions.promo_type_3"
                class="red--text text--accent-1 ml-1"
                size="18px"
                style="margin-bottom: 4px"
              >
                mdi-wallet-giftcard
              </v-icon>
            </v-list-item-title>
            <div class="grey--text text--darken-2 text-subtitle-2">
              <span class="font-weight-bold">{{ data.item.SKU }} - </span>
              <span class="item-subtitle" v-html="data.item.name"></span>
            </div>
            <div class="d-flex justify-space-between text-subtitle-2 mt-1">
              <div>
                <template
                  v-if="
                    calculateSalePrice(
                      data.item.price,
                      data.item.promotions
                    ) !== data.item.price
                  "
                >
                  <span class="red--text">{{
                    calculateSalePrice(data.item.price, data.item.promotions)
                      | formatCurrency
                  }}</span>
                  <span
                    class="text-decoration-line-through font-weight-light ml-2"
                    >{{ data.item.price | formatCurrency }}</span
                  >
                </template>
                <template v-else>
                  <span class="red--text">{{
                    data.item.price | formatCurrency
                  }}</span>
                </template>
              </div>
              <div>Sẵn bán: {{ data.item.total_serials }}</div>
            </div>
          </v-list-item-content>
        </template>

        <template #append-outer>
          <v-icon
            class="tp-btn--text"
            color="white"
            @click="searchBy = 'SERIAL'"
            >mdi-barcode-off</v-icon
          >
        </template>
      </v-autocomplete>
      <!-- End: Search by option -->
      <!-- Start: Search by serial -->
      <v-text-field
        v-if="searchBy === 'SERIAL'"
        v-model="serialNumber"
        clearable
        class="text-body-1"
        prepend-inner-icon="mdi-magnify"
        dense
        flat
        hide-details
        placeholder="Nhập serial và ấn enter"
        solo
        style="max-width: 344px"
        @keypress.enter="getSerialBySerialNumber"
      >
        <template #append-outer>
          <v-hover v-slot:default="{ hover }">
            <v-icon
              class="tp-btn--text"
              :color="hover ? 'grey darken-1' : 'grey'"
              @click="searchBy = 'OPTION'"
              >mdi-barcode</v-icon
            >
          </v-hover>
        </template>
      </v-text-field>
      <!-- End: Search by serial -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productSearchKey: null,
      searchBy: "OPTION", // OPTION, SERIAL
      serialNumber: null
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },

    filterSearchProductOptions() {
      if (this.saleTabActive) {
        const arr = this.saleTabActive.sale_receipt.options.map(
          item => item.id
        );

        return this.searchProductOptions.filter(item => !arr.includes(item.id));
      }
      return this.searchProductOptions;
    },

    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    },

    productOptionStockSerials() {
      return this.$store.getters["SERIAL/productOptionStockSerials"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    searchProductOptions() {
      return this.$store.getters["PRODUCT_OPTION/searchProductOptions"];
    },

    serialInfo() {
      return this.$store.getters["SERIAL/serialInfo"];
    },

    serialStatusRequest() {
      return this.$store.getters["SERIAL/statusRequest"];
    }
  },
  watch: {
    productSearchKey(val) {
      if (val && val.length > 0) {
        this.$store.dispatch("PRODUCT_OPTION/searchProductOptions", {
          search: val
        });
      }
    }
  },
  methods: {
    calculateSalePrice(price, promotions) {
      if (!promotions || (promotions && !promotions.promo_type_12))
        return price;

      const promotion = promotions.promo_type_12;

      if (promotion.tk_type === 1) {
        return (parseInt(price) * (100 - parseInt(promotion.tk_percent))) / 100;
      }
      if (promotion.tk_type === 2) {
        return parseInt(price) - parseInt(promotion.tk_money);
      }
    },

    async getSerialBySerialNumber() {
      await this.$store.dispatch(
        "SERIAL/getSerialBySerialNumber",
        this.serialNumber
      );

      if (
        this.serialStatusRequest.value === "success-getSerialBySerialNumber"
      ) {
        // Check valid serial
        if (this.serialInfo && this.serialInfo.id) {
          if (this.serialInfo.status === 1) {
            // Ordered serial or delivered serial
            if (
              this.serialInfo.order_status ||
              this.serialInfo.exchange_status
            ) {
              this.$modal.show({
                name: "modal-alert",
                payload: {
                  title: `<span class="red--text text--accent-2">${
                    this.serialInfo.order_status
                      ? "Serial đã bị đặt"
                      : "Serial đang được chuyển"
                  }</span>`,
                  cancelBtnText: "OK"
                }
              });
            } else if (
              this.saleTabActive.branch_id &&
              this.serialInfo.branch_id !== this.saleTabActive.branch_id
            ) {
              this.$modal.show({
                name: "modal-alert",
                payload: {
                  title: `<span class="red--text text--accent-2">Serial hiện tại đang ở ${this.serialInfo.branch_name}</span>`,
                  cancelBtnText: "OK"
                }
              });
            } else {
              // Valid case, so set branch
              this.saleTabActive.branch_id = this.serialInfo.branch_id;

              // Get product option of serial
              await this.$store.dispatch(
                "PRODUCT_OPTION/searchProductOptions",
                {
                  search: this.serialInfo.option_sku
                }
              );

              const option = this.searchProductOptions[0];
              let optionIndex = null;

              // Find option in saleTabActive
              const foundOption = this.saleTabActive.sale_receipt.options.find(
                (item, index) => {
                  if (item.id === option.id) {
                    optionIndex = index;
                    return true;
                  }
                }
              );

              if (foundOption) {
                const foundSerial = foundOption.serials.find(
                  serial => serial.id === this.serialInfo.id
                );

                if (foundSerial) return false;
                const optionSerials = await this.getStockSerialsByOptionId(
                  foundOption
                );

                foundOption.allSerials = optionSerials;
                foundOption.serials.push({ ...this.serialInfo });

                this.saleTabActive.sale_receipt.options[
                  optionIndex
                ] = foundOption;
                // Update sale active tab
                await this.$store.dispatch(
                  "SALE/setSaleTabActive",
                  this.saleTabActive
                );
              } else {
                const optionSerials = await this.getStockSerialsByOptionId(
                  option
                );

                await this.selectProductOption(
                  option,
                  [this.serialInfo],
                  optionSerials
                );
              }

              this.serialNumber = null;
            }
          } else {
            // Not in stock or not for sale
            this.$modal.show({
              name: "modal-alert",
              payload: {
                title: `<span class="red--text text--accent-2">Serial này đã bị bán hoặc thuộc kho không kinh doanh</span>`,
                cancelBtnText: "OK"
              }
            });
          }
        } else {
          this.$modal.show({
            name: "modal-alert",
            payload: {
              title: `<span class="red--text text--accent-2">Không tìm thấy serial</span>`,
              cancelBtnText: "OK"
            }
          });
        }
      } else if (
        this.serialStatusRequest.value === "error-getSerialBySerialNumber"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.serialStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async getStockSerialsByOptionId(option) {
      let res = [];

      await this.$store.dispatch("SERIAL/getStockSerialsByOptionId", option.id);

      this.branches.forEach(branch => {
        // Filter serial by branch id
        let arr = this.productOptionStockSerials.filter(
          item => item.branch_id === branch.id
        );

        arr = arr.map(value => {
          return {
            ...value,
            disabled:
              this.saleTabActive.branch_id &&
              this.saleTabActive.branch_id !== branch.id
          };
        });

        // Push into res array
        if (arr.length > 0) {
          res.push({ header: `${branch.name} (${arr.length})` });
          res = res.concat(arr);
          // Push divider
          // if (index < this.branches.length - 1) {
          //   res.push({ divider: true });
          // }
        }
      });

      return res;
    },

    async selectProductOption(item, serials, allSerials) {
      if (item && item.id) {
        const price = item.price,
          salePrice = this.calculateSalePrice(price, item.promotions);

        await this.saleTabActive.sale_receipt.options.unshift({
          id: item.id,
          SKU: item.SKU,
          product_name: item.product_name,
          name: item.name,
          unit_sold_price: price,
          rebate_amount: price - salePrice,
          sold_price: salePrice,
          serials: serials ? serials : [],
          allSerials: allSerials ? allSerials : [],
          promotions: item.promotions
        });

        // Update sale active tab
        await this.$store.dispatch("SALE/setSaleTabActive", this.saleTabActive);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-box {
  width: 360px;
}
</style>
