<template>
  <v-radio-group
    v-model="selected"
    :column="false"
    class="form-radio-site-model-available"
  >
    <v-radio
      v-for="(item, index) in internalValue"
      :key="index"
      :disabled="isDisable"
      :value="LIST_MODEL_SITES[item.site].id"
    >
      <template v-slot:label>
        <v-img
          :alt="LIST_MODEL_SITES[item.site].text"
          :src="LIST_MODEL_SITES[item.site].logo"
          :width="
            LIST_MODEL_SITES[item.site].id === LIST_MODEL_SITES.GOODSPACE.id
              ? '106px'
              : null
          "
          height="22px"
        ></v-img>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
import { LIST_MODEL_SITES } from "@/core/constant";

export default {
  props: {
    modelId: {
      type: Number,
      default: null,
    },
    modelType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Hiển thị trên website",
    },
    value: {
      type: Array,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      LIST_MODEL_SITES,
    };
  },

  computed: {
    internalValue() {
      const listModelSites = Object.values(LIST_MODEL_SITES);

      if (this.value.length === listModelSites.length) return this.value;

      return listModelSites.map((item) => {
        return {
          available: false,
          site: item.id,
          model_id: this.modelId,
          model_type: this.modelType,
        };
      });
    },
    selected: {
      get() {
        const foundItem = this.internalValue.find((item) => item.available);

        return foundItem ? foundItem.site : [];
      },
      set(val) {
        this.internalValue.forEach((item) => {
          item.available = item.site === val;
        });

        this.$emit("input", this.internalValue);
      },
    },
  },
};
</script>

<style lang="scss">
.form-radio-site-model-available {
  padding: 0;
  margin: 0;
  height: 30px;

  .v-input--selection-controls__input {
    display: none;
  }

  .v-radio {
    opacity: 0.4;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
      box-shadow: rgb(177, 230, 236) 0px 0px 0px 2px;
    }
  }

  .v-item--active {
    box-shadow: rgb(177, 230, 236) 0px 0px 0px 2px;
    background: white;
    opacity: 1;
  }

  .v-radio {
    padding: 4px 8px;
    margin: 0 8px;
    border-radius: 4px;
  }
}
</style>
