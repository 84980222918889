<template>
  <div class="order-header" style="height: 46px">
    <div
      class="d-flex flex-row align-center justify-space-between order-header-sub"
    >
      <div class="d-flex align-center px-4">
        <div class="d-flex tab-wrapper">
          <v-tabs
            v-model="activeTab"
            active-class="black--text font-weight-bold"
            background-color="transparent"
            class="font-weight-medium custom-order-tabs"
            color="primary"
            height="46px"
          >
            <v-tab
              class="text-body-1 text-none px-3 black--text"
              @click="onHandlerClickTab('tabDetail')"
            >
              <div class="d-flex align-baseline">
                Thông tin đơn hàng
                <v-chip class="ml-2" color="primary" x-small
                  >{{ totalSaleTabsProduct }}
                </v-chip>
              </div>
            </v-tab>

            <v-tab
              v-if="saleTabActive.id"
              class="text-body-1 text-none px-3 black--text"
              @click="onHandlerClickTab('TabTransaction')"
            >
              Lịch sử thanh toán
            </v-tab>

            <v-tab
              v-if="saleTabActive.id"
              class="text-body-1 text-none px-3 black--text"
              @click="onHandlerClickTab('TabParticipant')"
            >
              <div class="d-flex align-baseline">
                Phục vụ
                <v-chip class="ml-2" color="primary" x-small
                  >{{ orderInvoiceParticipants.length }}
                </v-chip>
              </div>
            </v-tab>
          </v-tabs>
        </div>
      </div>

      <div class="d-flex align-center">
        <v-btn
          :to="{ name: 'transaction_orders' }"
          class="text-body-1 text-none font-weight-medium mr-4"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-arrow-top-right</v-icon>
          Tất cả đơn đặt hàng
        </v-btn>

        <button-more-action></button-more-action>
      </div>
    </div>
  </div>
</template>

<script>
import { LIST_MODEL_TYPES } from "@/core/constant";
import ButtonMoreAction from "./ButtonMoreAction";

export default {
  components: { ButtonMoreAction },
  data() {
    return {
      activeTab: null,
      activeOrderProcessing: null,
      LIST_MODEL_TYPES
    };
  },

  computed: {
    computedModelSiteConfigs: {
      get() {
        return this.saleModelSiteConfigs;
      },
      set(val) {
        this.$store.dispatch("SALE/setSaleModelSiteConfigs", val);
      }
    },

    order() {
      return this.$store.getters["ORDER/order"];
    },

    orderInvoiceParticipants() {
      return this.$store.getters["INVOICE_PARTICIPANT/invoiceParticipants"];
    },

    orderPaymentTransactionsLength() {
      return this.$store.getters["ORDER/orderPaymentTransactionsLength"];
    },

    productQuantity() {
      return this.order?.details.reduce((total, item) => {
        return total + Number(item.qty);
      }, 0);
    },

    saleModelSiteConfigs() {
      return this.$store.getters["SALE/saleModelSiteConfigs"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },

    totalSaleTabsProduct() {
      return this.saleTabActive?.details?.length;
    },
    totalSerialsAvailableInHandleDetails() {
      let totalSerialsCount = 0;

      this.saleTabActive.handles.forEach(handle => {
        if (handle.cancel_status === 0) {
          handle.details.forEach(detail => {
            totalSerialsCount += detail.serials.length;
          });
        }
      });

      return totalSerialsCount;
    }
  },

  watch: {
    "saleTabActive.id": {
      async handler(val) {
        if (val) {
          const response = await this.$store.dispatch(
            "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
            {
              modelId: val,
              modelType: LIST_MODEL_TYPES.ORDER
            }
          );
          // this.$emit("updateModelSiteConfigs", [...res]);
          await this.$store.dispatch("SALE/setSaleModelSiteConfigs", response);
        } else {
          await this.$store.dispatch("SALE/setSaleModelSiteConfigs", []);
        }
      },
      immediate: true
    }
  },

  methods: {
    async onHandlerClickTab(payload) {
      this._validateTabAction(payload);

      if (payload === "TabDetail" && this.order.id) {
        await this.$store.dispatch("ORDER/getOrderById", this.order.id);

        console.log("SALE/SET_SALE_TAB_ACTIVE -> SaleAtStoreHeader");

        this.$store.commit("SALE/SET_SALE_TAB_ACTIVE", this.order);
      }

      this.$emit("select-tab", payload);
    },

    _validateTabAction(payload) {
      if (
        ["DEPOSIT", "DETAIL_ORDER_PROCESSING"].includes(payload) &&
        this.totalSaleTabsProduct === 0
      ) {
        this.$modal.show({ name: "modal-empty-product" });
      }
    },

    shortenCode(code) {
      let arr = code.split("-");
      return arr[arr.length - 1];
    }
  }
};
</script>

<style lang="scss" scoped>
.order-header {
  top: 46px !important;
}

.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}
</style>
<style lang="scss">
.order-header .v-toolbar__content {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
}

.order-header .order-header-main {
  padding-left: 16px;
  padding-right: 16px;
}

.order-header .order-header-sub {
  background: #edf5ff;
}

.border-0 {
  border: none !important;
}

.tab-wrapper {
  max-width: calc(100vw - 456px);
}

.custom-order-tabs {
  .v-tabs-slider-wrapper {
    height: 3px !important;
    display: flex;
    justify-content: center;

    .v-tabs-slider {
      width: 85%;
    }
  }
}

.custom-divider::after {
  content: "";
  background-color: white;
  height: 24px;
  width: 2px;
  position: absolute;
  right: 0;
}

.flex-1 {
  flex: 1;
}
</style>
