<template>
  <div class="relative">
    <v-btn
      v-if="!value"
      class="unset-case font-weight-medium text-body-1 pa-0"
      :disabled="isDisabled"
      small
      text
      @click="visible = !visible"
    >
      Đặt hẹn
      <v-icon right>mdi-chevron-right</v-icon>
    </v-btn>

    <v-btn
      v-else
      class="unset-case font-weight-medium text-body-1 pa-0"
      :disabled="isDisabled"
      small
      text
      @click="visible = !visible"
    >
      {{ humanizeShippingEstTime(value) }}
      <v-icon right>mdi-chevron-right</v-icon>
    </v-btn>

    <v-card v-if="visible" class="pa-2 menu-popover" elevation="3">
      <date-picker
        v-model="internalDeliveryDate"
        class="custom-date-picker"
        format="DD/MM/YYYY HH:mm"
        value-type="YYYY-MM-DD HH:mm:ss"
        type="datetime"
      ></date-picker>

      <div class="mt-2 d-flex justify-end">
        <v-btn class="mr-2" depressed x-small @click="visible = false"
          >Đóng</v-btn
        >
        <v-btn color="primary" depressed x-small @click="onSelected"
          >Chọn</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { timeLeftFromCurent } from "@/core/utils";

export default {
  name: "ButtonChooseCustomer",

  components: {
    DatePicker
  },

  props: {
    value: {
      type: String,
      default: () => ""
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      visible: false,
      internalDeliveryDate: null
    };
  },

  computed: {
    customer() {
      return this.value;
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },

  watch: {
    visible: {
      handler() {
        this.internalDeliveryDate = this.value;
      },
      immediate: true
    }
  },

  methods: {
    onSelected() {
      this.$emit("input", this.internalDeliveryDate);
      this.visible = false;
    },

    humanizeShippingEstTime(dateTime) {
      const now = new Date();
      const shippingEstDate = new Date(dateTime);

      if (this.saleTabActive.shipping_status === 5) {
        return `Đã giao ${timeLeftFromCurent(dateTime)} trước`;
      } else if (shippingEstDate < now) {
        return `Quá ${timeLeftFromCurent(dateTime)}`;
      } else {
        return `Còn ${timeLeftFromCurent(dateTime)}`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-date-picker {
  width: 100%;
  ::v-deep .mx-input-wrapper {
    input {
      height: 40px;
      border: 1px solid #9e9e9e;
    }
  }
}

.menu-popover {
  position: absolute;
  top: 28px;
  right: 0;
  z-index: 999;
  width: 200px;
  max-width: 300px;
  padding: 10px;
}
</style>
